<template>
  <v-row class="ma-0">
    <v-col cols="2" class="pa-0">
      <v-menu open-on-click right offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            v-if="
              params &&
              params.context &&
              params.context.parentComponent &&
              params.context.parentComponent.permissions &&
              params.context.parentComponent.permissions.change
            "
            v-on="on"
            v-bind="attrs"
            class="mx-2 primary--text"
          >
            mdi-eye
          </v-icon>
        </template>

        <v-list>
          <v-list-item dense @click="integrationResponseBody">
            <v-list-item-title class="text-body-1">
              Response Body
            </v-list-item-title>
          </v-list-item>
          <v-divider />
          <v-list-item dense @click="integrationRequestBody">
            <v-list-item-title class="text-body-1">
              Request Body</v-list-item-title
            >
          </v-list-item>
        </v-list>
      </v-menu>
      <v-dialog v-model="responseModalOpen" width="800">
        <v-card>
          <v-card-title>Response Body</v-card-title>
          <v-card-text>
            <pre class="DynamicDialog">{{ responseBody }}</pre>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" @click="responseModalOpen = false"
              >Close</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="requestModalOpen" width="800">
        <v-card>
          <v-card-title>Request Body</v-card-title>
          <v-card-text>
            <pre class="DynamicDialog">{{ requestBody }}</pre>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" @click="requestModalOpen = false"
              >Close</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>

    <v-col cols="2" class="ma-0 pa-0 pl-3">
      <v-tooltip>
        <template #activator="{ on, attrs }">
          <v-icon
            class="mx-2 primary--text"
            v-on="on"
            v-bind="attrs"
            @click="resendFailedData(params.data)"
            v-if="
              params &&
              params.context &&
              params.context.parentComponent &&
              params.context.parentComponent.permissions &&
              params.context.parentComponent.permissions.change &&
              params.data.resend
            "
          >
            mdi-send
          </v-icon>
        </template>
        <span>Resend</span>
      </v-tooltip>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      responseModalOpen: false,
      requestModalOpen: false,
      responseBody: null,
      requestBody: null,
    };
  },
  methods: {
    resendFailedData(sapData) {
      this.params.context.parentComponent.resendSAPData(
        sapData
      );
    },
    integrationResponseBody() {
      this.responseModalOpen = true;
      this.responseBody = JSON.stringify(
        this.params.data.response_body,
        null,
        2
      );
    },

    integrationRequestBody() {
      this.requestModalOpen = true;
      this.requestBody = JSON.stringify(this.params.data.request_body, null, 2);
    },
  },
};
</script>

<style>
.DynamicDialog {
  white-space: pre-wrap;
  word-wrap: break-word;
  text-align: justify;
}
</style>
