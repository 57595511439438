

<template>
  <v-dialog v-model="viewBidInformation" max-width="500">
    <v-card>
      <v-card-title class="secondary white--text">
        Bid Details {{ formDetails.bid_request_id}}

        <v-spacer></v-spacer>

        <v-btn icon @click="viewBidInformation = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <!-- Static content goes here -->
        <div>
          <div class="info-item info-row">
            <strong>Container Type:</strong>
            {{ formDetails.container_name }}
          </div>
        </div>
        <div class="info-item info-row">
          <strong>Rate:</strong>
          {{ formDetails.rate}}
        </div>
        <div class="info-item info-row">
          <strong>End Date:</strong>
          {{ formDetails.end_date }}
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<style scoped>
.info-row {
  display: flex;
  justify-content: space-between;
}

.info-item {
  flex: 1; /* Equal width for both items */
  margin: 5px 0;
}
</style>

<script>
export default {
  props: {
    value: {
      type: Boolean,
    },
    formDetails: {
      type: Object,
    },
    fieldKey: {
      type: String,
    },
    label: {
      type: String,
    },
    isRequiredRemarks: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    viewBidInformation: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {},
};
</script>
