<template>
  <FormComponent
    title="Edit User"
    backRoute="/app/admin/user-management/list"
    :stickyHeader="true"
  >
    <template #formActions>
      <v-btn color="primary" depressed :disabled="!isValid" @click="submitForm">
        Update
      </v-btn>
    </template>

    <template #formContent>
      <v-form v-model="isValid">
        <v-row class="ma-0">
          <v-col cols="12" sm="6" md="4" lg="3" class="pa-2">
            <InputField
              hide-details="auto"
              type="text"
              v-model="userDetails.username"
              :rules="[(val) => !!val || 'Username is Required!']"
              label="Username *"
              required
              :error-messages="formErrors.username"
              @input="formErrors.username = ''"
            />
          </v-col>

          <v-col cols="12" sm="6" md="4" lg="3" class="pa-2">
            <InputField
              hide-details="auto"
              :type="isTextInput ? 'text' : 'password'"
              label="Password"
              :append-icon="!isTextInput ? 'mdi-eye-off' : 'mdi-eye'"
              required
              v-model="userDetails.password"
              @click:append="isTextInput = !isTextInput"
              :error-messages="formErrors.password"
              @input="formErrors.password = ''"
            />
          </v-col>

          <v-col cols="12" sm="6" md="4" lg="3" class="pa-2">
            <InputField
              hide-details="auto"
              type="text"
              :rules="[(val) => !!val || 'First Name is Required!']"
              label="First Name *"
              required
              v-model="userDetails.first_name"
              :error-messages="formErrors.first_name"
              @input="formErrors.first_name = ''"
            />
          </v-col>

          <v-col cols="12" sm="6" md="4" lg="3" class="pa-2">
            <InputField
              hide-details="auto"
              type="text"
              :rules="[(val) => !!val || 'Last Name is Required!']"
              label="Last Name *"
              required
              v-model="userDetails.last_name"
              :error-messages="formErrors.last_name"
              @input="formErrors.last_name = ''"
            />
          </v-col>

          <v-col cols="12" sm="6" md="4" lg="3" class="pa-2">
            <InputField
              hide-details="auto"
              type="text"
              :rules="[(val) => !!val || 'Designation is Required!']"
              label="Designation *"
              required
              v-model="userDetails.designation"
              :error-messages="formErrors.designation"
              @input="formErrors.designation = ''"
            />
          </v-col>

          <v-col cols="12" sm="6" md="4" lg="4" class="pa-2">
            <div class="d-flex">
              <MultiSelect
                hide-details="auto"
                :items="allPhoneCodeList"
                :rules="[(val) => !!val || 'Phone Code is Required!']"
                label="Phone Code *"
                itemText="phone_code"
                itemValue="phone_code"
                required
                v-model="userDetails.phone_code"
                :error-messages="formErrors.phone_code"
                @input="formErrors.phone_code = ''"
                class="Phone-Code-Class mr-2"
              />

              <InputField
                hide-details="auto"
                type="number"
                :rules="[(val) => !!val || 'Contact Number is Required!']"
                label="Contact Number *"
                required
                v-model="userDetails.contact_number"
                :error-messages="formErrors.contact_number"
                @input="formErrors.contact_number = ''"
              />
            </div>
          </v-col>

          <v-col cols="12" sm="6" md="4" lg="3" class="pa-2">
            <InputField
              hide-details="auto"
              type="text"
              :rules="[
                (val) => !!val || 'Email is Required!',
                (val) => /.+@.+\..+/.test(val) || 'Email must be valid',
              ]"
              label="Email *"
              required
              v-model="userDetails.email"
              :error-messages="formErrors.email"
              @input="formErrors.email = ''"
            />
          </v-col>

          <v-col
            cols="12"
            sm="6"
            md="4"
            lg="3"
            class="pa-2"
            v-if="userDetails.user_type == 'Admin'"
          >
            <MultiSelect
              label="Select Branch *"
              :items="allBranchList"
              itemText="branch_title"
              itemValue="id"
              :rules="[(val) => !!val || 'Branch is Required!']"
              hide-details="auto"
              v-model="userDetails.branch"
              :error-messages="formErrors.branch"
              @input="delete formErrors.branch"
            ></MultiSelect>
          </v-col>

          <v-col
            cols="12"
            sm="6"
            md="4"
            lg="3"
            class="pa-2"
            v-if="userDetails.user_type == 'Admin'"
          >
            <MultiSelect
              label="Select Depot *"
              :items="allDepotList"
              itemText="depot_name"
              itemValue="id"
              :rules="[(val) => !!val || 'Depot is Required!']"
              hide-details="auto"
              v-model="userDetails.depot"
              :error-messages="formErrors.depot"
              @input="delete formErrors.depot"
            ></MultiSelect>
          </v-col>

          <v-col cols="12" sm="6" md="4" lg="2" class="pa-2">
            <v-switch
              hide-details="auto"
              v-model="userDetails.is_active"
              :label="`Active : ${
                userDetails.is_active == 'Yes' ? 'Yes' : 'No'
              }`"
              true-value="Yes"
              false-value="No"
              class="mt-1"
              :error-messages="formErrors.is_active"
              @change="formErrors.is_active = ''"
            ></v-switch>
          </v-col>

          <v-col
            cols="12"
            sm="6"
            md="4"
            lg="3"
            class="pa-2"
            v-if="userDetails.user_type == 'Admin'"
          >
            <v-checkbox
              hide-details="auto"
              label="Primary User"
              true-value="Yes"
              false-value="No"
              class="mt-1"
              v-model="userDetails.is_primary"
              :error-messages="formErrors.is_primary"
              @change="formErrors.is_primary = ''"
            ></v-checkbox>
          </v-col>

          <v-col
            cols="12"
            sm="6"
            md="4"
            lg="3"
            class="pa-2"
            v-if="!userDetails.is_primary || userDetails.is_primary == 'No'"
          >
            <MultiSelect
              hide-details="auto"
              :items="allRoles"
              :rules="[(val) => !!val || 'Role is Required!']"
              label="User Role *"
              item-text="role_name"
              item-value="id"
              required
              v-model="userDetails.role"
              :error-messages="formErrors.role"
              @change="formErrors.role_name = ''"
            />
          </v-col>
        </v-row>
      </v-form>
    </template>
  </FormComponent>
</template>

<script>
import InputField from "@/components/FormBaseComponents/InputField.vue";
import SelectField from "@/components/FormBaseComponents/SelectField.vue";
import FormComponent from "@/components/General/FormComponent.vue";
import MultiSelect from "@/components/FormBaseComponents/MultiSelect.vue";
import {
  isAuthenticated,
  hasRouteAccess,
  getPermissions,
  encryptText,
} from "@/utils/functions";

export default {
  components: { FormComponent, InputField, SelectField, MultiSelect },
  data() {
    return {
      checkbox: true,
      isTextInput: false,
      allRoles: [],
      allPhoneCodeList: [],
      allDepotList: [],
      allBranchList: [],

      userDetails: {
        is_primary: "Yes",
        is_active: "Yes",
      },
      isValid: true,
      formErrors: {},
    };
  },

  computed: {
    currentProfile() {
      let obj = JSON.parse(localStorage.getItem("userProfile"));
      if (!obj) {
        return null;
      } else {
        return obj;
      }
    },
  },

  methods: {
    getUserObject(id) {
      this.$bus.$emit("showLoader", true);
      this.$api.user
        .getUserObject(id)
        .then((res) => {
          this.userDetails = res.data;
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },

    getRoleList(params = {}) {
      this.$bus.$emit("showLoader", true);
      params = {
        ...params,
        company: this.currentProfile.company,
        limit: "all",
      };
      this.$api.role
        .getRoleList(params)
        .then((res) => {
          this.allRoles = res.data;
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },

    getPhoneCodeList(params = {}) {
      this.$bus.$emit("showLoader", true);
      params = {
        ...params,
        limit: "all",
      };
      this.$api.country
        .getCountryList(params)
        .then((res) => {
          this.allPhoneCodeList = res.data;
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },

    submitForm() {
      this.$bus.$emit("showLoader", true);
      let payload = {
        id: this.userDetails.id,
        data: this.userDetails,
      };
      if (this.userDetails && this.userDetails.password) {
        payload.data.password = encryptText(this.userDetails.password);
      }
      this.$api.user
        .updateUser(payload)
        .then((res) => {
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message: "User Updated Successfully!",
            color: "success",
          });
          this.$router.push("/app/admin/user-management/list");
        })
        .catch((err) => {
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message: "Couldn't Update User!",
            color: "error",
          });
          this.formErrors = err.data;
        });
    },
    getBranchList(params = {}) {
      this.$bus.$emit("showLoader", true);
      params = {
        limit: "all",
        ...params,
      };
      this.$api.branch
        .getBranchSelectList(params)
        .then((res) => {
          this.$bus.$emit("showLoader", false);
          this.allBranchList = res.data;
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    getDepotList(params = {}) {
      this.$bus.$emit("showLoader", true);
      params = {
        limit: "all",
        ...params,
      };
      this.$api.depot
        .getDepotSelectList(params)
        .then((res) => {
          this.$bus.$emit("showLoader", false);
          this.allDepotList = res.data;
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
  },
  beforeMount() {
    if (!isAuthenticated()) {
      this.$router.push("/");
    } else if (!hasRouteAccess(this.$route)) {
      this.$router.push(`/${localStorage.getItem("user_type").toLowerCase()}`);
    } else {
      const permissions = getPermissions("user");
      if (!permissions || !permissions.change) {
        this.$bus.$emit("showToastMessage", {
          message: "You don't have access to this page",
          color: "error",
        });
        this.$router.push(
          `/app/${
            localStorage.getItem("user_type")
              ? localStorage.getItem("user_type").toLowerCase()
              : "error"
          }`
        );
      } else {
        this.getRoleList();
        this.getPhoneCodeList();
        this.getBranchList();
        this.getDepotList();
        this.getUserObject(this.$route.params.id);
      }
    }
  },
};
</script>
