<template>
  <ListComponent title="Requested Quotations">
    <template #list>
      <v-row class="ma-0">
        <v-col cols="4" class="px-0 pt-0 pb-2">
          <v-text-field
            outlined
            dense
            v-model="filters.search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            clearable
            @input="searchRole"
          ></v-text-field>
        </v-col>
        <v-col cols="4" class="px-0 pt-0 pb-2">
          <v-tabs v-model="tab" height="35" centered>
            <v-tabs-slider></v-tabs-slider>
            <v-tab> Pending </v-tab>
            <v-tab> Acknowledged </v-tab>
            <v-tab> Approved </v-tab>
            <v-tab> Rejected </v-tab>
            <v-tab> Cancelled </v-tab>
          </v-tabs>
        </v-col>
        <v-col
          v-if="totalItems > 0"
          cols="4"
          class="px-0 pt-0 pb-2 d-flex justify-end"
        >
          <Pagination
            :pageNo="pageNo"
            :totalItems="totalItems"
            :pageSize="itemsPerPage"
            @itemsPerPageChange="itemsPerPageChanged"
            @prevPage="prevPage"
            @nextPage="nextPage"
          />
        </v-col>
        <v-col cols="12" class="pa-0">
          <AgGridVue
            style="width: 100%; height: calc(100vh - 172px)"
            class="ag-theme-alpine"
            :columnDefs="headers"
            :context="context"
            @grid-ready="onGridReady"
            :defaultColDef="defaultColDef"
            :grid-options="gridOptions"
            :suppressDragLeaveHidesColumns="true"
            :rowData="quotationList"
          ></AgGridVue>
        </v-col>
      </v-row>
      <DetailsParent :resizable="true" v-model="displayDrawer">
        <template #details>
          <CustomerQuotationDetails
            @submitResp="showConfirmationDialog"
            :q_id="selectedQuotationId"
            :permissions="permissions"
          />
        </template>
      </DetailsParent>

      <RejectionRemarksDialog
        v-model="showRemarksForm"
        :formDetails="quotationDetails"
        :isRequiredRemarks="!isAccepted"
        fieldKey="customer_remarks"
        :label="`${!isAccepted ? 'Rejection' : 'Acceptance'} Remarks `"
        @submitRemarks="submitForm"
      />
    </template>
    <template #actions>
      <v-btn
        v-if="permissions && permissions.add"
        color="primary"
        depressed
        @click="requestQuotation"
      >
        <v-icon>mdi-plus</v-icon>
        <span>Request Quotation</span>
      </v-btn>
    </template>
  </ListComponent>
</template>

<script>
import ListComponent from "@/components/General/ListComponent.vue";
import { AgGridVue } from "ag-grid-vue";
import Pagination from "@/components/General/Pagination.vue";
import QuotationButton from "@/components/AgGridComponents/QuotationEditButton.vue";
import DetailsParent from "@/components/General/DetailsParent.vue";
import CustomerQuotationDetails from "@/components/ModuleBased/details/QuotationDetails.vue";
import RejectionRemarksDialog from "@/components/ModuleBased/dialogs/RejectionRemarksDialog.vue";

import {
  isAuthenticated,
  hasRouteAccess,
  getPermissions,
} from "@/utils/functions";

export default {
  components: {
    ListComponent,
    CustomerQuotationDetails,
    AgGridVue,
    Pagination,
    QuotationButton,
    DetailsParent,
    RejectionRemarksDialog,
  },
  data() {
    return {
      displayDrawer: false,
      showRemarksForm: false,
      isAccepted: false,
      quotationDetails: {},
      sorting: {},
      headers: [
        {
          headerName: "Quotation ID",
          field: "quotation_id",
          sortable: true,
        },
        {
          headerName: "Source",
          field: "source_name",
          sortable: true,
        },
        {
          headerName: "Source Country",
          field: "source_country",
          sortable: true,
        },
        {
          headerName: "Destination",
          field: "destination_name",
          sortable: true,
        },
        {
          headerName: "Destination Country",
          field: "destination_country",
          sortable: true,
        },
        {
          headerName: "Start Date",
          field: "start_date",
          sortable: true,
          valueFormatter: (params) => {
            return this.$globals.formatDate(params.value);
          },
        },
        {
          headerName: "End Date",
          field: "end_date",
          sortable: true,
          valueFormatter: (params) => {
            return this.$globals.formatDate(params.value);
          },
        },
        {
          headerName: "Distance",
          field: "distance",
          sortable: true,
        },

        {
          headerName: "Service Type",
          field: "service_type",
          sortable: true,
        },
        {
          headerName: "Job Type",
          field: "job_type",
        },
        {
          headerName: "Actions",
          field: "actions",
          cellRendererFramework: "QuotationButton",
          pinned: "right",
        },
      ],
      quotationList: [],

      pageNo: 1,
      itemsPerPage: 20,
      totalItems: 0,
      filters: {},

      headerSelected: [],
      statuses: ["Pending", "Sent", "Approved", "Rejected", "Cancelled"],
      tab: 0,

      // ag-grid vars
      gridApi: null,
      gridColumnApi: null,
      defaultColDef: {
        width: 150,
        resizable: true,
      },
      gridOptions: {
        onGridSizeChanged: () => {
          this.resizeGrid();
        },
        suppressRowClickSelection: true,
        suppressDragLeaveHidesColumns: true,
        enableCellTextSelection: true,
        tooltipShowDelay: 0,
        rowHeight: 35,
      },

      permissions: null,
      selectedQuotationId: null,
    };
  },
  computed: {
    context() {
      return { parentComponent: this };
    },
    currentProfile() {
      let obj = JSON.parse(localStorage.getItem("userProfile"));
      if (obj) {
        return obj;
      } else {
        return null;
      }
    },
    offset() {
      return this.itemsPerPage * (this.pageNo - 1);
    },
  },
  watch: {
    tab() {
      this.getQuotationList();
      this.displayDrawer = false;
    },
  },
  methods: {
    applyGridSort(key, type) {
      if (this.filters && !this.filters.ordering) {
        this.filters.ordering = [];
      }

      if (type == null) {
        delete this.sorting[key];
        this.filters.ordering.splice(
          this.filters.ordering.indexOf(`-${key}`),
          1
        );
      } else if (type == "asc") {
        this.filters.ordering.push(key);
      } else if (type == "desc") {
        this.filters.ordering.splice(
          this.filters.ordering.indexOf(key),
          1,
          `-${key}`
        );
      }
      if (this.filters.ordering.length == 0) {
        delete this.filters.ordering;
      }
      this.itemsPerPageChanged(20);
    },
    showConfirmationDialog(isAccepted) {
      this.isAccepted = isAccepted;
      this.showRemarksForm = true;
    },
    submitForm() {
      this.showRemarksForm = false;
      this.$bus.$emit("showLoader", true);
      this.quotationDetails.customer = this.currentProfile.company;

      let payload = {
        id: this.selectedQuotationId,
        data: {
          customer_remarks: this.quotationDetails.customer_remarks,
        },
      };
      if (this.isAccepted) {
        payload.data.status = "Approved";
      } else {
        payload.data.status = "Rejected";
      }

      this.$api.quotation
        .partiallyUpdateQuotation(payload)
        .then((res) => {
          this.getQuotationList();
          this.displayDrawer = false;
          this.$bus.$emit("showLoader", false);
          if (this.isAccepted) {
            this.$bus.$emit("showToastMessage", {
              message: "Quotation Update Successfully",
              color: "success",
            });
          } else {
            this.$bus.$emit("showToastMessage", {
              message: "Quotation Rejected Successfully",
              color: "warning",
            });
          }

          this.$router.push("/app/customer/rate/quotation/list");

          delete this.quotationDetails.customer_remarks;
        })
        .catch((err) => {
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message: "Couldn't Update Request Quotation!",
            color: "error",
          });
          this.formErrors = err.data;
        });
    },
    resizeGrid() {
      if (this.gridOptions && "api" in this.gridOptions) {
        setTimeout(() => {
          this.gridOptions.api.sizeColumnsToFit();
        }, 100);
      }
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.resizeGrid();
    },
    searchRole() {
      this.pageNo = 1;
      this.getQuotationList();
    },
    itemsPerPageChanged(e) {
      this.pageNo = 1;
      this.itemsPerPage = e;
      this.getQuotationList();
    },
    prevPage() {
      this.pageNo--;
      this.getQuotationList();
    },
    nextPage() {
      this.pageNo++;
      this.getQuotationList();
    },
    getQuotationList() {
      this.$bus.$emit("showLoader", true);
      const params = {
        status: this.statuses[this.tab],
        customer: this.currentProfile.company,
        offset: this.offset,
        limit: this.itemsPerPage,
        ...this.filters,
      };
      if ("ordering" in params && params.ordering.length > 0) {
        params = { ...params, ordering: params.ordering.join(",") };
      }
      this.$api.quotation
        .getQuotationList(params)
        .then((res) => {
          this.quotationList = res.data;
          this.totalItems = res.count;
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    viewDetails(id) {
      this.selectedQuotationId = id;
      this.displayDrawer = true;
    },
    requestQuotation() {
      this.$router.push("/app/customer/rate/quotation/request");
    },
    editQuotation(id) {
      this.$router.push(`/app/customer/rate/quotation/edit/${id}`);
    },
  },
  beforeMount() {
    if (!isAuthenticated()) {
      this.$router.push("/");
    } else if (!hasRouteAccess(this.$route)) {
      this.$router.push(`/${localStorage.getItem("user_type").toLowerCase()}`);
    } else {
      const permissions = getPermissions("rate_enquiry");
      if (!permissions || !permissions.view) {
        this.$bus.$emit("showToastMessage", {
          message: "You don't have access to this page",
          color: "error",
        });
        this.$router.push(
          `/app/${
            localStorage.getItem("user_type")
              ? localStorage.getItem("user_type").toLowerCase()
              : "error"
          }`
        );
      } else {
        this.permissions = { ...permissions };
        this.getQuotationList();
      }
    }
  },
};
</script>
