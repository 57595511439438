import { subHire } from "@/utils/constants";
import { handleError, handleResponse } from "@/utils/functions";

export default (axios) => ({
  getLegListFromLane(id, params = {}) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${subHire.subHireLegList}${id}/`, {
          params: params,
        })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getSubHireList(params = {}) {
    return new Promise((resolve, reject) => {
      axios
        .get(subHire.subHireBase, {
          params: params,
        })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getSubHireDetails(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${subHire.subHireBase}${id}/view/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getSubHireObject(params = {}) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${subHire.subHireBase}${params.id}/`, {                                                                                                                                                                                                                                                                                         
          params: params,
        })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  submitSubhireData(data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${subHire.subHireBase}`, data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  updateSubHireJob(payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${subHire.subHireBase}${payload.id}/`, payload.data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  approvedSubhire(data) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`${subHire.subHireBase}${id}/approved_sub_hire_job/`, {
          data
        })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  approvedToPending(sub_hire_ids) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`${subHire.subHireBase}status_approved_to_pending/`, {
          sub_hire_ids
        })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  subhireStatusUpdate(id, data) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`${subHire.subHireBase}${id}/approved_sub_hire_job/`, {
          data
        })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getSubHireJObLogs(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${subHire.subHireBase}${id}/sub_hire_job_logs/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getTransporterSubHireList(params = {}) {
    return new Promise((resolve, reject) => {
      axios
        .get(subHire.transporterSubHireBase, {
          params: params,
        })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getTransporterSubHireDetail(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${subHire.transporterSubHireBase}${id}/view/`)
        .then((res) => {
          resolve(handleResponse(res))
        })
        .catch((err) => {
          reject(handleError(err))
        })
    })
  },
  updateTransporterAsset(payload) {
    
    return new Promise((resolve, reject) => {
      axios
        .patch(`${subHire.transporterSubHireBase}subhire_legs_asset_details/`, payload)
        .then((res) => {
          resolve(handleResponse(res))
        })
        .catch((err) => {
          reject(handleError(err))
        })
    })
  },

});
