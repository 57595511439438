<template>
  <div>
    <v-row class="heading ma-0">
      <v-col cols="12" lg="4" class="d-flex">
        <v-tooltip top>
          <template #activator="{ on, attrs }">
            <v-btn
              @click="callBackRoute"
              icon
              v-bind="attrs"
              v-on="on"
              class="mr-1"
            >
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
          </template>
          <span>Back</span>
        </v-tooltip>
        <div>
          <div>
            <Tooltip
              tooltipTitle="LRFQ"
              v-if="lrfqDetails.lrfq_id"
              :tooltipText="lrfqDetails.lrfq_id"
              top
              tooltipClass="text-h5"
            />
            <span class="text-h5">&nbsp;|&nbsp;</span>
            <Tooltip
              tooltipTitle="Job Type"
              v-if="lrfqDetails.job_type"
              :tooltipText="lrfqDetails.job_type"
              top
              tooltipClass="text-h5"
            />
            <span class="text-h5">&nbsp;|&nbsp;</span>
            <Tooltip
              tooltipTitle="Status"
              v-if="lrfqDetails.status"
              :tooltipText="lrfqDetails.status"
              top
              tooltipClass="text-h5"
            />
          </div>
          <div v-if="lrfqDetails.end_date">
            <span class="text-caption">End Date &amp; Time </span>
            <span class="font-weight-medium">
              {{ lrfqDetails.end_date | formatDate(true) }}
            </span>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-row class="ma-0">
      <v-col cols="12" class="py-2">
        <v-row class="ma-0">
          <v-col cols="12" lg="12" class="py-1 pl-1">
            <v-card>
              <v-row>
                <v-col cols="12" sm="12">
                  <v-card>
                    <v-card-title class="primary white--text">
                      <span>Enter Rates </span>
                    </v-card-title>
                    <v-card-text>
                      <v-form v-model="isValid" ref="bidRequestForm">
                        <v-row
                          v-for="(lrfq, index) in allLrfqList"
                          :key="index"
                        >
                          <v-col cols="12" sm="1">
                            <v-text-field
                              v-model="formData.lrfqRequests[index]"
                              :label="'Lrfq Request Id'"
                              :value="lrfq.lrfq_request_id"
                              disabled
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="3">
                            <v-text-field
                              v-model="formData.source_location[index]"
                              :label="'Source Location'"
                              :value="lrfq.source_location"
                              disabled
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="2">
                            <v-text-field
                              v-model="formData.mid_location[index]"
                              :label="'Mid Location'"
                              :value="lrfq.mid_location"
                              disabled
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="2">
                            <v-text-field
                              v-model="formData.destination_location[index]"
                              :label="'Destination Location'"
                              :value="lrfq.destination_location"
                              disabled
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="1">
                            <v-text-field
                              v-model="formData.container_type[index]"
                              :label="'Container Type'"
                              :value="lrfq.container_type"
                              disabled
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="1">
                            <v-text-field
                              v-model="formData.container_count[index]"
                              :label="'Container Count'"
                              :value="lrfq.container_count"
                              disabled
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="1">
                            <v-text-field
                              v-model="formData.status[index]"
                              :label="'Status'"
                              :value="lrfq.status"
                              disabled
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="1">
                            <div class="flex-container">
                              <v-text-field
                                v-model="formData.rates[index]"
                                :label="'Rate'"
                                :readonly="lrfq.status=='Participated'"
                              ></v-text-field>
                              <v-btn
                                icon
                                @click="copyToClipboard(formData.rates[index])"
                              >
                                <v-icon>mdi-content-copy</v-icon>
                              </v-btn>
                            </div>
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions class="justify-end">
                      <v-btn
                        depressed
                        color="primary"
                        :disabled="!isValid"
                        @click="submitForm"
                      >
                        <span>Submit</span>
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-row>
              <v-spacer></v-spacer>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-app> </v-app>
  </div>
</template>

<script>
import Tooltip from "@/components/General/Tooltip.vue";
export default {
  components: {
    Tooltip,
  },

  data() {
    return {
      isValid: true,
      lrfqDetails: {},
      allLrfqList: [],
      formData: {
        rates: [], // Initialize as an empty array
        lrfqRequests: [],
        container_type: [],
        container_count: [],
        source_location: [],
        mid_location: [],
        destination_location: [],
        status: [],
      },
      backRoute: { path: "/app/transporter/lrfq/list" },
    };
  },
  computed: {
    currentProfile() {
      let obj = JSON.parse(localStorage.getItem("userProfile"));
      return obj || null;
    },
  },
  methods: {
    copyToClipboard(value) {
      const textField = document.createElement("textarea");
      textField.innerText = value;
      document.body.appendChild(textField);
      textField.select();
      document.execCommand("copy");
      textField.remove();

      this.$bus.$emit("showToastMessage", {
        message: "Copied to clipboard",
        color: "success",
      });
    },
    submitForm() {
      // Handle form submission
      const formDataArray = [];

      for (let i = 0; i < this.allLrfqList.length; i++) {
        const formDataObj = {
          lrfq_requests: this.formData.lrfqRequests[i],
          rates: this.formData.rates[i],
        };

        formDataArray.push(formDataObj);
      }
      this.$bus.$emit("showLoader", true);
      this.$api.lrfq
        .updateLrfqLaneRates(formDataArray)
        .then((res) => {
          this.$bus.$emit("showLoader", false);
          this.$router.push("/app/transporter/lrfq/list");
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    async getLrfqDetails(params = {}) {
      this.$bus.$emit("showLoader", true);

      params = {
        ...params,
        transporter: this.currentProfile.company,
      };
      await this.$api.lrfq
        .getTransporterLrfqDetails(this.$route.params.id, params)
        .then((res) => {
          this.lrfqDetails = res.data;

          this.allLrfqList = res.data.lrfq_request;

          this.allLrfqList = this.allLrfqList.map((lrfq) => {
            const {
              lrfq_request_id,
              source_location,
              mid_location,
              destination_location,
              container_type_name,
              container_count,
              rate,
              status,
            } = lrfq;
            return {
              lrfq_request_id,
              source_location,
              mid_location,
              destination_location,
              container_type_name,
              container_count,
              rate,
              status,
            };
          });
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message: "Bid Detail not found!",
            color: "error",
          });
        });
    },
    callBackRoute() {
      if (Object.keys(this.backRoute).length === 0) {
        this.$router.push("/app/transporter/lrfq/list");
      } else {
        this.$router.push(this.backRoute);
      }
    },
    setBackRoute() {
      if ("backRoute" in this.$route.query) {
        this.backRoute.path = this.$route.query.backRoute;
      }
      if ("active_tab" in this.$route.query) {
        if (!this.backRoute.query) {
          this.backRoute.query = {};
        }
        this.backRoute.query.active_tab = this.$route.query.active_tab;
      }
    },
  },
  beforeMount() {
    this.setBackRoute();
  },

  async created() {
    await this.getLrfqDetails()
      .then(() => {
        if (!this.allLrfqList || this.allLrfqList.length === 0) {
          console.error("No data available in allLrfqList.");
          return;
        }
        this.allLrfqList.forEach((lrfq, i) => {
          this.formData.rates.push(lrfq.rate);
          this.formData.lrfqRequests.push(lrfq.lrfq_request_id);
          this.formData.container_type.push(lrfq.container_type_name);
          this.formData.container_count.push(lrfq.container_count);
          this.formData.source_location.push(lrfq.source_location);
          this.formData.mid_location.push(lrfq.mid_location);
          this.formData.destination_location.push(lrfq.destination_location);
          this.formData.status.push(lrfq.status);
        });
      })
      .catch((error) => {
        console.error("Error fetching data: " + error);
      });
    // Add created() lifecycle hook
  },
};
</script>
<style scoped>
.flex-container {
  display: flex;
  align-items: center;
}
.flex-container v-text-field {
  flex: 1; /* Allow text field to grow and take remaining space */
  margin-right: 8px; /* Add some spacing between text field and button */
}
</style>
 





