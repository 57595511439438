<template>
  <v-card class="fill-height">
    <v-card-title class="heading mb-4">
      <Tooltip
        tooltipTitle="Quotation Id"
        v-if="quotationDetails.quotation_id"
        :tooltipText="quotationDetails.quotation_id"
        right
        tooltipClass="text-h5 font-weight-medium"
      />
    </v-card-title>

    <v-card-subtitle
      class="subHeading py-2"
      v-if="!hideActions && quotationDetails.status == 'Sent'"
    >
      <div class="d-flex">
        <span class="text-subtitle-1 font-weight-medium">
          Quotation Rates
        </span>

        <v-spacer></v-spacer>

        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-btn
              class="mr-2"
              depressed
              color="success"
              v-on="on"
              v-bind="attrs"
              small
              @click="$emit('submitResp', true)"
              v-if="permissions && permissions.add"
            >
              <v-icon> mdi-check </v-icon>
            </v-btn>
          </template>
          <span>Accept Rates</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-btn
              depressed
              color="error"
              v-on="on"
              v-bind="attrs"
              small
              @click="$emit('submitResp', false)"
              v-if="permissions && permissions.add"
            >
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </template>
          <span>Reject Rates</span>
        </v-tooltip>
      </div>
    </v-card-subtitle>

    <v-card-text class="mt-2">
      <v-tabs fixed-tabs v-model="detailsTab" class="mb-2">
        <v-tabs-slider color="white"></v-tabs-slider>
        <v-tab>Details</v-tab>
        <v-tab>Logs</v-tab>
      </v-tabs>

      <v-tabs-items v-model="detailsTab">
        <v-tab-item
          :class="
            !hideActions && quotationDetails.status == 'Sent'
              ? 'Height-Class'
              : 'Sent-Height-Class'
          "
        >
          <div
            class="mb-3"
            v-if="
              'quotation_rates' in quotationDetails &&
              quotationDetails.quotation_rates.length > 0
            "
          >
            <span class="text-h6 font-weight-bold">Quotation Rates</span>
            <v-divider class="mb-2"></v-divider>

            <v-expansion-panels
              multiple
              focusable
              v-model="quotationRateExpansion"
            >
              <CardDetails
                v-for="(obj, index) in quotationDetails.quotation_rates"
                :key="index"
                :title="`Quotation Rate ${index + 1}`"
                :displayObject="obj"
              />
            </v-expansion-panels>
          </div>

          <span class="text-h6 font-weight-bold">Other Details</span>
          <v-divider class="mb-2"></v-divider>

          <v-expansion-panels
            multiple
            focusable
            v-model="quotationDetailsExpansion"
          >
            <CardDetails
              v-if="quotationDetails.customer_info"
              title="Customer Details"
              :displayObject="quotationDetails.customer_info"
            />

            <CardDetails
              v-if="
                quotationDetails.contract_detail &&
                Object.keys(quotationDetails.contract_detail).length
              "
              title="Tariff Details"
              :displayObject="quotationDetails.contract_detail"
            />

            <CardDetails
              v-if="quotationDetails.source_detail"
              title="Source Details"
              :displayObject="quotationDetails.source_detail"
            />

            <CardDetails
              v-if="quotationDetails.mid_detail"
              title="Mid Details"
              :displayObject="quotationDetails.mid_detail"
            />

            <CardDetails
              v-if="quotationDetails.destination_detail"
              title="Destination Details"
              :displayObject="quotationDetails.destination_detail"
            />

            <CardDetails
              v-if="quotationDetails.remarks_detail"
              title="Remarks Details"
              :displayObject="quotationDetails.remarks_detail"
            />

            <CardDetails
              v-if="quotationDetails.extra_info"
              title="Extra Details"
              :displayObject="quotationDetails.extra_info"
            />
          </v-expansion-panels>
        </v-tab-item>

        <v-tab-item>
          <v-sheet>
            <v-timeline v-if="logs && logs.length" class="px-0" align-top dense>
              <v-timeline-item
                v-for="(log, index) in logs"
                :key="index"
                color="primary"
                icon="mdi-circle"
                small
                class="pb-3"
              >
                <v-card class="elevation-2">
                  <v-card-text>
                    <table class="Full-Width">
                      <tbody>
                        <tr>
                          <th class="text-left">{{ log.updated_by }}</th>
                          <td class="text-caption text-right">
                            {{ log.created | formatDate(true) }}
                          </td>
                        </tr>
                        <tr>
                          <td colspan="2">{{ log.message }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </v-card-text>
                </v-card>
              </v-timeline-item>
            </v-timeline>
            <div class="text-center mt-8" v-else>No Logs to show</div>
          </v-sheet>
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
  </v-card>
</template>

<script>
import CardDetails from "@/components/General/CardDetails.vue";
import Tooltip from "@/components/General/Tooltip.vue";

export default {
  components: { CardDetails, Tooltip },
  props: {
    q_id: {
      type: Number,
    },
    hideActions: {
      type: Boolean,
      default: false,
    },
    permissions: {
      type: Object,
      default: () => ({}),
    },
  },
  watch: {
    q_id(val) {
      this.logs = [];
      this.getCustomerquotationDetails(val);
      this.quotationActivityLogs();
    },
  },
  data() {
    return {
      quotationDetails: {},

      quotationRateExpansion: [],
      quotationDetailsExpansion: [0, 1, 2, 3, 4, 5],

      detailsTab: 0,
      logs: [],
    };
  },
  methods: {
    getCustomerquotationDetails(id) {
      this.$bus.$emit("showLoader", true);
      this.$api.quotation
        .getQuotationDetails(id)
        .then((res) => {
          this.quotationDetails = res.data;
          if (this.quotationDetails.quotation_rates) {
            this.quotationDetails.quotation_rates.forEach((val, index) => {
              this.quotationRateExpansion.push(index);
              
            });
          }
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    quotationActivityLogs(params = {}) {
      this.$bus.$emit("showLoader", true);
      params = {
        ...params,
        quotation: this.q_id,
        limit: "50",
        offset: this.logs.length,
      };
      this.$api.activityLogs
        .quotationActivityLogs(params)
        .then((res) => {
          this.logs.push(...res.data);
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
  },
  mounted() {
    this.logs = [];
    this.quotationRateExpansion = [];
    this.getCustomerquotationDetails(this.q_id);
    this.quotationActivityLogs();
  },
};
</script>

<style scoped>
.Height-Class {
  height: calc(100vh - 288px);
  overflow-y: auto;
}

.Sent-Height-Class {
  height: calc(100vh - 252px);
  overflow-y: auto;
}
</style>
