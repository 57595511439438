import Blank from "@/layouts/blank.vue"
import CustomerInvoiceList from "@/pages/admin/invoice/customer/CustomerInvoiceList.vue";
import CustomerInvoiceCreate from "@/pages/admin/invoice/customer/CustomerInvoiceCreate.vue";
import MiscellaneousInvoiceCreate from "@/pages/admin/invoice/customer/MiscellaneousInvoiceCreate.vue";
import customerInvoiceCreateCreditNote from "@/pages/admin/invoice/customer/customerInvoiceCreateCreditNote.vue";
import CustomerInvoiceCostRevenueDetails from "@/pages/admin/invoice/customer/CustomerInvoiceCostRevenueDetails.vue";

import CustomerInvoiceDetails from "@/pages/admin/invoice/customer/CustomerInvoiceDetails.vue";
// import CustomerInvoiceEdit from "@/pages/admin/invoice/customer/CustomerInvoiceEdit.vue";
import NewCustomerInvoiceEdit from "@/pages/admin/invoice/customer/NewCustomerInvoiceEdit.vue";
import MiscellaneousInvoiceEdit from "@/pages/admin/invoice/customer/MiscellaneousInvoiceEdit.vue";
import BulkCustomerInvoiceLogsList from "@/pages/admin/invoice/customer/BulkCustomerInvoiceLogsList.vue";

const customerInvoiceRoutes = [
  {
    path: "customer",
    name: "app-admin-invoice-customer",
    component: Blank,
    children: [
      {
        path: "/",
        redirect: "/app/admin/invoice/customer/list",
      },
      {
        path: "list",
        name: "app-admin-invoice-customer-list",
        component: CustomerInvoiceList,
      },
      {
        path: "bulk-invoice-logs",
        name: "app-admin-invoice-customer-bulk-invoice-logs",
        component: BulkCustomerInvoiceLogsList,
      },
      
      {
        path: "create",
        name: "app-admin-invoice-customer-create",
        component: CustomerInvoiceCreate,
      },
      {
        path: "create-miscellaneous",
        name: "app-admin-invoice-customer-create",
        component: MiscellaneousInvoiceCreate,
      },
      {
        path: ":id",
        name: "app-admin-invoice-customer-id",
        component: Blank,
        
        children: [
          {
            path: "/",
            redirect: "/app/admin/invoice/customer/list",
          },
          {
            path: "create-credit-note",
            name: "app-admin-invoice-customer-id-create-credit-note",
            component: customerInvoiceCreateCreditNote,
          }
        ]
      },
      {
        path: "details",
        name: "app-admin-invoice-customer-details",
        component: Blank,
        children: [
          {
            path: "/",
            redirect: "/app/admin/invoice/customer/list",
          },
          {
            path: ":id",
            name: "app-admin-invoice-customer-details-id",
            component: CustomerInvoiceDetails,
          }
        ]
      },

      // {
      //   path: "edit",
      //   name: "app-admin-invoice-customer-edit",
      //   component: Blank,
      //   children: [
      //     {
      //       path: "/",
      //       redirect: "/app/admin/invoice/customer/list",
      //     },
      //     {
      //       path: ":id",
      //       name: "app-admin-invoice-customer-edit-id",
      //       component: CustomerInvoiceEdit,
      //     }
      //   ]
      // },
      {
        path: "detailView",
        name: "app-admin-invoice-customer-detail-view",
        component: Blank,
        children: [
          {
            path: "/",
            redirect: "/app/admin/invoice/customer/list",
          },
          {
            path: ":id",
            name: "app-admin-invoice-customer-detail-view-id",
            component: CustomerInvoiceCostRevenueDetails,
          },
        ],
      },
      {
        path: "edit",
        name: "app-admin-invoice-customer-edit",
        component: Blank,
        children: [
          {
            path: "/",
            redirect: "/app/admin/invoice/customer/list",
          },
          {
            path: ":id",
            name: "app-admin-invoice-customer-edit-id",
            component: NewCustomerInvoiceEdit,
          }
        ]
      },
      {
        path: "edit-miscellaneous",
        name: "app-admin-invoice-customer-edit-miscellaneous",
        component: Blank,
        children: [
          {
            path: "/",
            redirect: "/app/admin/invoice/customer/list",
          },
          {
            path: ":id",
            name: "app-admin-invoice-customer-edit-miscellaneous-id",
            component: MiscellaneousInvoiceEdit,
          }
        ]
      },
    ]
  },
]

export default customerInvoiceRoutes;