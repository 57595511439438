const globals = {
  currency: "AED",
  country: "United Arab Emirates",
  emailRegExp: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/,
  containerNumberRegExp: /^((\s)*[a-zA-Z]{4})[0-9]{7}?$/,
  containerWeightFormatRegExp: /^\d{1,2}(\.\d{1,2})?$/,
  twoDecimalPlacesRegExp: /^[0-9]*(\.[0-9]{0,2})?$/,
  fourDecimalPlacesRegExp: /^[0-9]*(\.[0-9]{0,4})?$/,
  eightDigitsTwoDecimalPlacesRegExp: /^\d{1,8}(\.\d{1,2})?$/,
  numbersOnlyRegExp: /^[0-9]+$/,
  lettersOnlyRegExp: /^[a-zA-Z\s]*$/,
  formatDate: (value, withTime) => {
    if (value) {
      const _date = new Date(value)

      if (_date) {

        let date = _date.getDate()
        let month = _date.getMonth() + 1
        let year = _date.getFullYear()

        date = date < 10 ? `0${date}` : `${date}`
        month = month < 10 ? `0${month}` : `${month}`

        let fullDate = [date, month, year].join("/")
        let time = []
        if (withTime) {
          let hour = _date.getHours()
          let minute = _date.getMinutes()

          hour = hour < 10 ? `0${hour}` : `${hour}`
          minute = minute < 10 ? `0${minute}` : `${minute}`

          time = [hour, minute].join(":")

          fullDate = [fullDate, time].join(" ")
        }

        return fullDate
      } else {
        return ""
      }
    }
    return ''
  },
  getColorKey: (statusName) => {
    const statusToColor = {
      primary: ["Unassigned", "Pending", "Confirmed", "On Trip", "Draft", "Scheduled"],
      info: ["Active", "Idle", "Sent", ""],
      orange: ["In Progress", "Reached Drop Location"],
      success: ["Accepted", "Paid", "Success", "Recommended", "Ready For Invoice"],
      error: [
        "Rejected",
        "Cancelled",
        "Failed",
        "Deactivated",
        "Closed",
        "Expired",
        "Off"
      ],
      yellow: ["Assigned",],
      blue: ["Completed", ],
      secondary: ["Enroute", "Reached Pickup Location"],
      purple: ["Invoice Generated", "In Maintenance", "On Leave", "Total Orders", "Total Trips"],
      grey: ["Created",]
    };
    for (const color in statusToColor) {
      if (statusToColor[color].includes(statusName)) {
        return `${color}--text`;
      }
    }
    return "black--text";
  },
};

export default globals;