<template>
  <v-row class="pa-0">
    <v-col>
      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-icon
            color="primary"
            v-on="on"
            v-bind="attrs"
            @click="showLrfqSubmittedRates"
            class="ml-1 mr-2"
          >
            mdi-eye
          </v-icon>
        </template>
        <span>View Rates</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-icon
            class="ml-2 mr-2"
            color="primary"
            v-on="on"
            v-bind="attrs"
            @click="download()"
          >
            mdi-file-download
          </v-icon>
        </template>
        <span>Download</span>
      </v-tooltip>
    </v-col>
  </v-row>
</template>

<script>
export default {
  methods: {
    showLrfqSubmittedRates() {
      this.params.context.parentComponent.viewLrfq(this.params.data.id);
    },
    download() {
      this.params.context.parentComponent.downloadLrfqRequest(
        this.params.data.id
      );
    },
  },
};
</script>

<style>
</style>