<template>
  <div>
    <v-tooltip top>
      <template #activator="{ on, attrs }">
        <v-icon
          v-if="
            params &&
            params.context &&
            params.context.parentComponent &&
            params.context.parentComponent.permissions &&
            params.context.parentComponent.permissions.change &&
            'tab' in params.context.parentComponent &&
            params.context.parentComponent.tab == 3
          "
          class="ml-1 mr-2"
          color="primary"
          v-on="on"
          v-bind="attrs"
          @click="newUser"
        >
          mdi-check
        </v-icon>
      </template>
      <span>Accept</span>
    </v-tooltip>

    <v-tooltip top>
      <template #activator="{ on, attrs }">
        <v-icon
          v-if="
            params &&
            params.context &&
            params.context.parentComponent &&
            params.context.parentComponent.permissions &&
            params.context.parentComponent.permissions.view
          "
          class="ml-2 mr-1"
          color="primary"
          v-on="on"
          v-bind="attrs"
          @click="viewDetails"
        >
          mdi-eye
        </v-icon>
      </template>
      <span>View Details</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  methods: {
    newUser() {
      this.params.context.parentComponent.newUser(this.params.data.id);
    },
    viewDetails() {
      this.params.context.parentComponent.viewDetails(this.params.data.id);
    },
  },
};
</script>
