<template>
  <v-card>
    <!-- <v-card-title class="secondary white--text rounded-0">
      <span> Bulk Operations </span>
    </v-card-title> -->

    <v-card-text class="px-3 pb-3 pt-2">
      <v-btn
        depressed
        medium
        color="primary"
        class="mr-3"
        @click="updateBonusData"
      >
        Update Asset Details
      </v-btn>
      <v-form v-model="isValid">
        <v-row class="mt-0 pa-1">
          <v-col cols="4" lg="3" class="Form-Columns-Dense" v-if="showRoute">
            <InputField
              hide-details="auto"
              type="text"
              label="Driver Name *"
              :clearable="true"
              v-model="filterDetails.bonus"
            />
          </v-col>
          <v-col cols="4" lg="3" class="Form-Columns-Dense" v-if="showRoute">
            <InputField
              hide-details="auto"
              type="text"
              label="Vehicle Number *"
              :clearable="true"
              v-model="filterDetails.bonus"
            />
          </v-col>
          <v-col cols="4" lg="3" class="Form-Columns-Dense" v-if="showRoute">
            <InputField
              hide-details="auto"
              type="text"
              label="Trailer Number *"
              :clearable="true"
              v-model="filterDetails.bonus"
            />
          </v-col>
          <v-col cols="4" class="pa-1" v-if="showRoute">
            <v-btn
              depressed
              color="primary"
              class="mr-2"
              :disabled="
                !(selectedRows && selectedRows.length && filterDetails.bonus)
              "
              @click="submitForm"
            >
              Submit
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>

    <v-card-text class="pt-0 px-0 pb-2">
      <v-divider></v-divider>
      <v-divider></v-divider>
    </v-card-text>
    <span class="text-h6 font-weight-regular ml-4">
      Total Selected Leg -
      <span class="font-weight-bold"> {{ legCount }} </span>
    </span>
    <v-card-text class="px-4 pb-3">
      <v-row>
        <v-col cols="12" class="pt-3 pb-1">
          <AgGridVue
            style="width: 100%; height: calc(100vh - 270px)"
            class="small-table ag-theme-alpine"
            id="advancedSearchGrid"
            @selection-changed="onSelectionChanged"
            :columnDefs="headers"
            :context="context"
            rowSelection="multiple"
            @grid-ready="onGridReady"
            :defaultColDef="defaultColDef"
            :grid-options="gridOptions"
            :suppressDragLeaveHidesColumns="true"
            :rowData="allRowList"
          ></AgGridVue>
        </v-col>

        <v-col v-if="totalLegs > 0" cols="12" class="d-flex justify-end py-0">
          <Pagination
            :pageNo="pageNo"
            :totalItems="totalLegs"
            :pageSize="itemsPerPage"
            @itemsPerPageChange="itemsPerPageChanged"
            @prevPage="prevPage"
            @nextPage="nextPage"
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import InputField from "@/components/FormBaseComponents/InputField.vue";
import { AgGridVue } from "ag-grid-vue";
import Pagination from "@/components/General/Pagination.vue";

export default {
  components: {
    AgGridVue,
    InputField,
    Pagination,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  watch: {},
  computed: {
    context() {
      return { parentComponent: this };
    },
    offset() {
      return this.itemsPerPage * (this.pageNo - 1);
    },
  },
  data() {
    return {
      selectedRows: [],
      subHireList: [],
      allRowList: [],
      sorting: {},
      formErrors: {},
      isValid: true,
      legCount: 0,
      filterDetails: {},
      showRoute: false,
      filters: {},
      // pagination vars
      pageNo: 1,
      itemsPerPage: 100,
      totalLegs: 100,
      tab: null,

      // ag-grid vars
      defaultColDef: {
        resizable: true,
      },
      gridOptions: {
        onGridSizeChanged: () => {
          this.resizeGrid();
        },
        rowStyle: {
          "font-size": "10px",
        },
        rowHeight: 35,
        suppressRowClickSelection: true,
        suppressDragLeaveHidesColumns: true,
        enableCellTextSelection: true,
        tooltipShowDelay: 0,
      },
      headers: [
        {
          headerName: "DA",
          field: "da_no",
          sortable: true,
          minWidth: 65,
        },

        {
          headerName: "Leg",
          field: "leg_id",
          minWidth: 75,
          tooltipField: "leg_id",
          sortable: true,
        },

        {
          headerName: "Movement Date",
          field: "movement_date",
          sortable: true,
          minWidth: 70,
          valueFormatter: (params) => {
            return this.$globals.formatDate(params.value);
          },
          tooltipValueGetter: (params) => {
            return this.$globals.formatDate(params.value);
          },
        },

        {
          headerName: "Source",
          field: "source_location_code",
          sortable: true,
          minWidth: 80,
          tooltipField: "source_address",
        },
        {
          headerName: "Mid",
          field: "mid_location_code",
          sortable: true,
          minWidth: 80,
        },
        {
          headerName: "Destination",
          field: "destination_location_code",
          sortable: true,
          minWidth: 80,
        },

        {
          headerName: "Select for Update Bonus",
          minWidth: 50,
          checkboxSelection: true,
          headerCheckboxSelection: true,
        },
      ],
    };
  },
  methods: {
    onEnter() {
      this.pageNo = 1;
      this.getSubHireDetail();
    },
    onSelectionChanged() {
      this.selectedRows = this.gridApi.getSelectedRows();
      this.legCount = this.selectedRows.length;
    },

    resizeGrid() {
      if (this.gridOptions && "api" in this.gridOptions) {
        setTimeout(() => {
          this.gridOptions.api.sizeColumnsToFit();
        }, 100);
      }
    },
    submitForm() {
      this.$bus.$emit("showLoader", true);
      let payload = {
        id: this.selectedRows.map((da) => da.id),
        bonus: this.filterDetails.bonus,
      };
      this.$api.trip
        .bulkUpdateLegbonus(payload)
        .then((res) => {
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message: "Leg Bonus Updated Successfully!",
            color: "success",
          });
          this.formErrors = {};
          this.legCount = 0;
          this.$emit("refreshList");
        })
        .catch((err) => {
          this.formErrors = {};
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message: "Couldn't update Bonus!",
            color: "error",
          });
        });
    },

    getSubHireDetail(params) {
      this.$bus.$emit("showLoader", true);
      params = {
        sub_hire_id: this.$route.params.id,
      };
      this.$api.bidding
        .getTransporterBRSubHireDetail(params)
        .then((res) => {
          this.subHireList = res.data;
          this.allRowList = res.data.sub_hire_leg;
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },

    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.resizeGrid();
    },

    itemsPerPageChanged(e) {
      this.pageNo = 1;
      this.itemsPerPage = e;
      this.getSubHireDetail();
      this.legCount = 0;
    },
    prevPage() {
      this.pageNo--;
      this.getSubHireDetail();
      this.legCount = 0;
    },
    nextPage() {
      this.pageNo++;
      this.getSubHireDetail();
      this.legCount = 0;
    },
    applyGridSort(key, type) {
      if (this.filters && !this.filters.ordering) {
        this.filters.ordering = [];
      }

      if (type == null) {
        delete this.sorting[key];
        this.filters.ordering.splice(
          this.filters.ordering.indexOf(`-${key}`),
          1
        );
      } else if (type == "asc") {
        this.filters.ordering.push(key);
      } else if (type == "desc") {
        this.filters.ordering.splice(
          this.filters.ordering.indexOf(key),
          1,
          `-${key}`
        );
      }
      if (this.filters.ordering.length == 0) {
        delete this.filters.ordering;
      }
      this.itemsPerPageChanged(50);
    },

    updateBonusData() {
      this.showstartDate = true;
      this.showendDate = true;
      this.showRoute = true;
    },
  },
  beforeMount() {
    this.getSubHireDetail();
  },
};
</script>
