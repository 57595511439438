<template>
  <div>
    <v-chip
      label
      x-small
      :color="getColorWithLighten(params.status)"
      :text-color="getColorWithDarken(params.status)"
      class="font-weight-bold px-2"
    >
      {{ params.status }}
    </v-chip>
  </div>
</template>

<script>
export default {
  methods: {
    getColorWithLighten(status) {
      const colorKey = this.$globals.getColorKey(status);
      if (colorKey) {
        return `${colorKey.replace("--text", "")} lighten-4`;
      }
      return "black lighten-4";
    },

    getColorWithDarken(status) {
      const colorKey = this.$globals.getColorKey(status);
      if (colorKey) {
        return `${colorKey.replace("--text", "")} darken-4`;
      }
      return "black darken-4";
    },
  },
};
</script>

<style scoped></style>
