import Vue from 'vue'
import App from './App.vue'
import vuetify from "@/plugins/vuetify";
import VueRouter from "vue-router";

import html2pdf from "html2pdf.js";
import api from "@/plugins/api";
import globals from "@/plugins/globals";
import ws from "@/plugins/ws";
import confirmation from "@/plugins/confirmation";

import router from "@/router/index";

import { formatDate, formatNumber } from "@/plugins/filters"

Vue.prototype.$bus = new Vue();
Vue.prototype.$ws = ws;
Vue.prototype.$api = api;
Vue.prototype.$globals = globals;
Vue.prototype.$router = router;
Vue.prototype.$route = router.currentRoute;
Vue.prototype.$html2pdf = html2pdf;
Vue.prototype.$confirm = confirmation;

Vue.config.productionTip = false

Vue.use(VueRouter);

Vue.filter('formatDate', formatDate)

Vue.filter('formatNumber', formatNumber)

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
