<template>
  <div>
    <v-card style="background-color: #ffece0">
      <v-card-title>
        <v-row>
          <v-col cols="6" class="d-flex align-center py-2">
            <v-icon small class="pr-3" color="black">
              mdi-map-marker-path
            </v-icon>
            <h5 class="font-weight-light">
              {{
                driverDetails.active_trip &&
                Object.keys(driverDetails.active_trip).length > 0
                  ? driverDetails.active_trip.trip_id
                  : ""
              }}
            </h5>
          </v-col>
        </v-row>
      </v-card-title>
    </v-card>
    <v-row no-gutters class="py-3 px-4">
      <v-col cols="6" class="d-flex align-centerpy-0 mb-4">
        <v-btn
          color="black"
          outlined
          @click="documentListDialog = true"
          style="width: 100%; background-color: white"
        >
          View Documents
        </v-btn>
      </v-col>
      <v-col cols="12">
        <v-card elevation="0" outlined>
          <v-card-title
            class="
              text-body-2
              font-weigh-bold
              py-2
              text-capitalize
              blue-grey
              lighten-5
            "
            >driver details</v-card-title
          >
          <v-divider></v-divider>
          <v-card-text>
            <table class="Primary-Table">
              <tbody>
                <tr v-if="driverDetails.username">
                  <td>Username</td>
                  <th class="py-1">{{ driverDetails.username }}</th>
                </tr>
                <tr v-if="driverDetails.driver_name">
                  <td>Driver Name</td>
                  <th class="py-1">{{ driverDetails.driver_name }}</th>
                </tr>
                <tr v-if="driverDetails.dob">
                  <td>Date Of Birth</td>
                  <th class="py-1">
                    {{ driverDetails.dob | formatDate(false) }}
                  </th>
                </tr>
                <tr v-if="driverDetails.phone_code">
                  <td>Phone Code</td>
                  <th class="py-1">{{ driverDetails.phone_code }}</th>
                </tr>
                <tr v-if="driverDetails.contact_number">
                  <td>Contact Number</td>
                  <th class="py-1">{{ driverDetails.contact_number }}</th>
                </tr>
                <tr v-if="driverDetails.full_contact_number">
                  <td>Full Contact Number</td>
                  <th class="py-1">{{ driverDetails.full_contact_number }}</th>
                </tr>
                <tr v-if="driverDetails.employee_id">
                  <td>Employee ID</td>
                  <th class="py-1">{{ driverDetails.employee_id }}</th>
                </tr>
                <tr v-if="driverDetails.designation">
                  <td>Designation</td>
                  <th class="py-1">{{ driverDetails.designation }}</th>
                </tr>
                <tr v-if="driverDetails.service_start_date">
                  <td>Service Start Date</td>
                  <th class="py-1">
                    {{ driverDetails.service_start_date | formatDate(false) }}
                  </th>
                </tr>
              </tbody>
            </table>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" class="py-3">
        <v-card elevation="0" outlined>
          <v-card-title
            class="
              text-body-2
              font-weigh-bold
              py-2
              text-capitalize
              blue-grey
              lighten-5
            "
            >document details</v-card-title
          >
          <v-divider></v-divider>
          <v-card-text>
            <table class="Primary-Table">
              <tbody>
                <tr v-if="driverDetails.nationality">
                  <td>Nationality</td>
                  <th class="py-1">{{ driverDetails.nationality }}</th>
                </tr>
                <tr v-if="driverDetails.national_id">
                  <td>National ID</td>
                  <th class="py-1">{{ driverDetails.national_id }}</th>
                </tr>
                <tr v-if="driverDetails.national_id_expiry">
                  <td>National ID Expiry Date</td>
                  <th class="py-1">
                    {{ driverDetails.national_id_expiry | formatDate(false) }}
                  </th>
                </tr>

                <tr v-if="driverDetails.license_number">
                  <td>License No.</td>
                  <th class="py-1">{{ driverDetails.license_number }}</th>
                </tr>
                <tr v-if="driverDetails.license_expiry">
                  <td>License Expiry Date</td>
                  <th class="py-1">
                    {{ driverDetails.license_expiry | formatDate(false) }}
                  </th>
                </tr>
                <tr v-if="driverDetails.license_types">
                  <td>License Types</td>
                  <th class="py-1">{{ driverDetails.license_types }}</th>
                </tr>

                <tr v-if="driverDetails.passport_number">
                  <td>Passport No.</td>
                  <th class="py-1">{{ driverDetails.passport_number }}</th>
                </tr>
                <tr v-if="driverDetails.passport_expiry">
                  <td>Passport Expiry Date</td>
                  <th class="py-1">
                    {{ driverDetails.passport_expiry | formatDate(false) }}
                  </th>
                </tr>

                <tr v-if="driverDetails.visa_number">
                  <td>Visa No.</td>
                  <th class="py-1">{{ driverDetails.visa_number }}</th>
                </tr>
                <tr v-if="driverDetails.visa_expiry">
                  <td>Visa Expiry Date</td>
                  <th class="py-1">
                    {{ driverDetails.visa_expiry | formatDate(false) }}
                  </th>
                </tr>
              </tbody>
            </table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <BaseDriverDialog fullscreen v-model="documentListDialog" title="Documents">
      <template #information>
        <DriverAppViewDocumentVue
          :documentListDialog="documentListDialog"
          :documentList="driverDetails.driver_documents"
        />
      </template>
    </BaseDriverDialog>
  </div>
</template>
  
  <script>
import BaseDriverDialog from "@/components/General/BaseDriverDialog.vue";
import DriverAppViewDocumentVue from "@/components/ModuleBased/details/driverApp/DriverAppViewDocument.vue";

export default {
  components: {
    BaseDriverDialog,
    DriverAppViewDocumentVue,
  },
  props: {
    profileDialog: {
      type: Boolean,
    },
  },
  watch: {
    profileDialog(val) {
      if (val) {
        this.getDriverAppProfile();
      }
    },
  },
  data() {
    return {
      driverDetails: {},
      documentListDialog: false,
    };
  },
  methods: {
    showDocumentListDialog() {
      this.documentList = driverDetails.driver_documents;
      this.documentListDialog = true;
    },
    getDriverAppProfile() {
      this.$bus.$emit("showLoader", true);

      this.$api.driverApp
        .getDriverAppProfile()
        .then((res) => {
          this.driverDetails = res.data;
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
  },

  mounted() {
    this.getDriverAppProfile();
  },
};
</script>
    
    
    <style scoped lang="scss">
.card-outlined {
  border: 1px solid #0000004f !important;
  border-radius: 6px;
}
</style>