<template>
  <div>
    <v-tooltip bottom>
      <template #activator="{ on, attrs }">
        <v-icon
          class="ml-2 mr-1"
          color="primary"
          v-on="on"
          v-bind="attrs"
          @click="viewItem"
          >mdi-eye</v-icon
        >
      </template>
      <span>View</span>
    </v-tooltip>
    <v-tooltip bottom>
      <template #activator="{ on, attrs }">
        <v-icon
          class="ml-2 mr-1"
          color="primary"
          v-on="on"
          v-bind="attrs"
          @click="downloadItem"
          >mdi-download</v-icon
        >
      </template>
      <span>Download Document</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  methods: {
    viewItem() {
      this.params.context.parentComponent.viewDeliveryAdviceDocument(
        this.params.data
      );
    },
     downloadItem() {
      this.params.context.parentComponent.downloadAttachment(this.params.data)
    },
  },
};
</script>

<style>
</style>