<template>
  <div>
    <v-row class="ma-0">
      <v-col cols="12" lg="6" class="d-flex align-center">
        <v-tooltip top>
          <template #activator="{ on, attrs }">
            <v-btn
              icon
              @click="callBackRoute"
              v-bind="attrs"
              v-on="on"
              class="mr-3"
            >
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
          </template>
          <span>Back</span>
        </v-tooltip>

        <span class="text-h5">Order Details</span>
      </v-col>

      <v-col cols="12" lg="6" class="text-right">
        <v-tooltip left>
          <template #activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on" class="text-h6">
              {{ orderDetails.status }}
            </span>
          </template>
          <span>Order Status</span>
        </v-tooltip>
      </v-col>

      <v-col cols="12" class="heading">
        <v-row class="ma-0" justify="space-between">
          <v-col cols="12" lg="auto" class="Form-Columns-Dense">
            <div class="d-flex">
              <div>
                <span class="text-caption">Order No</span>
                <p class="text-subtitle-1 font-weight-medium mb-0">
                  {{ orderDetails.order_no }}
                </p>
              </div>
              <v-divider vertical class="mx-3"></v-divider>
              <div>
                <span class="text-caption">BOE/Reference No</span>
                <p class="text-subtitle-1 font-weight-medium mb-0">
                  {{ orderDetails.boe }}
                </p>
              </div>
            </div>
          </v-col>

          <v-col cols="12" lg="2" class="Form-Columns-Dense">
            <div class="d-flex">
              <div>
                <span class="text-caption">Job Type</span>
                <p class="text-subtitle-1 font-weight-medium mb-0">
                  {{ orderDetails.job_type }}
                </p>
              </div>
              <v-divider vertical class="mx-3"></v-divider>
              <div class="align-self-center">
                <p class="mb-0">
                  <ViewInformationOnHover
                    icon="mdi-train-car-container"
                    :badgeValue="orderDetails.order_container_count"
                    cardTitle="Order Container List"
                  >
                    <template #information>
                      <ContainerCard
                        v-for="(container, i) in orderDetails.order_containers"
                        :key="i"
                        :container="container"
                      />
                    </template>
                  </ViewInformationOnHover>
                </p>
              </div>
            </div>
          </v-col>

          <v-col cols="12" lg="2" class="Form-Columns-Dense"> </v-col>
        </v-row>
      </v-col>

      <v-col cols="12">
        <AgGridVue
          style="width: 100%; height: calc(100vh - 226px)"
          class="ag-theme-alpine"
          id="daGrid"
          :columnDefs="daHeaders"
          :context="context"
          @grid-ready="onGridReady"
          :defaultColDef="defaultColDef"
          :grid-options="gridOptions"
          :suppressDragLeaveHidesColumns="true"
          :rowData="orderDeliveryList"
        ></AgGridVue
      ></v-col>
    </v-row>

    <DetailsParent v-model="daDetailsDrawer" :resizable="true">
      <template #details>
        <v-card class="fill-height">
          <v-card-title class="heading mb-4">
            <span
              class="text-h5 font-weight-medium"
              v-if="fetchedDeliveryAdvice && fetchedDeliveryAdvice.da_no"
            >
              Delivery Advice - {{ fetchedDeliveryAdvice.da_no }}
            </span>
          </v-card-title>

          <v-card-subtitle class="subHeading py-2">
            <div class="d-flex">
              <Tooltip
                tooltipTitle="BOE No"
                v-if="fetchedDeliveryAdvice && fetchedDeliveryAdvice.boe"
                :tooltipText="fetchedDeliveryAdvice.boe"
                right
                tooltipClass="text-subtitle-1 font-weight-medium"
              />

              <v-spacer></v-spacer>

              <Tooltip
                tooltipTitle="Status"
                v-if="fetchedDeliveryAdvice && fetchedDeliveryAdvice.status"
                :tooltipText="fetchedDeliveryAdvice.status"
                left
                tooltipClass="text-subtitle-1 font-weight-medium"
              />
            </div>
          </v-card-subtitle>

          <v-card-text class="mt-2">
            <v-tabs v-model="drawerTabs" fixed-tabs>
              <v-tab>DA Details</v-tab>
              <v-tab>Container Details</v-tab>
            </v-tabs>

            <v-tabs-items v-model="drawerTabs">
              <v-tab-item>
                <div v-if="fetchedDeliveryAdvice" class="Height-Class mt-3">
                  <v-expansion-panels
                    focusable
                    multiple
                    v-model="daDetailsView"
                  >
                    <CardDetails
                      title="Timing Details"
                      :displayObject="fetchedDeliveryAdvice.timing_details"
                    />
                    <CardDetails
                      title="Address Details"
                      :displayObject="fetchedDeliveryAdvice.address_details"
                    />
                    <CardDetails
                      title="Other Details"
                      :displayObject="fetchedDeliveryAdvice.other_details"
                    />
                  </v-expansion-panels>
                </div>
              </v-tab-item>

              <v-tab-item>
                <div
                  v-if="fetchedDeliveryAdviceContainer"
                  class="Height-Class mt-3"
                >
                  <ContainerCard
                    v-for="(
                      container, i
                    ) in fetchedDeliveryAdviceContainer.delivery_advice_containers"
                    :key="i"
                    :container="container"
                  />
                </div>
              </v-tab-item>
            </v-tabs-items>
          </v-card-text>
        </v-card>
      </template>
    </DetailsParent>

    <DetailsParent v-model="orderLogDetailsDrawer" :resizable="true">
      <template #details>
        <v-card elevation="0">
          <v-card-title class="heading">
            <span class="text-h6">Order Log</span>
          </v-card-title>

          <v-card-text>
            <v-row class="Order-Log-Scrolling-Class mt-3">
              <v-col cols="12">
                <span
                  class="py-12"
                  v-if="
                    orderDetails.order_logs &&
                    orderDetails.order_logs.length == 0
                  "
                >
                  No logs to show!
                </span>

                <v-timeline dense clipped v-else>
                  <v-timeline-item
                    v-for="(log, i) in orderDetails.order_logs"
                    :key="i"
                    icon-color="primary"
                    small
                  >
                    <v-card class="elevation-2">
                      <v-card-text>
                        <table class="Full-Width">
                          <tbody>
                            <tr>
                              <th class="text-left">{{ log.added_by }}</th>
                              <td class="text-caption text-right">
                                {{ log.created | formatDate(true) }}
                              </td>
                            </tr>
                            <tr>
                              <td colspan="2">{{ log.message }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </v-card-text>
                    </v-card>
                  </v-timeline-item>
                </v-timeline>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </template>
    </DetailsParent>
    <CustomerAttachmentsDownload v-model="showAttachmentsDialog" :daID="daID" />
  </div>
</template>

<script>
import ViewInformationOnHover from "@/components/General/ViewInformationOnHover.vue";
import ContainerCard from "@/components/General/ContainerCard.vue";
import CardDetails from "@/components/General/CardDetails.vue";
import DetailsParent from "@/components/General/DetailsParent.vue";
import Tooltip from "@/components/General/Tooltip.vue";
import CustomerDaButton from "@/components/AgGridComponents/CustomerDaButton.vue";
import { AgGridVue } from "ag-grid-vue";
import CustomerAttachmentsDownload from "@/components/ModuleBased/dialogForms/CustomerAttachmentsDownload.vue";

import {
  isAuthenticated,
  hasRouteAccess,
  getPermissions,
} from "@/utils/functions";

export default {
  components: {
    ViewInformationOnHover,
    ContainerCard,
    CardDetails,
    DetailsParent,
    Tooltip,
    AgGridVue,
    CustomerDaButton,
    CustomerAttachmentsDownload,
  },

  data() {
    return {
      da_permissions: null,
      permissions: null,
      orderDetails: {},
      orderDeliveryList: [],
      daHeaders: [
        {
          headerName: "DA No.",
          field: "da_no",
          minWidth: 110,
        },
        {
          headerName: "Trip",
          field: "da_leg_trip_id.trip_id",
          tooltipField: "da_leg_trip_id.trip_id",
          minWidth: 150,
          sortable: true,
          cellStyle: (params) => {
            let style = {
              backgroundColor: params.data.status_color,
              textDecoration: "underline"
            };
            return style;
          },
          onCellClicked: (params) => {
            const path = {
              path: `/app/customer/trip/details/${params.data.da_leg_trip_id.id}`,
              query: {
                 backRoute: `/app/customer/order/details/${params.data.order}`,
              },
            };
            this.$router.push(path);
          },
        },
        
        {
          headerName: "Container(s)",
          field: "da_container_details.da_container_count",
          minWidth: 80,
        },

        {
          headerName: "Status",
          field: "status",
          minWidth: 110,
        },
        {
          headerName: "Movement date",
          field: "movement_date",
          minWidth: 140,
          valueFormatter: (params) => {
            return this.$globals.formatDate(params.value);
          },
        },
        {
          headerName: "Time",
          field: "movement_time",
          minWidth: 80,
        },
        {
          headerName: "Created At",
          field: "created",
          minWidth: 160,
          valueFormatter: (params) => {
            return this.$globals.formatDate(params.value, true);
          },
        },
        {
          headerName: "Completed On",
          field: "completed_on",
          minWidth: 160,
          valueFormatter: (params) => {
            return this.$globals.formatDate(params.value, true);
          },
        },
        {
          headerName: "Gen",
          field: "has_genset",
          minWidth: 50,
        },
        {
          headerName: "Haz",
          field: "is_hazmat",
          minWidth: 50,
        },
        {
          headerName: "Source",
          field: "source_location_name",
          tooltipField: "source_address",
          minWidth: 140,
        },
        {
          headerName: "Mid",
          field: "mid_location_name",
          tooltipField: "mid_address",
          minWidth: 140,
        },
        {
          headerName: "Destination",
          field: "destination_location_name",
          tooltipField: "destination_address",
          minWidth: 140,
        },
        {
          headerName: `Rate (${this.$globals.currency})`,
          field: "rate",
          minWidth: 100,
        },
        {
          headerName: `Tariff`,
          field: "customer_contract_id",
          minWidth: 100,
        },
        {
          headerName: "Actions",
          field: "actions",
          cellRendererFramework: "CustomerDaButton",
          pinned: "right",
          width: 120,
        },
      ],

      selectedOrderContainer: null,
      fetchedDeliveryAdvice: null,
      fetchedDeliveryAdviceContainer: null,

      drawerTabs: 0,
      orderLogDetailsDrawer: false,
      showAttachmentsDialog: false,
      daDetailsDrawer: false,
      daDetailsView: [0, 1, 2],
      daID: null,

      backRoute: { path: "/app/customer/order/list" },
      gridApi: null,
      gridColumnApi: null,
      defaultColDef: {
        resizable: true,
        flex: 1,
      },
      gridOptions: {
        onGridSizeChanged: () => {},
        suppressRowClickSelection: true,
        suppressDragLeaveHidesColumns: true,
        enableCellTextSelection: true,
        tooltipShowDelay: 0,
        rowHeight: 35,
      },
    };
  },

  computed: {
    context() {
      return { parentComponent: this };
    },
  },

  methods: {
    viewDADocuments(id) {
      this.showAttachmentsDialog = true;
      this.daID = id;
    },
    callBackRoute() {
      this.$router.push(this.backRoute);
    },
    viewOrderLogs() {
      this.orderLogDetailsDrawer = true;
    },
    viewDeliveryAdviceDetails(id) {
      this.viewDeliveryAdviceObject(id);
      this.viewDeliveryAdviceContainerObject(id);
      this.daDetailsDrawer = true;
    },
    selectContainer(container) {
      if (container == this.selectedOrderContainer) {
        this.selectedOrderContainer = null;
      } else {
        this.selectedOrderContainer = container;
      }
    },

    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
    },

    viewDeliveryAdviceObject(id) {
      this.$bus.$emit("showLoader", true);
      this.$api.deliveryAdvice
        .getDeliveryAdviceDetails(id)
        .then((res) => {
          this.fetchedDeliveryAdvice = res.data;

          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },

    viewDeliveryAdviceContainerObject(id) {
      this.$bus.$emit("showLoader", true);
      this.$api.deliveryAdvice
        .getDeliveryAdviceObject(id)
        .then((res) => {
          this.fetchedDeliveryAdviceContainer = res.data;
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },

    getOrderObject(id) {
      this.$bus.$emit("showLoader", true);
      this.$api.customerOrder
        .getCustomerOrderObject(id)
        .then((res) => {
          this.orderDetails = res.data;
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },

    getOrderDeliveryAdviceList(params = {}) {
      this.$bus.$emit("showLoader", true);
      params = {
        ...params,
        limit: "all",
      };
      this.$api.deliveryAdvice
        .getDeliveryAdviceList(params)
        .then((res) => {
          this.orderDeliveryList = res.data;
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },

    setBackRoute() {
      if ("backRoute" in this.$route.query) {
        this.backRoute.path = this.$route.query.backRoute;
      }
      if ("active_tab" in this.$route.query) {
        if (!this.backRoute.query) {
          this.backRoute.query = {};
        }
        this.backRoute.query.active_tab = this.$route.query.active_tab;
      }
    },
  },

  beforeMount() {
    if (!isAuthenticated()) {
      this.$router.push("/");
    } else if (!hasRouteAccess(this.$route)) {
      this.$router.push(`/${localStorage.getItem("user_type").toLowerCase()}`);
    } else {
      this.permissions = getPermissions("order");

      this.da_permissions = getPermissions("delivery_advice");
      if (!this.permissions || !this.permissions.view) {
        this.$bus.$emit("showToastMessage", {
          message: "You don't have access to this page",
          color: "error",
        });
        this.$router.push(
          `/app/${
            localStorage.getItem("user_type")
              ? localStorage.getItem("user_type").toLowerCase()
              : "error"
          }`
        );
      } else {
        this.getOrderDeliveryAdviceList({ order: this.$route.params.id });
        this.getOrderObject(this.$route.params.id);
      }
    }
  },

  mounted() {
    this.setBackRoute();
  },
};
</script>

<style scoped>
.Order-Log-Scrolling-Class {
  height: calc(100vh - 180px);
  overflow-y: auto;
}

.Height-Class {
  height: calc(100vh - 292px);
  overflow-y: auto;
}
</style>
