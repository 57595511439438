<template>
  <FormComponent
    title="Edit Quotation"
    backRoute="/app/customer/rate/quotation/list"
    :nonFieldErrors="formErrors.non_field_errors"
  >
    <template #formActions>
      <v-btn color="primary" depressed :disabled="!isValid" @click="submitForm">
        Update
      </v-btn>
    </template>

    <template #formContent>
      <div class="Height-Class">
        <v-form v-model="isValid" ref="locationForm">
          <v-row class="ma-0">
            <v-col
              cols="12"
              class="pa-2"
              v-if="quotationDetails.is_contract_created"
            >
              <span class="text-subtitle-1 font-weight-bold font-italic">
                Tariff ID : {{ quotationDetails.contract_id }}
              </span>
            </v-col>

            <v-col cols="12" sm="6" md="4" lg="6" class="pa-2">
              <DateField
                :inputProps="{
                  dense: true,
                  label: 'Start Date *',
                  'hide-details': 'auto',
                  clearable: !quotationDetails.is_contract_created,
                  disabled: quotationDetails.is_contract_created,
                  rules: [(v) => !!v || 'Start Date is Required!'],
                }"
                required
                v-model="quotationDetails.start_date"
              />
            </v-col>

            <v-col cols="12" sm="6" md="4" lg="6" class="pa-2">
              <DateField
                :inputProps="{
                  dense: true,
                  label: 'End Date *',
                  'hide-details': 'auto',
                  clearable: !quotationDetails.is_contract_created,
                  disabled: quotationDetails.is_contract_created,
                  'error-messages': formErrors.end_date,
                  rules: [(v) => !!v || 'End Date is Required!'],
                }"
                :min="quotationDetails.start_date"
                required
                v-model="quotationDetails.end_date"
                @input="delete formErrors.end_date"
              />
            </v-col>

            <v-col cols="12" sm="6" md="4" lg="6" class="pa-2">
              <v-text-field
                hide-details="auto"
                label="Source Location Name *"
                id="sourceLocation"
                ref="sourceLocation"
                dense
                outlined
                :disabled="quotationDetails.is_contract_created == true"
                :rules="[(v) => !!v || 'Source Location Name is Required!']"
                required
                v-model="quotationDetails.source_name"
                :error-messages="formErrors.source_name"
                @input="delete formErrors.source_name"
              />
            </v-col>

            <v-col cols="12" sm="6" md="4" lg="6" class="pa-2">
              <v-text-field
                hide-details="auto"
                label="Destination Location Name *"
                id="destinationLocation"
                ref="destinationLocation"
                dense
                outlined
                :disabled="quotationDetails.is_contract_created == true"
                :rules="[
                  (v) => !!v || 'Destination Location Name is Required!',
                ]"
                required
                v-model="quotationDetails.destination_name"
                :error-messages="formErrors.destination_name"
                @input="delete formErrors.destination_name"
                @click="delete formErrors.destination_name"
              />
            </v-col>

            <v-col cols="12" sm="6" md="4" lg="6" class="pa-2">
              <v-text-field
                hide-details="auto"
                label="Source State"
                dense
                outlined
                readonly
                :clearable="false"
                v-model="quotationDetails.source_state"
              />
            </v-col>

            <v-col cols="12" sm="6" md="4" lg="6" class="pa-2">
              <v-text-field
                hide-details="auto"
                label="Destination State"
                dense
                outlined
                readonly
                :clearable="false"
                v-model="quotationDetails.destination_state"
              />
            </v-col>

            <v-col cols="12" sm="6" md="4" lg="6" class="pa-2">
              <v-text-field
                hide-details="auto"
                label="Source Country Code"
                dense
                outlined
                readonly
                :clearable="false"
                v-model="quotationDetails.source_country_code"
              />
            </v-col>

            <v-col cols="12" sm="6" md="4" lg="6" class="pa-2">
              <v-text-field
                hide-details="auto"
                label="Destination Country Code"
                dense
                outlined
                readonly
                :clearable="false"
                v-model="quotationDetails.destination_country_code"
              />
            </v-col>

            <v-col cols="12" sm="6" md="4" lg="6" class="pa-2">
              <v-text-field
                hide-details="auto"
                label="Source Country"
                dense
                outlined
                readonly
                :clearable="false"
                v-model="quotationDetails.source_country"
              />
            </v-col>

            <v-col cols="12" sm="6" md="4" lg="6" class="pa-2">
              <v-text-field
                hide-details="auto"
                label="Destination Country"
                dense
                outlined
                readonly
                :clearable="false"
                v-model="quotationDetails.destination_country"
              />
            </v-col>

            <v-col cols="12" sm="6" md="4" lg="6" class="pa-2">
              <MultiSelect
                hide-details="auto"
                label="Job Type *"
                :items="allJobType"
                itemText="display_name"
                itemValue="value"
                v-model="quotationDetails.job_type"
                :rules="[(v) => !!v || 'Job Type is Required']"
                :error-messages="formErrors['job_type']"
                @change="formErrors['job_type'] = ''"
              />
            </v-col>
            <v-col cols="12" class="pa-2">
              <v-row
                v-for="(rate, i) in quotationDetails.quotation_rates"
                :key="i"
                class="ma-0 heading rounded-lg mb-2"
              >
                <v-col cols="12">
                  <div class="d-flex">
                    <span class="text-h6">
                      Container Details {{ i + 1 }}
                    </span>

                    <v-spacer></v-spacer>

                    <v-btn
                      color="primary"
                      depressed
                      small
                      @click="addContainer"
                      class="mr-2"
                    >
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>

                    <v-btn
                      color="secondary"
                      depressed
                      small
                      :disabled="quotationDetails.quotation_rates.length <= 1"
                      @click="removeContainer(i)"
                    >
                      <v-icon>mdi-minus</v-icon>
                    </v-btn>
                  </div>
                </v-col>
                <v-col cols="12" class="px-0 pt-0 pb-1">
                  <v-divider></v-divider>
                </v-col>

                <v-col cols="12" sm="6" class="pa-3">
                  <MultiSelect
                    hide-details="auto"
                    label="Trailer Type *"
                    :items="allContainersType"
                    itemText="title"
                    itemValue="id"
                    :rules="[(v) => !!v || 'Container Type is Required']"
                    v-model="rate.container_type"
                    :error-messages="
                      formErrors &&
                      formErrors['quotation_rates'] &&
                      formErrors['quotation_rates'][i] &&
                      formErrors['quotation_rates'][i]['container_type']
                        ? formErrors['quotation_rates'][i]['container_type']
                        : null
                    "
                    @input="
                      formErrors &&
                      formErrors['quotation_rates'] &&
                      formErrors['quotation_rates'][i] &&
                      formErrors['quotation_rates'][i]['container_type']
                        ? (delete formErrors['quotation_rates'][i][
                            'container_type'
                          ],
                          checkErrors())
                        : null
                    "
                  />
                </v-col>

                <v-col cols="12" sm="6" class="pa-3">
                  <InputField
                    hide-details="auto"
                    type="number"
                    label="Trailer Count *"
                    :rules="[
                      (v) => !!v || 'Container Count is Required!',
                      (v) => v > 0 || 'Container count can not be less than 1',
                      (v) => v < 5 || 'Container count can not be more than 4',
                    ]"
                    v-model="rate.container_count"
                    :error-messages="
                      formErrors &&
                      formErrors['quotation_rates'] &&
                      formErrors['quotation_rates'][i] &&
                      formErrors['quotation_rates'][i]['container_count']
                        ? formErrors['quotation_rates'][i]['container_count']
                        : null
                    "
                    @input="
                      formErrors &&
                      formErrors['quotation_rates'] &&
                      formErrors['quotation_rates'][i] &&
                      formErrors['quotation_rates'][i]['container_count']
                        ? (delete formErrors['quotation_rates'][i][
                            'container_count'
                          ],
                          checkErrors())
                        : null
                    "
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
      </div>
    </template>
  </FormComponent>
</template>

<script>
import InputField from "@/components/FormBaseComponents/InputField.vue";
import DateField from "@/components/FormBaseComponents/DateField.vue";

import MultiSelect from "@/components/FormBaseComponents/MultiSelect.vue";
import FormComponent from "@/components/General/FormComponent.vue";
import { sourceMarker, destinationMarker } from "@/assets/images/mapIcons.js";
import {
  isAuthenticated,
  hasRouteAccess,
  getPermissions,
} from "@/utils/functions";

export default {
  components: {
    InputField,
    MultiSelect,
    FormComponent,
    DateField,
  },

  data() {
    return {
      sourceMarker,
      destinationMarker,
      isValid: true,
      quotationDetails: {
        round_trip: "No",
        quotation_rates: [{}],
      },
      formErrors: {},

      // autocomplete vars
      sourceLocationAutocomplete: null,
      markerInstance: null,

      locationTypeList: [],
      allContainersType: [],
      allJobType: [],

      today: new Date().toISOString().slice(0, 10),

      directions: [],
      directionsTimeout: null,
      selectedRoute: {},
      markerTimeout: null,
    };
  },

  computed: {
    currentProfile() {
      let obj = JSON.parse(localStorage.getItem("userProfile"));
      if (obj) {
        return obj;
      } else {
        return null;
      }
    },
  },

  methods: {
    addContainer() {
      this.quotationDetails.quotation_rates.push({});
    },

    removeContainer(index) {
      this.quotationDetails.quotation_rates.splice(index, 1);
    },

    getContainersType() {
      this.$api.containerType
        .getContainerTypeList({ limit: "all" })
        .then((res) => {
          this.allContainersType = res.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },

    checkErrors() {
      if (
        this.formErrors &&
        this.formErrors.quotation_rates &&
        (this.formErrors.quotation_rates.length == 0 ||
          this.formErrors.quotation_rates.every(
            (obj) => Object.keys(obj).length == 0
          ))
      ) {
        delete this.formErrors.quotation_rates;
      }
    },

    submitForm() {
      this.$bus.$emit("showLoader", true);
      this.quotationDetails.customer = this.currentProfile.company;

      let data = {
        ...this.quotationDetails,
      };

      if (data.distance) {
        data.distance = parseFloat(data.distance.replace(/\,/g, ""));
      }

      const payload = {
        id: this.$route.params.id,
        data: data,
      };

      this.$api.quotation
        .updateQuotation(payload)
        .then((res) => {
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message: "Quotation requested!",
            color: "success",
          });
          this.$router.push("/app/customer/rate/quotation/list");
        })
        .catch((err) => {
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message: "Couldn't request quotation!",
            color: "error",
          });
          this.formErrors = err.data;
        });
    },

    getAutoComplete() {
      const interval = setInterval(() => {
        if (
          this.$refs.destinationLocation &&
          this.$refs.sourceLocation &&
          window.google &&
          window.google.maps
        ) {
          clearInterval(interval);

          let sourceLocationInput = document.getElementById("sourceLocation");
          let destinationLocationInput = document.getElementById(
            "destinationLocation"
          );

          this.sourceLocationAutocomplete =
            new window.google.maps.places.Autocomplete(sourceLocationInput);
          this.destinationLocationAutocomplete =
            new window.google.maps.places.Autocomplete(
              destinationLocationInput
            );

          sourceLocationInput.setAttribute("placeholder", "");
          destinationLocationInput.setAttribute("placeholder", "");

          this.sourceLocationAutocomplete.addListener("place_changed", () => {
            let place = this.sourceLocationAutocomplete.getPlace();
            let obj = { ...this.quotationDetails };

            obj.source_coordinates = {
              latitude: place.geometry.location.lat(),
              longitude: place.geometry.location.lng(),
            };

            obj.source_state =
              place.address_components[
                place.address_components.length - 2
              ].long_name;
            obj.source_name = place.name;
            obj.source_formatted_address = place.formatted_address;

            for (var i = 0; i < place.address_components.length; i++) {
              var addressType = place.address_components[i].types[0];

              if (addressType == "country") {
                obj.source_country_code =
                  place.address_components[i].short_name;

                obj.source_country = place.address_components[i].long_name;
              }
            }
            this.quotationDetails = obj;
          });
          this.destinationLocationAutocomplete.addListener(
            "place_changed",
            () => {
              let place = this.destinationLocationAutocomplete.getPlace();
              let obj = { ...this.quotationDetails };

              obj.destination_coordinates = {
                latitude: place.geometry.location.lat(),
                longitude: place.geometry.location.lng(),
              };

              obj.destination_state =
                place.address_components[
                  place.address_components.length - 2
                ].long_name;
              obj.destination_name = place.name;
              obj.destination_formatted_address = place.formatted_address;

              for (var i = 0; i < place.address_components.length; i++) {
                var addressType = place.address_components[i].types[0];

                if (addressType == "country") {
                  obj.destination_country_code =
                    place.address_components[i].short_name;

                  obj.destination_country =
                    place.address_components[i].long_name;
                }
              }
              this.quotationDetails = obj;
            }
          );
        }
      }, 100);
    },

    getQuotationDetails(id) {
      this.$api.quotation
        .getQuotationObject(id)
        .then((res) => {
          this.quotationDetails = res.data;
          if (this.quotationDetails.is_contract_created) {
            this.quotationDetails.start_date = res.data.contract_start_date;
            this.quotationDetails.end_date = res.data.contract_end_date;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getJobTypeOptions() {
      this.$bus.$emit("showLoader", true);
      this.$api.customerOrder
        .getOrderOptions()
        .then((res) => {
          let jobType = res.data.actions.POST.job_type.choices;
          this.allJobType = jobType.filter(
            (job) => job.display_name !== "Empty" && job.display_name !== "LCL"
          );
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
  },

  beforeMount() {
    if (!isAuthenticated()) {
      this.$router.push("/");
    } else if (!hasRouteAccess(this.$route)) {
      this.$router.push(`/${localStorage.getItem("user_type").toLowerCase()}`);
    } else {
      const permissions = getPermissions("rate_enquiry");
      if (!permissions || !permissions.change) {
        this.$bus.$emit("showToastMessage", {
          message: "You don't have access to this page",
          color: "error",
        });
        this.$router.push(
          `/app/${
            localStorage.getItem("user_type")
              ? localStorage.getItem("user_type").toLowerCase()
              : "error"
          }`
        );
      } else {
        this.getQuotationDetails(this.$route.params.id);
      }
    }
  },
  mounted() {
    this.getAutoComplete();
    this.getContainersType();
    this.getJobTypeOptions();
  },
};
</script>

<style scoped>
.Height-Class {
  height: calc(100vh - 160px);
  overflow-y: auto;
}
</style>
