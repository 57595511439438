<template>
  <FilterParent
    module="Transporter Tariff"
    maxWidth="500"
    v-model="showAdminTransporterContractFilters"
  >
    <template #filterContent>
      <v-row class="mt-0">
        <v-col cols="12" class="Form-Columns">
          <InputField
            type="date"
            label="Start Date"
            v-model="filterObject.start_date"
          />
        </v-col>

        <v-col cols="12" class="Form-Columns">
          <InputField
            type="date"
            label="End Date"
            v-model="filterObject.end_date"
          />
        </v-col>
      </v-row>
    </template>

    <template #filterAction>
      <v-btn
        color="secondary"
        depressed
        @click="
          $emit('resetFilters'), (showAdminTransporterContractFilters = false)
        "
        >Reset</v-btn
      >
      <v-btn
        color="primary"
        class="ml-4"
        depressed
        @click="
          $emit('applyFilters'), (showAdminTransporterContractFilters = false)
        "
        >Apply</v-btn
      >
    </template>
  </FilterParent>
</template>

<script>
import InputField from "@/components/FormBaseComponents/InputField.vue";
import MultiSelect from "@/components/FormBaseComponents/MultiSelect.vue";
import FilterParent from "@/components/General/FilterParent.vue";

export default {
  components: {
    FilterParent,
    MultiSelect,
    InputField,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    bindingObject: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {};
  },
  computed: {
    showAdminTransporterContractFilters: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    filterObject: {
      get() {
        return this.bindingObject;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {},
};
</script>

<style>
</style>