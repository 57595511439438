<template>
  <div>
    <v-row no-gutters class="py-3 px-4">
      <v-col cols="12">
        <v-card
          elevation="0"
          outlined
          v-for="(containerDetails, key) in containerList"
          :key="`container_card${key}`"
        >
          <v-card-title
            class="
              text-body-2
              font-weigh-bold
              py-2
              text-capitalize
              blue-grey
              lighten-5
            "
          >
            <v-icon small class="pr-1" color="black"> mdi-cube-outline </v-icon>
            <span class="mt-1"> {{ containerDetails.container_no }}</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <table class="Primary-Table">
              <tbody>
                <tr v-if="containerDetails.container_type_title">
                  <td>Container Type</td>
                  <th class="py-1">
                    {{ containerDetails.container_type_title }}
                  </th>
                </tr>
                <tr v-if="containerDetails.weight">
                  <td>Container Weight</td>
                  <th class="py-1">
                    {{ containerDetails.weight }}
                  </th>
                </tr>
              </tbody>
            </table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    containerList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {};
  },
};
</script>
  
  
  <style scoped lang="scss">
.card-outlined {
  border: 1px solid #0000004f !important;
  border-radius: 6px;
}
</style>