<template>
  <v-dialog fullscreen v-model="showReportsDetailDialog">
    <v-card>
      <v-card-title class="secondary white--text px-4 py-2">
        <span> {{ reportHeader }}</span>

        <v-spacer></v-spacer>

        <v-btn icon @click="closeModal()">
          <v-icon class="white--text">mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="pa-2 mt-1">
        <AgGridVue
          style="width: 100%; height: calc(100vh - 80px)"
          class="ag-theme-alpine"
          id="myGrid"
          :columnDefs="headers"
          :context="context"
          :grid-options="gridOptions"
          :suppressDragLeaveHidesColumns="true"
          :rowData="reportList"
        ></AgGridVue>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";

export default {
  components: {
    AgGridVue,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    reportObjId: {
      default: null,
    },
    reportType: {
      default: null
    }
  },
  watch: {
    value(val) {
      if (val) {
        if (this.reportObjId) {
          this.reportHeader = this.reportType
          this.getReportpDetail();
        }
      }
    },
  },
  computed: {
    showReportsDetailDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    context() {
      return { parentComponent: this };
    },
  },
  data() {
    return {
      reportList: [],
      reportHeader: null,
      // ag-grid vars

      gridOptions: {
        defaultColDef: {
          resizable: true,
          flex: 1,
        },
        suppressRowClickSelection: true,
        suppressDragLeaveHidesColumns: true,
        enableCellTextSelection: true,
        tooltipShowDelay: 0,
        rowHeight: 35,
      },
      headers: null,
    };
  },
  methods: {
    getReportpDetail() {
      this.$bus.$emit("showLoader", true);
      this.$api.report
        .getReportDetails(this.reportObjId)
        .then((res) => {
          if (res.data) {
            if (res.data.headers) {
              this.generateHeadres(res.data.headers);
            }
            if (res.data.response) {
              this.reportList = res.data.response;
            }
          }
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    generateHeadres(headers) {
      this.headers = [];
      headers.forEach((header) => {
        let name = this.getName(header);
        this.headers.push({
          headerName: name,
          field: header,
          minWidth: 70,
        });
      });
    },
    getName(header) {
      let name = header.replace(/_/g, " ");

      name = name
        .split(" ")
        .map((word) => {
          let char = word[0].toUpperCase();
          let s = word.slice(1, word.length);
          return `${char}${s}`;
        })
        .join(" ");

      return name;
    },
    closeModal(){
      this.showReportsDetailDialog = false;
      this.reportList = []
    }
  },
};
</script>
