export const TODAY = "Today";

export const THIS_WEEK = "This Week";
export const THIS_MONTH = "This Month";
export const THIS_YEAR = "This Year";

export const NEXT_WEEK = "Next Week";
export const NEXT_MONTH = "Next Month";
export const NEXT_YEAR = "Next Year";

export const LAST_WEEK = "Last Week";
export const LAST_MONTH = "Last Month";
export const LAST_YEAR = "Last Year";
