<template>
  <v-card class="fill-height">
    <v-card-title class="heading mb-4">
      <Tooltip
        tooltipTitle="Tariff Code"
        v-if="contractDetails.contract_id"
        :tooltipText="contractDetails.contract_id"
        right
        tooltipClass="text-h5 font-weight-medium"
      />
    </v-card-title>

    <v-card-subtitle class="subHeading py-2">
      <div class="d-flex">
        <Tooltip
          tooltipTitle="Created From"
          v-if="contractDetails.created_from"
          :tooltipText="contractDetails.created_from"
          right
          tooltipClass="text-subtitle-1 font-weight-medium"
        />

        <v-spacer></v-spacer>

        <v-tooltip
          left
          v-if="
            contractDetails.status == 'Active' &&
            userType &&
            userType == 'Admin'
          "
        >
          <template
            #activator="{ on, attrs }"
            v-if="permissions && permissions.add"
          >
            <v-icon
              color="primary"
              v-on="on"
              v-bind="attrs"
              @click="$emit('submitResp', true)"
            >
              mdi-alert-octagon
            </v-icon>
          </template>
          <span>Close Tariff</span>
        </v-tooltip>
      </div>
    </v-card-subtitle>

    <v-card-text>
      <v-tabs fixed-tabs v-model="detailsTab" class="my-3">
        <v-tabs-slider color="white"></v-tabs-slider>
        <v-tab>Tariff Rates</v-tab>
        <v-tab v-if="userType && userType == 'Admin'">Logs</v-tab>
      </v-tabs>

      <v-tabs-items v-model="detailsTab">
        <v-tab-item class="Height-Class">
          <v-expansion-panels
            multiple
            focusable
            v-model="contractDetailView"
            v-if="
              'transporter_contract_rates' in contractDetails &&
              contractDetails.transporter_contract_rates.length > 0
            "
            class="mb-3"
          >
            <CardDetails
              v-for="(obj, index) in contractDetails.transporter_contract_rates"
              :key="index"
              :title="`Tariff Rate ${index + 1}`"
              :displayObject="obj"
            />
          </v-expansion-panels>

          <span class="text-h6 font-weight-bold">Other Details</span>
          <v-divider></v-divider>

          <v-expansion-panels
            multiple
            focusable
            v-model="transporterDetailView"
            class="mt-2"
          >
            <CardDetails
              v-if="contractDetails.transporter_detail"
              title="Transporter Details"
              :displayObject="contractDetails.transporter_detail"
            />

            <CardDetails
              v-if="contractDetails.source_detail"
              title="Source Details"
              :displayObject="contractDetails.source_detail"
            />
            <CardDetails
              v-if="contractDetails.mid_detail"
              title="Mid Details"
              :displayObject="contractDetails.mid_detail"
            />
            <CardDetails
              v-if="contractDetails.destination_detail"
              title="Destination Details"
              :displayObject="contractDetails.destination_detail"
            />

            <CardDetails
              v-if="contractDetails.remarks_detail"
              title="Remarks Details"
              :displayObject="contractDetails.remarks_detail"
            />

            <CardDetails
              v-if="contractDetails.extra_info"
              title="Extra Info"
              :displayObject="contractDetails.extra_info"
            />
          </v-expansion-panels>
        </v-tab-item>

        <v-tab-item v-if="userType && userType == 'Admin'">
          <v-sheet
            style="
              height: calc(100vh - 300px);
              overflow-y: auto;
              overflow-x: hidden;
            "
          >
            <v-timeline v-if="logs && logs.length" class="px-0" align-top dense>
              <v-timeline-item
                v-for="(log, index) in logs"
                :key="index"
                color="primary"
                icon="mdi-circle"
                small
              >
                <v-row class="pt-1">
                  <v-col>
                    <strong>{{ log.created | formatDate(true) }}</strong>
                    <div class="text-body-2">{{ log.message }}</div>
                    <div class="text-caption">- {{ log.updated_by }}</div>
                  </v-col>
                </v-row>
              </v-timeline-item>
            </v-timeline>
            <div v-else>No Logs to show</div>
          </v-sheet>
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
  </v-card>
</template>

<script>
import CardDetails from "@/components/General/CardDetails.vue";
import Tooltip from "@/components/General/Tooltip.vue";

export default {
  components: { CardDetails, Tooltip },
  props: {
    contractId: {
      type: Number,
    },
          permissions: {
        type: Object,
        default: () => ({}),
      },
  },
  watch: {
    contractId(val) {
      this.logs = [];
      this.getTransporterContractDetails(val);
      this.transporterContractActivityLogs();
    },
  },
  computed: {
    userType() {
      return localStorage.getItem("user_type");
    },
  },
  data() {
    return {
      contractDetails: {},
      contractDetailView: [],
      logs: [],
      transporterDetailView: [0, 1, 2, 3, 4],
      detailsTab: null,

    };
  },
  methods: {
    closeContract() {
      if (
        confirm(
          `Are you sure you want to close Tariff ${this.contractDetails.contract_id}`
        )
      ) {
        this.$nuxt.$emit("showLoader", true);
        this.$api.transporterContract
          .closeTransporterContract(this.contractDetails.id)
          .then((res) => {
            this.$emit("refreshList");
            this.$nuxt.$emit("showToastMessage", {
              message: "Tariff Closed Successfully!",
              color: "success",
            });
            this.$nuxt.$emit("showLoader", false);
          })
          .catch((err) => {
            this.$nuxt.$emit("showToastMessage", {
              message: `${
                err.data && err.data.non_field_errors
                  ? err.data.non_field_errors.join()
                  : "Tariff cant be closed"
              }`,
              color: "error",
            });
            this.$nuxt.$emit("showLoader", false);
          });
      }
    },
    getTransporterContractDetails(id) {
      this.$nuxt.$emit("showLoader", true);
      this.$api.transporterContract
        .getTransporterContractDetails(id)
        .then((res) => {
          this.contractDetails = res.data;
          if (this.contractDetails.transporter_contract_rates) {
            this.contractDetails.transporter_contract_rates.forEach(
              (val, index) => {
                this.contractDetailView.push(index);
              }
            );
          }
          this.$nuxt.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$nuxt.$emit("showLoader", false);
        });
    },
    transporterContractActivityLogs(params = {}) {
      params = {
        ...params,
        transporter_contract: this.contractId,
        limit: "50",
        offset: this.logs.length,
      };
      this.$api.activityLogs
        .transporterContractActivityLogs(params)
        .then((res) => {
          this.logs.push(...res.data);
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
  mounted() {
    this.logs = [];
    this.contractDetailView = [];
    this.getTransporterContractDetails(this.contractId);
    this.transporterContractActivityLogs();
  },
};
</script>

<style scoped>
.Height-Class {
  height: calc(100vh - 296px);
  overflow-y: auto;
}
</style>