<template>
  <v-card class="fill-height">
    <v-card-title class="heading" v-if="Object.keys(laneObj).length">
      <div>
        <span class="text-h6 font-weight-medium">Source</span>
        <p class="text-subtitle-1 mb-2">
          {{ laneObj.source }}
        </p>

        <span class="text-h6 font-weight-medium">Mid</span>
        <p class="text-subtitle-1 mb-0">{{ laneObj.mid }}</p>
        <span class="text-h6 font-weight-medium">Destination</span>
        <p class="text-subtitle-1 mb-0">{{ laneObj.destination }}</p>
      </div>
    </v-card-title>

    <v-card-text class="mt-2">
      <v-tabs fixed-tabs v-model="detailsTab">
        <v-tabs-slider color="white"></v-tabs-slider>
        <v-tab>BMR</v-tab>
        <v-tab>Logs</v-tab>
      </v-tabs>

      <v-tabs-items v-model="detailsTab">
        <v-tab-item>
          <div class="d-flex mt-2">
            <span class="text-h6">BMR List</span>

            <v-spacer></v-spacer>

            <v-btn
               
              depressed
              color="primary"
              @click="addRateForm"
              small
              v-show="permissions && permissions.add"
            >
              <v-icon class="mr-2">mdi-plus</v-icon>
              <span>BMR</span>
            </v-btn>
          </div>

          <v-divider class="mb-2" />

          <AgGridVue
            class="ag-theme-alpine grid-height"
            id="myGrid"
            :columnDefs="headers"
            :context="context"
            @grid-ready="onGridReady"
            :defaultColDef="defaultColDef"
            :grid-options="gridOptions"
            :suppressDragLeaveHidesColumns="true"
            :rowData="bmrList"
          ></AgGridVue>
        </v-tab-item>

        <v-tab-item>
          <v-sheet style="height: calc(100vh - 332px); overflow-y: auto">
            <v-timeline v-if="logs && logs.length" dense>
              <v-timeline-item
                v-for="(log, index) in logs"
                :key="index"
                color="primary"
                small
                class="pb-3"
              >
                <v-card class="elevation-2">
                  <v-card-text class="py-2">
                    <table class="Full-Width">
                      <tbody>
                        <tr>
                          <th class="text-left">{{ log.updated_by }}</th>
                          <td class="text-caption text-right">
                            {{ log.created | formatDate(true) }}
                          </td>
                        </tr>
                        <tr>
                          <td colspan="2">{{ log.message }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </v-card-text>
                </v-card>
              </v-timeline-item>
            </v-timeline>
            <div v-else>No Logs to show</div>
          </v-sheet>
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>

    <RateForm
      v-model="showRateForm"
      :laneObj="laneObj"
      :bmrId="bmrId"
      :editMode="editMode"
      @closeBMRForm="closeLaneBMRForm"
      @refreshList="getLaneBMRList()"
    />
  </v-card>
</template>

<script>
import Tooltip from "@/components/General/Tooltip.vue";
import RateForm from "../dialogForms/RateForm.vue";
import LaneBMRButton from "@/components/AgGridComponents/LaneBMRButton.vue";
import { AgGridVue } from "ag-grid-vue";
import Pagination from "@/components/General/Pagination.vue";

export default {
  components: {
    Tooltip,
    RateForm,
    LaneBMRButton,
    AgGridVue,
    Pagination,
  },
  props: {
    laneObj: {
      type: Object,
      default: () => ({}),
    },
    permissions: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      // BMR list vars
      bmrList: [],

      // bmr edit vars
      bmrId: null,

      // form Vars
      showRateForm: false,

      editMode: false,

      // pagination vars
      itemsPerPage: 10,
      pageNo: 1,
      totalItems: 0,
      filters: {},

      detailsTab: 0,
      logs: [],

      // ag-grid vars
      gridApi: null,
      gridColumnApi: null,
      defaultColDef: {
        resizable: true,
      },
      gridOptions: {
        onGridSizeChanged: () => {
          this.gridOptions.api.sizeColumnsToFit();
        },
        suppressRowClickSelection: true,
        suppressDragLeaveHidesColumns: true,
        enableCellTextSelection: true,
        tooltipShowDelay: 0,
        rowHeight: 35,
      },
    };
  },
  computed: {
    context() {
      return { parentComponent: this };
    },
    headers() {
      return [
        {
          headerName: "Job Type",
          field: "job_type",
          tooltipField: "job_type",
        },
        {
          headerName: "Container Type",
          field: "container_type_title",
          tooltipField: "container_type_title",
        },
        {
          headerName: "Container Count",
          field: "container_count",
        },
        {
          headerName: `Rate (${this.$globals.currency})`,
          field: "rate",
        },
        {
          headerName: "Actions",
          field: "actions",
          cellRendererFramework: "LaneBMRButton",
        },
      ];
    },
    offset() {
      return this.itemsPerPage * (this.pageNo - 1);
    },
  },
  watch: {
    laneObj: {
      handler() {
        this.logs = [];
        this.getLaneBMRList();
        this.laneActivityLogs();
      },
      deep: true,
    },
    tab() {
      this.getLaneBMRList();
    },
  },
  methods: {
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
    },

    getLaneBMRList(params = {}) {
      params = {
        ...params,
        lane: this.laneObj.id,
      };
      this.$api.bmr
        .getBMRList(params)
        .then((res) => {
          this.bmrList = res.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },

    addRateForm() {
      this.bmrId = null;
      this.editMode = false;
      this.showRateForm = true;
    },

    editLaneBMR(id) {
      this.bmrId = id;
      this.editMode = true;
      this.showRateForm = true;
    },

    closeLaneBMRForm() {
      this.bmrId = null;
      this.showRateForm = false;
      this.editMode = false;
    },

    laneActivityLogs(params = {}) {
      params = {
        ...params,
        lane: this.laneObj.id,
        limit: "50",
        offset: this.logs.length,
      };
      this.$api.activityLogs
        .laneActivityLogs(params)
        .then((res) => {
          this.logs.push(...res.data);
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
  mounted() {
    this.logs = [];
    this.getLaneBMRList(this.laneObj.id);
    this.laneActivityLogs();
  },
};
</script>

<style scoped>
.grid-height {
  height: calc(100vh - 381px);
  overflow-y: auto;
}
</style>
