<template>
  <FilterParent module="Vehicle Type" v-model="showAdminVehicleTypeFilters">
    <template #filterContent>
      <v-row class="ma-0 pb-3">
        <v-col cols="12" lg="6" class="Form-Columns">
          <v-switch
            hide-details="auto"
            :label="`Temperature Controlled : ${
              filterObject.temperature_controlled ? 'Yes' : 'No'
            }`"
            class="mt-1"
            v-model="filterObject.temperature_controlled"
          ></v-switch>
        </v-col>

        <v-col cols="12" lg="6" class="Form-Columns">
          <v-switch
            hide-details="auto"
            :label="`Attach Trailer : ${
              filterObject.attach_trailer ? 'Yes' : 'No'
            }`"
            class="mt-1"
            v-model="filterObject.attach_trailer"
          ></v-switch>
        </v-col>
      </v-row>
    </template>

    <template #filterAction>
      <v-btn
        color="secondary"
        depressed
        @click="
          $emit('resetFilters');
          showAdminVehicleTypeFilters = false;
        "
      >
        Reset
      </v-btn>
      <v-btn
        color="primary"
        class="ml-4"
        depressed
        @click="
          $emit('applyFilters');
          showAdminVehicleTypeFilters = false;
        "
      >
        Apply
      </v-btn>
    </template>
  </FilterParent>
</template>

<script>
import MultiSelect from "@/components/FormBaseComponents/MultiSelect.vue";
import FilterParent from "@/components/General/FilterParent.vue";

export default {
  components: {
    FilterParent,
    MultiSelect,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    bindingObject: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {};
  },
  watch: {
    value(val) {
      if (val) {
      }
    },
  },
  computed: {
    showAdminVehicleTypeFilters: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    filterObject: {
      get() {
        return this.bindingObject;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {},
};
</script>

<style></style>
