var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"max-width":"500"},model:{value:(_vm.showBidDetailsDialog),callback:function ($$v) {_vm.showBidDetailsDialog=$$v},expression:"showBidDetailsDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"secondary white--text"},[_c('span',[_vm._v("Submit Rate")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){(_vm.showBidDetailsDialog = false), (_vm.formData[_vm.fieldKey] = null)}}},[_c('v-icon',{staticClass:"white--text"},[_vm._v("mdi-close")])],1)],1),_c('v-card-text',{staticClass:"pt-2"},[(_vm.showBidDetailsDialog)?_c('v-form',{ref:"bidRequestForm",model:{value:(_vm.isValid),callback:function ($$v) {_vm.isValid=$$v},expression:"isValid"}},[_c('v-row',{staticClass:"my-0"},[_c('v-col',{staticClass:"Form-Columns",attrs:{"cols":"12"}},[_c('InputField',{attrs:{"hide-details":"auto","type":"number","step":".01","label":`Rate (${_vm.$globals.currency}) *`,"required":"","rules":[
                (val) => !!val || 'Rate is Required',
                (val) => val > 0 || 'Rate can not be less than or equal 0',
              ],"clearable":false,"error-messages":_vm.formErrors.rate},on:{"click":function($event){return _vm.clearRateOnInput()},"input":function($event){delete _vm.formErrors.rate}},model:{value:(_vm.formDataPOST.rate),callback:function ($$v) {_vm.$set(_vm.formDataPOST, "rate", $$v)},expression:"formDataPOST.rate"}})],1),_c('v-col',{staticClass:"Form-Columns",attrs:{"cols":"12"}},[_c('InputField',{attrs:{"hide-details":"auto","type":"number","label":"Container Count *","required":"","rules":[
                (val) => !!val || 'Container Count is Required',
              ],"clearable":false,"error-messages":_vm.formErrors.container_count},on:{"input":function($event){delete _vm.formErrors.container_count}},model:{value:(_vm.formDataPOST.container_count),callback:function ($$v) {_vm.$set(_vm.formDataPOST, "container_count", $$v)},expression:"formDataPOST.container_count"}})],1),_c('v-col',{staticClass:"Form-Columns-Dense",attrs:{"cols":"12"}},[_c('DateField',{attrs:{"inputProps":{
                dense: true,
                label: 'Rate Expiry Date *',
                rules: [(val) => !!val || 'Rate Expiry Date is Required!'],
                'hide-details': 'auto',
                'error-messages': _vm.formErrors.rate_expiry_date,
              },"min":_vm.today,"required":""},on:{"input":function($event){delete _vm.formErrors.rate_expiry_date}},model:{value:(_vm.formDataPOST.rate_expiry_date),callback:function ($$v) {_vm.$set(_vm.formDataPOST, "rate_expiry_date", $$v)},expression:"formDataPOST.rate_expiry_date"}})],1),_c('v-col',{staticClass:"Form-Columns",attrs:{"cols":"12"}},[_c('InputField',{attrs:{"label":"Remarks","required":_vm.isRequiredRemarks,"hide-details":"auto"},model:{value:(_vm.formDataPOST.transporter_remarks),callback:function ($$v) {_vm.$set(_vm.formDataPOST, "transporter_remarks", $$v)},expression:"formDataPOST.transporter_remarks"}})],1)],1)],1):_vm._e()],1),_c('v-divider'),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"depressed":"","color":"primary","disabled":!_vm.isValid},on:{"click":_vm.submitForm}},[_c('span',[_vm._v("Submit")])])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }