<template>
    <div>
      <v-tooltip top>
        <template #activator="{ on, attrs }">
          <v-icon
            v-if="
              params &&
              params.context &&
              params.context.parentComponent &&
              params.context.parentComponent.permissions &&
              params.context.parentComponent.permissions.change &&
              params.context.parentComponent.selectedDaDetails &&
              params.context.parentComponent.selectedDaDetails.invoice_status &&
              params.context.parentComponent.selectedDaDetails.invoice_status ==
                'Pending' &&
              params.context.parentComponent.selectedDaDetails.payment_term !=
                'Cash'
            "
            class="ml-1 mr-2"
            color="primary"
            v-on="on"
            v-bind="attrs"
            @click="editItem"
          >
            mdi-pencil
          </v-icon>
        </template>
        <span>Edit</span>
      </v-tooltip>
  
      <v-tooltip top>
        <template #activator="{ on, attrs }">
          <v-icon
            v-if="
              params &&
              params.context &&
              params.context.parentComponent &&
              params.context.parentComponent.permissions &&
              params.context.parentComponent.permissions.delete &&
              params.context.parentComponent.selectedDaDetails &&
              params.context.parentComponent.selectedDaDetails.status &&
              ['Ready For Invoice', 'Invoice Generated'].indexOf(
                params.context.parentComponent.selectedDaDetails.status
              ) == -1 &&
              params.context.parentComponent.selectedDaDetails.payment_term !=
                'Cash'
            "
            class="ml-2 mr-1"
            color="primary"
            v-on="on"
            v-bind="attrs"
            @click="deleteDeliveryCharge"
          >
            mdi-delete
          </v-icon>
        </template>
        <span>Delete</span>
      </v-tooltip>
    </div>
  </template>
  <script>
  export default {
    methods: {
      editItem() {
        this.params.context.parentComponent.editCostDeliveryCharge(
          this.params.data
        );
      },
      deleteDeliveryCharge() {
        this.params.context.parentComponent.deleteDeliveryCharge(
          this.params.data.id
        );
      },
    },
  };
  </script>
  
  <style></style>
  