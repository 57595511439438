<template>
  <FilterParent v-model="showAdminUserFilters" module="Company">
    <template #filterContent>
      <v-row class="ma-0">
        <v-col cols="12" lg="6" class="pa-2">
          <MultiSelect
            hide-details="auto"
            :items="allRoles"
            itemText="role_name"
            itemValue="id"
            label="Select Role"
            clearable
            v-model="filterObject.role"
          />
        </v-col>

        <v-col cols="12" lg="6" class="pa-2">
          <MultiSelect
            hide-details="auto"
            :items="booleanValues"
            label="Is Primary User"
            clearable
            v-model="filterObject.primary_user"
          />
        </v-col>
      </v-row>
    </template>

    <template #filterAction>
      <v-btn
        depressed
        color="secondary"
        @click="$emit('resetFilters'), (showAdminUserFilters = false)"
      >
        Reset
      </v-btn>

      <v-btn
        color="primary"
        class="ml-4"
        depressed
        @click="$emit('applyFilters'), (showAdminUserFilters = false)"
      >
        Apply
      </v-btn>
    </template>
  </FilterParent>
</template>

<script>
import MultiSelect from "@/components/FormBaseComponents/MultiSelect.vue";
import FilterParent from "@/components/General/FilterParent.vue";

export default {
  components: {
    FilterParent,
    MultiSelect,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    bindingObject: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      customerGroupList: [],
      allRoles: [],
      paymentTerms: [],
      booleanValues: [
        {
          text: "Yes",
          value: "true",
        },
        {
          text: "No",
          value: "false",
        },
      ],
    };
  },
  watch: {
    value(val) {
      if (val) {
        this.getCompanyOptions();
        this.getRoleList();
      }
    },
  },
  computed: {
    showAdminUserFilters: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    filterObject: {
      get() {
        return this.bindingObject;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    currentProfile() {
      let obj = JSON.parse(localStorage.getItem("userProfile"));
      if (!obj) {
        return null;
      } else {
        return obj;
      }
    },
  },
  methods: {
    getRoleList(params = {}) {
      this.$bus.$emit("showLoader", true);
      params = {
        ...params,
        company: this.currentProfile.company,
        limit: "all",
      };
      this.$api.role
        .getRoleList(params)
        .then((res) => {
          this.allRoles = res.data;
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    getCompanyOptions() {
      this.$bus.$emit("showLoader", true);
      this.$api.company
        .getCompanyOptions()
        .then((res) => {
          this.paymentTerms = res.data.actions.POST.payment_term.choices.map(
            (obj) => {
              return { text: obj.display_name, value: obj.value };
            }
          );
          this.customerGroupList =
            res.data.actions.POST.customer_group.choices.map((obj) => {
              return { text: obj.display_name, value: obj.value };
            });

          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
  },
};
</script>

<style></style>
