import Blank from "@/layouts/blank.vue"

import BidRate from "@/pages/hsm/bid/BidRate.vue"
import TransporterAssetDetails from "@/pages/hsm/bid/TransporterAssetDetails.vue"
import SuccessPage from "@/pages/hsm/bid/SuccessPage.vue"
import ExpirePage from "@/pages/hsm/bid/ExpiredPage.vue"
import WonPage from "@/pages/hsm/bid/WonPage.vue"
import AssetDetailUpdatedPage from "@/pages/hsm/bid/AssetDetailUpdatedPage.vue"

const bidRoutes = [

  {
    path: "hsm",
    name: "app-bid-hsm",
    component: Blank,
    children: [
      {
        path: "/",
        redirect: "/app/bid/hsm",
      },
      {
        path: "rate",
        name: "/app/bid/hsm/rate",
        component: Blank,
        children: [
          {
            path: ":id",
            name: "app-bid-hsm-rate-id",
            component: BidRate,
          }
        ]
      },
      {
        path: "assetDetails",
        name: "/app/bid/hsm/assetDetails",
        component: Blank,
        children: [
          {
            path: ":id",
            name: "app-bid-hsm-assetDetails-id",
            component: TransporterAssetDetails,
          }
        ]
      },
      {
        path: "success",
        name: "/app/bid/hsm/success",
        component: SuccessPage,
      },
      {
        path: "assetDetailUpdated",
        name: "/app/bid/hsm/assetDetailUpdated",
        component: AssetDetailUpdatedPage,
      },
      {
        path: "expire",
        name: "/app/bid/hsm/expire",
        component: ExpirePage,
      },
      {
        path: "won",
        name: "/app/bid/hsm/won",
        component: WonPage,
      },
    ]
  },
]

export default bidRoutes