<template>
  <div>
    <!-- Top Navigation -->
    <v-app-bar
      elevation="4"
      :clipped-left="true"
      :clipped-right="true"
      fixed
      app
    >
      <v-app-bar-nav-icon
        @click.stop="drawer = !drawer"
        color="primary--text"
      ></v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <img
        class="Brand-Logo"
        alt="Brand Logo"
        src="@/assets/images/brand-logo.png"
      />
      <v-spacer></v-spacer>
      <v-btn icon @click="chatBoxDialog = true">
        <v-icon class="primary--text">mdi-message-text</v-icon>
      </v-btn>
    </v-app-bar>

    <!-- Side Navigation -->
    <v-navigation-drawer
      v-model="drawer"
      absolute
      left
      style="background-color: #ffece0"
      temporary
    >
      <div class="text-center white box-shadow-bottom">
        <img
          class="Brand-Logo"
          alt="Brand Logo"
          src="@/assets/images/brand-logo.png"
        />
      </div>

      <v-row>
        <v-col cols="12">
          <h4 class="text-center py-5">{{ currentProfile.first_name }}</h4>
        </v-col>
        <v-col cols="12" class="d-flex align-center px-6">
          <!-- <router-link to="/driver/driver_profile" class="d-flex align-center" style="text-decoration: none"> -->
          <v-icon small class="pr-4"> mdi-account </v-icon>
          <h5 @click="profileDialog = true">Profile</h5>
          <!-- </router-link> -->
        </v-col>
        <v-col cols="12" class="px-6 py-0" style="opacity: 0.3">
          <hr />
        </v-col>
        <!-- <v-col cols="12" class="d-flex align-center pa-6">
          <v-btn
            icon
            @click="openLanguageChangeDialog = true"
            color="primary"
            style="width: 100%"
            class="d-flex justify-space-between pr-0"
          >
            <v-icon>mdi-translate</v-icon>
            <span class="pl-2" v-if="currentLanguageObj">
              {{ currentLanguageObj.label }}
            </span>
          </v-btn>
        </v-col> -->

        <v-col cols="12" class="d-flex align-center pa-6">
          <v-btn
            color="primary"
            outlined
            style="width: 100%"
            class="d-flex justify-space-between pr-0"
            @click="logout"
          >
            Logout
            <v-icon small class="pr-4"> mdi-logout </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-navigation-drawer>

    <!-- Base Content -->
    <v-main>
      <v-container fluid class="pa-0">
        <router-view />
      </v-container>
    </v-main>

    <BaseDriverDialog fullscreen v-model="profileDialog" title="Driver Profile">
      <template #information>
        <DriverAppProfile :profileDialog="profileDialog" />
      </template>
    </BaseDriverDialog>

    <BaseDriverDialog fullscreen v-model="chatBoxDialog" title="Chat">
      <template #information>
        <DriverAppChat
          :chatBoxDialog="chatBoxDialog"
          :tripDetails="tripDetails"
        />
      </template>
    </BaseDriverDialog>

    <ChangeLanguage
      @changeLanguage="googleTranslateElementInit"
      v-model="openLanguageChangeDialog"
    />
  </div>
</template>

<script>
import AppBar from "@/components/BaseComponents/AppBar.vue";
import AppFooter from "@/components/BaseComponents/AppFooter.vue";
import RightDrawer from "@/components/BaseComponents/RightDrawer.vue";
import SideNavigation from "@/components/BaseComponents/SideNavigation.vue";
import { Loader } from "@googlemaps/js-api-loader";
import { isImpersonating } from "@/utils/functions";
import BaseDriverDialog from "@/components/General/BaseDriverDialog.vue";
import DriverAppProfile from "@/components/ModuleBased/details/driverApp/DriverAppProfile.vue";
import DriverAppChat from "@/components/ModuleBased/details/driverApp/DriverAppChat.vue";
import ChangeLanguage from "@/components/General/ChangeLanguage.vue";
import { getCookie, setCookie, eraseCookie } from "@/utils/functions";

export default {
  components: {
    AppBar,
    SideNavigation,
    AppFooter,
    RightDrawer,
    BaseDriverDialog,
    DriverAppProfile,
    DriverAppChat,
    ChangeLanguage,
  },

  name: "Driver",

  data() {
    return {
      profileDialog: false,
      chatBoxDialog: false,
      openLanguageChangeDialog: false,
      unreadCount: 0,
      companyNotifications: [],
      userCompanyId: null,
      userType: null,
      // Drawer vars
      drawer: false,
      rightDrawer: false,

      // Snackbar vars
      snackbar: false,
      vertical: false,
      hasActions: false,
      text: null,
      color: null,
      timeout: 2000,
      position: null,

      // Loader Var
      showOverlay: false,

      tripDetails: {},

      language: [
        {
          label: "English",
          value: "en",
        },
        {
          label: "Arabic",
          value: "ar",
        },
        {
          label: "Hindi",
          value: "hi",
        },
      ],
      currentLanguage: "en",
    };
  },
  computed: {
    currentProfile() {
      let obj = JSON.parse(localStorage.getItem("userProfile"));
      if (!obj) {
        return {};
      } else {
        return obj;
      }
    },
    currentLanguageObj() {
      return this.language.find((obj) => obj.value == this.currentLanguage);
    },
  },
  methods: {
    logout() {
      this.$bus.$emit("showLoader", true);
      this.$api.authentication
        .logout()
        .then(() => {
          this.$bus.$emit("showLoader", false);
          if (isImpersonating()) {
            localStorage.removeItem("i_user");
            this.loadData();
          } else {
            localStorage.clear();
            this.$router.push("/app/login");
          }
          this.$emit("closeWebSocket");
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    setupWS(userType) {
      if (!this.$ws.instance) {
        this.$ws.createInstance(userType);
        this.$ws.instance.onopen = this.onOpen;
      } else {
        this.onOpen();
      }
      this.$ws.instance.onmessage = this.onMessage;
    },
    getOldNotifications(refreshHistory) {
      if (refreshHistory) {
        this.getNotificationHistory();
      }
    },
    onClose() {
      this.$ws.removeInstance();
    },
    onMessage(ev) {
      if (ev && ev.data) {
        const data =
          typeof ev.data == typeof "" ? JSON.parse(ev.data) : ev.data;
        this.$bus.$emit("socketMessageReceived", data);
      }
    },
    onOpen() {},
    checkCurrentLang() {
      this.currentLanguage = !!getCookie("currentLanguage")
        ? getCookie("currentLanguage")
        : "en";
    },
    googleTranslateElementInit(language = null, reload = false) {
      if (this.currentLanguage === language) {
        return;
      }
      if (getCookie("googtrans")) {
        eraseCookie("googtrans");
      }

      setCookie(
        "googtrans",
        `/${this.currentLanguage}/${language}`,
        process.env.coockieDomain
      );

      setCookie("currentLanguage", language);

      new google.translate.TranslateElement(
        {
          pageLanguage: this.currentLanguage,
          layout: google.translate.TranslateElement.InlineLayout.SIMPLE,
          autoDisplay: true,
          includedLanguages: "ar,en,hi",
        },
        "app"
      );

      this.currentLanguage = language;

      setCookie("currentLanguage", this.currentLanguage);
      if (reload) {
        location.reload();
      }
    },
    loadLang() {
      // setCookie("googtrans", `/en`, 30);
      // setCookie("currentLanguage", this.currentLanguage, 30);
      new google.translate.TranslateElement(
        {
          pageLanguage: "en",
          layout: google.translate.TranslateElement.InlineLayout.SIMPLE,
          autoDisplay: true,
          includedLanguages: "ar,en,hi",
        },
        "app"
      );
    },
  },
  beforeCreate() {
    this.$bus.$emit("showLoader", true);
    this.$api.authentication
      .getProfileInfo()
      .then((res) => {
        this.userType = res.data.user_type.toLowerCase();
        this.$bus.$emit("showLoader", false);
        localStorage.setItem("userProfile", JSON.stringify(res.data));
        localStorage.setItem("user_type", this.userType);
        const preference = res?.data?.user_preferences || {};
        localStorage.setItem("userPreferences", JSON.stringify(preference));
        localStorage.setItem(
          "permissions",
          JSON.stringify(res.data.permissions)
        );
        if (res.data && this.userType) {
          this.setupWS(this.userType);
        }
      })
      .catch((err) => {
        this.$bus.$emit("showLoader", false);
      });
  },

  created() {
    this.$bus.$on("setTripDetails", (data) => {
      this.tripDetails = data;
    });
    this.$bus.$on("socketMessageReceived", (data) => {
      let socketDataType = null;
    });
  },
  async mounted() {
    // Google map loading code
    const interval = setInterval(() => {
      if (window && window.google && window.google.translate) {
        clearInterval(interval);
        this.checkCurrentLang();
        this.loadLang();
      }
    }, 300);
    if (window.google == undefined || window.google.map == undefined) {
      const loader = new Loader({
        apiKey: process.env.VUE_APP_MAP_KEY,
        version: "weekly",
        libraries: ["places", "geometry", "drawing"],
      });
      await loader.load();
    }
  },
};
</script>

<style scoped>
.box-shadow-bottom {
  -webkit-box-shadow: 0px 2px 2px -1px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: 0px 2px 2px -1px rgba(0, 0, 0, 0.06);
  box-shadow: 0px 2px 2px -1px rgba(0, 0, 0, 0.06);
}
</style>