<template>
  <ListComponent
    title="Bidding"
    :totalCount="totalBidRequestStatistics.total_bid_request"
  >
    <template #list>
      <v-row class="ma-0">
        <v-col cols="4" class="pl-0 pr-2 pt-0 pb-2 d-flex align-center">
          <v-text-field
            label="Search"
            outlined
            dense
            append-icon="mdi-magnify"
            clearable
            hide-details="auto"
            v-model="filters.search"
            @input="searchBid"
          ></v-text-field>

          <v-tooltip top>
            <template #activator="{ on, attrs }">
              <v-btn
                depressed
                icon
                color="primary"
                v-on="on"
                v-bind="attrs"
                @click="showFilterDialog = true"
                class="mx-2"
              >
                <v-badge
                  dot
                  color="primary"
                  :value="Object.keys(filters).length"
                >
                  <v-icon>mdi-filter</v-icon>
                </v-badge>
              </v-btn>
            </template>

            <span v-if="Object.keys(filters).length == 0">Filters</span>

            <v-row v-else no-gutters>
              <v-col cols="12" class="font-weight-bold">Applied Filters</v-col>
              <v-col cols="12">
                <span
                  v-for="(value, key) in filters"
                  :key="key"
                  class="text-capitalize"
                  >{{ key.replace("_", " ") }}
                  <br />
                </span>
              </v-col>
            </v-row>
          </v-tooltip>

          <Columns :headers="headers" :gridColInstance="gridColumnApi" />
        </v-col>

        <v-col cols="4" class="px-0 pt-0 pb-2">
          <v-tabs height="35" v-model="tab" centered>
            <v-tabs-slider></v-tabs-slider>
            <v-tab>
              Pending
              <v-badge
                v-if="totalBidRequestStatistics.pending_bid"
                top
                :content="totalBidRequestStatistics.pending_bid"
                overlap
                class="mb-4 ml-3"
              >
              </v-badge>
            </v-tab>
            <v-tab>
              Participated
              <v-badge
                v-if="totalBidRequestStatistics.participated_bid"
                top
                :content="totalBidRequestStatistics.participated_bid"
                overlap
                class="mb-4 ml-3"
              >
              </v-badge>
            </v-tab>
            <v-tab>
              Closed
              <v-badge
                v-if="totalBidRequestStatistics.closed_bid"
                top
                :content="totalBidRequestStatistics.closed_bid"
                overlap
                class="mb-4 ml-3"
              >
              </v-badge>
            </v-tab>
          </v-tabs>
        </v-col>
        <v-col cols="4" class="px-0 pt-0 pb-2 d-flex justify-end">
          <Pagination
            :pageNo="pageNo"
            :totalItems="totalItems"
            :pageSize="itemsPerPage"
            @itemsPerPageChange="itemsPerPageChanged"
            @prevPage="prevPage"
            @nextPage="nextPage"
          />
        </v-col>

        <v-col cols="12">
          <AgGridVue
            style="width: 100%; height: calc(100vh - 172px)"
            class="ag-theme-alpine"
            id="myGrid"
            :columnDefs="headers"
            :context="context"
            @grid-ready="onGridReady"
            :defaultColDef="defaultColDef"
            :grid-options="gridOptions"
            :suppressDragLeaveHidesColumns="true"
            :rowData="allbidList"
          ></AgGridVue>
        </v-col>
      </v-row>
    </template>
    <template #dialogs>
      <TransporterBidRateDialog
        v-model="showBidDetailsDialog"
        :formDetails="selectedBid"
        @refreshBidRequestList="getBidList"
      />
      <TransporterBidRateDetails
        v-model="viewBidInformation"
        :formDetails="selectedBid"
      />
      <AdminBidFilters
        :currentTab="tab"
        v-model="showFilterDialog"
        :bindingObject="filters"
        @resetFilters="
          resetFilters();
          getTotalBidCount();
        "
        @applyFilters="
          itemsPerPageChanged(20);
          getTotalBidCount();
        "
      />
    </template>

    <template #actions> </template>
  </ListComponent>
</template>

<script>
import ListComponent from "@/components/General/ListComponent.vue";
import Columns from "@/components/General/Columns.vue";

import { AgGridVue } from "ag-grid-vue";
import Pagination from "@/components/General/Pagination.vue";
import TransporterBidButton from "@/components/AgGridComponents/TransporterBidButton.vue";
import CustomHeader from "@/components/AgGridComponents/General/CustomHeader.vue";
import AdminBidFilters from "@/components/ModuleBased/dialogs/filters/AdminBidFilters.vue";

import TransporterBidRateDialog from "@/components/ModuleBased/dialogs/TransporterBidRateDialog.vue";
import TransporterBidRateDetails from "@/components/ModuleBased/details/TransporterBidRateDetails.vue";

import {
  isAuthenticated,
  hasRouteAccess,
  getPermissions,
} from "@/utils/functions";

export default {
  components: {
    ListComponent,
    AgGridVue,
    AdminBidFilters,
    Pagination,
    TransporterBidButton,
    agColumnHeader: CustomHeader,
    TransporterBidRateDialog,
    TransporterBidRateDetails,
    Columns,
  },
  data() {
    return {
      allbidList: [],
      bidLogs: [],
      statuses: ["Pending", "Participated", "Closed"],
      openFilterDialog: false,
      showBidLogDialog: false,
      showDialog: false,
      bidLogDetailsDrawer: false,
      tab: 0,
      selectedBid: {},

      //bidStatistics
      totalBidRequestStatistics: [],

      showCloseRemarksForm: false,

      sorting: {},

      headers: [
        {
          headerName: "BID ID",
          field: "bid_id",
          sortable: true,
          minWidth: 120,
        },
        {
          headerName: "BID Request ID",
          field: "bid_request_id",
          sortable: true,
          minWidth: 120,
        },
        {
          headerName: "Route",
          field: "bid_lane_title_name",
          sortable: true,
          minWidth: 500,
        },

        {
          headerName: "Container Type",
          field: "container_name",
          tooltipField: "container_name",
          sortable: true,
          minWidth: 140,
        },
         {
          headerName: "Container Count",
          field: "transporter_container_count",
          tooltipField: "ctransporter_container_count",
          sortable: true,
          minWidth: 140,
        },
         {
          headerName: "Rate/Container",
          field: "rate",
          tooltipField: "rate",
          sortable: true,
          minWidth: 140,
        },
       
        //{
         // headerName: `Expected Rate(${this.$globals.currency})`,
         // field: "expected_rate",
         // sortable: true,
        //  minWidth: 140,
        //},
        {
          headerName: "End date",
          field: "end_date",
          sortable: true,
          minWidth: 140,
          tooltipValueGetter: (params) => {
            return this.$globals.formatDate(params.value);
          },
          valueFormatter: (params) => {
            return this.$globals.formatDate(params.value);
          },
        },
  
         {
          headerName: "Admin Remarks",
          field: "admin_remarks",
          sortable: true,
          minWidth: 140,
        },
  {
          headerName: "Transporter Remarks",
          field: "transporter_remarks",
          sortable: true,
          minWidth: 340,
        },
  
        {
          headerName: "Actions",
          field: "actions",
          cellRenderer: "TransporterBidButton",
          pinned: "right",
          minWidth: 180,
        },
      ],

      // details vars
      selectedBidId: null,
      permissions: null,

      // pagination vars
      pageNo: 1,
      itemsPerPage: 10,
      totalItems: 100,

      // filter vars
      filters: {},
      showFilterDialog: false,
      showBidDetailsDialog: false,
      viewBidInformation: false,

      headerSelected: [],

      // ag-grid vars
      gridApi: null,
      gridColumnApi: null,
      defaultColDef: {
        width: 150,
        resizable: true,
        flex: 1,
      },
      gridOptions: {
        onGridSizeChanged: () => {
          this.resizeGrid();
        },
        suppressRowClickSelection: true,
        suppressDragLeaveHidesColumns: true,
        enableCellTextSelection: true,
        tooltipShowDelay: 0,
      },
    };
  },
  watch: {
    tab() {
      this.getBidList();
    },
  },
  computed: {
    context() {
      return { parentComponent: this };
    },
    offset() {
      return this.itemsPerPage * (this.pageNo - 1);
    },
    currentProfile() {
      let obj = localStorage.getItem("userProfile");
      return !obj ? obj : JSON.parse(obj);
    },
  },

  methods: {
    getTotalBidCount(params) {
      if (this.searchDAStatisticTimeout) {
        clearTimeout(this.searchDAStatisticTimeout);
      }
      this.searchDAStatisticTimeout = setTimeout(() => {
        params = { ...this.filters, transporter: this.currentProfile.company };
        delete params.limit;
        delete params.offset;
        this.$api.bidding
          .getBidRequestStatistics(params)
          .then((res) => {
            this.totalBidRequestStatistics = res.data;
          })
          .catch((err) => {
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message: "Couldn't  get bid count!",
              color: "error",
            });
          });
      }, 1000);
    },
    showConfirmationDialog() {
      this.showCloseRemarksForm = true;
    },

    resizeGrid() {
      if (this.gridOptions && "api" in this.gridOptions) {
        setTimeout(() => {
          this.gridOptions.api.sizeColumnsToFit();
        }, 100);
      }
    },
    resetFilters() {
      this.filters = {};
      this.itemsPerPageChanged(10);
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.resizeGrid();
    },
    searchBid() {
      this.pageNo = 1;
      this.getBidList();
    },

    headersChanged(value) {
      this.headerSelected = value;
      this.resizeGrid();
    },
    itemsPerPageChanged(e) {
      this.pageNo = 1;
      this.itemsPerPage = e;
      this.getBidList();
    },
    prevPage() {
      this.pageNo--;
      this.getBidList();
    },
    nextPage() {
      this.pageNo++;
      this.getBidList();
    },
    applyGridSort(key, type) {
      if (this.filters && !this.filters.ordering) {
        this.filters.ordering = [];
      }

      if (type == null) {
        delete this.sorting[key];
        this.filters.ordering.splice(
          this.filters.ordering.indexOf(`-${key}`),
          1
        );
      } else if (type == "asc") {
        this.filters.ordering.push(key);
      } else if (type == "desc") {
        this.filters.ordering.splice(
          this.filters.ordering.indexOf(key),
          1,
          `-${key}`
        );
      }
      if (this.filters.ordering.length == 0) {
        delete this.filters.ordering;
      }
      this.getBidList();
    },
    getBidList(params = {}) {
      this.$bus.$emit("showLoader", true);
      let status = this.statuses[this.tab];
      if (status == this.statuses[2]) {
        status = ["Won", "Loss", "Expired"].join(",");
      }
      let laneId;

      if (this.filters && this.filters.lane) {
        laneId = this.filters.lane.id;
        delete this.filters.lane;
        this.filters.lane = laneId;
      }

      params = {
        offset: this.offset,
        limit: this.itemsPerPage,
        status: status,
        transporter: this.currentProfile.company,
        ...params,
        ...this.filters,
      };
      if (params.ordering && params.ordering.length > 0) {
        params = { ...params, ordering: params.ordering.join(",") };
      }

      this.$api.bidding
        .getTransporterBidList(params)
        .then((res) => {
          this.allbidList = res.data;
          this.totalItems = res.count;
          this.getTotalBidCount()
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    editBidDetails(data) {
      this.showBidDetailsDialog = true;
      this.selectedBid = data;
    },
    
    viewBidDetails(data) {
      this.viewBidInformation = true;
      this.selectedBid = data;
    },
    // Admin Bid Close Function
    closeBid() {
      this.$bus.$emit("showLoader", true);
      let payload = {
        status: "Closed",
        company: this.currentProfile.user_type,
      };
      this.$api.bid
        .updateStatus(this.selectedBid, payload)
        .then((res) => {
          this.showConfirmationDialog = false;
          this.$bus.$emit("showLoader", false);
          if (res && res.data && res.data.message) {
            this.$bus.$emit("showToastMessage", {
              message: res.data.message,
              color: "success",
            });
          }
          this.getBidList();
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
  },
  beforeMount() {
    if (!isAuthenticated()) {
      this.$router.push("/");
    } else if (!hasRouteAccess(this.$route)) {
      this.$router.push(`/${localStorage.getItem("user_type").toLowerCase()}`);
    } else {
      const permissions = getPermissions("contract");
      if (!permissions || !permissions.view) {
        this.$bus.$emit("showToastMessage", {
          message: "You don't have access to this page",
          color: "error",
        });
        this.$router.push(
          `/${
            localStorage.getItem("user_type")
              ? localStorage.getItem("user_type").toLowerCase()
              : "error"
          }`
        );
      } else {
        this.permissions = { ...permissions };
        this.getBidList();
      }
    }
  },
  mounted() {
    this.getTotalBidCount();
  },
};
</script>