import Blank from "@/layouts/blank.vue"

import TransporterRFQList from "@/pages/admin/transporter-rfq/TransporterRFQList.vue";
import TransporterRFQAdd from "@/pages/admin/transporter-rfq/TransporterRFQAdd.vue";
import TransporterRFQDetails from "@/pages/admin/transporter-rfq/TransporterRFQDetails.vue";
import LrfqDetailPage from "@/pages/admin/transporter-rfq/LrfqDetailPage.vue";

const transporterRFQRoutes = [
  {
    path: "lrfq",
    name: "app-admin-lrfq",
    component: Blank,
    children: [
      {
        path: "/",
        redirect: "/app/admin/lrfq/list",
      },
      {
        path: "list",
        name: "app-admin-lrfq-list",
        component: TransporterRFQList,
      },
      {
        path: "add",
        name: "app-admin-lrfq-add",
        component: TransporterRFQAdd,
      },
       
      {
        path: "detail",
        name: "app-admin-lrfq-detail",
        component: Blank,
        children: [
          {
            path: "/",
            redirect: "/app/admin/lrfq/list",
          },
          {
            path: ":id",
            name: "app-admin-lrfq-detail-id",
            component: LrfqDetailPage,
          }
        ]
      },
    ]
  },
]

export default transporterRFQRoutes