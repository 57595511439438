<template>
  <v-row class="pa-0">
    <v-col>
      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-icon
            v-if="params.data && params.data.status == 'Participated'
            "
            color="primary"
            v-on="on"
            v-bind="attrs"
            @click="accept()"
            class="ml-1 mr-2"
          >
            mdi-check
          </v-icon>
        </template>
        <span>Accept</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-icon
           v-if="params.data && params.data.status == 'Participated'
            "
            class="ml-2 mr-2"
            color="primary"

            v-on="on"
            v-bind="attrs"
            @click="reject()"
          >
            mdi-close
          </v-icon>
        </template>
        <span>Reject</span>
      </v-tooltip>
    </v-col>
  </v-row>
</template>

<script>
export default {
  methods: {
    accept() {
      this.params.context.parentComponent.acceptRejectRate(this.params.data.lrfq_request, 'Accepted');
    },
    reject() {
      this.params.context.parentComponent.acceptRejectRate(
        this.params.data.lrfq_request, 'Rejected'
      );
    },
  },
};
</script>

<style>
</style>