import { customerOrder, trip, version2 } from "@/utils/constants";
import { handleError, handleResponse } from "@/utils/functions";

export default (axios) => ({
  getCustomerOrderList(params = {}) {
    return new Promise((resolve, reject) => {
      axios
        .get(customerOrder.customerOrderBase, {
          params: params,
        })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  addCustomerOrder(data) {
    return new Promise((resolve, reject) => {
      axios
        .post(customerOrder.customerOrderBase, data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getOrderListStatistics(params = {}) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${customerOrder.customerOrderBase}order_statistics/`, {
          params: params,
        })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },

  updateCustomerOrder(payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${customerOrder.customerOrderBase}${payload.id}/`, payload.data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getCustomerOrderObject(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${customerOrder.customerOrderBase}${id}/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getCustomerOrderDetails(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${customerOrder.customerOrderBase}${id}/view/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getOrderOptions(params = {}) {
    return new Promise((resolve, reject) => {
      axios
        .options(customerOrder.customerOrderBase, {
          params: params,
        })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },

  //customer order pod
  getCustomerOrderPODAttachments(params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${version2}customer_order_pod_attachment/`, {
          params: params,
        })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
    //download customer order pod
    getDownloadDocumentsBlopObject(id) {
      console.log(id);
      return new Promise((resolve, reject) => {
        axios
          .get(`${trip.legPODBase}${id}/download/`, {
            responseType: 'blob',
          })
          .then((res) => {
            resolve(handleResponse(res))
          })
          .catch((err) => {
            reject(handleError(err))
          })
      })
    },
    
});
