<template>
  <v-dialog v-model="showBidForm" width="700">
    <v-card>
      <v-card-title class="secondary white--text">
        Create Bid
        <v-spacer></v-spacer>
        <v-btn icon @click="resetForm(), (showBidForm = false)">
          <v-icon class="white--text">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pa-5">
        <v-form ref="sendForm" v-model="isValid" lazy-validation>
          <v-row class="ma-0">
            <v-col cols="12" lg="4" class="Form-Columns-Dense">
              <InputField
                type="text"
                label="Job Type"
                hide-details="auto"
                :clearable="false"
                v-model="bidDetails.job_type"
                :readonly="true"
            /></v-col>

            <v-col cols="12" lg="4" class="Form-Columns-Dense">
              <InputField
                type="text"
                label="Route"
                hide-details="auto"
                :clearable="false"
                v-model="bidDetails.lane_title"
                :readonly="true"
            /></v-col>

            <v-col cols="12" lg="4" class="Form-Columns-Dense">
              <InputField
                type="text"
                label="Container Type"
                hide-details="auto"
                :clearable="false"
                v-model="bidDetails.container_type_count"
                :readonly="true"
            /></v-col>
            <v-col cols="12" lg="4" class="Form-Columns-Dense">
              <DateField
                :inputProps="{
                  dense: true,
                  label: 'Bid End Date *',
                  'hide-details': 'auto',
                  rules: [(val) => !!val || 'End Date is required!'],
                  'error-messages': formErrors.end_date,
                }"
                fieldType="datetime"
                required
                v-model="bidDetails.end_date"
                @input="delete formErrors.end_date"
              />
            </v-col>
            <v-col cols="12" lg="4" class="Form-Columns-Dense">
              <InputField
                type="number"
                label="Expected Rate"
                hide-details="auto"
                v-model="bidDetails.expected_rate"
              />
            </v-col>
            <v-col cols="12" lg="4" class="Form-Columns-Dense">
              <InputField
                type="text"
                label="Admin Remarks"
                hide-details="auto"
                v-model="bidDetails.admin_remarks"
              />
            </v-col>

            <v-col cols="12" lg="12" class="Form-Columns-Dense">
              <MultiSelect
                :showAll="true"
                label="Select Transporter(s) *"
                :items="transporterList"
                itemText="company_name"
                itemValue="id"
                hide-details="auto"
                :rules="[val => val && !!val.length || 'Transporter is required!']"
                :multiple="true"
                :chips="true"
                chipWidthStyle="max-width: 3.7vw;"
                v-model="bidDetails.transporter"
                :error-messages="formErrors.transporter"
                @change="delete formErrors.transporter"
              >
              </MultiSelect>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="pa-5">
        <v-btn  :disabled="
            !isValid" class="ml-auto" @click="submitForm()" outlined color="primary"
          >Submit</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import InputField from "@/components/FormBaseComponents/InputField.vue";
 
import MultiSelect from "@/components/FormBaseComponents/MultiSelect.vue";
import DateField from "@/components/FormBaseComponents/DateField.vue";
 
export default {
  components: {
    DateField,
    InputField,
     
    MultiSelect,
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },
    bidFormDetails: {
      type: Object,
      required: true,
    },
  },
  watch: {
    value(val) {
      if (val) {
        this.getTransporterList();
         
        this.bidDetails = { ...this.bidFormDetails };
        
      } else {
      }
    },
  },
  computed: {
    showBidForm: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },

    context() {
      return { parentComponent: this };
    },
  },
  data() {
    return {
      bidDetails: {
        transporter: [],
      },
      formErrors: {},
      transporterList: [],

      isValid: true,  
    };
  },
  methods: {
    getTransporterList(params = {}) {
      this.$bus.$emit("showLoader", true);
      params = {
        company_type: "Transporter",
        limit: "all",
        ...params,
      };
      this.$api.company
        .getCompanyList(params)
        .then((res) => {
          this.$bus.$emit("showLoader", false);
          this.transporterList = res.data;
        })
        .catch(() => {
          this.$bus.$emit("showLoader", false);
        });
    },
    async submitForm() {
      this.$bus.$emit("showLoader", true);

      const propertiesToDelete = ["lane_title"];
      propertiesToDelete.forEach((property) => {
        delete this.bidDetails[property];
      });
      let data = {
        ...this.bidDetails,
      };

      if (this.bidDetails.transporter && this.bidDetails.transporter.length) {
        data.bid_request = this.bidDetails.transporter.map((t) => ({
          transporter: t,
        }));
      }

      await this.$api.bidding
        .addBid(data)
        .then((res) => {
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message: "Bid Created Successfully!",
            color: "success",
          });
          this.resetForm();
        })
        .catch((err) => {
          this.showBidForm = false
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message:
              err.data && err.data.non_field_errors
                ? err.data.non_field_errors[0]
                : "Couldn't Create Bid!",
            color: "error",
          });
          this.formErrors = { ...this.formErrors, ...err.data };
        });
    },
    resetForm() {
      this.showBidForm = false
      this.bidDetails = {
        transporter: [],
      };
    },
  },
};
</script>