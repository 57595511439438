<template>
  <v-dialog width="500" v-model="showCurrencyForm">
    <v-card>
      <v-card-title class="secondary white--text">
        <span> {{ editMode ? "Update" : "Add" }} Currency </span>
        <v-spacer></v-spacer>

        <v-btn icon @click="resetForm(), (showCurrencyForm = false)">
          <v-icon class="white--text">mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="pt-2">
        <v-form ref="currencyForm" v-if="showCurrencyForm" v-model="isValid">
          <v-row class="my-0">
            <v-col cols="12" class="Form-Columns">
              <InputField
                type="text"
                label="Currency Code *"
                required
                :rules="[
                  (val) => !!val || 'Currency Code is Required!',
                  (val) =>
                    $globals.lettersOnlyRegExp.test(val) ||
                    'Invalid Currency Code!',
                ]"
                hide-details="auto"
                v-model="currencyDetails.currency_code"
                :error-messages="formErrors.currency_code"
                @input="delete formErrors.currency_code"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions class="justify-end">
        <v-btn
          color="primary"
          depressed
          :disabled="!isValid"
          @click="submitForm"
        >
          Submit
        </v-btn>

        <v-btn
          class="ml-3"
          color="secondary"
          v-if="!editMode"
          depressed
          @click="resetForm"
        >
          Reset
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import InputField from "@/components/FormBaseComponents/InputField.vue";

export default {
  components: {
    InputField,
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    editCurrencyID: {
      type: [Number, null],
      default: null,
    },
  },

  watch: {
    value(val) {
      if (val) {
        if (this.editMode) {
          this.getCurrencyObject(this.editCurrencyID);
        }
      } else {
        this.resetForm();
      }
    },
  },

  computed: {
    showCurrencyForm: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },

  data() {
    return {
      formErrors: {},
      isValid: true,

      currencyDetails: {},

      allCurrencyList: [],
    };
  },

  methods: {
    submitForm() {
      this.$bus.$emit("showLoader", true);
      if (!this.editMode) {
        this.$api.currency
          .addCurrency(this.currencyDetails)
          .then((res) => {
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message: "Currency Added Successfully!",
              color: "success",
            });
            this.resetForm();
            this.showCurrencyForm = false;
            this.$emit("refreshList");
          })
          .catch((err) => {
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message: "Couldn't Add Currency!",
              color: "error",
            });
            this.formErrors = err.data;
          });
      } else {
        this.$api.currency
          .updateCurrency({
            id: this.currencyDetails.id,
            data: this.currencyDetails,
          })
          .then((res) => {
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message: "Currency Updated Successfully!",
              color: "success",
            });

            this.resetForm();
            this.showCurrencyForm = false;
            this.$emit("refreshList");
          })
          .catch((err) => {
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message: "Couldn't Update Currency!",
              color: "error",
            });
            this.formErrors = err.data;
          });
      }
    },

    resetForm() {
      if (this.$refs.currencyForm) {
        this.$refs.currencyForm.reset();
      }
      this.currencyDetails = {};
    },

    getCurrencyObject(id) {
      this.$bus.$emit("showLoader", true);

      this.$api.currency
        .getCurrencyObject(id)
        .then((res) => {
          this.$bus.$emit("showLoader", false);
          this.currencyDetails = res.data;
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
  },
};
</script>
