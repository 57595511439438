<template>
  <v-dialog
    v-bind="$attrs"
    hide-overlay
    transition="dialog-bottom-transition"
    v-on="$listeners"
    v-model="baseDialog"
    persistent
  >
    <v-card elevation="0">
      <v-card-title
        class="py-4 d-flex justify-center box-shadow-bottom"
        style="position: relative"
      >
        <span style="position: absolute; left: 10px">
          <v-icon class="primary--text" @click="closeDialog"
            >mdi-arrow-left-thin</v-icon
          >
        </span>
        <span
          class="
            text-body-1
            font-weight-bold
            text-capitalize
            primary--text
            opacity
          "
          v-if="title"
        >
          {{ title }}
        </span>
      </v-card-title>

      <v-card-text class="pa-0">
        <slot name="information"></slot>
      </v-card-text>
      <v-card-actions v-if="btnEnable">
        <slot name="action"> </slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "BaseDriverDialog",
  components: {},
  props: {
    title: {
      type: String,
      required: true,
    },
    btnEnable: {
      type: Boolean,
      default: false,
    },
    value: Boolean,
  },
  data() {
    return {};
  },
  computed: {
    baseDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    closeDialog() {
      this.baseDialog = false;
      this.$emit("reset");
    },
  },
  mounted() {},
};
</script>
<style scoped>
.opacity {
  opacity: 0.7;
}
.box-shadow-bottom {
  -webkit-box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
    0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%) !important;
  -moz-box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
    0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%) !important;
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
    0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%) !important;
}
</style>
