var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',[(_vm.dashboardData && _vm.dashboardData.totals)?_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","lg":"3"}},[_c('v-card',{staticClass:"fill-height"},[_c('v-card-title',{staticClass:"grey darken-1 py-3"},[_c('span',{staticClass:"text-h6 white--text"},[_vm._v(" Assets ")])]),_c('v-card-text',{staticClass:"mt-4"},[_c('table',{staticClass:"Primary-Table"},[_c('tbody',_vm._l((_vm.dashboardData.totals),function(value,key){return _c('tr',{key:key},[_c('td',[_vm._v(_vm._s(key.replace("_", " ")))]),_c('th',[_vm._v(_vm._s(value))])])}),0)])])],1)],1):_vm._e(),(_vm.dashboardData && _vm.dashboardData.totals)?_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","lg":"3"}},[_c('v-card',{staticClass:"fill-height"},[_c('v-card-title',{staticClass:"grey darken-1 py-3"},[_c('span',{staticClass:"text-h6 white--text"},[_vm._v(" Legs/Trips ")])]),_c('v-card-text',{staticClass:"mt-4"},[_c('table',{staticClass:"Primary-Table"},[_c('tbody',_vm._l((_vm.dashboardData.others),function(value,key){return _c('tr',{key:key},[_c('td',[_vm._v(_vm._s(key.replace("_", " ")))]),_c('th',[_vm._v(_vm._s(value))])])}),0)])])],1)],1):_vm._e(),(_vm.dashboardData && _vm.dashboardData.contracts)?_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","lg":"3"}},[_c('v-card',{staticClass:"fill-height"},[_c('v-card-title',{staticClass:"grey darken-1 py-3"},[_c('span',{staticClass:"text-h6 white--text"},[_vm._v(" Tariff ")])]),_c('v-card-text',{staticClass:"mt-4"},[_c('table',{staticClass:"Primary-Table"},[_c('tbody',_vm._l((_vm.dashboardData.contracts),function(value,key){return _c('tr',{key:key},[_c('td',[_vm._v(_vm._s(key.replace("_", " ")))]),_c('th',[_vm._v(_vm._s(value))])])}),0)])])],1)],1):_vm._e(),(
      'trip' in _vm.dashboardData && Object.keys(_vm.dashboardData.trip).length > 0
    )?_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-title',{staticClass:"heading"},[_c('span',[_vm._v("Trips Status Wise Bifurcation")]),_c('v-spacer'),_c('v-chip',{staticClass:"mx-2"},[_vm._v(" Total Trips : "+_vm._s(_vm.dashboardData.trip.total)+" ")]),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","lg":"3"}},[_c('DateField',{attrs:{"inputProps":{
              dense: true,
              label: 'Start Date',
              clearable: true,
              'hide-details': 'auto',
            },"max":_vm.today},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","lg":"3"}},[_c('DateField',{attrs:{"inputProps":{
              dense: true,
              label: 'End Date',
              clearable: true,
              'hide-details': 'auto',
            },"min":_vm.startDate,"max":_vm.today,"disabled":!_vm.startDate},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}})],1),_c('v-col',{attrs:{"cols":""}},[_c('v-btn',{attrs:{"depressed":"","color":"primary"},on:{"click":function($event){return _vm.datesChanged()}}},[_vm._v(" Submit ")])],1)],1),_c('v-divider'),(
          _vm.dashboardData &&
          _vm.dashboardData.trip &&
          _vm.dashboardData.trip.trip_status_date_wise_data
        )?_c('v-card-text',{staticClass:"Chart-Height"}):_vm._e()],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }