<template>
  <v-dialog max-width="65%" v-model="showRateForm">
    <v-card v-if="showRateForm">
      <v-card-title class="secondary white--text mb-4">
        <span v-if="editMode">Edit Rate</span>
        <span v-else>Add Rate</span>

        <v-spacer></v-spacer>

        <v-btn icon @click="$emit('closeBMRForm')">
          <v-icon class="white--text">mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-subtitle class="subHeading pt-0 pb-2">
        <v-row v-if="Object.keys(laneObj).length" class="mt-0">
          <v-col cols="12" md="4">
            <span class="text-h6 font-weight-medium"> Source :</span>
            <span class="text-body-1">
              {{ laneObj.source }}
            </span>
          </v-col>

          <v-col cols="12" md="4">
            <span class="text-h6 font-weight-medium"> Mid :</span>
            <span class="text-body-1">
              {{ laneObj.mid }}
            </span>
          </v-col>
          <v-col cols="12" md="4">
            <span class="text-h6 font-weight-medium"> Destination :</span>
            <span class="text-body-1">
              {{ laneObj.destination }}
            </span>
          </v-col>
        </v-row>
      </v-card-subtitle>

      <v-card-text class="pt-2">
        <v-form ref="rateForm" v-if="showRateForm" v-model="isValid">
          <v-row class="mt-0">
            <v-col cols="12" v-if="formErrors.non_field_errors">
              <v-alert
                close-icon="mdi-close"
                v-if="formErrors.non_field_errors.length"
                dense
                type="error"
              >
                <v-list
                  class="pa-0"
                  dense
                  style="background: inherit !important"
                  v-for="(error, i) in formErrors.non_field_errors"
                  :key="i"
                >
                  <v-list-item dense style="min-height: 20px !important">
                    <span>{{ i }} .</span><span>{{ error }}</span>
                  </v-list-item>
                </v-list>
              </v-alert>
            </v-col>

            <v-col cols="12" lg="3" class="Form-Columns">
              <MultiSelect
                label="Job Type *"
                :items="allJobTypeList"
                itemText="display_name"
                itemValue="value"
                :rules="[(val) => !!val || 'Job Type is required!']"
                v-model="bmrDetails.job_type"
                :error-messages="formErrors.job_type"
                @input="delete formErrors.job_type"
              />
            </v-col>
            <v-col cols="12" lg="3" class="Form-Columns">
              <MultiSelect
                label="Container Type *"
                :items="containerTypeList"
                itemText="type_name"
                itemValue="id"
                :rules="[(val) => !!val || 'Container Type is Required!']"
                v-model="bmrDetails.container_type"
                :error-messages="formErrors.container_type"
                @change="delete formErrors.container_type"
              />
            </v-col>

            <v-col cols="12" lg="3" class="Form-Columns">
              <InputField
                type="number"
                label="Container Count *"
                min="1"
                max="4"
                :rules="[
                  (v) => !!v || 'Container Count is Required!',
                  (v) => v > 0 || 'Container count can not be less than 1',
                  (v) => v < 5 || 'Container count can not be more than 4',
                ]"
                required
                v-model="bmrDetails.container_count"
                :error-messages="formErrors.container_count"
                @input="delete formErrors.container_count"
              />
            </v-col>

            <v-col cols="12" lg="3" class="Form-Columns">
              <InputField
                type="number"
                :label="`Rate (${$globals.currency}) *`"
                step="0.01"
                :rules="[(val) => !!val || 'Rate is required!']"
                required
                v-model="bmrDetails.rate"
                :error-messages="formErrors.rate"
                @input="delete formErrors.rate"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-divider></v-divider>
      <v-card-actions class="d-flex justify-end">
        <v-btn
          color="primary"
          depressed
          :disabled="!isValid"
          @click="submitForm"
        >
          Submit
        </v-btn>
        <v-btn
          class="ml-3"
          color="secondary"
          depressed
          @click="resetForm"
          v-if="!editMode"
        >
          Reset
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import InputField from "@/components/FormBaseComponents/InputField.vue";
import MultiSelect from "@/components/FormBaseComponents/MultiSelect.vue";
export default {
  components: {
    MultiSelect,
    InputField,
  },
  props: {
    value: {
      type: Boolean,
    },
    laneObj: {
      type: Object,
      default: () => ({}),
    },
    bmrId: {
      type: Number,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    showRateForm: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  watch: {
    value(val) {
      if (val) {
        this.getJobTypes();
        this.getContainerTypeList();
      } else {
        this.bmrDetails = {};
        this.formErrors = {};
      }

      if (val && this.editMode && this.bmrId != null) {
        this.getBMRDetails(this.bmrId);
      }
    },
  },
  data() {
    return {
      isValid: true,
      allJobTypeList: [],
      containerTypeList: [],
      bmrDetails: {},
      formErrors: {},
    };
  },

  methods: {
    getJobTypes() {
      let payload = {
        source_location: this.laneObj.source_location,
        mid_location: this.laneObj.mid_location,
        destination_location: this.laneObj.destination_location,
      };
      if (payload) {
        this.$bus.$emit("showLoader", true);
        this.$api.customerContract
          .getJobTypes(payload)
          .then((res) => {
            this.allJobTypeList = res.data;
            this.$bus.$emit("showLoader", false);
          })
          .catch((err) => {
            console.error(err);
            this.$bus.$emit("showLoader", false);
          });
      }
    },
    getBMRDetails(id) {
      this.$bus.$emit("showLoader", true);
      this.$api.bmr
        .getBMRObject(id)
        .then((res) => {
          this.$bus.$emit("showLoader", false);
          this.bmrDetails = res.data;
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },

    submitForm() {
      this.$bus.$emit("showLoader", true);
      let payload;
      if (!this.editMode) {
        payload = {
          lane: this.laneObj.id,
          ...this.bmrDetails,
        };
        this.$api.bmr
          .addBMR(payload)
          .then((res) => {
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message: "BMR Added Successfully!",
              color: "success",
            });
            this.$emit("refreshList");
            this.showRateForm = false;
          })
          .catch((err) => {
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message: "Couldn't Add BMR!",
              color: "error",
            });
            this.formErrors = err.data;
          });
      } else {
        payload = {
          id: this.bmrDetails.id,
          data: this.bmrDetails,
        };
        this.$api.bmr
          .updateBMR(payload)
          .then((res) => {
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message: "BMR Updated Successfully!",
              color: "success",
            });
            this.$emit("refreshList");
            this.resetForm();
            this.showRateForm = false;
          })
          .catch((err) => {
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message: "Couldn't Update BMR!",
              color: "error",
            });
            this.formErrors = err.data;
          });
      }
    },
    resetForm() {
      this.$refs.rateForm.reset();
    },

    getContainerTypeList() {
      this.$api.containerType
        .getContainerTypeSelectList({ limit: "all" })
        .then((res) => {
          this.containerTypeList = res.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
};
</script>

<style></style>
