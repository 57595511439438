<template>
  <v-dialog v-model="showCombineLegsForm" width="80%">
    <v-card>
      <v-card-title class="secondary white--text">
        <span class="text-capitalize"> Combine Legs </span>
        <v-chip small class="ml-2">
          {{
            legDetails.current_leg
              ? legDetails.current_leg.source_location_name
              : ""
          }}</v-chip
        >
        <span class="mdi mdi-arrow-right-thin ml-2"></span>
        <v-chip small class="ml-2">
          {{
            legDetails.current_leg
              ? legDetails.current_leg.mid_location_name
              : ""
          }}</v-chip
        >
        <span
          class="mdi mdi-arrow-right-thin ml-2"
          v-if="
            legDetails.current_leg &&
            legDetails.current_leg.destination_location_name
          "
        ></span>
        <v-chip
          small
          class="ml-2"
          v-if="
            legDetails.current_leg &&
            legDetails.current_leg.destination_location_name
          "
        >
          {{
            legDetails.current_leg
              ? legDetails.current_leg.destination_location_name
              : ""
          }}
        </v-chip>
        <v-spacer></v-spacer>

        <v-btn icon @click="showCombineLegsForm = false">
          <v-icon class="white--text">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text
        v-if="formErrors && formErrors.non_field_errors"
        class="mt-3"
      >
        <v-alert close-icon="mdi-close" dense type="error">
          <v-list
            class="pa-0"
            dense
            style="background: inherit !important"
            v-for="(error, i) in formErrors.non_field_errors"
            :key="i"
          >
            <v-list-item dense style="min-height: 20px !important">
              <span>{{ i + 1 }} .</span><span>{{ error }}</span>
            </v-list-item>
          </v-list>
        </v-alert>
      </v-card-text>

      <v-card-text class="pt-2">
        <v-row>
          <v-col cols="12" class="pb-0">
            <div class="d-flex">
              <span class="text-h6">Selected Leg Details</span>
            </div>
            <v-divider></v-divider>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="2">
            <span class="text-caption">Leg Id</span>
            <p class="text-subtitle-1 font-weight-medium mb-0">
              {{ legDetails.current_leg ? legDetails.current_leg.leg_id : "" }}
            </p>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="2">
            <span class="text-caption">DA</span>
            <p class="text-subtitle-1 font-weight-medium mb-0">
              {{ legDetails.current_leg ? legDetails.current_leg.da_no : "" }}
            </p>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="2">
            <span class="text-caption">Containers</span>
            <p class="text-subtitle-1 font-weight-medium mb-0">
              {{
                legDetails.current_leg
                  ? legDetails.current_leg.leg_containers
                      .map((c) => c.order_container_no)
                      .join()
                  : ""
              }}
            </p>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="2">
            <span class="text-caption">Trip Id</span>
            <p class="text-subtitle-1 font-weight-medium mb-0">
              {{
                legDetails.current_leg
                  ? legDetails.current_leg.leg_trip.trip_id
                  : ""
              }}
            </p>
          </v-col>

          <v-col cols="12" sm="6" md="4" lg="2">
            <span class="text-caption">Transporter</span>
            <p class="text-subtitle-1 font-weight-medium mb-0">
              {{
                legDetails.current_leg
                  ? legDetails.current_leg.company_name
                  : ""
              }}
            </p>
          </v-col>

          <v-col cols="12" sm="6" md="4" lg="2">
            <span class="text-caption">BOE</span>
            <p class="text-subtitle-1 font-weight-medium mb-0">
              {{ legDetails.current_leg ? legDetails.current_leg.boe : "" }}
            </p>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text>
        <v-row>
          <v-col cols="12" lg="8" class="Form-Columns">
            <span class="text-h6">Similar Legs</span>
          </v-col>
          <v-col cols="12" lg="4" class="Form-Columns text-right">
            <v-text-field
              label="Search"
              outlined
              dense
              id="filter-text-box"
              append-icon="mdi-magnify"
              clearable
              hide-details="auto"
              v-model="search"
              @input="onFilterTextBoxChanged()"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <AgGridVue
              style="width: 100%; height: 25vh"
              class="ag-theme-alpine"
              rowSelection="multiple"
              id="combineLegGrid"
              :columnDefs="headers"
              :context="context"
              @grid-ready="onGridReady"
              :defaultColDef="defaultColDef"
              :grid-options="gridOptions"
              :rowData="allLegsList"
              @selection-changed="setSelectedRows"
            ></AgGridVue>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text class="pt-2">
        <v-form ref="combineLegAssetForm" v-if="value" v-model="isValid">
          <v-row>
            <v-col
              cols="12"
              md="6"
              lg="2"
              v-if="availableTrips && availableTrips.length"
            >
              <MultiSelect
                :returnObject="true"
                hide-details="auto"
                :items="availableTrips"
                itemText="trip_id"
                itemValue="id"
                label="Select Trip"
                required
                :rules="[(val) => !!val || 'Trip is required']"
                v-model="selectedTrip"
                @change="getAllocatedAssets()"
              ></MultiSelect>
            </v-col>
            <v-col cols="12" lg="2">
              <DateField
                ref="TripDateField"
                :inputProps="{
                  dense: true,
                  label: 'Trip Date *',
                  rules: [(val) => !!val || 'Trip Date is Required!'],
                  'hide-details': 'auto',
                  'error-messages': formErrors.trip_date,
                }"
                :min="today"
                v-model="assetFormDetails.trip_date"
                @change="
                  delete formErrors.trip_date;
                  refreshFormDetails();
                  checkAssetType();
                "
              />
            </v-col>

            <v-col cols="12" md="6" lg="2">
              <MultiSelect
                label="Select Driver *"
                hide-details="auto"
                :items="driverList"
                itemText="driver_name"
                itemValue="id"
                :disabled="!assetFormDetails.trip_date"
                :rules="[(val) => !!val || 'Driver is required']"
                v-model="assetFormDetails.driver"
                :error-messages="formErrors.driver"
                @change="vehicleAssigned"
              />
            </v-col>

            <v-col cols="12" md="6" lg="2">
              <MultiSelect
                label="Select Vehicle *"
                :items="vehicleList"
                itemText="inventory_number"
                itemValue="id"
                hide-details="auto"
                :disabled="!assetFormDetails.trip_date"
                :rules="[(val) => !!val || 'Vehicle is required']"
                v-model="assetFormDetails.vehicle"
                :error-messages="formErrors.vehicle"
                @change="delete formErrors.vehicle"
              />
            </v-col>

            <v-col cols="12" md="6" lg="2">
              <MultiSelect
                label="Select Trailer 1*"
                :items="trailer1List"
                hide-details="auto"
                itemText="inventory_number"
                itemValue="id"
                :disabled="!assetFormDetails.trip_date"
                v-model="assetFormDetails.trailer_1"
                :rules="[(val) => !!val || 'Trailer 1 is required']"
                :error-messages="formErrors.trailer_1"
                @change="delete formErrors.trailer_1"
              />
            </v-col>

            <v-col cols="12" md="6" lg="2">
              <MultiSelect
                label="Select Trailer 2"
                :items="trailer2List"
                itemText="inventory_number"
                itemValue="id"
                hide-details="auto"
                :clearable="true"
                :item-disabled="
                  (item) => {
                    return item.id == assetFormDetails.trailer_1;
                  }
                "
                :disabled="
                  !assetFormDetails.trip_date || !assetFormDetails.trailer_1
                "
                v-model="assetFormDetails.trailer_2"
                :error-messages="formErrors.trailer_2"
                @change="delete formErrors.trailer_2"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-divider></v-divider>
      <v-card-actions class="d-flex justify-end">
        <v-btn
          color="primary"
          depressed
          :disabled="!isValid"
          @click="submitForm"
        >
          Submit
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
 
<script>
import DateField from "@/components/FormBaseComponents/DateField.vue";
import InputField from "@/components/FormBaseComponents/InputField.vue";
import MultiSelect from "@/components/FormBaseComponents/MultiSelect.vue";
import SelectField from "@/components/FormBaseComponents/SelectField.vue";
import FormComponent from "@/components/General/FormComponent.vue";
import { AgGridVue } from "ag-grid-vue";
import moment from "moment";

export default {
  components: {
    DateField,
    InputField,
    SelectField,
    MultiSelect,
    AgGridVue,
    FormComponent,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    legId: {
      type: [Number, String, null],
      default: null,
    },
    companyId: {
      required: true,
    },
  },
  watch: {
    value(val) {
      if (val) {
        this.formErrors = {};
        this.getSimillarLegs();
      } else {
        this.resetValues();
      }
    },
  },
  data() {
    return {
      getListCalled: false,
      trailerListTimeout: null,
      driverListTimeout: null,
      vehicleListTimeout: null,
      today: new Date().toISOString().slice(0, 16),

      search: null,
      allLegsList: [],
      selectedLegs: [],
      selectedLegIds: [],

      formErrors: {},
      selectedTrip: null,
      isValid: true,

      vehicleList: [],

      driverList: [],
      trailer1List: [],
      trailer2List: [],
      trip_date: null,
      allListApi: [],
      routeDuration: null,

      legDetails: {},
      assetFormDetails: {},
      headers: [
        {
          headerName: "Leg",
          field: "leg_id",
          minWidth: 140,
          checkboxSelection: true,
        },
        {
          headerName: "Customer",
          field: "customer_name",
          minWidth: 140,
        },
        {
          headerName: "DA",
          field: "da_no",
          minWidth: 120,
        },

        {
          headerName: "Containers",
          field: "leg_containers",
          minWidth: 180,
          valueFormatter: (params) => {
            return params.data.leg_containers
              .map((c) => c.order_container_no)
              .join();
          },
          getQuickFilterText: (params) => {
            if (params.value && params.value.length > 0) {
              return params.value.map((c) => c.order_container_no).join();
            }
          },
        },
        {
          headerName: "Trip",
          field: "leg_trip",
          minWidth: 110,
          valueFormatter: (params) => {
            if (params.data.leg_trip.trip_id) {
              return params.data.leg_trip.trip_id;
            } else {
              return "";
            }
          },
          getQuickFilterText: (params) => {
            if (params.value) {
              return params.value.trip_id;
            }
          },
        },
        {
          headerName: "BOE",
          field: "boe",
          minWidth: 110,
        },
      ],

      defaultColDef: {
        resizable: true,
        flex: 1,
      },
      gridOptions: {
        onGridSizeChanged: () => {
          this.resizeGrid();
        },
        cacheQuickFilter: true,
        suppressRowClickSelection: true,
        suppressDragLeaveHidesColumns: true,
        enableCellTextSelection: true,
        tooltipShowDelay: 0,
        rowHeight: 35,
      },
    };
  },

  computed: {
    showCombineLegsForm: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    context() {
      return { parentComponent: this };
    },
    availableTrips() {
      if (
        this.legDetails &&
        this.legDetails.current_leg &&
        this.legDetails.current_leg.leg_trip &&
        Object.keys(this.legDetails.current_leg.leg_trip).length
      ) {
        this.legDetails.current_leg.leg_trip.leg_id =
          this.legDetails.current_leg.id;
      }
      return [
        ...this.selectedLegs.map((leg) => {
          if (Object.keys(leg.leg_trip).length) {
            leg.leg_trip.leg_id = leg.id;
          }
          return leg.leg_trip;
        }),
        this.legDetails.current_leg && this.legDetails.current_leg.leg_trip
          ? this.legDetails.current_leg.leg_trip
          : {},
      ].filter((obj) => Object.keys(obj).length);
    },
  },

  methods: {
    onFilterTextBoxChanged() {
      this.gridApi.setQuickFilter(
        document.getElementById("filter-text-box").value
      );
    },
    refreshFormDetails() {
      this.formDetails = { ...this.formDetails };
    },
    vehicleAssigned() {
      delete this.formErrors.driver;
      let assignedVehicle = this.driverList.find(
        (dri) => dri.id == this.assetFormDetails.driver
      )?.assigned_vehicle;
      let vehicleExist = this.vehicleList.find(
        (obj) => obj.id == assignedVehicle
      );
      if (vehicleExist) {
        this.assetFormDetails = {
          ...this.assetFormDetails,
          vehicle: assignedVehicle,
        };
      }
    },

    getLists() {
      if (this.getListCalled) {
        return;
      }
      this.$bus.$emit("showLoader", true);
      this.getListCalled = true;
      return new Promise((resolve, reject) => {
        Promise.all([
          this.getAssetList("Trailer"),
          this.getAssetList("Driver"),
          this.getAssetList("Vehicle"),
        ])
          .then(() => {
            this.getListCalled = false;
            this.$bus.$emit("showLoader", false);
            resolve();
          })
          .catch((err) => {
            this.getListCalled = false;
            this.$bus.$emit("showLoader", false);
            reject();
          });
      });
    },

    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.resizeGrid();
    },
    resizeGrid() {
      if (this.gridOptions && "api" in this.gridOptions) {
        setTimeout(() => {
          this.gridOptions.api.sizeColumnsToFit();
        }, 100);
      }
    },
    setSelectedRows() {
      this.selectedLegs = this.gridApi.getSelectedRows();
      this.resetFormValidation();
    },
    partResetValues() {
      this.assetFormDetails.trailer_1 = null;
      this.assetFormDetails.trailer_2 = null;
      this.assetFormDetails.vehicle = null;
      this.assetFormDetails.driver = null;
      this.resetFormValidation();
    },
    checkAssetType() {
      if (this.assetFormDetails.trip_date) {
        this.getLists();
      }
    },

    resetFormValidation() {
      if (this.$refs.combineLegAssetForm) {
        this.$refs.combineLegAssetForm.resetValidation();
      }
    },

    resetValues() {
      this.assetFormDetails = {};
      this.selectedTrip = null;
      this.allLegsList = [];
      this.selectedLegs = [];
      this.vehicleList = [];
      this.driverList = [];
      this.trailer1List = [];
      this.trailer2List = [];
      this.trip_date = null;
      this.selectedLegIds = [];
    },

    getAssetList(assetType) {
      const params = {
        ...params,
        asset_type: assetType,
        trip_date: this.assetFormDetails.trip_date,
      };

      if (this.legDetails && this.companyId) {
        params.company = this.companyId;
      }

      if (this.assetFormDetails.id) {
        params.trip = this.assetFormDetails.id;
      }

      return this.$api.trip
        .getTripAssetList(this.legId, params)
        .then((res) => {
          if (assetType == "Trailer") {
            this.trailer1List = res.data.trailer1;
            this.trailer2List = res.data.trailer2;
          }
          if (assetType == "Vehicle") {
            this.vehicleList = res.data;
          }
          if (assetType == "Driver") {
            this.driverList = res.data;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },

    async getAllocatedAssets() {
      this.allListApi = [];

      if (this.selectedTrip) {
        await this.$api.trip
          .getAllocatedAssets({ leg: this.selectedTrip.leg_id })
          .then(async (res) => {
            this.assetFormDetails = { ...res.data };
            if (this.assetFormDetails.duration) {
              this.routeDuration = this.assetFormDetails.duration;
            }
            if (this.assetFormDetails.trip_date) {
              this.assetFormDetails.trip_date = res.data.trip_date.replace(
                " ",
                "T"
              );
            }

            if (this.assetFormDetails.trip_date) {
              this.getLists()
                .then(() => {
                  this.assetFormDetails.vehicle = res.data.vehicle;
                  this.assetFormDetails.trailer_1 = res.data.trailer_1;
                  this.assetFormDetails.trailer_2 = res.data.trailer_2;
                  this.assetFormDetails.driver = res.data.driver;
                })
                .catch((err) => {
                  console.error(err);
                });
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }
    },

    getSimillarLegs(params = {}) {
      this.$bus.$emit("showLoader", true);

      params = { leg: this.legId };
      this.$api.trip
        .getSimillarLegs(params)
        .then((res) => {
          this.legDetails = res.data;
          this.allLegsList = [
            ...res.data.combined_legs,
            ...res.data.similar_legs,
          ];
          this.setSelectedValues(res.data.combined_legs);
          if (
            res.data &&
            res.data.current_leg &&
            res.data.current_leg.leg_trip
          ) {
            this.selectedTrip = {
              ...res.data.current_leg.leg_trip,
              leg_id: res.data.current_leg.id,
            };
            setTimeout(() => {
              this.getAllocatedAssets();
            }, 400);
          }
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },

    setSelectedValues(combineNodes = []) {
      const interval = setInterval(() => {
        if (this.gridApi) {
          clearInterval(interval);
          const selectedLegsIds = combineNodes.map((leg) => leg.id);
          this.gridApi.forEachNode((node) => {
            if (selectedLegsIds.indexOf(node.data.id) > -1) {
              node.setSelected(true);
            }
          });
        }
      }, 400);
    },

    submitForm() {
      this.formErrors = {};
      this.$bus.$emit("showLoader", true);
      const data = {
        current_leg: this.legDetails.current_leg.id,
        combined_legs: this.selectedLegs.map((leg) => leg.id),
        trip: {
          ...this.assetFormDetails,
        },
      };

      this.$api.trip
        .combineLegs(data)
        .then((res) => {
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message: "Leg Combined successfully!",
            color: "success",
          });
          this.resetFormValidation();
          this.showCombineLegsForm = false;
          this.$emit("refreshList");
        })
        .catch((err) => {
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message: "Couldn't Combine legs!",
            color: "error",
          });
          this.formErrors = err.data;
        });
    },
  },
};
</script>
 
