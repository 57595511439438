<template>
  <div>
    <v-tooltip top>
      <template #activator="{ on, attrs }">
        <v-icon
          class="mr-4 ml-1"
          color="primary"
          v-on="on"
          v-bind="attrs"
          @click="editItem"
        >
          mdi-pencil
        </v-icon>
      </template>
      <span>Edit</span>
    </v-tooltip>
    <v-tooltip top>
      <template #activator="{ on, attrs }">
        <v-icon
          class="ml-2 mr-1"
          color="primary"
          v-on="on"
          v-bind="attrs"
          @click="viewLogs"
        >
          mdi-information
        </v-icon>
      </template>
      <span>Lease Driver Logs</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  methods: {
    editItem() {
      this.params.context.parentComponent.editLeaseDriver(this.params.data.id);
    },
    viewLogs() {
      this.params.context.parentComponent.viewLeaseDriverLogs(this.params.data.id);
    },
  },
};
</script>

<style></style>
