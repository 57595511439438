<template>
  <v-row class="justify-center align-center Login-Section">
    <v-col cols="12" sm="8" md="6" lg="4">
      <v-form @submit.prevent="submitForm" ref="signUpForm" v-model="isValid">
        <v-card elevation="10" class="pa-2">
          <v-card-title class="d-flex justify-center">
            <img
              class="Brand-Logo mb-10"
              alt="Brand Logo"
              src="@/assets/images/brand-logo.png"
            />
          </v-card-title>

          <v-card-text>
            <v-row class="justify-center">
              <!-- <v-col cols="12">
                <InputField
                  hide-details="auto"
                  label="Username"
                  autocomplete="off"
                  required
                  :dense="false"
                  :rules="[(val) => !!val || 'Username is required']"
                  v-model="userDetails.username"
                />
              </v-col>

              <v-col cols="12">
                <InputField
                  hide-details="auto"
                  label="Password"
                  autocomplete="off"
                  required
                  :dense="false"
                  :rules="[(val) => !!val || 'Password is required']"
                  :append-icon="passwordEye ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="passwordEye ? 'text' : 'password'"
                  v-model="userDetails.password"
                  @click:append="passwordEye = !passwordEye"
                  @input="validateForm"
                />
              </v-col>

              <v-col
                cols="12"
                :class="{
                  'pb-0':
                    userDetails.password &&
                    userDetails.confirm_password &&
                    userDetails.confirm_password != userDetails.password,
                }"
              >
                <InputField
                  hide-details="auto"
                  label="Confirm Password"
                  autocomplete="off"
                  required
                  :dense="false"
                  :rules="[(val) => !!val || 'Confirm Password is required']"
                  :type="passwordEye ? 'text' : 'password'"
                  v-model="userDetails.confirm_password"
                  @input="validateForm"
                />
              </v-col> -->

              <!-- <v-col
                v-if="
                  userDetails.password &&
                  userDetails.confirm_password &&
                  userDetails.confirm_password != userDetails.password
                "
                cols="12"
                class="pt-1"
              >
                <span class="px-3 error--text v-messages">
                  Password dosen't match.
                </span>
              </v-col> -->

              <v-col cols="12">
                <InputField
                  hide-details="auto"
                  type="text"
                  :rules="[(val) => !!val || 'First Name is Required!']"
                  label="First Name *"
                  required
                  v-model="userDetails.first_name"
                />
              </v-col>

              <v-col cols="12">
                <InputField
                  hide-details="auto"
                  type="text"
                  :rules="[(val) => !!val || 'Last Name is Required!']"
                  label="Last Name *"
                  required
                  v-model="userDetails.last_name"
                />
              </v-col>

              <v-col cols="12">
                <InputField
                  hide-details="auto"
                  type="text"
                  :rules="[(val) => !!val || 'Designation is Required!']"
                  label="Designation *"
                  required
                  v-model="userDetails.designation"
                />
              </v-col>

              <v-col cols="12" class="pa-2">
                <MultiSelect
                  hide-details="auto"
                  :items="compnayTypeList"
                  :rules="[(val) => !!val || 'User Type is Required!']"
                  label="User Type *"
                  itemText="user_type"
                  itemValue="value"
                  required
                  v-model="userDetails.user_type"
                />
              </v-col>
              <v-col cols="12" class="pa-2">
                <InputField
                  hide-details="auto"
                  type="text"
                  :rules="[(val) => !!val || 'Company Name is Required!']"
                  label="Company Name *"
                  required
                  v-model="userDetails.company_name"
                />
              </v-col>

              <v-col cols="12" class="pa-2">
                <InputField
                  hide-details="auto"
                  type="text"
                  :rules="[
                    (val) => !!val || 'Email is Required!',
                    (val) => /.+@.+\..+/.test(val) || 'Email must be valid',
                  ]"
                  label="Email *"
                  required
                  v-model="userDetails.email"
                />
              </v-col>

              <v-col cols="12" sm="4" md="4" lg="4" class="pa-2">
                <MultiSelect
                  hide-details="auto"
                  :items="allPhoneCodeList"
                  :rules="[(val) => !!val || 'Phone Code is Required!']"
                  label="Phone Code *"
                  itemText="phone_code"
                  itemValue="phone_code"
                  required
                  v-model="userDetails.phone_code"
                />
              </v-col>
              <v-col cols="12" sm="8" md="8" lg="8" class="pa-2">
                <InputField
                  hide-details="auto"
                  type="number"
                  :rules="[(val) => !!val || 'Contact Number is Required!']"
                  label="Contact Number *"
                  required
                  v-model="userDetails.contact_number"
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-row>
              <v-col cols="12" class="d-flex justify-center">
                <v-btn
                  type="submit"
                  color="primary"
                  depressed
                  :disabled="
                    !isValid ||
                    (userDetails.password &&
                      userDetails.confirm_password &&
                      userDetails.confirm_password !== userDetails.password)
                  "
                  :loading="btnLoading"
                  large
                >
                  Submit
                </v-btn>
              </v-col>
              <v-col cols="12" class="d-flex justify-center">
                <v-btn
                  @click="goToLogin"
                  type="button"
                  text
                  color="primary"
                  depressed
                  large
                >
                  Go To Login
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
import InputField from "@/components/FormBaseComponents/InputField.vue";
import MultiSelect from "@/components/FormBaseComponents/MultiSelect.vue";
import { encryptText } from "@/utils/functions";
export default {
  components: { InputField, MultiSelect },
  layout: "sigup",
  data() {
    return {
      formErrors: {},
      userDetails: {},
      allPhoneCodeList: [],
      compnayTypeList: ["Customer", "Transporter"],
      btnLoading: false,
      password: "Password",
      passwordEye: false,
      isValid: true,
      timer: 30,
      interval: null,
      disableOtpBtn: false,
    };
  },
  methods: {
    goToLogin() {
      this.$router.push("/app/login");
    },

    validateForm() {
      if (
        this.userDetails.password &&
        this.userDetails.confirm_password &&
        this.$refs.signUpForm
      ) {
        this.$refs.signUpForm.validate();
      }
    },
    reset() {
      this.userDetails = {};
    },
    getPhoneCodeList(params = {}) {
      this.$bus.$emit("showLoader", true);
      params = {
        ...params,
        limit: "all",
      };
      this.$api.unauthorisedApi
        .getCountryList(params)
        .then((res) => {
          this.allPhoneCodeList = res.data;
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    submitForm() {
      this.$bus.$emit("showLoader", true);
      let payload = {
        data: this.userDetails,
      };
      if (this.userDetails && this.userDetails.password) {
        payload.data.password = encryptText(this.userDetails.password);
      }
      
      this.$api.unauthorisedApi
        .addUser(payload.data)
        .then((res) => {
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message: "Check Your Mail Once Momentum Accept Your Request",
            color: "success",
          });
          this.reset();
          this.$router.push("/app/login");
        })
        .catch((err) => {
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message: "Couldn't Update User!",
            color: "error",
          });
          this.formErrors = err.data;
        });
    },
  },
  beforeMount() {
    this.getPhoneCodeList();
  },
};
</script>

<style scoped>
.Login-Section {
  height: 100vh;
}

.Login-Section .Brand-Logo {
  width: 250px;
}
</style>
