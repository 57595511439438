<template>
  <FormComponent
    title="Edit Vehicle"
    backRoute="/app/admin/asset-management/vehicle/list"
    :nonFieldErrors="formErrors.non_field_errors"
  >
    <template #formActions>
      <v-btn
        color="primary"
        depressed
        name="vehicle-form-update"
        :disabled="!allFormValidation"
        @click="submitForm"
      >
        Update
      </v-btn>
    </template>

    <template #formContent>
      <v-stepper v-model="stepper" non-linear flat>
        <v-stepper-header class="elevation-0">
          <v-stepper-step
            :rules="[() => (Object.keys(formErrors).length ? valid1 : true)]"
            step="1"
            editable
            name="vehicle-form-stepper-basic"
          >
            Basic Details
          </v-stepper-step>
          <v-divider class="ma-0"></v-divider>

          <v-stepper-step
            step="2"
            editable
            name="vehicle-form-stepper-document"
          >
            Document Upload
          </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1" class="pa-0">
            <v-form ref="stepperForm1" v-model="valid1">
              <v-row class="mx-2 my-0">
                <v-col cols="12" class="Form-Columns-Dense">
                  <span class="text-h6 font-weight-regular">Basic Details</span>
                </v-col>
                <v-col cols="12" lg="4" class="Form-Columns-Dense">
                  <MultiSelect
                    label="Vehicle Type *"
                    name="vehicle-form-vehicle-type"
                    :returnObject="true"
                    :items="vehicleTypeList"
                    itemText="type_name"
                    itemValue="id"
                    :rules="[(value) => !!value || 'Vehicle Type is Required!']"
                    hide-details="auto"
                    v-model="vehicleDetails.vehicle_type"
                    :error-messages="formErrors.vehicle_type"
                    @input="delete formErrors.vehicle_type"
                  ></MultiSelect>
                </v-col>

                <v-col cols="12" lg="4" class="Form-Columns-Dense">
                  <MultiSelect
                    label="Depot *"
                    name="vehicle-form-depot"
                    :returnObject="false"
                    :items="allDepot"
                    itemText="depot_name"
                    itemValue="depot_name"
                    :rules="[(value) => !!value || 'Depot  is Required!']"
                    hide-details="auto"
                    v-model="vehicleDetails.depot"
                    :error-messages="formErrors.depot"
                    @input="delete formErrors.depot"
                  ></MultiSelect>
                </v-col>
                <v-col cols="12" lg="4" class="Form-Columns-Dense">
                  <InputField
                    type="text"
                    label="Vehicle Id *"
                    name="vehicle-form-vehicle-id"
                    maxlength="30"
                    :rules="[(value) => !!value || 'Vehicle Id is Required!']"
                    required
                    hide-details="auto"
                    v-model="vehicleDetails.equipment_id"
                    :error-messages="formErrors.equipment_id"
                    @input="delete formErrors.equipment_id"
                  />
                </v-col>
                <v-col cols="12" lg="4" class="Form-Columns-Dense">
                  <InputField
                    type="text"
                    label="Vehicle Number *"
                    name="vehicle-form-vehicle-number"
                    maxlength="30"
                    :rules="[
                      (value) => !!value || 'Vehicle Number is Required!',
                    ]"
                    required
                    hide-details="auto"
                    v-model="vehicleDetails.vehicle_number"
                    :error-messages="formErrors.vehicle_number"
                    @input="delete formErrors.vehicle_number"
                  />
                </v-col>
                <v-col cols="12" lg="4" class="Form-Columns-Dense">
                  <InputField
                    type="text"
                    label="Fleet Number *"
                    name="vehicle-form-inventory-number"
                    :rules="[(value) => !!value || 'Fleet Number is Required!']"
                    required
                    hide-details="auto"
                    v-model="vehicleDetails.inventory_number"
                    :error-messages="formErrors.inventory_number"
                    @input="delete formErrors.inventory_number"
                  />
                </v-col>
                <v-col cols="12" lg="4" class="Form-Columns-Dense">
                  <InputField
                    type="text"
                    label="Location"
                    name="vehicle-form-location"
                    maxlength="255"
                    hide-details="auto"
                    v-model="vehicleDetails.location"
                    :error-messages="formErrors.location"
                    @input="delete formErrors.location"
                  />
                </v-col>

                <v-col cols="12" lg="4">
                  <v-row>
                    <v-col cols="12" class="Form-Columns-Dense">
                      <span class="text-h6 font-weight-regular">
                        Imco License Details
                      </span>
                    </v-col>
                    <v-col cols="12" lg="6" class="Form-Columns-Dense">
                      <InputField
                        type="text"
                        label="Imco License No"
                        name="vehicle-form-imco-license-no"
                        maxlength="255"
                        hide-details="auto"
                        v-model="vehicleDetails.imco_license_no"
                        :error-messages="formErrors.imco_license_no"
                        @input="delete formErrors.imco_license_no"
                      />
                    </v-col>
                    <v-col cols="12" lg="6" class="Form-Columns-Dense">
                      <DateField
                        :inputProps="{
                          dense: true,
                          label: 'Imco License Expiry',
                          name: 'vehicle-form-imco-license-expiry',
                          clearable: true,
                          'hide-details': 'auto',
                          'error-messages': formErrors.imco_license_expiry,
                        }"
                        v-model="vehicleDetails.imco_license_expiry"
                        @input="delete formErrors.imco_license_expiry"
                      />
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="12" lg="8">
                  <v-row>
                    <v-col cols="12" class="Form-Columns-Dense">
                      <span class="text-h6 font-weight-regular">
                        Identification Details
                      </span>
                    </v-col>
                    <v-col cols="12" lg="3" class="Form-Columns-Dense">
                      <DateField
                        :inputProps="{
                          dense: true,
                          label: 'Registration Start Date',
                          name: 'vehicle-form-registration-start-date',
                          clearable: true,
                          'hide-details': 'auto',
                          'error-messages': formErrors.reg_start_date,
                        }"
                        v-model="vehicleDetails.reg_start_date"
                        @input="delete formErrors.reg_start_date"
                      />
                    </v-col>
                    <v-col cols="12" lg="3" class="Form-Columns-Dense">
                      <DateField
                        :inputProps="{
                          dense: true,
                          label: 'Registration End Date',
                          name: 'vehicle-form-registration-end-date',
                          clearable: true,
                          'hide-details': 'auto',
                          'error-messages': formErrors.reg_exp_date,
                        }"
                        v-model="vehicleDetails.reg_exp_date"
                        @input="delete formErrors.reg_exp_date"
                      />
                    </v-col>
                    <v-col cols="12" lg="3" class="Form-Columns-Dense">
                      <InputField
                        type="text"
                        label="GPS ID"
                        name="vehicle-form-gps-id"
                        hide-details="auto"
                        v-model="vehicleDetails.gps_id"
                        :error-messages="formErrors.gps_id"
                        @input="delete formErrors.gps_id"
                      />
                    </v-col>
                    <v-col cols="12" lg="3" class="Form-Columns-Dense">
                      <InputField
                        type="number"
                        label="Odometer Reading"
                        name="vehicle-form-odometer-reading"
                        hide-details="auto"
                        v-model="vehicleDetails.odometer_reading"
                        :error-messages="formErrors.odometer_reading"
                        @input="delete formErrors.odometer_reading"
                      />
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="12" class="py-0">
                  <span class="text-h6 font-weight-regular">
                    Dimensions Details
                  </span>
                </v-col>
                <v-col cols="12" lg="2" class="Form-Columns-Dense">
                  <InputField
                    type="number"
                    label="Payload Capacity (TON) *"
                    name="vehicle-form-payload-capacity"
                    :rules="[
                      (value) => !!value || 'Payload Capacity is Required!',
                    ]"
                    required
                    hide-details="auto"
                    v-model="vehicleDetails.payload_capacity"
                    :error-messages="formErrors.payload_capacity"
                    @input="delete formErrors.payload_capacity"
                  />
                </v-col>
                <v-col cols="12" lg="2" class="Form-Columns-Dense">
                  <InputField
                    type="number"
                    label="Length (Meter)"
                    name="vehicle-form-length"
                    hide-details="auto"
                    v-model="vehicleDetails.length"
                    :error-messages="formErrors.length"
                    @input="delete formErrors.length"
                  />
                </v-col>
                <v-col cols="12" lg="2" class="Form-Columns-Dense">
                  <InputField
                    type="number"
                    label="Width (Meter)"
                    name="vehicle-form-width"
                    hide-details="auto"
                    v-model="vehicleDetails.width"
                    :error-messages="formErrors.width"
                    @input="delete formErrors.width"
                  />
                </v-col>
                <v-col cols="12" lg="2" class="Form-Columns-Dense">
                  <InputField
                    type="number"
                    label="Height (Meter)"
                    name="vehicle-form-height"
                    hide-details="auto"
                    v-model="vehicleDetails.height"
                    :error-messages="formErrors.height"
                    @input="delete formErrors.height"
                  />
                </v-col>
                <v-col cols="12" lg="2" class="Form-Columns-Dense">
                  <InputField
                    v-if="
                      vehicleDetails.vehicle_type &&
                      vehicleDetails.vehicle_type.temperature_controlled &&
                      vehicleDetails.vehicle_type.temperature_controlled ==
                        'Yes'
                    "
                    type="number"
                    label="Temperature Min *"
                    name="vehicle-form-temperature-min"
                    step="0.01"
                    :rules="[
                      (value) => !!value || 'Temperature Min is Required!',
                    ]"
                    required
                    hide-details="auto"
                    v-model="vehicleDetails.temperature_min"
                    :error-messages="formErrors.temperature_min"
                    @input="delete formErrors.temperature_min"
                  />
                </v-col>
                <v-col cols="12" lg="2" class="Form-Columns-Dense">
                  <InputField
                    v-if="
                      vehicleDetails.vehicle_type &&
                      vehicleDetails.vehicle_type.temperature_controlled &&
                      vehicleDetails.vehicle_type.temperature_controlled ==
                        'Yes'
                    "
                    type="number"
                    label="Temperature Max *"
                    name="vehicle-form-temperature-max"
                    step="0.01"
                    :rules="[
                      (value) => !!value || 'Temperature Max is Required!',
                    ]"
                    required
                    hide-details="auto"
                    v-model="vehicleDetails.temperature_max"
                    :error-messages="formErrors.temperature_max"
                    @input="delete formErrors.temperature_max"
                  />
                </v-col>

                <v-col cols="12" class="Form-Columns-Dense">
                  <v-btn
                    color="primary"
                    name="vehicle-form-stepper-basic-next"
                    @click="stepper = 2"
                    :disabled="!valid1"
                    class="mt-6"
                  >
                    Next
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-stepper-content>

          <v-stepper-content step="2" class="pa-0">
            <v-form ref="stepperForm2" v-model="valid2">
              <v-row class="mx-2 my-0">
                <v-col cols="12" class="Form-Columns-Dense">
                  <span class="text-h6 font-weight-regular">
                    Upload Documents
                  </span>
                </v-col>
                <v-col cols="12" lg="3" class="Form-Columns-Dense">
                  <MultiSelect
                    label="Document Type"
                    name="vehicle-form-document-type"
                    :items="documentTypes"
                    hide-details="auto"
                    v-model="document_type"
                    :error-messages="formErrors.document_type"
                    @input="delete formErrors.document_type"
                  />
                </v-col>
                <v-col cols="12" lg="8" class="Form-Columns-Dense">
                  <v-file-input
                    label="Document"
                    name="vehicle-form-document"
                    accept="image/x-png,image/jpg,image/jpeg,application/pdf"
                    outlined
                    dense
                    :rules="[
                      (file) =>
                        !file ||
                        file.size < 2e6 ||
                        'File size should be less than 2 MB!',
                    ]"
                    hide-details="auto"
                    v-model="document"
                    :error-messages="formErrors.document"
                    @change="delete formErrors.document, getFileData($event)"
                  />
                </v-col>

                <v-col
                  cols="12"
                  lg="1"
                  class="Form-Columns-Dense align-self-center"
                >
                  <v-btn
                    color="primary"
                    name="vehicle-form-document-add"
                    depressed
                    :disabled="isDisabled"
                    @click="addDocument"
                  >
                    Add
                  </v-btn>
                </v-col>

                <v-col cols="12" class="Form-Columns-Dense my-6">
                  <v-btn
                    color="secondary"
                    @click="stepper = 2"
                    name="vehicle-form-stepper-document-previous"
                  >
                    Previous
                  </v-btn>
                </v-col>

                <v-col
                  cols="12"
                  class="Form-Columns-Dense"
                  v-show="
                    vehicleDetails.vehicle_documents &&
                    vehicleDetails.vehicle_documents.length
                  "
                >
                  <span class="text-h6 font-weight-regular">Uploaded</span>

                  <v-card elevation="0" outlined>
                    <v-card-text class="Form-Columns-Dense">
                      <v-row
                        class="ma-0"
                        v-for="(data, i) in vehicleDetails.vehicle_documents"
                        :key="i"
                      >
                        <v-col cols="12" lg="5" class="py-1">
                          <a
                            :href="data.document"
                            target="_black"
                            name="vehicle-form-new-uploaded-document-type"
                          >
                            <span>{{ data.document_type }}</span>
                          </a>
                        </v-col>

                        <v-col cols="12" lg="3" class="py-1">
                          Created on : {{ data.created | formatDate(true) }}
                        </v-col>

                        <v-col cols="12" lg="3" class="py-1">
                          Added By : {{ data.created_by }}
                        </v-col>

                        <v-col cols="12" lg="1" class="text-right py-1">
                          <v-icon
                            small
                            color="red"
                            name="vehicle-form-new-uploaded-document-remove"
                            @click="deleteDocument(data, i)"
                          >
                            mdi-close
                          </v-icon>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>

                <v-col
                  cols="12"
                  class="Form-Columns-Dense"
                  v-show="vehicleNewDocuments && vehicleNewDocuments.length"
                >
                  <span class="text-h6 font-weight-regular">New Upload</span>

                  <v-card elevation="0" outlined>
                    <v-card-text class="Form-Columns-Dense">
                      <v-row
                        class="ma-0"
                        v-for="(data, j) in vehicleNewDocuments"
                        :key="j"
                      >
                        <v-col cols="12" lg="3" class="py-1">
                          <a
                            :href="generateUrl(data.document)"
                            target="_black"
                            name="vehicle-form-new-upload-document-type"
                          >
                            <span>{{ data.document_type }}</span>
                          </a>
                        </v-col>

                        <v-col cols="12" lg="6" class="py-1">
                          <a
                            :href="generateUrl(data.document)"
                            target="_black"
                            name="vehicle-form-new-upload-document"
                          >
                            <span>{{ data.document.name }}</span>
                          </a>
                        </v-col>

                        <v-col cols="12" lg="2" class="py-1">
                          <span>{{ getSize(data.document.size) }}</span>
                        </v-col>

                        <v-col cols="12" lg="1" class="text-right py-1">
                          <v-icon
                            small
                            color="primary"
                            @click="removeFile(j)"
                            name="vehicle-form-new-upload-document-remove"
                          >
                            mdi-close
                          </v-icon>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-form>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </template>
  </FormComponent>
</template>

<script>
import InputField from "@/components/FormBaseComponents/InputField.vue";
import SelectField from "@/components/FormBaseComponents/SelectField.vue";
import MultiSelect from "@/components/FormBaseComponents/MultiSelect.vue";
import TextareaField from "@/components/FormBaseComponents/TextareaField.vue";
import FormComponent from "@/components/General/FormComponent.vue";
import DateField from "@/components/FormBaseComponents/DateField.vue";

import {
  isAuthenticated,
  hasRouteAccess,
  getPermissions,
} from "@/utils/functions";

export default {
  components: {
    InputField,
    SelectField,
    MultiSelect,
    TextareaField,
    FormComponent,
    DateField,
  },

  data() {
    return {
      vehicleDetails: {},
      formErrors: {},
      stepper: "1",
      allDepot: [],
      valid1: true,
      valid2: true,
      // valid3: true,

      vehicleTypeList: [],

      // fields for docs
      vehicleNewDocuments: [],
      document_type: null,
      document: null,
      // Limitation on date input
      today: new Date().toISOString().slice(0, 10),
      documentTypes: [],
    };
  },

  computed: {
    allFormValidation() {
      return this.valid1 && this.valid2;
    },

    isDisabled() {
      if (this.document_type && this.document) {
        return false;
      } else {
        return true;
      }
    },
  },

  methods: {
    checkFileFormat(event) {
      const name = event.name;
      const lastDot = name.lastIndexOf(".");
      const ext = name.substring(lastDot + 1);
      let allowed = /(\jpg|\jpeg|\pdf|\png)$/i;
      if (!allowed.exec(ext)) {
        this.$nextTick(() => {
          this.document = null;
        });
        return false;
      } else {
        return true;
      }
    },
    getFileData(fileInput) {
      if (fileInput) {
        if (this.checkFileFormat(fileInput)) {
          if (fileInput && !this.document_type) {
            this.$nextTick(() => {
              this.document_type = null;
              this.document = null;
            });
            this.$bus.$emit("showToastMessage", {
              message: "Select Document type first!",
              color: "error",
            });
            return false;
          }
        } else {
          alert("This file format is not Supported!");
        }
      }
    },
    checkVehicle() {
      this.vehicleDetails.temperature_controlled =
        this.vehicleDetails.vehicle_type.temperature_controlled == "Yes";
    },
    addDocument() {
      this.vehicleNewDocuments.push({
        document_type: this.document_type,
        document: this.document,
      });
      this.document = null;
      this.document_type = null;
    },
    getDepotList(params = {}) {
      params = {
        limit: "all",
      };
      this.$api.depot
        .getDepotList(params)
        .then((res) => {
          this.allDepot = res.data;
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    deleteDocument(document, index) {
      if (
        confirm(`Are you sure you want to delete ${document.document_type} ?`)
      ) {
        this.$bus.$emit("showLoader", true);
        this.$api.vehicle
          .deleteVehicleDocument(document.id)
          .then((res) => {
            this.$bus.$emit("showToastMessage", {
              message: "Document deleted successfully!",
              color: "success",
            });
            this.vehicleDetails.vehicle_documents.splice(index, 1);
            this.$bus.$emit("showLoader", false);
          })
          .catch((err) => {
            this.$bus.$emit("showToastMessage", {
              message: "Couldn't delete Driver document!",
              color: "error",
            });
            this.$bus.$emit("showLoader", false);
          });
      }
    },
    removeFile(index) {
      this.vehicleNewDocuments.splice(index, 1);
    },
    generateUrl(file) {
      return URL.createObjectURL(file);
    },
    getSize(bytes) {
      var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
      if (bytes == 0) return "0 Byte";
      var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
      return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
    },

    submitForm() {
      this.$bus.$emit("showLoader", true);

      if (typeof this.vehicleDetails.vehicle_type == typeof {}) {
        this.vehicleDetails.vehicle_type = this.vehicleDetails.vehicle_type.id;
      }
      this.formErrors = {};
      let payload = { ...this.vehicleDetails };

      this.$api.vehicle
        .updateVehicle({
          id: this.vehicleDetails.id,
          data: payload,
        })
        .then(async (res) => {
          if (this.vehicleNewDocuments.length > 0) {
            await this.uploadVehicleDocuments(payload.id);
          }
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message: `Vehicle ${res.data.equipment_id} Updated successfully!`,
            color: "success",
          });
          this.$router.push("/app/admin/asset-management/vehicle/list");
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message: "Couldn't Update Vehicle!",
            color: "error",
          });
          this.formErrors = err.data;
        });
    },
    async uploadVehicleDocuments(id) {
      let i = 0;
      while (i < this.vehicleNewDocuments.length) {
        let payload = {
          id: id,
          data: null,
        };
        let newForm = new FormData();
        newForm.append(
          "document_type",
          this.vehicleNewDocuments[i].document_type
        );
        newForm.append("document", this.vehicleNewDocuments[i].document);
        payload.data = newForm;
        await this.$api.vehicle.uploadVehicleDocument(payload);
        i++;
      }
    },

    async getVehicleDetails(id) {
      await this.$api.vehicle
        .getVehicleObject(id)
        .then((res) => {
          this.vehicleDetails = res.data;

          this.vehicleDetails.temperature_controlled =
            this.vehicleTypeList.find((obj) => {
              return obj.id == this.vehicleDetails.vehicle_type;
            }).temperature_controlled == "Yes";
        })
        .catch((err) => {
          console.error(err);
        });
    },

    getAllVehicleTypeList() {
      this.$api.vehicleType
        .getVehicleTypeSelectList({ limit: "all" })
        .then((res) => {
          this.vehicleTypeList = res.data;
        })
        .catch((err) => {
          console(err);
        });
    },

    getVehicleDocumentOptions() {
      this.$bus.$emit("showLoader", true);
      this.$api.vehicle
        .getVehicleDocumentOptions()
        .then((res) => {
          this.documentTypes =
            res.data.actions.POST.vehicle_documents.child.children.document_type.choices.map(
              (obj) => {
                return {
                  text: obj.display_name,
                  value: obj.value,
                };
              }
            );
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
  },

  beforeMount() {
    if (!isAuthenticated()) {
      this.$router.push("/");
    } else if (!hasRouteAccess(this.$route)) {
      this.$router.push(`/${localStorage.getItem("user_type").toLowerCase()}`);
    } else {
      const permissions = getPermissions("vehicle");
      if (!permissions || !permissions.change) {
        this.$bus.$emit("showToastMessage", {
          message: "You don't have access to this page",
          color: "error",
        });
        this.$router.push(
          `/app/${
            localStorage.getItem("user_type")
              ? localStorage.getItem("user_type").toLowerCase()
              : "error"
          }`
        );
      }
    }
  },
  async mounted() {
    await this.getAllVehicleTypeList();
    await this.getVehicleDetails(this.$route.params.id);
    this.getVehicleDocumentOptions();
    this.getDepotList();
  },
};
</script>
