import Blank from "@/layouts/blank.vue"

import BiddingList from "@/pages/admin/commercials/bidding/BiddingList.vue"
import BiddingAdd from "@/pages/admin/commercials/bidding/BiddingAdd.vue";
import BiddingEdit from "@/pages/admin/commercials/bidding//BiddingEdit.vue";
import BiddingDetail from  "@/pages/admin/commercials/bidding/BiddingDetail.vue";
const biddingRoutes = [
  {
    path: "bidding",
    name: "app-admin-bidding",
    component: Blank,
    children: [
      {
        path: "/",
        redirect: "/app/admin/bidding/list",
      },
      {
        path: "list",
        name: "app-admin-bidding-list",
        component: BiddingList,
      },
      {
        path: "add",
        name: "app-admin-bidding-add",
        component: BiddingAdd,
      },
      {
        path: "edit",
        name: "app-admin-bidding-edit",
        component: Blank,
        children: [
          {
            path: "/",
            redirect: "/app/admin/bidding/list",
          },
          {
            path: ":id",
            name: "app-admin-bidding-edit-id",
            component: BiddingEdit,
          }
        ]
      },
      {
        path: "detail",
        name: "app-admin-bidding-detail",
        component: Blank,
        children: [
          {
            path: "/",
            redirect: "/app/admin/bidding/list",
          },
          {
            path: ":id",
            name: "app-admin-bidding-detail-id",
            component: BiddingDetail,
          }
        ]
      },
    ]
  },
]

export default biddingRoutes