<template>
  <div>
    <FormComponent
      title="Delivery Advice Form"
      :backRoute="backRoute"
      :nonFieldErrors="formErrors.non_field_errors"
    >
      <template #formActions>
        <v-btn
          color="primary"
          depressed
          :disabled="!allFormValidation || selectedContainers.length == 0"
          @click="submitForm"
        >
          Submit
        </v-btn>
      </template>

      <template #formContent>
        <v-row>
          <v-col cols="12" lg="3">
            <div class="pb-2">
              <span class="text-h6"> Containers </span>

              <v-divider></v-divider>
            </div>

            <div class="Container-Height-Class">
              <div
                v-for="(container, i) in orderDetails.order_containers"
                :key="i"
              >
                <ContainerCard
                  :draggable="true"
                  :container="container"
                  @dragstart="onDragStart(container.id)"
                />
              </div>
            </div>
          </v-col>

          <v-col cols="12" lg="9" class="DA-Form-Height-Class">
            <div class="pb-2">
              <span class="text-h6"> Selected Containers </span>
              <v-btn
                color="secondary"
                depressed
                @click="addContractPageRedirect()"
                class="ml-3 mb-3"
              >
                Add Tariff
              </v-btn>

              <v-divider></v-divider>
            </div>

            <div
              class="DropZone"
              dropzone
              @dragenter.prevent
              @dragover.prevent
              @drop="onDrop"
            >
              <div
                class="text-center DropZone-Label"
                v-if="selectedContainers.length == 0"
              >
                <span class="text-h6"> Drag & Drop Containers Here </span>
              </div>

              <div v-else>
                <v-data-table
                  :headers="containerHeaders"
                  :items="selectedContainers"
                  class="elevation-4"
                  hide-default-footer
                  disable-pagination
                >
                  <template v-slot:[`item.actions`]="{ index }">
                    <v-icon small class="mr-2" @click="removeContainer(index)">
                      mdi-close
                    </v-icon>
                  </template>
                </v-data-table>
              </div>
            </div>

            <v-row class="mt-3">
              <v-col cols="12">
                <v-tabs v-model="tab" grow>
                  <v-tabs-slider color="white"></v-tabs-slider>
                  <v-tab> Basic Details </v-tab>
                  <v-tab> Charges </v-tab>
                  <v-tab> Document Upload </v-tab>
                </v-tabs>
              </v-col>

              <v-col cols="12">
                <v-tabs-items v-model="tab">
                  <v-tab-item>
                    <v-form ref="stepperForm1" v-model="valid1">
                      <v-row class="ma-0">
                        <v-col cols="12" class="py-0">
                          <span class="text-h6 font-weight-regular">
                            Date And Time
                          </span>
                        </v-col>

                        <v-col
                          cols="12"
                          sm="6"
                          md="4"
                          lg="3"
                          class="Form-Columns"
                        >
                          <DateField
                            :inputProps="{
                              dense: true,
                              label: 'Movement Date *',
                              rules: [
                                (val) => !!val || 'Movement Date is Required!',
                              ],
                              'hide-details': 'auto',
                              'error-messages': formErrors.movement_date,
                            }"
                            required
                            :min="today"
                            v-model="daDetails.movement_date"
                            @input="delete formErrors.movement_date"
                          />
                        </v-col>

                        <v-col
                          cols="12"
                          sm="6"
                          md="4"
                          lg="3"
                          class="Form-Columns"
                        >
                          <InputField
                            type="time"
                            label="Movement Time *"
                            required
                            :rules="[
                              (val) => !!val || 'Movement Time is Required!',
                            ]"
                            hide-details="auto"
                            v-model="daDetails.movement_time"
                            :error-messages="formErrors.movement_time"
                            @input="delete formErrors.movement_time"
                          />
                        </v-col>

                       

                        <v-col cols="12" class="py-0">
                          <span class="text-h6 font-weight-regular">
                            Financial Details
                          </span>
                        </v-col>

                     
                        <v-col
                          cols="12"
                          v-if="
                            orderDetails.customer &&
                            orderDetails.payment_term == 'Cash'
                          "
                          lg="4"
                          class="Form-Columns"
                        >
                          <MultiSelect
                            label="Payment Receipt *"
                            :items="paymentReceiptList"
                            itemText="title"
                            itemValue="id"
                            hide-details="auto"
                            v-model="daDetails.payment_receipt"
                            :rules="[
                              (val) => !!val || 'Payment Receipt is Required!',
                            ]"
                            :error-messages="formErrors.payment_receipt"
                            @change="delete formErrors.payment_receipt"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          v-if="
                            orderDetails.customer &&
                            orderDetails.payment_term == 'Cash'
                          "
                          lg="4"
                          class="Form-Columns"
                        >
                          <v-btn
                            small
                            @click="showPaymentReceiptForm = true"
                            color="primary"
                            class="mr-2"
                            depressed
                          >
                            Add Payment Receipt
                          </v-btn>
                        </v-col>
                        <v-col cols="12" lg="4" class="Form-Columns">
                          <MultiSelect
                            label="Currency *"
                            :items="currencyList"
                            itemText="currency_code"
                            itemValue="currency_code"
                            :rules="[(val) => !!val || 'Currency is Required!']"
                            hide-details="auto"
                            v-model="daDetails.currency"
                            :error-messages="formErrors.currency"
                            @input="delete formErrors.currency"
                          ></MultiSelect>
                        </v-col>

                        <v-col cols="12" lg="4" class="Form-Columns">
                          <v-switch
                            :label="`Genset : ${daDetails.has_genset}`"
                            true-value="Yes"
                            false-value="No"
                            class="mt-1"
                            hide-details="auto"
                            v-model="daDetails.has_genset"
                            :error-messages="formErrors.has_genset"
                            @input="delete formErrors.has_genset"
                          />
                        </v-col>

                        <v-col cols="12" lg="4" class="Form-Columns">
                          <v-switch
                            :label="`Hazmat : ${daDetails.is_hazmat}`"
                            true-value="Yes"
                            false-value="No"
                            class="mt-1"
                            hide-details="auto"
                            v-model="daDetails.is_hazmat"
                            :error-messages="formErrors.is_hazmat"
                            @input="delete formErrors.is_hazmat"
                          />
                          <v-btn
                            icon
                            class="mr-3 red--text"
                            v-if="daDetails.is_hazmat == 'Yes'"
                            @click="showHazardousDialog = true"
                          >
                            <v-icon>mdi-alert</v-icon>
                          </v-btn>
                        </v-col>

                        <v-col
                          cols="12"
                          sm="6"
                          md="4"
                          lg="3"
                          class="Form-Columns"
                        >
                          <MultiSelect
                            label="Select Branch *"
                            :items="allBranchList"
                            itemText="branch_title"
                            itemValue="id"
                            :rules="[(val) => !!val || 'Branch is Required!']"
                            hide-details="auto"
                            v-model="daDetails.branch"
                            :error-messages="formErrors.branch"
                            @change="delete formErrors.branch"
                          ></MultiSelect>
                        </v-col>
                        <v-col
                          cols="12"
                          sm="6"
                          md="4"
                          lg="3"
                          class="Form-Columns"
                        >
                          <MultiSelect
                            label="Select Depot *"
                            :items="allDepotList"
                            itemText="depot_name"
                            itemValue="id"
                            :rules="[(val) => !!val || 'Depot is Required!']"
                            hide-details="auto"
                            v-model="daDetails.depot"
                            :error-messages="formErrors.depot"
                            @change="delete formErrors.depot"
                          ></MultiSelect>
                        </v-col>

                        <v-col
                          cols="12"
                          sm="6"
                          md="4"
                          lg="2"
                          class="Form-Columns"
                        >
                          <InputField
                            type="text"
                            label="Purchase Order"
                            hide-details="auto"
                            v-model="daDetails.po_no"
                            :error-messages="formErrors.po_no"
                            @input="delete formErrors.po_no"
                          />
                        </v-col>

                        <v-col
                          cols="12"
                          sm="6"
                          md="4"
                          lg="2"
                          class="Form-Columns"
                        >
                          <InputField
                            type="text"
                            label="Shipment Number"
                            hide-details="auto"
                            v-model="daDetails.shipment_no"
                            :error-messages="formErrors.shipment_no"
                            @input="delete formErrors.shipment_no"
                          />
                        </v-col>

                        <v-col
                          cols="12"
                          sm="6"
                          md="4"
                          lg="2"
                          class="Form-Columns"
                        >
                          <InputField
                            type="text"
                            label="Vessel Number"
                            hide-details="auto"
                            v-model="daDetails.vessel_no"
                            :error-messages="formErrors.vessel_no"
                            @input="delete formErrors.vessel_no"
                          />
                        </v-col>

                        <v-col cols="12" class="Form-Columns">
                          <MultiSelect
                            label="Select Milestones"
                            :multiple="true"
                            :chips="true"
                            :showAll="true"
                            :items="milestoneList"
                            itemText="milestone_name"
                            itemValue="id"
                            hide-details="auto"
                            v-model="daDetails.milestones"
                            :error-messages="formErrors.milestones"
                            @change="delete formErrors.milestones"
                          >
                          </MultiSelect>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-tab-item>

                  <v-tab-item>
                    <v-form ref="stepperForm2" v-model="valid2">
                      <v-row class="ma-0">
                        <v-col cols="12" class="py-0">
                          <span class="text-h6 font-weight-regular">
                            Add Charges
                          </span>
                        </v-col>

                        <v-col cols="12" sm="6" md="3" class="Form-Columns">
                          <MultiSelect
                            :items="allChargeList"
                            itemText="charge_title"
                            itemValue="id"
                            label="Select Charge"
                            :item-disabled="
                              (item) => {
                                return selectedDACharges.includes(item.id);
                              }
                            "
                            returnObject
                            hide-details="auto"
                            v-model="deliveryAdviceChargeDetails.charge"
                            :error-messages="formErrors.charge"
                            @change="
                              delete formErrors.charge;
                              setChargeDetails();
                            "
                          />
                        </v-col>
                        <v-col cols="12" sm="6" md="3">
                          <InputField
                            readonly
                            :hide-details="!formErrors.description"
                            v-model="deliveryAdviceChargeDetails.description"
                            label="Description"
                            :clearable="false"
                            :error-messages="formErrors.description"
                            @input="delete formErrors.description"
                          />
                        </v-col>
                        <v-col cols="12" sm="6" md="3" class="Form-Columns">
                          <InputField
                            label="Revenue GL No."
                            readonly
                            :clearable="false"
                            hide-details="auto"
                            v-model="deliveryAdviceChargeDetails.gl_no"
                            :error-messages="formErrors.gl_no"
                            @input="delete formErrors.gl_no"
                          />
                        </v-col>
                        <v-col cols="12" sm="6" md="3" class="Form-Columns">
                          <InputField
                            readonly
                            type="text"
                            hide-details="auto"
                            :clearable="false"
                            v-model="deliveryAdviceChargeDetails.tax_percentage"
                            label="Tax (%)"
                            :disabled="
                              !deliveryAdviceChargeDetails.tax_percentage
                            "
                            :error-messages="formErrors.tax_percentage"
                            @input="delete formErrors.tax_percentage"
                          />
                        </v-col>
                        <v-col cols="12" sm="6" md="3" class="Form-Columns">
                          <InputField
                            type="number"
                            :label="`Rate (${$globals.currency})`"
                            :disabled="!deliveryAdviceChargeDetails.charge"
                            :rules="[
                              (v) =>
                                $globals.twoDecimalPlacesRegExp.test(v) ||
                                'Rate must have 2 decimal places only',
                            ]"
                            hide-details="auto"
                            v-model="deliveryAdviceChargeDetails.rate"
                            :error-messages="formErrors.rate"
                            @input="
                              delete formErrors.rate;
                              calculateRate();
                            "
                          />
                        </v-col>
                        <v-col cols="12" sm="6" md="3" class="Form-Columns">
                          <InputField
                            hide-details="auto"
                            type="number"
                            :label="`Tax Amount (${$globals.currency})`"
                            :clearable="false"
                            readonly
                            :value="deliveryAdviceChargeDetails.tax"
                          />
                        </v-col>
                        <v-col cols="12" sm="6" md="3" class="Form-Columns">
                          <InputField
                            hide-details="auto"
                            type="number"
                            :label="`Amount (${$globals.currency})`"
                            :clearable="false"
                            readonly
                            :value="deliveryAdviceChargeDetails.amount_with_tax"
                          />
                        </v-col>

                        <v-col cols="12" sm="6" md="3">
                          <v-btn
                            color="primary"
                            depressed
                            :disabled="!deliveryAdviceChargeDetails.rate"
                            @click="
                              addDACharge();
                              delete formErrors.rate;
                              delete formErrors.charge;
                            "
                            >Add</v-btn
                          >
                        </v-col>

                        <v-col cols="12">
                          <div v-show="daCharges && daCharges.length">
                            <h3 class="Form-Heading">New Charges</h3>
                          </div>
                          <v-card-text>
                            <v-row>
                              <v-col cols="2">
                                <h4>Charge</h4>
                              </v-col>
                              <v-col cols="3">
                                <h4>Description</h4>
                              </v-col>
                              <v-col cols="2">
                                <h4>Revenue GL No.</h4>
                              </v-col>
                              <v-col cols="1">
                                <h4>Tax (%)</h4>
                              </v-col>
                              <v-col cols="1">
                                <h4>Rate</h4>
                              </v-col>
                              <v-col cols="1">
                                <h4>Tax Amount</h4>
                              </v-col>
                              <v-col cols="1">
                                <h4>Amount</h4>
                              </v-col>
                            </v-row>
                          </v-card-text>
                          <v-card
                            elevation="0"
                            outlined
                            v-for="(data, j) in daCharges"
                            :key="j"
                          >
                            <v-card-text>
                              <v-row>
                                <v-col cols="2">
                                  <h4>{{ data.charge_title }}</h4>
                                </v-col>
                                <v-col cols="3">
                                  <h4>{{ data.description }}</h4>
                                </v-col>
                                <v-col cols="2">
                                  <h4>{{ data.gl_no }}</h4>
                                </v-col>
                                <v-col cols="1">
                                  <h4>{{ data.tax_percentage }}</h4>
                                </v-col>
                                <v-col cols="1">
                                  <h4>{{ data.rate }}</h4>
                                </v-col>
                                <v-col cols="1">
                                  <h4>{{ data.tax }}</h4>
                                </v-col>
                                <v-col cols="1">
                                  <h4>{{ data.amount_with_tax }}</h4>
                                </v-col>

                                <v-col cols="1">
                                  <v-icon
                                    small
                                    color="primary"
                                    @click="removeCharges(j)"
                                  >
                                    mdi-close
                                  </v-icon>
                                </v-col>
                              </v-row>
                            </v-card-text>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-tab-item>
                  <v-tab-item>
                    <v-form ref="stepperForm2" v-model="valid2">
                      <v-row class="ma-0">
                        <v-col cols="12" class="py-0">
                          <span class="text-h6 font-weight-regular">
                            Upload Documents
                          </span>
                        </v-col>

                        <v-col cols="12" sm="6" md="4" class="Form-Columns">
                          <InputField
                            type="text"
                            v-model="document_type"
                            label="Document Type"
                            :error-messages="formErrors.document_type"
                            @input="delete formErrors.document_type"
                          />
                        </v-col>

                        <v-col cols="12" sm="6" md="4" class="Form-Columns">
                          <v-file-input
                            v-model="document"
                            label="Document"
                            :rules="[
                              (file) =>
                                !file ||
                                file.size < 2e6 ||
                                'File size should be less than 2 MB!',
                            ]"
                            accept="image/x-png,image/jpg,image/jpeg,application/pdf"
                            outlined
                            dense
                            :error-messages="formErrors.document"
                            @input="delete formErrors.document"
                          />
                        </v-col>

                        <v-col cols="12" sm="6" md="4">
                          <v-btn
                            color="primary"
                            depressed
                            :disabled="!document || !document_type"
                            @click="addDocument"
                            >Add</v-btn
                          >
                        </v-col>

                        <v-col cols="12">
                          <div v-show="daDocuments && daDocuments.length">
                            <h3 class="Form-Heading">New Upload</h3>
                          </div>

                          <v-card
                            elevation="0"
                            outlined
                            v-for="(data, j) in daDocuments"
                            :key="j"
                          >
                            <v-card-text>
                              <v-row>
                                <v-col cols="3">
                                  <a
                                    :href="generateUrl(data.document)"
                                    target="_black"
                                  >
                                    <h4>{{ data.document_type }}</h4>
                                  </a>
                                </v-col>
                                <v-col cols="4">
                                  <a
                                    :href="generateUrl(data.document)"
                                    target="_black"
                                  >
                                    <h4>{{ data.document.name }}</h4>
                                  </a>
                                </v-col>
                                <v-col cols="2">
                                  <h4>{{ getSize(data.document.size) }}</h4>
                                </v-col>
                                <v-col cols="2"> </v-col>
                                <v-col cols="1">
                                  <v-icon
                                    small
                                    color="primary"
                                    @click="removeFile(j)"
                                  >
                                    mdi-close
                                  </v-icon>
                                </v-col>
                              </v-row>
                            </v-card-text>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-tab-item>
                </v-tabs-items>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </template>
    </FormComponent>
    <AdminDAHazardousForm
      v-model="showHazardousDialog"
      dialogTitle="DA Hazardous Cargo"
      :daHazardousFormDetails="daHazardousFormDetails"
      @saveDAHazardousForm="saveDAHazardousForm"
    />
    <PaymentReceiptForm
      v-model="showPaymentReceiptForm"
      :companyId="orderDetails.customer"
      @refreshList="getPaymentReceiptList"
    />
  </div>
</template>

<script>
import FormComponent from "@/components/General/FormComponent.vue";
import InputField from "@/components/FormBaseComponents/InputField.vue";
import MultiSelect from "@/components/FormBaseComponents/MultiSelect.vue";
import Tooltip from "@/components/General/Tooltip.vue";
import ContainerCard from "@/components/General/ContainerCard.vue";
import DateField from "@/components/FormBaseComponents/DateField.vue";
import AdminDAHazardousForm from "@/components/ModuleBased/dialogForms/AdminDAHazardousForm.vue";
import PaymentReceiptForm from "@/components/ModuleBased/dialogForms/PaymentReceiptForm.vue";

import {
  isAuthenticated,
  hasRouteAccess,
  getPermissions,
} from "@/utils/functions";

export default {
  components: {
    FormComponent,
    InputField,
    MultiSelect,
    Tooltip,
    ContainerCard,
    DateField,
    AdminDAHazardousForm,
    PaymentReceiptForm,
  },

  data() {
    return {
      getListCalled: false,

      showPaymentReceiptForm: false,
      showHazardousDialog: false,
      hazardousId: null,
      daHazardousFormDetails: {},

      trip_permissions: null,
      deliveryAdviceChargeDetails: {},
      paymentReceiptList: [],
      daCharges: [],
      selectedDACharges: [],
      allChargeList: [],

      tab: 0,
      // docs vars
      document_type: null,
      document: null,
      daDocuments: [],
      allInternalTransporterList: [],
      allDepotList: [],
      allBranchList: [],

      paymentTerms: [],
      currencyList: [],

      selectedContainers: [],
      containerHeaders: [
        {
          text: "Container No.",
          value: "container_no",
        },
        {
          text: "Container Type",
          value: "container_type_title",
        },
        {
          text: "Source Address",
          value: "source_address",
        },
        {
          text: "Mid Point Address",
          value: "mid_address",
        },
        {
          text: "Destination Address",
          value: "destination_address",
        },
        {
          text: "Movement Date",
          value: "movement_date",
        },
        {
          text: "Movement Time",
          value: "movement_time",
        },
        {
          text: "Actions",
          value: "actions",
        },
      ],

      daDetails: {
        has_genset: "No",
        is_hazmat: "No",
        milestones: [],
      },
      orderDetails: {},
      valid1: true,
      valid2: true,
      formErrors: {},

      // drag & drop vars
      draggedId: null,

      today: new Date().toISOString().slice(0, 10),
      milestoneList: [],

      backRoute: { path: "/app/admin/order/list" },
    };
  },

  computed: {
    allFormValidation() {
      return this.valid1 && this.valid2;
    },
  },

  methods: {
    calculateRate() {
      const rate = this.deliveryAdviceChargeDetails.rate;
      const tax = this.deliveryAdviceChargeDetails.tax_percentage;
      const quantity = 1;
      if (rate && quantity) {
        const totalGrossRate = parseFloat(rate) * parseFloat(quantity);
        if (totalGrossRate && tax) {
          const totalTaxAmount = totalGrossRate * (parseFloat(tax) / 100);

          this.deliveryAdviceChargeDetails.tax = totalTaxAmount.toFixed(4);
          this.deliveryAdviceChargeDetails.amount_with_tax = (
            totalGrossRate + totalTaxAmount
          ).toFixed(4);
        }
      }
    },
    refreshDaDetails() {
      this.daDetails = { ...this.daDetails };
    },
    getPaymentReceiptList(params = {}) {
      if (this.orderDetails && this.orderDetails.customer) {
        params = {
          ...params,
          company: this.orderDetails.customer,
        };

        this.$api.deliveryAdvice
          .paymentReceiptsList(params)
          .then((res) => {
            this.paymentReceiptList = res.data;
          })
          .catch((err) => {
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message: "Couldn't Fetch delivery charge list!",
              color: "error",
            });
            this.formErrors = err.data;
          });
      }
    },
    addDAHazardousCargo(daID) {
      let payload = { ...this.daHazardousFormDetails, delivery_advice: daID };
      this.$api.daHazardousCargo
        .addDAHazardousCargo(payload)
        .then(() => {})
        .catch((err) => {
          console.log(err);
        });
    },
    saveDAHazardousForm(data) {
      this.daHazardousFormDetails = { ...data };
    },
    addContractPageRedirect() {
      const path = {
        path: "/app/admin/customer-contract/add",
      };
      path.query = {
        backRoute: this.$route.path,
        da_id: this.$route.query.da_id,
      };
      this.$router.push(path);
    },
    getDepotList(params = {}) {
      params = {
        limit: "all",
        ...params,
      };
      return this.$api.depot
        .getDepotList(params)
        .then((res) => {
          this.allDepotList = res.data;
          let user_data = JSON.parse(localStorage.getItem("userProfile"));
          if (user_data && user_data.depot) {
            this.daDetails.depot = user_data.depot;
          }
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    getBranchList(params = {}) {
      params = {
        limit: "all",
        ...params,
      };
      return this.$api.branch
        .getBranchSelectList(params)
        .then((res) => {
          this.allBranchList = res.data;
          let user_data = JSON.parse(localStorage.getItem("userProfile"));
          if (user_data && user_data.branch) {
            this.daDetails.branch = user_data.branch;
          }
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    addDocument() {
      this.daDocuments.push({
        document_type: this.document_type,
        document: this.document,
      });
      this.document = null;
      this.document_type = null;
    },
    getChargeList(params = {}) {
      params = {
        limit: "all",
        is_active: "true",
      };
      this.$api.charge
        .getChargeList(params)
        .then((res) => {
          this.allChargeList = res.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    addDACharge() {
      this.selectedDACharges.push(this.deliveryAdviceChargeDetails.charge.id);
      this.daCharges.push({
        charge_title: this.deliveryAdviceChargeDetails.charge.charge_title,
        description: this.deliveryAdviceChargeDetails.description,
        gl_no: this.deliveryAdviceChargeDetails.gl_no,
        charge: this.deliveryAdviceChargeDetails.charge.id,
        tax_percentage: this.deliveryAdviceChargeDetails.tax_percentage,
        rate: this.deliveryAdviceChargeDetails.rate,
        tax: this.deliveryAdviceChargeDetails.tax,
        amount_with_tax: this.deliveryAdviceChargeDetails.amount_with_tax,
      });
      this.deliveryAdviceChargeDetails = {};
    },
    removeCharges(index) {
      this.daCharges.splice(index, 1);
      this.selectedDACharges.splice(index, 1);
    },
    async createBulkDACharges(id) {
      let payload = {
        id: id,
        data: this.daCharges,
      };
      await this.$api.deliveryAdvice.createBulkDACharges(payload);
    },
    removeFile(index) {
      this.daDocuments.splice(index, 1);
    },
    generateUrl(file) {
      return URL.createObjectURL(file);
    },
    getSize(bytes) {
      var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
      if (bytes == 0) return "0 Byte";
      var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
      return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
    },
    async uploadDADocuments(id) {
      let i = 0;
      while (i < this.daDocuments.length) {
        let payload = {
          id: id,
          data: null,
        };
        let newForm = new FormData();
        newForm.append("document_type", this.daDocuments[i].document_type);
        newForm.append("document", this.daDocuments[i].document);
        payload.data = newForm;
        await this.$api.deliveryAdvice.uploadDeliveryAdviceDocument(payload);
        i++;
      }
    },

    removeContainer(index) {
      let order = this.selectedContainers.splice(index, 1)[0];
      this.orderDetails.order_containers.splice(order.oldIndex, 0, order);
    },
    onDragStart(id) {
      this.draggedId = id;
    },
    onDrop() {
      if (this.selectedContainers.length == 4) {
        this.$bus.$emit("showToastMessage", {
          message: "Can not add more than 4 containers!",
          color: "error",
        });
      } else {
        let index = this.orderDetails.order_containers.indexOf(
          this.orderDetails.order_containers.find((v) => v.id == this.draggedId)
        );
        if (~index) {
          if (
            this.selectedContainers.length > 0 &&
            this.selectedContainers[0].job_type !=
              this.orderDetails.order_containers[index].job_type
          ) {
            this.$bus.$emit("showToastMessage", {
              message: "Containers job type must be same in DA",
              color: "error",
            });
            return;
          }

          let order = this.orderDetails.order_containers.splice(
            index,
            1,
            {}
          )[0];
          order.oldIndex = index;
          this.selectedContainers.push(order);
          this.getMilestoneList();
        }
      }
    },
    submitForm() {
      this.$bus.$emit("showLoader", true);
      let payload = {
        order_containers: this.selectedContainers.map((obj) => obj.id),
        order: this.orderDetails.id,
        customer: this.orderDetails.customer,
        boe: this.orderDetails.boe,
        ...this.daDetails,
      };
      this.$api.deliveryAdvice
        .addDeliveryAdvice(payload)
        .then((res) => {
          this.$bus.$emit("showLoader", false);

          this.$bus.$emit("showToastMessage", {
            message: `Delivery Advice ${res.data.da_no} created successfully!`,
            color: "success",
          });
          if (this.daDocuments.length > 0) {
            this.uploadDADocuments(res.data.id);
          }
          if (this.daCharges.length > 0) {
            console.log(res.data);
            console.log(res.data.id, "resid");
            this.createBulkDACharges(res.data.response_data.id);
          }
          if (this.daHazardousFormDetails == "Yes") {
            this.addDAHazardousCargo(res.data.id);
          }
          if (this.trip_permissions && this.trip_permissions.add) {
            this.$router.push(`/app/admin/trip/list`);
          } else {
            this.$router.push("/app/admin/order/list");
          }
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);

          this.formErrors = { ...this.formErrors, ...err.data };
          this.$bus.$emit("showToastMessage", {
            message: "Couldn't create Delivery Advice!",
            color: "error",
          });
        });
    },

    getOrderObject(id) {
      return this.$api.customerOrder
        .getCustomerOrderObject(id)
        .then((res) => {
          this.orderDetails = res.data;
          this.daDetails.payment_term = this.orderDetails.customer_payment_term;
          this.daDetails.currency = this.orderDetails.customer_currency;
          this.orderDetails.order_containers = res.data.order_containers.filter(
            (obj) => {
              if (!obj.assigned_to_da) {
                return obj;
              }
            }
          );
          this.getPaymentReceiptList();
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },

    getDeliveryAdviceOptions() {
      return this.$api.deliveryAdvice
        .getDeliveryAdviceOptions()
        .then((res) => {
          this.paymentTerms = res.data.actions.POST.payment_term.choices;
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },

    getCurrencyList() {
      return this.$api.currency
        .getCurrencyList({ limit: "all" })
        .then((res) => {
          this.currencyList = res.data;

          const currency = this.currencyList.find((c) => {
            return c.currency_code == this.$globals.currency;
          });

          if (currency) {
            this.daDetails.currency = currency.currency_code;
          }
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },

    getMilestoneList() {
      if (this.selectedContainers.length == 1) {
        return this.$api.milestone
          .getMilestoneList({ job_type: this.selectedContainers[0].job_type })
          .then((res) => {
            this.milestoneList = res.data;
            this.daDetails.milestones = this.milestoneList.map((m) => m.id);
          })
          .catch((err) => {
            console.error(err);
          });
      }
    },

    setBackRoute() {
      if ("backRoute" in this.$route.query) {
        this.backRoute.path = this.$route.query.backRoute;
      }
      if ("active_tab" in this.$route.query) {
        if (!this.backRoute.query) {
          this.backRoute.query = {};
        }
        this.backRoute.query.active_tab = this.$route.query.active_tab;
      }
    },
    setChargeDetails() {
      if (this.deliveryAdviceChargeDetails.charge) {
        const c = { ...this.deliveryAdviceChargeDetails.charge };
        delete c.id;
        this.deliveryAdviceChargeDetails = {
          ...this.deliveryAdviceChargeDetails,
          ...c,
        };
        this.calculateRate();
      }
    },
    getLists(apis) {
      this.$bus.$emit("showLoader", true);
      return new Promise((resolve, reject) => {
        Promise.all(apis)
          .then((val) => {
            this.getListCalled = false;
            this.$bus.$emit("showLoader", false);
            resolve();
          })
          .catch((err) => {
            this.getListCalled = false;
            this.$bus.$emit("showLoader", false);
            reject();
          });
      });
    },
  },

  beforeMount() {
    if (!isAuthenticated()) {
      this.$router.push("/");
    } else if (!hasRouteAccess(this.$route)) {
      this.$router.push(`/${localStorage.getItem("user_type").toLowerCase()}`);
    } else {
      const permissions = getPermissions("delivery_advice");
      this.trip_permissions = getPermissions("leg_trip");
      if (!permissions || !permissions.view) {
        this.$bus.$emit("showToastMessage", {
          message: "You don't have access to this page",
          color: "error",
        });
        this.$router.push(
          `/app/${
            localStorage.getItem("user_type")
              ? localStorage.getItem("user_type").toLowerCase()
              : "error"
          }`
        );
      } else {
        this.getLists([
          this.getDeliveryAdviceOptions(),
          this.getCurrencyList(),
          this.getChargeList(),
          this.setBackRoute(),
          this.getDepotList(),
          this.getBranchList(),
          this.getOrderObject(this.$route.params.id),
        ]);
      }
    }
  },

  mounted() {},
};
</script>

<style scoped>
.Container-Height-Class {
  height: calc(100vh - 262px);
  overflow-y: auto;
}

.DA-Form-Height-Class {
  height: calc(100vh - 197px);
  overflow-y: auto;
}

.DropZone {
  min-height: 200px;
  max-height: 00px;
  overflow-y: auto;
  padding: 15px;
  padding-bottom: 25px;
  border-style: groove;
  border-color: #fafafa;
  background-color: #e0e0e0;
}
</style>
