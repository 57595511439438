var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"width":"700"},model:{value:(_vm.showBidForm),callback:function ($$v) {_vm.showBidForm=$$v},expression:"showBidForm"}},[_c('v-card',[_c('v-card-title',{staticClass:"secondary white--text"},[_vm._v(" Create Bid "),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.resetForm(), (_vm.showBidForm = false)}}},[_c('v-icon',{staticClass:"white--text"},[_vm._v("mdi-close")])],1)],1),_c('v-card-text',{staticClass:"pa-5"},[_c('v-form',{ref:"sendForm",attrs:{"lazy-validation":""},model:{value:(_vm.isValid),callback:function ($$v) {_vm.isValid=$$v},expression:"isValid"}},[_c('v-row',{staticClass:"ma-0"},[_c('v-col',{staticClass:"Form-Columns-Dense",attrs:{"cols":"12","lg":"4"}},[_c('InputField',{attrs:{"type":"text","label":"Job Type","hide-details":"auto","clearable":false,"readonly":true},model:{value:(_vm.bidDetails.job_type),callback:function ($$v) {_vm.$set(_vm.bidDetails, "job_type", $$v)},expression:"bidDetails.job_type"}})],1),_c('v-col',{staticClass:"Form-Columns-Dense",attrs:{"cols":"12","lg":"4"}},[_c('InputField',{attrs:{"type":"text","label":"Route","hide-details":"auto","clearable":false,"readonly":true},model:{value:(_vm.bidDetails.lane_title),callback:function ($$v) {_vm.$set(_vm.bidDetails, "lane_title", $$v)},expression:"bidDetails.lane_title"}})],1),_c('v-col',{staticClass:"Form-Columns-Dense",attrs:{"cols":"12","lg":"4"}},[_c('InputField',{attrs:{"type":"text","label":"Container Type","hide-details":"auto","clearable":false,"readonly":true},model:{value:(_vm.bidDetails.container_type_count),callback:function ($$v) {_vm.$set(_vm.bidDetails, "container_type_count", $$v)},expression:"bidDetails.container_type_count"}})],1),_c('v-col',{staticClass:"Form-Columns-Dense",attrs:{"cols":"12","lg":"4"}},[_c('DateField',{attrs:{"inputProps":{
                dense: true,
                label: 'Bid End Date *',
                'hide-details': 'auto',
                rules: [(val) => !!val || 'End Date is required!'],
                'error-messages': _vm.formErrors.end_date,
              },"fieldType":"datetime","required":""},on:{"input":function($event){delete _vm.formErrors.end_date}},model:{value:(_vm.bidDetails.end_date),callback:function ($$v) {_vm.$set(_vm.bidDetails, "end_date", $$v)},expression:"bidDetails.end_date"}})],1),_c('v-col',{staticClass:"Form-Columns-Dense",attrs:{"cols":"12","lg":"4"}},[_c('InputField',{attrs:{"type":"number","label":"Expected Rate","hide-details":"auto"},model:{value:(_vm.bidDetails.expected_rate),callback:function ($$v) {_vm.$set(_vm.bidDetails, "expected_rate", $$v)},expression:"bidDetails.expected_rate"}})],1),_c('v-col',{staticClass:"Form-Columns-Dense",attrs:{"cols":"12","lg":"4"}},[_c('InputField',{attrs:{"type":"text","label":"Admin Remarks","hide-details":"auto"},model:{value:(_vm.bidDetails.admin_remarks),callback:function ($$v) {_vm.$set(_vm.bidDetails, "admin_remarks", $$v)},expression:"bidDetails.admin_remarks"}})],1),_c('v-col',{staticClass:"Form-Columns-Dense",attrs:{"cols":"12","lg":"12"}},[_c('MultiSelect',{attrs:{"showAll":true,"label":"Select Transporter(s) *","items":_vm.transporterList,"itemText":"company_name","itemValue":"id","hide-details":"auto","rules":[val => val && !!val.length || 'Transporter is required!'],"multiple":true,"chips":true,"chipWidthStyle":"max-width: 3.7vw;","error-messages":_vm.formErrors.transporter},on:{"change":function($event){delete _vm.formErrors.transporter}},model:{value:(_vm.bidDetails.transporter),callback:function ($$v) {_vm.$set(_vm.bidDetails, "transporter", $$v)},expression:"bidDetails.transporter"}})],1)],1)],1)],1),_c('v-card-actions',{staticClass:"pa-5"},[_c('v-btn',{staticClass:"ml-auto",attrs:{"disabled":!_vm.isValid,"outlined":"","color":"primary"},on:{"click":function($event){return _vm.submitForm()}}},[_vm._v("Submit")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }