import { bidHSM } from "@/utils/constants";
import { handleError, handleResponse, handleRawResponse } from "@/utils/functions";



export default (axios) => ({
    getBiddingDetails(id) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${bidHSM.bidHSMBase}${id}/`)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    submitBidRequestRate(payload) {
        return new Promise((resolve, reject) => {
            axios
                .patch(`${bidHSM.bidHSMBase}${payload.id}/`, payload)
                .then((res) => {
                    resolve(handleResponse(res))
                })
                .catch((err) => {
                    reject(handleError(err))
                })
        })
    },

    //subhire
    getTransporterBRSubHireDetail(params = {}) {
        
        return new Promise((resolve, reject) => {
            axios
                .get(`${bidHSM.bidSubHireHSBase}`, {
                    params: params
                })
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    submitTransporterAssetDetails(payload) {
        
        return new Promise((resolve, reject) => {
            axios
                .patch(`${bidHSM.bidSubHireHSBase}`, payload)
                .then((res) => {
                    resolve(handleResponse(res))
                })
                .catch((err) => {
                    reject(handleError(err))
                })
        })
    },


});