import Blank from "@/layouts/blank.vue"

import TransporterContractList from "@/pages/admin/transporter-contract/TransporterContractList.vue";
import TransporterContractAdd from "@/pages/admin/transporter-contract/TransporterContractAdd.vue";
import TransporterContractEdit from "@/pages/admin/transporter-contract/TransporterContractEdit.vue";

const transporterContractRoutes = [
  {
    path: "transporter-contract",
    name: "app-admin-transporter-contract",
    component: Blank,
    children: [
      {
        path: "/",
        redirect: "/app/admin/transporter-contract/list",
      },
      {
        path: "list",
        name: "app-admin-transporter-contract-list",
        component: TransporterContractList,
      },
      {
        path: "add",
        name: "app-admin-transporter-contract-add",
        component: TransporterContractAdd,
      },
      {
        path: "edit",
        name: "app-admin-transporter-contract-edit",
        component: Blank,
        children: [
          {
            path: "/",
            redirect: "/app/admin/transporter-contract/list",
          },
          {
            path: ":id",
            name: "app-admin-transporter-contract-edit-id",
            component: TransporterContractEdit,
          }
        ]
      },
    ]
  },
]

export default transporterContractRoutes