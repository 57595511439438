<template>
  <FilterParent
    module="Trailer"
    maxWidth="500"
    v-model="showTransporterVehicleFilter"
  >
    <template #filterContent>
      <v-row class="ma-0">
        <v-col cols="12" class="Form-Columns">
          <MultiSelect
            label="Select Trailer Type"
            :items="allTrailerTypeList"
            itemText="title"
            itemValue="id"
            v-model="filterObject.trailer_type"
          />
        </v-col>
        <v-col cols="12" class="Form-Columns">
          <MultiSelect
            label="Depot"
            name="depot-filter-vehicle-type"
            :items="allDepot"
            itemText="depot_name"
            itemValue="depot_name"
            v-model="filterObject.depot"
          />
        </v-col>
        <v-col cols="12" class="Form-Columns">
          <MultiSelect
            label="Carry Road Trail"
            name="trail-filter"
            :items="allTrail"
            itemText="display_name"
            itemValue="value"
            v-model="filterObject.road_trail"
          />
        </v-col>
      </v-row>
    </template>

    <template #filterAction>
      <v-btn
        color="secondary"
        depressed
        @click="$emit('resetFilters'), (showTransporterVehicleFilter = false)"
        >Reset</v-btn
      >
      <v-btn
        color="primary"
        class="ml-4"
        depressed
        @click="$emit('applyFilters'), (showTransporterVehicleFilter = false)"
        >Apply</v-btn
      >
    </template>
  </FilterParent>
</template>

<script>
import MultiSelect from "@/components/FormBaseComponents/MultiSelect.vue";
import FilterParent from "@/components/General/FilterParent.vue";

export default {
  components: {
    FilterParent,
    MultiSelect,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    bindingObject: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      allTrailerTypeList: [],
      allDepot: [],
      allTrail: [
        { display_name: "Yes", value: "True" },
        { display_name: "No", value: "False" },
      ],
    };
  },
  watch: {
    value(val) {
      if (val) {
        this.getTrailerTypeList();
        this.getDepotList();
      }
    },
  },
  computed: {
    showTransporterVehicleFilter: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    filterObject: {
      get() {
        return this.bindingObject;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    getDepotList(params = {}) {
      params = {
        limit: "all",
      };
      this.$api.depot
        .getDepotList(params)
        .then((res) => {
          this.allDepot = res.data;
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    getTrailerTypeList() {
      this.$bus.$emit("showLoader", true);
      this.$api.trailerType
        .getTrailerTypeList({ limit: "all" })
        .then((res) => {
          this.allTrailerTypeList = res.data;
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
  },
};
</script>

<style></style>
