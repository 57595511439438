<template>
  <v-row class="ma-0">
    <v-col cols="3" class="pa-0">
      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-icon
            class="mr-4 ml-1"
            color="primary"
            v-on="on"
            v-bind="attrs"
            @click="viewItem"
          >
            mdi-eye
          </v-icon>
        </template>
        <span>View</span>
      </v-tooltip>
    </v-col>
  </v-row>
</template>

<script>
export default {
  methods: {
    viewItem() {
      this.params.context.parentComponent.allocateAsset(this.params.data.id);
    },
  
     
    
  },
};
</script>

<style>
</style>