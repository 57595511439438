import { render, staticRenderFns } from "./DeliveryAdviceList.vue?vue&type=template&id=4c05683a"
import script from "./DeliveryAdviceList.vue?vue&type=script&lang=js"
export * from "./DeliveryAdviceList.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports