<template>
  <div>
    <v-row class="heading ma-0">
      <v-col cols="12" lg="4" class="d-flex">
        <v-tooltip top>
          <template #activator="{ on, attrs }">
            <v-btn
              icon
              @click="callBackRoute"
              v-bind="attrs"
              v-on="on"
              class="mr-1"
            >
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
          </template>
          <span>Back</span>
        </v-tooltip>
        <div>
          <div>
            <Tooltip
              tooltipTitle="Bidding Id"
              v-if="biddingDetails.bid_id"
              :tooltipText="biddingDetails.bid_id"
              top
              tooltipClass="text-h5"
            />
            <span class="text-h5">&nbsp;|&nbsp;</span>
            <Tooltip
              tooltipTitle="Status"
              v-if="biddingDetails.status"
              :tooltipText="biddingDetails.status"
              top
              tooltipClass="text-h5"
            />
          </div>
          <div v-if="biddingDetails.end_date">
            <span class="text-caption">End Date &amp; Time </span>
            <span class="font-weight-medium">
              {{ biddingDetails.end_date | formatDate(true) }}
            </span>
          </div>
        </div>
      </v-col>
    </v-row>

    <v-row class="ma-0" justify="space-between">
      <v-col cols="6" lg="auto" class="d-flex">
        <div v-if="biddingDetails.bid_lane_title">
          <span class="text-caption">Route</span>
          <p class="font-weight-medium mb-0">
            {{ biddingDetails.bid_lane_title }}
          </p>
        </div>

        <div v-if="biddingDetails.customer_contract_id">
          <span class="text-caption">Customer Tariff</span>
          <p class="font-weight-medium mb-0">
            {{ biddingDetails.customer_contract_id }}
          </p>
        </div>
      </v-col>

      <v-col cols="12" lg="auto" class="d-flex justify-end">
        <v-divider vertical class="mx-3"></v-divider>
        <div>
          <span class="text-caption">Container Type</span>
          <p class="text-subtitle-1 font-weight-medium mb-0">
            {{ biddingDetails.container_name }}
          </p>
        </div>
        <v-divider vertical class="mx-3"></v-divider>
        <div>
          <span class="text-caption">Container Count</span>
          <p class="text-subtitle-1 font-weight-medium mb-0">
            {{ biddingDetails.container_count }}
          </p>
        </div>
        <v-divider vertical class="mx-3"></v-divider>
        <div>
          <span class="text-caption">Expected Rate </span>
          <p class="text-subtitle-1 font-weight-medium mb-0">
            {{ biddingDetails.expected_rate }} &nbsp;{{ $globals.currency }}
          </p>
        </div>
        
        
      </v-col>
    </v-row>

    <v-divider></v-divider>

    <v-row class="ma-0">
      <v-col cols="12" class="py-2">
        <v-row class="ma-0">
          <v-col cols="12" lg="12" class="py-1 pl-1">
            <v-card>
              <v-card-title class="heading py-2">
                <span class="text-h6 font-weight-medium"> Bid Request </span>

                <v-spacer></v-spacer>
              </v-card-title>
              <v-card-text
                class="pa-2"
                v-if="allBidList && allBidList.length > 0"
              >
                <AgGridVue
                  style="width: 100%; height: calc(110vh - 390px)"
                  class="ag-theme-alpine"
                  id="deliveryChargeGrid"
                  :columnDefs="headers"
                  :context="context"
                  :grid-options="gridOptions"
                  :suppressDragLeaveHidesColumns="true"
                  :rowData="allBidList"
                ></AgGridVue>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <AdminBidRequestDetails 
    v-model="viewBidRequestInformation"
    :bidRequestId= "selectedBid"
    :bidRequestSubHires="subHires" />
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";

import Tooltip from "@/components/General/Tooltip.vue";
import AdminBidRequestButton from "@/components/AgGridComponents/AdminBidRequestButton.vue";
import AdminBidRequestDetails from "@/components/ModuleBased/details/AdminBidRequestDetails.vue";

import {
  isAuthenticated,
  hasRouteAccess,
  getPermissions,
} from "@/utils/functions";

export default {
  components: {
    AgGridVue,
    Tooltip,
    AdminBidRequestButton,
    AdminBidRequestDetails,
  },

  data() {
    return {
      backRoute: {},
      biddingDetails: {},
      allBidList: [],
      viewBidRequestInformation: false,
      selectedBid: null,
      subHires: [],

      permissions: null,
      tripPermissions: null,

      // ag-grid vars
      gridApi: null,
      gridColumnApi: null,
      TripGridOptions: {
        onGridSizeChanged: (grid) => {
          if (grid && grid.api) {
            grid.api.sizeColumnsToFit();
          }
        },
        defaultColDef: {
          resizable: true,
        },
        rowHeight: 35,
        suppressRowClickSelection: true,
        suppressDragLeaveHidesColumns: true,
        enableCellTextSelection: true,
        tooltipShowDelay: 0,
      },
      headers: [
        {
          headerName: "Bid Request",
          field: "bid_request_id",
          sortable: true,
          minWidth: 120,
          pinned: "left",
        },
        {
          headerName: "Transporter",
          field: "transporter_name",
          sortable: true,
          minWidth: 170,
        },
        {
          headerName: "Rate/Container",
          field: "rate",
          sortable: true,
          minWidth: 130,
        },
          {
          headerName: "Container Count",
          field: "container_count",
          tooltipField: "container_count",
          sortable: true,
          minWidth: 140,
        },
        {
          headerName: "Status",
          field: "status",
          sortable: true,
          minWidth: 170,
          cellRenderer: "BidStatusCellRenderer",
        },

        {
          headerName: "Rate Valid Upto",
          field: "rate_expiry_date",
          sortable: true,
          minWidth: 250,
          tooltipValueGetter: (params) => {
            return this.$globals.formatDate(params.value);
          },
          valueFormatter: (params) => {
            return params.value
              ? this.$globals.formatDate(params.value)
              : "N/A";
          },
        },
        {
          headerName: "Transporter Remark",
          field: "transporter_remarks",
          minWidth: 200,
        },
        {
          headerName: "Actions",
          field: "actions",
          cellRenderer: "AdminBidRequestButton",
          pinned: "right",
          minWidth: 180,
        },
      ],

      tripGridApi: null,
      tripGridColumnApi: null,

      gridOptions: {
        onGridSizeChanged: (grid) => {
          if (grid && grid.api) {
            grid.api.sizeColumnsToFit();
          }
        },
        defaultColDef: {
          resizable: true,
        },
        rowHeight: 35,
        suppressRowClickSelection: true,
        suppressDragLeaveHidesColumns: true,
        enableCellTextSelection: true,
        tooltipShowDelay: 0,
      },
    };
  },

  computed: {
    context() {
      return { parentComponent: this };
    },
    currentProfile() {
      if (JSON.parse(localStorage.getItem("userProfile"))) {
        return JSON.parse(localStorage.getItem("userProfile"));
      } else {
        return null;
      }
    },
  },
  methods: {
    viewBidRequestDetails(data) {
      this.viewBidRequestInformation = true;
      this.selectedBid = data.bid_request_id;
      this.subHires = data.bid_request_sub_hires
    
    },
    // get Deatil Object  Api Call
    getBiddingDetails(params = {}) {
      this.$bus.$emit("showLoader", true);
      this.$api.bidding
        .getBidDetails(this.$route.params.id)
        .then((res) => {
          this.biddingDetails = res.data;
          this.allBidList = res.data.bid_request;
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message: "Bid Detail not found!",
            color: "error",
          });
        });
    },

    callBackRoute() {
      if (Object.keys(this.backRoute).length === 0) {
        this.$router.push("/app/admin/bidding/list");
      } else {
        this.$router.push(this.backRoute);
      }
    },
    setBackRoute() {
      if ("backRoute" in this.$route.query) {
        this.backRoute.path = this.$route.query.backRoute;
      }
      if ("active_tab" in this.$route.query) {
        if (!this.backRoute.query) {
          this.backRoute.query = {};
        }
        this.backRoute.query.active_tab = this.$route.query.active_tab;
      }
    },
  },
  beforeMount() {
    if (!isAuthenticated()) {
      this.$router.push("/");
    } else if (!hasRouteAccess(this.$route)) {
      this.$router.push(`/${localStorage.getItem("user_type").toLowerCase()}`);
    } else {
      this.permissions = getPermissions("delivery_advice");
      this.tripPermissions = getPermissions("leg_trip");
      if (!this.permissions || !this.permissions.view) {
        this.$bus.$emit("showToastMessage", {
          message: "You don't have access to this page",
          color: "error",
        });
        this.$router.push(
          `/app/${
            localStorage.getItem("user_type")
              ? localStorage.getItem("user_type").toLowerCase()
              : "error"
          }`
        );
      } else {
        this.getBiddingDetails();
        this.setBackRoute();
      }
    }
  },
};
</script>

<style scoped>
.Cards-Height-Class {
  height: calc(100vh - 374px);
  overflow-y: auto;
}
</style>
