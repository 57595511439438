<template>
  <v-row class="justify-center align-center Login-Section">
    <v-col cols="12" sm="8" md="6" lg="4">
      <v-form
        @submit.prevent="submitForm"
        ref="forgotPasswordForm"
        v-model="isValid"
      >
        <v-card elevation="10" class="pa-2">
          <v-card-title class="d-flex justify-center">
            <img
              class="Brand-Logo mb-10"
              alt="Brand Logo"
              src="@/assets/images/brand-logo.png"
            />
          </v-card-title>

          <v-card-text>
            <v-row class="justify-center">
              <v-col cols="12">
                <InputField
                  hide-details="auto"
                  style="width: calc(100% - 120px); float: left"
                  label="Username"
                  autocomplete="off"
                  required
                  :dense="false"
                  :rules="[(val) => !!val || 'Username is required']"
                  v-model="userDetails.username"
                />
                <v-btn
                  class="ml-2"
                  type="button"
                  color="primary"
                  depressed
                  @click="sendOtp"
                  :disabled="!userDetails.username || disableOtpBtn"
                >
                  {{ !disableOtpBtn ? "Get Otp" : `${timer}s` }}
                </v-btn>
              </v-col>

              <v-col cols="12">
                <InputField
                  hide-details="auto"
                  label="Password"
                  autocomplete="off"
                  required
                  :dense="false"
                  :rules="[(val) => !!val || 'Password is required']"
                  :append-icon="passwordEye ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="passwordEye ? 'text' : 'password'"
                  v-model="userDetails.new_password"
                  @click:append="passwordEye = !passwordEye"
                  @input="validateForm"
                />
              </v-col>

              <v-col
                cols="12"
                :class="{
                  'pb-0':
                    userDetails.new_password &&
                    userDetails.confirm_password &&
                    userDetails.confirm_password != userDetails.new_password,
                }"
              >
                <InputField
                  hide-details="auto"
                  label="Confirm Password"
                  autocomplete="off"
                  required
                  :dense="false"
                  :rules="[(val) => !!val || 'Confirm Password is required']"
                  :type="passwordEye ? 'text' : 'password'"
                  v-model="userDetails.confirm_password"
                  @input="validateForm"
                />
              </v-col>

              <v-col
                v-if="
                  userDetails.new_password &&
                  userDetails.confirm_password &&
                  userDetails.confirm_password != userDetails.new_password
                "
                cols="12"
                class="pt-1"
              >
                <span class="px-3 error--text v-messages">
                  Password dosen't match.
                </span>
              </v-col>

              <v-col cols="12">
                <InputField
                  hide-details="auto"
                  label="Verification Code"
                  autocomplete="off"
                  required
                  :dense="false"
                  :rules="[(val) => !!val || 'Verification Code is required']"
                  v-model="userDetails.verification_code"
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-row>
              <v-col cols="12" class="d-flex justify-center">
                <v-btn
                  type="submit"
                  color="primary"
                  depressed
                  :disabled="
                    !isValid ||
                    (userDetails.new_password &&
                      userDetails.confirm_password &&
                      userDetails.confirm_password !== userDetails.new_password)
                  "
                  :loading="btnLoading"
                  large
                >
                  Submit
                </v-btn>
              </v-col>
              <v-col cols="12" class="d-flex justify-center">
                <v-btn
                  @click="goToLogin"
                  type="button"
                  text
                  color="primary"
                  depressed
                  large
                >
                  Go To Login
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
import InputField from "@/components/FormBaseComponents/InputField.vue";
export default {
  components: { InputField },
  layout: "login",
  data() {
    return {
      userDetails: {},
      btnLoading: false,
      password: "Password",
      passwordEye: false,
      isValid: true,
      timer: 30,
      interval: null,
      disableOtpBtn: false,
    };
  },
  methods: {
    goToLogin() {
      this.$router.push("/app/login");
    },
    submitForm() {
      this.btnLoading = true;
      this.$api.authentication
        .restPassword(this.userDetails)
        .then(({ data }) => {
          this.$router.push("/app/login");
          this.btnLoading = false;
        })
        .catch((err) => {
          this.btnLoading = false;
          this.$bus.$emit("showToastMessage", {
            message: `${
              err.data && err.data.non_field_errors
                ? err.data.non_field_errors.join()
                : "Unable to proceed with OTP"
            }`,
            color: "error",
            hasActions: true,
          });
        });
    },
    validateForm() {
      if (
        this.userDetails.password &&
        this.userDetails.confirm_password &&
        this.$refs.forgotPasswordForm
      ) {
        this.$refs.forgotPasswordForm.validate();
      }
    },
    sendOtp() {
      this.$api.authentication
        .sendOtp({ username: this.userDetails.username })
        .then(({ data }) => {
          if (data && data.message) {
            this.$bus.$emit("showToastMessage", {
              message: data.message,
              color: "success",
            });
          }
          this.startTimer();
        })
        .catch((err) => {
          this.btnLoading = false;
          if (err && err.data && err.data.non_field_errors) {
            this.$bus.$emit("showToastMessage", {
              message: err.data.non_field_errors.join(),
              color: "error",
            });
          }
        });
    },
    startTimer() {
      this.timer = 30;
      this.interval = setInterval(() => {
        this.disableOtpBtn = true;
        if (!this.timer) {
          this.stopTimer();
        }
        this.timer--;
      }, 1000);
    },
    stopTimer() {
      this.disableOtpBtn = false;
      this.timer = 30;
      if (this.interval) {
        clearInterval(this.interval);
      }
    },
  },
};
</script>

<style scoped>
.Login-Section {
  height: 100vh;
}

.Login-Section .Brand-Logo {
  width: 250px;
}
</style>
