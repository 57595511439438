import Blank from "@/layouts/blank.vue"

 
import TripDetails from "@/pages/customer/trip/TripDetails.vue";

const tripRoutes = [
  {
    path: "trip",
    name: "app-customer-trip",
    component: Blank,
    children: [
      {
        path: "/",
        redirect: "/app/customer/trip/list",
      },
     
      {
        path: "details",
        name: "app-customer-trip-details",
        component: Blank,
        children: [
          {
            path: "/",
            redirect: "/app/customer/trip/list",
          },
          {
            path: ":id",
            name: "app-customer-trip-details-id",
            component: TripDetails,
          }
        ]
      },
    ]
  },
]

export default tripRoutes