<template>
  <div>
    <v-tooltip top>
      <template #activator="{ on, attrs }">
        <v-icon
        class="ml-1 mr-2"
          color="primary"
          v-on="on"
          v-bind="attrs"
          :disabled="
            params.data.status == 'Completed' ||
            params.data.status == 'Ready For Invoice' ||
            params.data.status == 'Invoice Generated' ||
            params.data.is_sub_hire == 'Yes'
          "
          @click="assetAllocation"
          v-if="
            params &&
            params.context &&
            params.context.parentComponent &&
            params.context.parentComponent.permissions &&
            params.context.parentComponent.permissions.add
          "
        >
          mdi-truck-flatbed
        </v-icon>
      </template>
      <span>Asset Allocation</span>
    </v-tooltip>

    <v-tooltip top>
      <template #activator="{ on, attrs }">
        <v-icon
        class="mr-1 ml-1"
          color="primary"
          v-on="on"
          v-bind="attrs"
          @click="deAllocateTransporter"
          v-if="
            params &&
            params.context &&
            params.context.parentComponent &&
            params.context.parentComponent.permissions &&
            params.context.parentComponent.permissions.add &&
            params.data.leg_sub_hire &&
            params.data.status == 'Created'
          "
        >
          mdi-replay
        </v-icon>
      </template>
      <span>Unallocate Transporter</span>
    </v-tooltip>
    <v-tooltip top>
      <template #activator="{ on, attrs }">
        <v-icon
         class="ml-2 mr-1"
          color="primary"
          v-on="on"
          v-bind="attrs"
          @click="combineLegs"
          :disabled="
            params.data.status != 'Created' && params.data.status != 'Assigned'
          "
          v-if="
            params &&
            params.context &&
            params.context.parentComponent &&
            params.context.parentComponent.permissions &&
            params.context.parentComponent.permissions.add &&
            params.data.is_sub_hire == 'No'
          "
        >
          mdi-sitemap-outline
        </v-icon>
      </template>
      <span>Combine Legs</span>
    </v-tooltip>

  </div>
</template>

<script>
export default {
  methods: {
    updateReadyToInvoice() {
      if (confirm("Are you sure you want to move it to ready for invoice.")) {
        this.$api.trip
          .partiallyUpdateLeg({
            id: this.params.data.id,
            data: { status: "Ready For Invoice" },
          })
          .then((res) => {
            this.params.context.parentComponent.getLegsList();
            this.$bus.$emit("showToastMessage", {
              message: "Status Updated Successfully!",
              color: "success",
            });
          })
          .catch((err) => {
            this.$bus.$emit("showToastMessage", {
              message: `${
                err.data && err.data.non_field_errors
                  ? err.data.non_field_errors.join()
                  : "Cant update status"
              }`,
              color: "error",
            });
          });
      }
    },
    viewItem() {
      this.params.context.parentComponent.viewTrip(this.params.data.trip_pk);
    },
    deAllocateTransporter() {
      this.params.context.parentComponent.deAllocateTransporter(
        this.params.data
      );
    },
    assetAllocation() {
      this.params.context.parentComponent.allocateAsset(this.params.data);
    },
    legChargeForm() {
      this.params.context.parentComponent.legChargeForm(
        this.params.data.id,
        this.params.data
      );
    },
    combineLegs() {
      this.params.context.parentComponent.combineLegs(
        this.params.data.id,
        this.params.data.company
      );
    },
  },
};
</script>

<style></style>
