<template>
  <v-dialog width="500" v-model="showLegBonusDialog">
    <v-card>
      <v-card-title class="secondary white--text">
        <span> Update Bonus </span>

        <v-spacer></v-spacer>
        <v-btn icon @click="showLegBonusDialog = false">
          <v-icon class="white--text">mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="pa-2">
        <v-form v-if="showLegBonusDialog" v-model="isValid">
          <v-row class="ma-0">
            <v-col cols="12" class="Form-Columns">
              <InputField
                label="Bonus *"
                :clearable="false"
                hide-details="auto"
                v-model="formDetails.bonus"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="justify-end">
        <v-btn
          color="primary"
          depressed
          :disabled="!isValid"
          @click="submitForm"
        >
          Submit
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import InputField from "@/components/FormBaseComponents/InputField.vue";

export default {
  components: {
    InputField,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    legBonus: {
      type: [Number, String],
    },
    legId: {
      type: [Array],
    },
  },
  computed: {
    showLegBonusDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  data() {
    return {
      module_type: null,
      isValid: true,
      formErrors: {},
      formDetails: {},
    };
  },
  watch: {
    value(val) {
      if (val) {
        this.formDetails = {
          bonus: this.legBonus,
          updatelegId: this.legId,
        };
      } else {
        this.formDetails = {};
      }
    },
  },
  methods: {
    submitForm() {
      this.$bus.$emit("showLoader", true);
      let payload = {
        id: this.formDetails.updatelegId,
        bonus: this.formDetails.bonus,
      };
     
      this.$api.trip
        .bulkUpdateLegbonus(payload)
        .then((res) => {
          this.showLegBonusDialog = false;
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message: "Leg Bonus Updated Successfully!",
            color: "success",
          });
          this.formErrors = {};
          this.$emit("refreshList");
        })
        .catch((err) => {
          this.formErrors = {};
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message: "Couldn't update Bonus!",
            color: "error",
          });
        });
    },
  },
};
</script>
