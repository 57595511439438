<template>
  <ListComponent title="LRFQ" :totalCount="totallrfqStatistics.total_lrfq">
    <template #list>
      <v-row class="ma-0">
        <v-col ccols="4" class="pl-0 pr-2 pt-0 pb-2 d-flex align-center">
          <!-- Tabs -->

          <v-text-field
            label="Search"
            outlined
            dense
            append-icon="mdi-magnify"
            clearable
            hide-details="auto"
            v-model="filters.search"
            @input="searchLrfq"
          ></v-text-field>

          <v-tooltip top>
            <template #activator="{ on, attrs }">
              <v-btn
                depressed
                icon
                v-on="on"
                color="primary"
                v-bind="attrs"
                @click="showFilterDialog = true"
                class="mx-2"
              >
                <v-badge
                  dot
                  color="primary"
                  :value="Object.keys(filters).length"
                >
                  <v-icon>mdi-filter</v-icon>
                </v-badge>
              </v-btn>
            </template>

            <span v-if="Object.keys(filters).length == 0">Filters</span>

            <v-row v-else no-gutters>
              <v-col cols="12" class="font-weight-bold">Applied Filters</v-col>
              <v-col cols="12">
                <span
                  v-for="(value, key) in filters"
                  :key="key"
                  class="text-capitalize"
                  >{{ key.replace("_", " ") }}
                  <br />
                </span>
              </v-col>
            </v-row>
          </v-tooltip>
          <Columns :headers="headers" :gridColInstance="gridColumnApi" />
        </v-col>

        <v-col cols="4" class="px-0 pt-0 pb-2">
          <v-tabs height="35" v-model="tab" centered>
            <v-tabs-slider></v-tabs-slider>
            <v-tab>
              Active
              <v-badge
                v-if="totallrfqStatistics.active_lrfq"
                top
                :content="totallrfqStatistics.active_lrfq"
                overlap
                class="mb-4 ml-3"
              >
              </v-badge>
            </v-tab>
            <v-tab>
              Closed
              <v-badge
                v-if="totallrfqStatistics.closed_lrfq"
                top
                :content="totallrfqStatistics.closed_lrfq"
                overlap
                class="mb-4 ml-3"
              >
              </v-badge>
            </v-tab>
          </v-tabs>
        </v-col>

        <v-col cols="4" class="px-0 pt-0 pb-2 d-flex justify-end">
          <Pagination
            v-if="totalItems > 0"
            :pageNo="pageNo"
            :totalItems="totalItems"
            :pageSize="itemsPerPage"
            @itemsPerPageChange="itemsPerPageChanged"
            @prevPage="prevPage"
            @nextPage="nextPage"
          />
        </v-col>

        <v-col cols="12" class="pa-0">
          <AgGridVue
            style="width: 100%; height: calc(100vh - 172px)"
            class="ag-theme-alpine"
            id="myGrid"
            :columnDefs="headers"
            :context="context"
            @grid-ready="onGridReady"
            :defaultColDef="defaultColDef"
            :grid-options="gridOptions"
            :suppressDragLeaveHidesColumns="true"
            :rowData="alllrfqList"
          ></AgGridVue>
        </v-col>
      </v-row>
      <DetailsParent v-model="lrfqLogDetailsDrawer" :resizable="true">
        <template #details>
          <v-card elevation="0">
            <v-card-title class="heading">
              <span class="text-h6">LRFQ Logs</span>
            </v-card-title>

            <v-card-text>
              <v-row class="Order-Log-Scrolling-Class mt-3">
                <v-col cols="12">
                  <span class="py-12" v-if="lrfqLogs && lrfqLogs.length == 0">
                    No logs to show!
                  </span>

                  <v-timeline dense clipped v-else>
                    <v-timeline-item
                      v-for="(log, i) in lrfqLogs"
                      :key="i"
                      icon-color="primary"
                      small
                    >
                      <v-card class="elevation-2">
                        <v-card-text>
                          <table class="Full-Width">
                            <tbody>
                              <tr>
                                <th class="text-left">{{ log.updated_by }}</th>
                                <td class="text-caption text-right">
                                  {{ log.created | formatDate(true) }}
                                </td>
                              </tr>
                              <tr>
                                <td colspan="2">{{ log.message }}</td>
                              </tr>
                            </tbody>
                          </table>
                        </v-card-text>
                      </v-card>
                    </v-timeline-item>
                  </v-timeline>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </template>
      </DetailsParent>
    </template>

    <template #dialogs>
      <AdminBidFilters
        v-model="showFilterDialog"
        :bindingObject="filters"
        :currentTab="tab"
        @resetFilters="resetFilters(), getTotalLrfqCount()"
        @applyFilters="
          itemsPerPageChanged(20);
          getTotalLrfqCount();
        "
      />
      <BulkUpload
        v-model="lrfqBulkUploadDialog"
        :uploadTo="uploadType"
        @refreshList="itemsPerPageChanged(20)"
      />
    </template>

    <template #actions>
      <v-btn
        class="ml-1"
        v-if="permissions && permissions.add"
        @click="openLrfqBulkUpload('transporter_lrfq')"
        color="primary"
        depressed
      >
        <v-icon>mdi-plus</v-icon>
        <span>Bulk Update</span>
      </v-btn>
    </template>
  </ListComponent>
</template>

<script>
import Columns from "@/components/General/Columns.vue";
import ListComponent from "@/components/General/ListComponent.vue";
import { AgGridVue } from "ag-grid-vue";
import Pagination from "@/components/General/Pagination.vue";
import TransporterLrfqButton from "@/components/AgGridComponents/TransporterLrfqButton.vue";
import BulkUpload from "@/components/General/BulkUpload.vue";

import CustomHeader from "@/components/AgGridComponents/General/CustomHeader.vue";
import AdminBidFilters from "@/components/ModuleBased/dialogs/filters/AdminBidFilters.vue";
import DetailsParent from "@/components/General/DetailsParent.vue";

import {
  isAuthenticated,
  hasRouteAccess,
  getPermissions,
} from "@/utils/functions";

export default {
  components: {
    ListComponent,
    AgGridVue,
    AdminBidFilters,
    Pagination,
    TransporterLrfqButton,
    agColumnHeader: CustomHeader,
    DetailsParent,
    Columns,
    BulkUpload,
  },
  data() {
    return {
      alllrfqList: [],
      lrfqLogs: [],
      totallrfqStatistics: [],
      statuses: ["Active", "Closed"],
      openFilterDialog: false,
      showLrfqLogDialog: false,
      showDialog: false,
      lrfqLogDetailsDrawer: false,
      tab: 0,

      showCloseRemarksForm: false,
      lrfqBulkUploadDialog: false,
      uploadType: "lrfq_rates_update",

      sorting: {},

      headers: [
        {
          headerName: "LRFQ ID",
          field: "lrfq_id",
          sortable: true,
          minWidth: 120,
        },
        {
          headerName: "Start date",
          field: "start_date",
          sortable: true,
          minWidth: 140,
          tooltipValueGetter: (params) => {
            return this.$globals.formatDate(params.value);
          },
          valueFormatter: (params) => {
            return this.$globals.formatDate(params.value);
          },
        },
        {
          headerName: "End date",
          field: "end_date",
          sortable: true,
          minWidth: 140,
          tooltipValueGetter: (params) => {
            return this.$globals.formatDate(params.value);
          },
          valueFormatter: (params) => {
            return this.$globals.formatDate(params.value);
          },
        },
        {
          headerName: "Last Submission Date",
          field: "last_submission_date",
          sortable: true,
          minWidth: 140,
          tooltipValueGetter: (params) => {
            return this.$globals.formatDate(params.value);
          },
          valueFormatter: (params) => {
            return this.$globals.formatDate(params.value);
          },
        },
        {
          headerName: "Status",
          field: "status",
          sortable: true,
          minWidth: 140,
        },
        {
          headerName: "Total LRFQ Request",
          field: "lrfq_request_count",
          sortable: true,
          minWidth: 140,
        },
        {
          headerName: "Created By",
          field: "created_by",
          minWidth: 150,
          sortable: true,
          tooltipField: "created_by",
        },
        {
          headerName: "Actions",
          field: "actions",
          cellRenderer: "TransporterLrfqButton",
          pinned: "right",
          minWidth: 180,
        },
      ],

      // details vars

      permissions: null,

      // pagination vars
      pageNo: 1,
      itemsPerPage: 10,
      totalItems: 0,

      // filter vars
      filters: {},
      showFilterDialog: false,

      headerSelected: [],

      // ag-grid vars
      gridApi: null,
      gridColumnApi: null,
      defaultColDef: {
        width: 150,
        resizable: true,
        flex: 1,
      },
      gridOptions: {
        onGridSizeChanged: () => {
          this.resizeGrid();
        },
        suppressRowClickSelection: true,
        suppressDragLeaveHidesColumns: true,
        enableCellTextSelection: true,
        tooltipShowDelay: 0,
      },
    };
  },
  watch: {
    tab() {
      this.getLrfqList();
    },
  },
  computed: {
    currentProfile() {
      let obj = JSON.parse(localStorage.getItem("userProfile"));
      if (!obj) {
        return null;
      } else {
        return obj;
      }
    },
    context() {
      return { parentComponent: this };
    },
    offset() {
      return this.itemsPerPage * (this.pageNo - 1);
    },
  },

  methods: {
    openLrfqBulkUpload(type) {
      this.uploadType = type;
      this.lrfqBulkUploadDialog = true;
    },
    getTotalLrfqCount(params) {
      if (this.searchDAStatisticTimeout) {
        clearTimeout(this.searchDAStatisticTimeout);
      }
      this.searchDAStatisticTimeout = setTimeout(() => {
        params = { ...this.filters };
        delete params.limit;
        delete params.offset;
        this.$api.lrfq
          .getLrfqStatistics(params)
          .then((res) => {
            this.totallrfqStatistics = res.data;
          })
          .catch((err) => {
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message: "Couldn't  Find Bid!",
              color: "error",
            });
          });
      }, 1000);
    },

    resizeGrid() {
      if (this.gridOptions && "api" in this.gridOptions) {
        setTimeout(() => {
          this.gridOptions.api.sizeColumnsToFit();
        }, 100);
      }
    },
    resetFilters() {
      this.filters = {};
      this.itemsPerPageChanged(10);
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.resizeGrid();
    },
    searchLrfq() {
      this.pageNo = 1;
      this.getLrfqList();
      this.getTotalLrfqCount();
    },

    headersChanged(value) {
      this.headerSelected = value;
      this.resizeGrid();
    },
    itemsPerPageChanged(e) {
      this.pageNo = 1;
      this.itemsPerPage = e;
      this.getLrfqList();
    },
    prevPage() {
      this.pageNo--;
      this.getLrfqList();
    },
    nextPage() {
      this.pageNo++;
      this.getLrfqList();
    },
    applyGridSort(key, type) {
      if (this.filters && !this.filters.ordering) {
        this.filters.ordering = [];
      }

      if (type == null) {
        delete this.sorting[key];
        this.filters.ordering.splice(
          this.filters.ordering.indexOf(`-${key}`),
          1
        );
      } else if (type == "asc") {
        this.filters.ordering.push(key);
      } else if (type == "desc") {
        this.filters.ordering.splice(
          this.filters.ordering.indexOf(key),
          1,
          `-${key}`
        );
      }
      if (this.filters.ordering.length == 0) {
        delete this.filters.ordering;
      }
      this.getLrfqList();
    },
    getLrfqList(params = {}) {
      this.$bus.$emit("showLoader", true);
      let laneId;

      if (this.filters && this.filters.lane) {
        laneId = this.filters.lane.id;
        delete this.filters.lane;
        this.filters.lane = laneId;
      }

      params = {
        offset: this.offset,
        limit: this.itemsPerPage,
        status: this.statuses[this.tab],
        ...params,
        ...this.filters,
        transporter: this.currentProfile.company,
      };

      if (params.ordering && params.ordering.length > 0) {
        params = { ...params, ordering: params.ordering.join(",") };
      }

      this.$api.lrfq
        .getTransporterLrfqList(params)
        .then((res) => {
          this.alllrfqList = res.data;
          this.totalItems = res.count;
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          if (err && err.data) {
            const showToast = (message) => {
              this.$bus.$emit("showToastMessage", {
                message,
                color: "error",
                hasActions: true,
              });
            };

            if (err.data.message) {
              showToast(err.data.message);
            } else if (
              err.data.error &&
              err.data.error.non_field_errors &&
              err.data.error.non_field_errors.length
            ) {
              showToast(err.data.error.non_field_errors.join(", "));
            } else {
              showToast("Something Went Wrong");
            }
          }
          this.$bus.$emit("showLoader", false);
        });
    },

    viewLrfq(id) {
      const path = {
        path: `/app/transporter/lrfq/detail/${id}`,
        query: { backRoute: `/app/transporter/lrfq/list` },
      };
      this.$router.push(path);
    },
    async downloadLrfqRequest(id) {
      this.$bus.$emit("showLoader", true);
      let params = {
        transporter: this.currentProfile.company,
        lrfq_id: id,
      };
      await this.$api.lrfq
        .getLrfqRequestBlopObject(id, params)
        .then(async (response) => {
          this.$bus.$emit("showLoader", false);
          var blob = response.data;

          var downloadLink = window.document.createElement("a");
          downloadLink.href = window.URL.createObjectURL(
            new Blob([blob], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            })
          );
          downloadLink.download = "LrfqDetails.xlsx";
          downloadLink.click();
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showToastMessage", {
            message: `${
              err.data && err.data.non_field_errors
                ? err.data.non_field_errors.join()
                : "Data not found"
            }`,
            color: "error",
          });

          this.$bus.$emit("showLoader", false);
        });
    },
  },
  beforeMount() {
    if (!isAuthenticated()) {
      this.$router.push("/");
    } else if (!hasRouteAccess(this.$route)) {
      this.$router.push(`/${localStorage.getItem("user_type").toLowerCase()}`);
    } else {
      const permissions = getPermissions("contract");
      if (!permissions || !permissions.view) {
        this.$bus.$emit("showToastMessage", {
          message: "You don't have access to this page",
          color: "error",
        });
        this.$router.push(
          `/${
            localStorage.getItem("user_type")
              ? localStorage.getItem("user_type").toLowerCase()
              : "error"
          }`
        );
      } else {
        this.permissions = { ...permissions };
        this.getLrfqList();
        this.getTotalLrfqCount();
      }
    }
  },
};
</script>
