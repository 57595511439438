<template>
  <v-dialog width="800" v-model="showChargeForm">
    <v-card>
      <v-card-title class="secondary white--text">
        <span> {{ editMode ? "Update" : "Add" }} Charge </span>

        <v-spacer></v-spacer>

        <v-btn icon @click="resetForm(), (showChargeForm = false)">
          <v-icon class="white--text">mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="pt-2">
        <v-form ref="chargeForm" v-if="showChargeForm" v-model="isValid">
          <v-row class="my-0">
            <v-col cols="12" lg="4" class="Form-Columns">
              <InputField
                type="text"
                label="Charge Title *"
                required
                :rules="[
                  (val) => !!val || 'Charge Title is Required!',
                  (val) =>
                    !$globals.numbersOnlyRegExp.test(val) ||
                    'Invalid Charge Title!',
                ]"
                hide-details="auto"
                :readonly="isReadOnly"
                :clearable="!isReadOnly"
                v-model="chargeDetails.charge_title"
                :error-messages="formErrors.charge_title"
                @input="delete formErrors.charge_title"
              />
            </v-col>

            <v-col cols="12" lg="4" class="Form-Columns">
              <InputField
                type="text"
                label="Revenue GL No. *"
                hide-details="auto"
                :rules="[(val) => !!val || 'Revenue GL No is Required!']"
                v-model="chargeDetails.gl_no"
                :error-messages="formErrors.gl_no"
                @input="delete formErrors.gl_no"
              />
            </v-col>

            <v-col cols="12" lg="4" class="Form-Columns">
              <InputField
                type="text"
                label="Cost GL No. *"
                hide-details="auto"
                :rules="[(val) => !!val || 'Cost GL No is Required!']"
                v-model="chargeDetails.cost_gl_no"
                :error-messages="formErrors.cost_gl_no"
                @input="delete formErrors.cost_gl_no"
              />
            </v-col>

            <v-col cols="12" lg="3" class="Form-Columns">
              <InputField
                type="number"
                :label="`Rate (${$globals.currency})`"
                hide-details="auto"
                v-model="chargeDetails.rate"
                :error-messages="formErrors.rate"
                @input="delete formErrors.rate"
              />
            </v-col>

            <v-col cols="12" lg="3" class="Form-Columns">
              <InputField
                type="number"
                :label="`Tax Percentage (${$globals.currency})`"
                hide-details="auto"
                v-model="chargeDetails.tax_percentage"
                :readonly="isReadOnly"
                :clearable="!isReadOnly"
                :error-messages="formErrors.tax_percentage"
                @input="delete formErrors.tax_percentage"
              />
            </v-col>

            <v-col cols="12" lg="6" class="Form-Columns">
              <InputField
                label="Description*"
                hide-details="auto"
                :rules="[(val) => !!val || 'Description is Required!']"
                v-model="chargeDetails.description"
                :error-messages="formErrors.description"
                @input="delete formErrors.description"
              />
            </v-col>

            <v-col cols="8" lg="6" class="Form-Columns">
              <v-switch
                :label="`Active : ${
                  chargeDetails.is_active == 'Yes' ? 'Yes' : 'No'
                }`"
                class="mt-1 pl-2"
                true-value="Yes"
                false-value="No"
                :readonly="isReadOnly"
                :clearable="!isReadOnly"
                hide-details="auto"
                v-model="chargeDetails.is_active"
                :error-messages="formErrors.is_active"
                @change="delete formErrors.is_active"
              ></v-switch>

              <v-col cols="6" lg="6" class="Form-Columns">
                <v-switch
                  :label="`Revenue : ${
                    chargeDetails.is_revenue == 'Yes' ? 'Yes' : 'No'
                  }`"
                  class="mt-1"
                  true-value="Yes"
                  false-value="No"
                  :readonly="isReadOnly"
                  :clearable="!isReadOnly"
                  hide-details="auto"
                  v-model="chargeDetails.is_revenue"
                  :error-messages="formErrors.is_revenue"
                  @change="
                    delete formErrors.is_revenue;
                    setChargeType('setRevenue');
                  "
                ></v-switch>
              </v-col>
              <v-col cols="6" lg="6" class="Form-Columns">
                <v-switch
                  :label="`Cost : ${
                    chargeDetails.is_cost == 'Yes' ? 'Yes' : 'No'
                  }`"
                  class="mt-1"
                  true-value="Yes"
                  false-value="No"
                  :readonly="isReadOnly"
                  :clearable="!isReadOnly"
                  hide-details="auto"
                  v-model="chargeDetails.is_cost"
                  :error-messages="formErrors.is_cost"
                  @change="
                    delete formErrors.is_cost;
                    setChargeType('setCost');
                  "
                ></v-switch>
              </v-col>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-divider></v-divider>
      <v-card-actions class="justify-end">
        <v-btn
          color="primary"
          depressed
          :disabled="!isValid"
          @click="submitForm"
        >
          Submit
        </v-btn>

        <v-btn
          class="ml-2"
          color="secondary"
          v-if="!editMode"
          depressed
          @click="resetForm"
        >
          Reset
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import InputField from "@/components/FormBaseComponents/InputField.vue";
import MultiSelect from "@/components/FormBaseComponents/MultiSelect.vue";
import SelectField from "@/components/FormBaseComponents/SelectField.vue";

export default {
  components: {
    InputField,
    SelectField,
    MultiSelect,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    editChargeID: {
      type: [Number, null],
      default: null,
    },
  },
  watch: {
    value(val) {
      if (val) {
        if (this.editMode) {
          this.getChargeObject(this.editChargeID);
        }
      } else {
        this.resetForm();
      }
    },
  },
  computed: {
    showChargeForm: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    isReadOnly() {
      return this.chargeDetails && this.chargeDetails.is_system_generated;
    },
  },
  data() {
    return {
      chargeDetails: {
        is_active: "Yes",
        is_revenue: "Yes",
      },
      formErrors: {},
      isTextInput: false,
      isValid: true,
    };
  },
  methods: {
    setChargeType(chargeType) {
      if (chargeType === "setRevenue") {
        if (this.chargeDetails.is_revenue == "No") {
          this.chargeDetails.is_cost = "Yes";
        }
      }
      if (chargeType === "setCost") {
        if (this.chargeDetails.is_cost == "No") {
          this.chargeDetails.is_revenue = "Yes";
        }
      }
    },

    submitForm() {
      this.$bus.$emit("showLoader", true);
      if (!this.editMode) {
        this.chargeDetails = {
          ...this.chargeDetails,
        };
        this.$api.charge
          .addCharge(this.chargeDetails)
          .then((res) => {
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message: "Charge added successfully!",
              color: "success",
            });
            this.resetForm();
            this.showChargeForm = false;
            this.$emit("refreshList");
          })
          .catch((err) => {
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message: "Couldn't add charge!",
              color: "error",
            });
            this.formErrors = err.data;
          });
      } else {
        this.$api.charge
          .updateCharge({ id: this.chargeDetails.id, data: this.chargeDetails })
          .then((res) => {
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message: "Charge updated successfully!",
              color: "success",
            });
            this.resetForm();
            this.showChargeForm = false;
            this.$emit("refreshList");
          })
          .catch((err) => {
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message: "Couldn't update charge!",
              color: "error",
            });
            this.formErrors = err.data;
          });
      }
    },

    resetForm() {
      if (this.$refs.chargeForm) {
        this.$refs.chargeForm.reset();
      }
      this.chargeDetails = {
        is_active: "Yes",
        is_revenue: "Yes",
      };
    },

    getChargeObject(id) {
      this.$bus.$emit("showLoader", true);
      this.$api.charge
        .getChargeObject(id)
        .then((res) => {
          this.$bus.$emit("showLoader", false);
          this.chargeDetails = res.data;
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
  },
};
</script>
