var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(
          _vm.params &&
          _vm.params.context &&
          _vm.params.context.parentComponent &&
          _vm.params.context.parentComponent.permissions &&
          _vm.params.context.parentComponent.permissions.change &&
          'tab' in _vm.params.context.parentComponent &&
          _vm.params.context.parentComponent.tab == 3
        )?_c('v-icon',_vm._g(_vm._b({staticClass:"ml-1 mr-2",attrs:{"color":"primary"},on:{"click":_vm.newUser}},'v-icon',attrs,false),on),[_vm._v(" mdi-check ")]):_vm._e()]}}])},[_c('span',[_vm._v("Accept")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(
          _vm.params &&
          _vm.params.context &&
          _vm.params.context.parentComponent &&
          _vm.params.context.parentComponent.permissions &&
          _vm.params.context.parentComponent.permissions.view
        )?_c('v-icon',_vm._g(_vm._b({staticClass:"ml-2 mr-1",attrs:{"color":"primary"},on:{"click":_vm.viewDetails}},'v-icon',attrs,false),on),[_vm._v(" mdi-eye ")]):_vm._e()]}}])},[_c('span',[_vm._v("View Details")])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }