<template>
  <ListComponent
    title="Delivery Advices"
    :totalCount="totalDaStatistics.total_da"
  >
    <template #list>
      <v-row class="ma-0">
        <v-col cols="4" class="pl-0 pr-2 pt-0 pb-2 d-flex align-center">
          <v-text-field
            label="Search On Enter"
            outlined
            dense
            append-icon="mdi-magnify"
            clearable
            @click:clear="onEnter()"
            hide-details="auto"
            v-model="filters.search"
            v-on:keyup.enter="onEnter"
          ></v-text-field>

          <v-tooltip top>
            <template #activator="{ on, attrs }">
              <v-btn
                depressed
                icon
                v-on="on"
                v-bind="attrs"
                @click="showFiltersDialog = true"
                class="mx-2"
              >
                <v-badge
                  dot
                  color="primary"
                  :value="Object.keys(nonEmptyFilters).length"
                >
                  <v-icon>mdi-filter</v-icon>
                </v-badge>
              </v-btn>
            </template>

            <!-- Text when no filters are applied -->
            <span v-if="Object.keys(nonEmptyFilters).length === 0"
              >Filters</span
            >

            <!-- Show applied filters if any -->
            <v-row v-else no-gutters>
              <v-col cols="12" class="font-weight-bold">Applied Filters</v-col>
              <v-col cols="12">
                <span
                  v-for="(value, key) in nonEmptyFilters"
                  :key="key"
                  class="text-capitalize"
                >
                  {{ key.replace("_", " ") }}
                  <br />
                </span>
              </v-col>
            </v-row>
          </v-tooltip>

          <Columns :headers="headers" :gridColInstance="gridColumnApi" />
        </v-col>

        <v-col cols="4" class="px-0 pt-0 pb-2">
          <v-tabs height="35" v-model="tab" centered>
            <v-tabs-slider></v-tabs-slider>
            <v-tab>
              Active
              <v-badge
                v-if="totalDaStatistics.active_da"
                top
                :content="totalDaStatistics.active_da"
                overlap
                class="mb-4 ml-3"
              >
              </v-badge>
            </v-tab>
            <v-tab>
              Completed
              <v-badge
                v-if="totalDaStatistics.completed_da"
                top
                :content="totalDaStatistics.completed_da"
                overlap
                class="mb-4 ml-3"
              >
              </v-badge>
            </v-tab>
            <v-tab>
              Cancelled
              <v-badge
                v-if="totalDaStatistics.cancelled_da"
                top
                :content="totalDaStatistics.cancelled_da"
                overlap
                class="mb-4 ml-3"
              >
              </v-badge>
            </v-tab>
          </v-tabs>
        </v-col>

        <v-col
          v-if="totalItems > 0"
          cols="4"
          class="px-0 pt-0 pb-2 d-flex justify-end"
        >
          <Pagination
            :pageNo="pageNo"
            :totalItems="totalItems"
            :pageSize="itemsPerPage"
            @itemsPerPageChange="itemsPerPageChanged"
            @prevPage="prevPage"
            @nextPage="nextPage"
          />
        </v-col>

        <v-col cols="12" class="pa-0">
          <AgGridVue
            style="width: 100%; height: calc(100vh - 172px)"
            class="ag-theme-alpine"
            id="daList"
            :columnDefs="headers"
            :context="context"
            @grid-ready="onGridReady"
            @columnMoved="resizeGrid"
            @columnVisible="resizeGrid"
            :defaultColDef="defaultColDef"
            :grid-options="gridOptions"
            :suppressDragLeaveHidesColumns="true"
            :rowData="allDeliveryAdviceList"
          ></AgGridVue>
        </v-col>
      </v-row>
    </template>

    <template #dialogs>
      <AdminDeliveryAdviceFilters
        :bindingObject="filters"
        :currentTab="tab"
        v-model="showFiltersDialog"
        @resetFilters="
          resetFilters();
          getTotalDACount();
        "
        @applyFilters="
          itemsPerPageChanged(20);
          getTotalDACount();
        "
      />
      <BulkCancelDaDialog
        v-model="showBulkCancelDaForm"
        @refreshList="
          getDeliveryAdviceList();
          getTotalDACount();
        "
      />
      <RejectionRemarksDialog
        v-model="showRemarksForm"
        :formDetails="daCancellationRemark"
        fieldKey="admin_remarks"
        :isRequiredRemarks="true"
        :label="`${!isAccepted ? 'Cancellation' : 'Acceptance'} Remarks `"
        @submitRemarks="cancelDeliveryAdvice"
      />
      <LegMovementDateForm
        v-model="showMovementDateDialog"
        :legs="selectedDas"
        :module="module_name"
        @refreshList="
          getDeliveryAdviceList();
          selectedDas = [];
        "
      />
    </template>

    <template #actions>
      <v-btn
        v-if="permissions && permissions.add"
        @click="createDirectDeliveryAdvice"
        color="primary"
        depressed
        class="mr-1"
      >
        <v-icon>mdi-plus</v-icon>
        <span>Create Credit DA</span>
      </v-btn>
      <v-btn
        v-if="permissions && permissions.add"
        @click="createCashDeliveryAdvice"
        color="primary"
        depressed
      >
        <v-icon>mdi-plus</v-icon>
        <span>Create Cash DA</span>
      </v-btn>

      <v-btn
        v-if="
          permissions &&
          permissions.add &&
          currentProfile &&
          currentProfile.is_primary_user
        "
        color="primary"
        depressed
        class="ml-3"
        @click="showBulkCancelDaForm = true"
      >
        <span>Cancel DA</span>
      </v-btn>
    </template>
  </ListComponent>
</template>

<script>
import Columns from "@/components/General/Columns.vue";
import ListComponent from "@/components/General/ListComponent.vue";
import { AgGridVue } from "ag-grid-vue";
import Pagination from "@/components/General/Pagination.vue";
import DeliveryAdvicesListButton from "@/components/AgGridComponents/DeliveryAdvicesListButton.vue";
import CustomHeader from "@/components/AgGridComponents/General/CustomHeader.vue";
import AdminDeliveryAdviceFilters from "@/components/ModuleBased/dialogs/filters/AdminDeliveryAdviceFilters.vue";
import RejectionRemarksDialog from "@/components/ModuleBased/dialogs/RejectionRemarksDialog.vue";
import LegMovementDateForm from "@/components/ModuleBased/dialogForms/LegMovementDateForm.vue";
import BulkCancelDaDialog from "@/components/ModuleBased/dialogForms/BulkCancelDaDialog.vue";

import {
  isAuthenticated,
  hasRouteAccess,
  getPermissions,
} from "@/utils/functions";

export default {
  components: {
    ListComponent,
    Columns,
    AgGridVue,
    Pagination,
    DeliveryAdvicesListButton,
    agColumnHeader: CustomHeader,
    AdminDeliveryAdviceFilters,
    RejectionRemarksDialog,
    LegMovementDateForm,
    BulkCancelDaDialog,
  },
  data() {
    return {
      userType: null,
      searchTimeout: null,
      searchDAStatisticTimeout: null,
      showBulkCancelDaForm: false,

      sorting: {},
      headers: [
        {
          headerName: "DA No",
          field: "da_no",
          sortable: true,
          minWidth: 105,
        },
        {
          headerName: "BOE No",
          field: "boe",
          sortable: true,
          minWidth: 120,
        },
        {
          headerName: "Customer Name",
          field: "customer_name",
          sortable: true,
          minWidth: 230,
          tooltipField: "customer_code",
        },
        {
          headerName: "Route",
          field: "lane_title",
          sortable: false,
          minWidth: 150,
        },
        {
          headerName: "Job Type",
          field: "job_type",
          sortable: true,
          minWidth: 85,
        },
        {
          headerName: "Genset",
          field: "has_genset",
          sortable: true,
          minWidth: 65,
        },
        {
          headerName: "Hazmat",
          field: "is_hazmat",
          sortable: true,
          minWidth: 65,
        },
        {
          headerName: "Payment Term",
          field: "payment_term",
          sortable: true,
          minWidth: 90,
        },
        {
          headerName: "Currency",
          field: "currency",
          sortable: true,
          minWidth: 70,
        },
        {
          headerName: "Movement Date",
          field: "movement_date_time",
          sortable: false,
          minWidth: 160,
          valueFormatter: (params) => {
            return this.$globals.formatDate(params.value, true);
          },
          onCellClicked: (params) => {
            if (
              params &&
              params.data &&
              params.data.status != "Invoice Generated" &&
              params.data.status != "Cancelled"
            ) {
              this.selectedDas = [];
              this.selectedDas.push(params.data);
              this.showMovementDateDialog = true;
            }
          },
        },
        {
          headerName: "Branch",
          field: "branch_name",
          sortable: true,
          minWidth: 100,
        },
        {
          headerName: "Depot",
          field: "depot_name",
          sortable: true,
          minWidth: 100,
        },
        {
          headerName: "Tariff",
          field: "customer_contract_id",
          sortable: true,
          minWidth: 100,
        },
        {
          headerName: `Rate (${this.$globals.currency})`,
          field: "rate",
          sortable: true,
          minWidth: 90,
        },
        {
          headerName: "Customer Groups",
          field: "customer_groups",
          sortable: true,
          minWidth: 150,
          tooltipField: "customer_groups",
        },
        {
          headerName: "Status",
          field: "status",
          sortable: true,
          minWidth: 130,
          tooltipField: "status",
        },
        {
          headerName: "Invoice No",
          field: "invoice_no_da",
          sortable: true,
          minWidth: 130,
        },
        {
          headerName: "Is Send To Sap",
          field: "is_send_to_sap",
          sortable: true,
          minWidth: 130,
        },
        {
          headerName: "Sub Hire",
          field: "is_sub_hire",
          sortable: true,
          minWidth: 100,
        },
        {
          headerName: "Actions",
          field: "actions",
          cellRendererFramework: "DeliveryAdvicesListButton",
          pinned: "right",
          minWidth: 175,
        },
      ],

      tab: null,
      allDeliveryAdviceList: [],
      daCancellationRemark: {},

      // pagination vars
      pageNo: 1,
      itemsPerPage: 20,
      totalItems: 0,

      permissions: null,
      userPermissions: null,

      showRemarksForm: false,
      isAccepted: false,

      // Filter Vars
      filters: {},
      showFiltersDialog: false,

      //daStatistics
      totalDaStatistics: [],

      selectedDas: [],
      showMovementDateDialog: false,
      module_name: "delivery_advice",

      // ag-grid vars
      gridApi: null,
      gridColumnApi: null,
      defaultColDef: {
        resizable: true,
      },
      gridOptions: {
        onGridSizeChanged: () => {
          this.resizeGrid();
        },
        suppressRowClickSelection: true,
        suppressDragLeaveHidesColumns: true,
        enableCellTextSelection: true,
        tooltipShowDelay: 0,
        rowHeight: 35,
      },
    };
  },
  computed: {
    context() {
      return { parentComponent: this };
    },
    offset() {
      return this.itemsPerPage * (this.pageNo - 1);
    },
    currentProfile() {
      let obj = localStorage.getItem("userProfile");
      return !obj ? obj : JSON.parse(obj);
    },

    nonEmptyFilters() {
      return Object.fromEntries(
        Object.entries(this.filters).filter(([key, value]) => {
          if (typeof value === "object" && value !== null) {
            return Object.keys(value).length > 0; // Only include non-empty objects
          }

          return value;
        })
      );
    },
  },
  watch: {
    tab() {
      this.getDeliveryAdviceList();
    },
  },
  methods: {
    checkFilters() {
      const filters = localStorage.getItem("da_filters");
      const user_data = JSON.parse(localStorage.getItem("userProfile"));

      if (filters) {
        this.filters = JSON.parse(filters);
        if (this.filters.sorting) {
          this.sorting = { ...this.filters.sorting };
          this.headers.map((obj) => {
            if (obj.field in this.sorting) {
              if (this.sorting[obj.field] == false) {
                obj.sort = "desc";
              } else if (this.sorting[obj.field]) {
                obj.sort = "asc";
              }
            }
          });
        }
      }
    },
    setFilters() {
      if (
        this.filters &&
        Object.keys(this.filters).length > 0 &&
        Object.keys(this.filters).indexOf("ordering")
      ) {
        delete this.filters.ordering;
      }
      localStorage.setItem(
        "da_filters",
        JSON.stringify({ ...this.filters, sorting: this.sorting })
      );
    },
    sentToSAP() {
      const config = {
        status: "secondary",
        title: "Sent To SAP",
        text: "Are you sure you want to sent to SAP?",
      };

      this.$confirm(config).then(() => {
        this.$bus.$emit("showLoader", true);
        let da_ids = [...this.selectedDA];
        this.$api.deliveryAdvice
          .bulkUpdateSapDetail(da_ids)
          .then((resp) => {
            this.getDeliveryAdviceList();
            this.getTotalDACount();
            this.$bus.$emit("showToastMessage", {
              message: "Send To Sap!",
              color: "success",
            });
            this.resetForm();
          })
          .catch((err) => {
            console.error(err);
            this.$bus.$emit("showLoader", false);
          });
      });
    },
    daCostChargesSendToSap(id) {
      this.selectedDA = [];
      this.selectedDA.push(id);
      this.sentToSAP();
    },
    onEnter() {
      this.searchDeliveryAdvices();
      this.getTotalDACount();
    },
    openCancelDaRemarkForm(id) {
      this.cancelDaId = id;
      this.showRemarksForm = true;
    },

    cancelDeliveryAdvice() {
      let payload = {
        id: this.cancelDaId,
        cancel_remarks: this.daCancellationRemark.admin_remarks,
      };
      this.$api.deliveryAdvice
        .cancelDeliveryAdvice(payload)
        .then((res) => {
          this.getDeliveryAdviceList();
          this.getTotalDACount();
          this.showRemarksForm = false;
        })
        .catch((err) => {
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message: "Couldn't cancel DA!",
            color: "error",
          });
        });
    },
    getTotalDACount(params) {
      if (this.searchDAStatisticTimeout) {
        clearTimeout(this.searchDAStatisticTimeout);
      }
      this.searchDAStatisticTimeout = setTimeout(() => {
        params = { ...this.filters };
        delete params.limit;
        delete params.offset;
        delete params.statuses;
        this.$api.deliveryAdvice
          .getDeliveryAdviceListStatistics(params)
          .then((res) => {
            this.totalDaStatistics = res.data;
          })
          .catch((err) => {
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message: "Couldn't  DA!",
              color: "error",
            });
          });
      }, 1000);
    },
    resizeGrid() {
      if (this.gridOptions && this.gridOptions.api) {
        setTimeout(() => {
          this.gridOptions.api.sizeColumnsToFit();
        }, 100);
      }
    },
    resetFilters() {
      this.filters = {};
      this.itemsPerPageChanged(20);
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.resizeGrid();
    },
    searchDeliveryAdvices() {
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
      }
      this.searchTimeout = setTimeout(() => {
        this.pageNo = 1;
        this.getDeliveryAdviceList();
      }, 100);
    },
    itemsPerPageChanged(e) {
      this.pageNo = 1;
      this.itemsPerPage = e;
      this.getDeliveryAdviceList();
    },
    prevPage() {
      this.pageNo--;
      this.getDeliveryAdviceList();
    },
    nextPage() {
      this.pageNo++;
      this.getDeliveryAdviceList();
    },
    applyGridSort(key, type) {
      if (this.filters && !this.filters.ordering) {
        this.filters.ordering = [];
      }

      if (type == null) {
        delete this.sorting[key];
        this.filters.ordering.splice(
          this.filters.ordering.indexOf(`-${key}`),
          1
        );
      } else if (type == "asc") {
        this.filters.ordering.push(key);
      } else if (type == "desc") {
        this.filters.ordering.splice(
          this.filters.ordering.indexOf(key),
          1,
          `-${key}`
        );
      }
      if (this.filters.ordering.length == 0) {
        delete this.filters.ordering;
      }
      this.itemsPerPageChanged(20);
    },

    getDeliveryAdviceList(params = {}) {
      this.$bus.$emit("showLoader", true);
      let laneId;
      const laneObject = Object.assign({}, this.filters.lane);
      if (this.filters && this.filters.lane) {
        laneId = this.filters.lane.id;
        delete this.filters.lane;
        this.filters.lane = laneId;
      }

      params = {
        limit: this.itemsPerPage,
        offset: this.offset,
        statuses:
          this.tab == 0
            ? ["Created", "Active", "Scheduled"].join(",")
            : this.tab == 1
            ? ["Completed", "Ready For Invoice", "Invoice Generated"].join(",")
            : ["Cancelled"].join(","),
        ...params,
        ...this.filters,
      };
      if ("ordering" in params && params.ordering.length > 0) {
        params = { ...params, ordering: params.ordering.join(",") };
      }
      this.setFilters();
      this.$api.deliveryAdvice
        .getDeliveryAdviceList(params)
        .then((res) => {
          this.allDeliveryAdviceList = res.data;
          this.totalItems = res.count;

          this.$bus.$emit("showLoader", false);
          this.filters.lane = laneObject;
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },

    createDirectDeliveryAdvice() {
      const path = {
        path: "/app/admin/delivery-advice/direct-da-add",
        query: {
          active_tab: this.tab,
        },
      };
      this.$router.push(path);
    },

    createCashDeliveryAdvice() {
      const path = {
        path: "/app/admin/delivery-advice/cash-da-add",
        query: {
          active_tab: this.tab,
        },
      };
      this.$router.push(path);
    },

    viewDeliveryAdvicesDetails(id) {
      this.$router.push({
        path: `/app/admin/delivery-advice/details/${id}`,
        query: { backRoute: `/app/admin/delivery-advice/list` },
      });
    },

    editItem(data) {
      if (data.is_direct_order_da) {
        if (data && data.payment_term == "Cash") {
          this.$router.push({
            path: `/app/admin/delivery-advice/cash-da-edit/${data.id}`,

            query: {
              backRoute: `/app/admin/delivery-advice/list`,
            },
          });
        } else {
          this.$router.push({
            path: `/app/admin/delivery-advice/direct-da-edit/${data.order}`,
            query: {
              backRoute: `/app/admin/delivery-advice/list`,
            },
          });
        }
      } else {
        this.$router.push({
          path: `/app/admin/delivery-advice/edit/${data.id}`,
          query: {
            backRoute: `/app/admin/delivery-advice/list`,
          },
        });
      }
    },
  },
  beforeMount() {
    if (!isAuthenticated()) {
      this.$router.push("/");
    } else if (!hasRouteAccess(this.$route)) {
      this.$router.push(`/${localStorage.getItem("user_type").toLowerCase()}`);
    } else {
      this.permissions = getPermissions("delivery_advice");
      this.tripPermissions = getPermissions("leg_trip");
      if (!this.permissions || !this.permissions.view) {
        this.$bus.$emit("showToastMessage", {
          message: "You don't have access to this page",
          color: "error",
        });
        this.$router.push(
          `/app/${
            localStorage.getItem("user_type")
              ? localStorage.getItem("user_type").toLowerCase()
              : "error"
          }`
        );
      }
    }
  },
  mounted() {
    this.checkFilters();
    this.getTotalDACount();
  },
};
</script>
