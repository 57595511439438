<template>
  <div>
    <v-card style="background-color: #ffece0">
      <v-card-title>
        <v-row>
          <v-col cols="6" class="d-flex align-center py-2">
            <v-icon small class="pr-3" color="black">
              mdi-map-marker-path
            </v-icon>
            <h5 class="font-weight-light">{{ tripDetails.trip_id }}</h5>
          </v-col>
        </v-row>
      </v-card-title>
    </v-card>
    <v-row no-gutters class="py-3 px-4">
      <v-col
        cols="12"
        v-if="vehicleDetails && Object.keys(vehicleDetails).length"
      >
        <v-card elevation="0" outlined>
          <v-card-title
            class="
              text-body-2
              font-weigh-bold
              py-2
              text-capitalize
              blue-grey
              lighten-5
            "
          >
            <v-row>
              <v-col class="d-flex align-center">
                <span> Vehicle Details</span>
              </v-col>
              <v-col>
                <v-btn
                  color="black"
                  outlined
                  @click="showDocumentListDialog('Vehicle')"
                  x-small
                  style="width: 100%; background-color: white"
                >
                  View Documents
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <table class="Primary-Table">
              <tbody>
                <tr v-if="vehicleDetails.vehicle_number">
                  <td>Vehicle No.</td>
                  <th class="py-1">
                    {{ vehicleDetails.vehicle_number }}
                  </th>
                </tr>
                <tr v-if="vehicleDetails.vehicle_type_title">
                  <td>Vehicle Type</td>
                  <th class="py-1">
                    {{ vehicleDetails.vehicle_type_title }}
                  </th>
                </tr>
                <tr v-if="vehicleDetails.odometer_reading">
                  <td>Odometer Reading (Kms)</td>
                  <th class="py-1">
                    {{ vehicleDetails.odometer_reading }}
                  </th>
                </tr>
                <tr v-if="vehicleDetails.equipment_id">
                  <td>Equipment ID</td>
                  <th class="py-1">
                    {{ vehicleDetails.equipment_id }}
                  </th>
                </tr>
                <tr v-if="vehicleDetails.inventory_number">
                  <td>Inventory Number</td>
                  <th class="py-1">
                    {{ vehicleDetails.inventory_number }}
                  </th>
                </tr>
                <tr v-if="vehicleDetails.reg_start_date">
                  <td>Reg Start Date</td>
                  <th class="py-1">
                    {{ vehicleDetails.reg_start_date | formatDate(false)}}
                  </th>
                </tr>
                <tr v-if="vehicleDetails.payload_capacity">
                  <td>Payload Capacity</td>
                  <th class="py-1">
                    {{ vehicleDetails.payload_capacity }}
                  </th>
                </tr>
                <tr v-if="vehicleDetails.length">
                  <td>Length</td>
                  <th class="py-1">
                    {{ vehicleDetails.length }}
                  </th>
                </tr>
                <tr v-if="vehicleDetails.width">
                  <td>Width</td>
                  <th class="py-1">
                    {{ vehicleDetails.width }}
                  </th>
                </tr>
                <tr v-if="vehicleDetails.height">
                  <td>Height</td>
                  <th class="py-1">
                    {{ vehicleDetails.height }}
                  </th>
                </tr>
              </tbody>
            </table>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        class="py-3"
        v-if="trailer1Details && Object.keys(trailer1Details).length"
      >
        <v-card elevation="0" outlined>
          <v-card-title
            class="
              text-body-2
              font-weigh-bold
              py-2
              text-capitalize
              blue-grey
              lighten-5
            "
          >
            <v-row>
              <v-col class="d-flex align-center">
                <span>Trailer 1 details</span></v-col
              >
              <v-col>
                <v-btn
                  color="black"
                  outlined
                  x-small
                  @click="showDocumentListDialog('Trailer 1')"
                  style="width: 100%; background-color: white"
                >
                  View Documents
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <table class="Primary-Table">
              <tbody>
                <tr v-if="trailer1Details.inventory_number">
                  <td>Trailer 1 No.</td>
                  <th class="py-1">
                    {{ trailer1Details.inventory_number }}
                  </th>
                </tr>
                <tr v-if="trailer1Details.trailer_type_title">
                  <td>Trailer 1 Type</td>
                  <th class="py-1">
                    {{ trailer1Details.trailer_type_title }}
                  </th>
                </tr>
                <tr v-if="trailer1Details.equipment_id">
                  <td>Equipment ID</td>
                  <th class="py-1">
                    {{ trailer1Details.equipment_id }}
                  </th>
                </tr>
                <tr v-if="trailer1Details.inventory_number">
                  <td>Inventory Number</td>
                  <th class="py-1">
                    {{ trailer1Details.inventory_number }}
                  </th>
                </tr>
                <tr v-if="trailer1Details.payload_capacity">
                  <td>Payload Capacity</td>
                  <th class="py-1">
                    {{ trailer1Details.payload_capacity }}
                  </th>
                </tr>
                <tr v-if="trailer1Details.length">
                  <td>Length</td>
                  <th class="py-1">
                    {{ trailer1Details.length }}
                  </th>
                </tr>
                <tr v-if="trailer1Details.width">
                  <td>Width</td>
                  <th class="py-1">
                    {{ trailer1Details.width }}
                  </th>
                </tr>
                <tr v-if="trailer1Details.height">
                  <td>Height</td>
                  <th class="py-1">
                    {{ trailer1Details.height }}
                  </th>
                </tr>
              </tbody>
            </table>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        class="py-3"
        v-if="trailer2Details && Object.keys(trailer2Details).length"
      >
        <v-card elevation="0" outlined>
          <v-card-title
            class="
              text-body-2
              font-weigh-bold
              py-2
              text-capitalize
              blue-grey
              lighten-5
            "
          >
            <v-row>
              <v-col class="d-flex align-center">
                <span>Trailer 2 details</span></v-col
              >
              <v-col>
                <v-btn
                  color="black"
                  outlined
                  x-small
                  @click="showDocumentListDialog('Trailer 2')"
                  style="width: 100%; background-color: white"
                >
                  View Documents
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <table class="Primary-Table">
              <tbody>
                <tr v-if="trailer2Details.inventory_number">
                  <td>Trailer 1 No.</td>
                  <th class="py-1">
                    {{ trailer2Details.inventory_number }}
                  </th>
                </tr>
                <tr v-if="trailer2Details.trailer_type_title">
                  <td>Trailer 1 Type</td>
                  <th class="py-1">
                    {{ trailer2Details.trailer_type_title }}
                  </th>
                </tr>
                <tr v-if="trailer2Details.equipment_id">
                  <td>Equipment ID</td>
                  <th class="py-1">
                    {{ trailer2Details.equipment_id }}
                  </th>
                </tr>
                <tr v-if="trailer2Details.inventory_number">
                  <td>Inventory Number</td>
                  <th class="py-1">
                    {{ trailer2Details.inventory_number }}
                  </th>
                </tr>
                <tr v-if="trailer2Details.payload_capacity">
                  <td>Payload Capacity</td>
                  <th class="py-1">
                    {{ trailer2Details.payload_capacity }}
                  </th>
                </tr>
                <tr v-if="trailer2Details.length">
                  <td>Length</td>
                  <th class="py-1">
                    {{ trailer2Details.length }}
                  </th>
                </tr>
                <tr v-if="trailer2Details.width">
                  <td>Width</td>
                  <th class="py-1">
                    {{ trailer2Details.width }}
                  </th>
                </tr>
                <tr v-if="trailer2Details.height">
                  <td>Height</td>
                  <th class="py-1">
                    {{ trailer2Details.height }}
                  </th>
                </tr>
              </tbody>
            </table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <BaseDriverDialog fullscreen v-model="documentListDialog" title="Documents">
      <template #information>
        <DriverAppViewDocumentVue
          :documentListDialog="documentListDialog"
          :documentList="documentList"
        />
      </template>
    </BaseDriverDialog>
    
  </div>
</template>
  
  <script>
import BaseDriverDialog from "@/components/General/BaseDriverDialog.vue";
import DriverAppViewDocumentVue from "@/components/ModuleBased/details/driverApp/DriverAppViewDocument.vue";

export default {
  components: {
    BaseDriverDialog,
    DriverAppViewDocumentVue,
  },
  props: {
    assetDetailDialog: {
      type: Boolean,
    },
    tripDetails: {
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
      documentList: [],
      documentListDialog: false,
      assetDetails: {},
      vehicleDetails: {},
      trailer1Details: {},
      trailer2Details: {},
    };
  },
  watch: {
    assetDetailDialog(val) {
      if (val) {
        this.getDriverAppAssetDetails();
      }
    },
  },
  methods: {
    showDocumentListDialog(assetType) {
      if (assetType == "Vehicle") {
        this.documentList = this.vehicleDetails.vehicle_documents;
      } else if (assetType == "Trailer 1") {
        this.documentList = this.trailer1Details.trailer_documents;
      } else if (assetType == "Trailer 2") {
        this.documentList = this.trailer2Details.trailer_documents;
      }
      this.documentListDialog = true;
    },
    getDriverAppAssetDetails() {
      this.$bus.$emit("showLoader", true);

      this.$api.driverApp
        .getDriverAppAssetDetails()
        .then((res) => {
          this.assetDetails = res.data;
          if (this.assetDetails && this.assetDetails.vehicle) {
            this.vehicleDetails = { ...this.assetDetails.vehicle };
          }
          if (this.assetDetails && this.assetDetails.trailer_1) {
            this.trailer1Details = { ...this.assetDetails.trailer_1 };
          }
          if (this.assetDetails && this.assetDetails.trailer_2) {
            this.trailer2Details = { ...this.assetDetails.trailer_2 };
          }
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message: "Couldn't get driver's trip asset details!",
            color: "error",
            hasActions: true,
          });
        });
    },
  },
  mounted() {
    this.getDriverAppAssetDetails();
  },
};
</script>
    
    
    <style scoped lang="scss">
.card-outlined {
  border: 1px solid #0000004f !important;
  border-radius: 6px;
}
</style>