<template>
  <v-dialog v-model="showBidDetailsDialog" max-width="500">
    <v-card>
      <v-card-title class="secondary white--text">
        <span>Submit Rate</span>

        <v-spacer></v-spacer>

        <v-btn
          icon
          @click="(showBidDetailsDialog = false), (formData[fieldKey] = null)"
        >
          <v-icon class="white--text">mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="pt-2">
        <v-form
          v-model="isValid"
          v-if="showBidDetailsDialog"
          ref="bidRequestForm"
        >
          <v-row class="my-0">
            <v-col cols="12" class="Form-Columns">
              <InputField
                hide-details="auto"
                type="number"
                step=".01"
                :label="`Rate (${$globals.currency}) *`"
                required
                :rules="[
                  (val) => !!val || 'Rate is Required',
                  (val) => val > 0 || 'Rate can not be less than or equal 0',
                ]"
                :clearable="false"
                v-model="formDataPOST.rate"
                :error-messages="formErrors.rate"
                @click="clearRateOnInput()"
                @input="delete formErrors.rate"
              />
            </v-col>
             <v-col cols="12" class="Form-Columns">
              <InputField
                hide-details="auto"
                type="number"
                label="Container Count *"
                required
                :rules="[
                  (val) => !!val || 'Container Count is Required',
                ]"
                :clearable="false"
                v-model="formDataPOST.container_count"
                :error-messages="formErrors.container_count"
                @input="delete formErrors.container_count"
              />
            </v-col>
            <v-col cols="12" class="Form-Columns-Dense">
              <DateField
                :inputProps="{
                  dense: true,
                  label: 'Rate Expiry Date *',
                  rules: [(val) => !!val || 'Rate Expiry Date is Required!'],
                  'hide-details': 'auto',
                  'error-messages': formErrors.rate_expiry_date,
                }"
                :min="today"
                required
                v-model="formDataPOST.rate_expiry_date"
                @input="delete formErrors.rate_expiry_date"
              />
            </v-col>
            <v-col cols="12" class="Form-Columns">
              <InputField
                label="Remarks"
                :required="isRequiredRemarks"
                hide-details="auto"
                v-model="formDataPOST.transporter_remarks"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-divider></v-divider>
      <v-card-actions class="justify-end">
        <v-btn
          depressed
          color="primary"
          :disabled="!isValid"
          @click="submitForm"
        >
          <span>Submit</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import InputField from "@/components/FormBaseComponents/InputField.vue";
import DateField from "@/components/FormBaseComponents/DateField.vue";
export default {
  components: { InputField, DateField },
  props: {
    value: {
      type: Boolean,
    },
    formDetails: {
      type: Object,
    },
    fieldKey: {
      type: String,
    },
    label: {
      type: String,
    },
    isRequiredRemarks: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      formErrors: {},
      isValid: true,
      formDataPOST: {},
      today: new Date().toISOString().slice(0, 10),
    };
  },
  watch: {
    value(val) {
      if (val) {
        this.formDataPOST = { ...this.formDetails };
        if(this.formDataPOST.rate_expiry_date == null) {
          const endDate = new Date(this.formDetails.end_date);
          const formattedDate = endDate.toISOString().split('T')[0];
          this.formDataPOST.rate_expiry_date = formattedDate
        }
        this.resetFormValidation();
      } else {
        this.resetFormValidation();
      }
    },
  },

  computed: {
    formData: {
      get() {
        return this.formDetails;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    showBidDetailsDialog: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
     clearRateOnInput() {
      this.formDataPOST.rate = null;
    },
    resetFormValidation() {
      if (this.$refs.bidRequestForm) {
        this.$refs.bidRequestForm.resetValidation();
      }
    },
    submitForm() {
      this.$bus.$emit("showLoader", true);
      let payload = {
        id: this.formDataPOST.id,
        rate: this.formDataPOST.rate,
        transporter_remarks: this.formDataPOST.transporter_remarks,
        container_count: this.formDataPOST.container_count,
        rate_expiry_date: this.formDataPOST.rate_expiry_date,
        status: "Participated",
      };

      this.$api.bidding
        .submitBidRequestRate(payload)
        .then((res) => {
          this.$bus.$emit("showLoader", false);
          if (res && res.data && res.data.message) {
            this.$bus.$emit("showToastMessage", {
              message: res.data.message,
              color: "success",
            });
          }
          this.showBidDetailsDialog = false;
          this.$emit("refreshBidRequestList");
        })
        .catch((err) => {
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message: `${
              err.data && err.data.non_field_errors
                ? err.data.non_field_errors.join()
                : "Couldn't update Bid"
            }`,
            color: "error",
          });
          this.formErrors = err.data;
        });
    },
  },
};
</script>