<template>
  <v-row class="ma-0">
    <v-col cols="9" class="d-flex align-center">
      <v-tooltip top>
        <template #activator="{ on, attrs }">
          <v-btn
            icon
            @click="callBackRoute"
            v-bind="attrs"
            v-on="on"
            class="mr-3"
          >
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
        </template>
        <span>Back</span>
      </v-tooltip>

      <span class="text-h5 mr-3">
        Trip Details - {{ tripDetails.trip_id }}
      </span>

      <v-chip> {{ tripDetails.status }}</v-chip>

      <v-menu open-on-hover right offset-y v-if="tripDetails">
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-on="on" v-bind="attrs" class="mx-4">
            mdi-map-marker-distance
          </v-icon>
        </template>
        <v-card>
          <v-card-title
            class="secondary white--text text-subtitle-1 font-weight-medium py-1"
          >
            Route Address
          </v-card-title>
          <v-card-text class="mt-2">
            <table class="Normal-Table">
              <tbody>
                <tr v-if="tripDetails.source_location_name">
                  <th>Source Location</th>
                  <th class="px-2">:</th>
                  <td>
                    {{ tripDetails.source_location_name }}
                  </td>
                </tr>
                <tr v-if="tripDetails.mid_location_name">
                  <th>Mid Point Location</th>
                  <th class="px-2">:</th>
                  <td>
                    {{ tripDetails.mid_location_name }}
                  </td>
                </tr>
                <tr v-if="tripDetails.destination_location_name">
                  <th>Destination Location</th>
                  <th class="px-2">:</th>
                  <td>
                    {{ tripDetails.destination_location_name }}
                  </td>
                </tr>
                <tr v-if="tripDetails.source_address">
                  <th>Source Address</th>
                  <th class="px-2">:</th>
                  <td>
                    {{ tripDetails.source_address }}
                  </td>
                </tr>
                <tr v-if="tripDetails.mid_address">
                  <th>Mid Point Address</th>
                  <th class="px-2">:</th>
                  <td>
                    {{ tripDetails.mid_address }}
                  </td>
                </tr>
                <tr v-if="tripDetails.destination_address">
                  <th>Destination Address</th>
                  <th class="px-2">:</th>
                  <td>
                    {{ tripDetails.destination_address }}
                  </td>
                </tr>
              </tbody>
            </table>
          </v-card-text>
        </v-card>
      </v-menu>

      <v-menu
        open-on-hover
        right
        offset-y
        v-if="tripDetails.trip_leg_containers"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-badge overlap :content="tripDetails.trip_container_count">
            <v-icon v-on="on" v-bind="attrs" class="mx-2">
              mdi-train-car-container
            </v-icon>
          </v-badge>
        </template>
        <v-card>
          <v-card-title
            class="secondary white--text text-subtitle-1 font-weight-medium py-1"
          >
            Container Information
          </v-card-title>
          <v-card-text class="mt-2">
            <v-row>
              <v-col
                cols="12"
                v-for="(container, i) in tripDetails.trip_leg_containers"
                :key="i"
              >
                <ContainerCard
                  :container="container"
                  :showAddressDetails="false"
                  :isTransporter="true"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-menu>
    </v-col>

    <v-col cols="12" class="heading">
      <v-row class="ma-0" justify="space-between">
        <v-col cols="12" lg="auto" class="Form-Columns-Dense">
          <div class="d-flex">
            <div v-if="tripDetails.source_location_code">
              <span class="text-caption">Source Location</span>
              <p class="font-weight-medium mb-0">
                {{ tripDetails.source_location_code }}
              </p>
            </div>
            <v-divider vertical class="mx-3"></v-divider>
            <div v-if="tripDetails.mid_location_code">
              <span class="text-caption">Mid Point Location</span>
              <p class="font-weight-medium mb-0">
                {{ tripDetails.mid_location_code }}
              </p>
            </div>
            <v-divider
              vertical
              class="mx-3"
              v-if="tripDetails.destination_location_code"
            ></v-divider>
            <div v-if="tripDetails.destination_location_code">
              <span class="text-caption">Destination Location</span>
              <p class="font-weight-medium mb-0">
                {{ tripDetails.destination_location_code }}
              </p>
            </div>
          </div>
        </v-col>

        <v-col cols="12" lg="auto" class="Form-Columns-Dense">
          <div class="d-flex">
            <div v-if="tripDetails.trip_date">
              <span class="text-caption">Trip Date</span>
              <p class="font-weight-medium mb-0">
                {{ tripDetails.trip_date | formatDate(false) }}
              </p>
            </div>
            <v-divider vertical class="mx-3"></v-divider>
            <div v-if="tripDetails.driver_name">
              <span class="text-caption">Driver Name</span>
              <p class="font-weight-medium mb-0">
                {{ tripDetails.driver_name }}
              </p>
            </div>
            <v-divider vertical class="mx-3"></v-divider>
            <div v-if="tripDetails.vehicle_number">
              <span class="text-caption">Vehicle</span>
              <p class="font-weight-medium mb-0">
                {{ tripDetails.vehicle_number }}
              </p>
            </div>
            <v-divider vertical class="mx-3"></v-divider>
            <div v-if="tripDetails.trailer_1_number">
              <span class="text-caption">Trailer 1</span>
              <p class="font-weight-medium mb-0">
                {{ tripDetails.trailer_1_number }}
              </p>
            </div>
            <v-divider
              vertical
              class="mx-3"
              v-if="tripDetails.trailer_2_number"
            ></v-divider>
            <div v-if="tripDetails.trailer_2_number">
              <span class="text-caption">Trailer 2</span>
              <p class="font-weight-medium mb-0">
                {{ tripDetails.trailer_2_number }}
              </p>
            </div>
            <v-divider vertical class="mx-3"></v-divider>
            <div v-if="tripDetails.channel">
              <span class="text-caption">Channel</span>
              <p class="font-weight-medium mb-0">
                {{ tripDetails.channel }}
              </p>
            </div>
          </div>
        </v-col>

        <v-col cols="12" lg="auto" class="Form-Columns-Dense">
          <div class="d-flex">
            <div v-if="tripDetails.trip_start">
              <span class="text-caption">Actual Trip Start</span>
              <p class="font-weight-medium mb-0">
                {{ tripDetails.trip_start | formatDate(true) }}
              </p>
            </div>
            <v-divider
              vertical
              class="mx-3"
              v-if="tripDetails.trip_end"
            ></v-divider>
            <div class="ml-4" v-if="tripDetails.trip_end">
              <span class="text-caption">Actual Trip End</span>
              <p class="font-weight-medium mb-0">
                {{ tripDetails.trip_end | formatDate(true) }}
              </p>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-col>

    <v-col
      cols="12"
      class="pt-1"
      v-if="tripDetails && Object.keys(tripDetails).length > 0"
    >
      <v-row class="ma-0">
        <v-col cols="12" lg="8" class="Form-Columns-Dense">
          <v-btn
            class="ml-3"
            color="primary"
            depressed
            @click="showTimeStampDialog = true"
          >
            Time Stamp
          </v-btn>
        </v-col>
        <v-col cols="12" class="px-0">
          <v-slider
            v-model="tripNumaricalStatus"
            :tick-labels="tripMilestones"
            thumb-label="always"
            :max="4"
            class="pt-8 Milestone"
            step="1"
            ticks="always"
            tick-size="10"
            :readonly="!tripDetails.is_internal_trip"
          >
          </v-slider>
        </v-col>
      </v-row>
    </v-col>

    <v-col cols="12" class="heading">
      <v-row v-if="Object.keys(tripDetails).length > 0">
        <v-col cols="12" lg="12" class="pr-2">
          <GoogleMap
            ref="transporterTripDetailsMap"
            mapHeight="calc(109vh - 460px)"
            mapWidth="100%"
            :hasMapInfo="true"
          >
            <template #mapInfo>
              <v-card-text>
                <v-row>
                  <v-col cols="12" class="d-flex align-center pa-2">
                    <img style="width: 18px" :src="sourceMarker" />
                    <span class="ml-4 text-body-2 font-weight-bold">
                      Source
                    </span>
                  </v-col>

                  <v-col cols="12" class="d-flex align-center pa-2">
                    <img style="width: 18px" :src="midMarker" />
                    <span class="ml-4 text-body-2 font-weight-bold"> Mid </span>
                  </v-col>

                  <v-col cols="12" class="d-flex align-center pa-2">
                    <img style="width: 18px" :src="destinationMarker" />
                    <span class="ml-4 text-body-2 font-weight-bold">
                      Destination
                    </span>
                  </v-col>
                  <v-col cols="12" class="d-flex align-center pa-2">
                    <img style="width: 18px" :src="truckMarker" />
                    <span class="ml-4 text-body-2 font-weight-bold">
                      Vehicle
                    </span>
                  </v-col>
                </v-row>
              </v-card-text>
            </template>
          </GoogleMap>
        </v-col>
      </v-row>
    </v-col>
    <v-dialog v-model="showTimeStampDialog" width="70%">
      <v-card v-if="tripDetails.trip_leg_containers">
        <v-card-title class="secondary white--text px-4 py-2">
          <span class="text-capitalize">Time Stamp</span>

          <v-spacer></v-spacer>

          <v-btn
            @click="resetForm(), (showTimeStampDialog = false)"
            color="white"
            icon
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pa-2">
          <AgGridVue
            style="width: 100%; height: calc(100vh - 590px)"
            class="ag-theme-alpine"
            id="tripLegGrid"
            :columnDefs="TimeStampHeaders"
            :defaultColDef="defaultColDef"
            :grid-options="gridOptions"
            :suppressDragLeaveHidesColumns="true"
            :rowData="tripDetails.trip_logs"
            :context="context"
          ></AgGridVue>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import InputField from "@/components/FormBaseComponents/InputField.vue";
import MultiSelect from "@/components/FormBaseComponents/MultiSelect.vue";
import ContainerCard from "@/components/General/ContainerCard.vue";
import GoogleMap from "@/components/General/GoogleMap.vue";

import {
  sourceMarker,
  destinationMarker,
  midMarker,
  truckMarker,
} from "@/assets/images/mapIcons.js";
import DateField from "@/components/FormBaseComponents/DateField.vue";
import { AgGridVue } from "ag-grid-vue";

import {
  isAuthenticated,
  hasRouteAccess,
  getPermissions,
} from "@/utils/functions";

export default {
  components: {
    InputField,
    AgGridVue,
    MultiSelect,
    GoogleMap,
    ContainerCard,
    DateField,
  },
  TripDetails: {
    default: false,
  },
  computed: {
    userType() {
      return localStorage.getItem("user_type");
    },
    context() {
      return { parentComponent: this };
    },
  },
  data() {
    return {
      sourceMarker,
      destinationMarker,
      truckMarker,
      midMarker,
      waypoints: [],
      waypointNames: [],

      selectAllCheckList: false,

      showTimeStampDialog: false,
      isValid: true,

      tripMilestones: [
        "Assigned",
        "Enroute",
        "Reached Pickup Location",
        "Reached Drop Location",
        "Completed",
      ],
      tripNumaricalStatus: 0,
      oldtripNumaricalStatus: 0,
      daDetails: {},
      // Trip Details
      tripDetails: {},
      tripLogErrors: {},

      backRoute: { path: "/transporter/trips/list" },

      // checklist form
      checkList: [],
      initialLoad: true,

      detailsTab: true,
      newStatus: null,
      milestone: null,

      directions: [],
      directionsTimeout: null,
      selectedRoute: {},
      markerTimeout: null,

      tripLogDetails: {},

      tripCheckListButton: false,
      tripdefectCheckListButton: false,

      driverLocationDetails: {},
      defaultColDef: {
        resizable: true,
      },
      gridOptions: {
        onGridSizeChanged: () => {
          this.resizeGrid();
        },
        suppressRowClickSelection: true,
        suppressDragLeaveHidesColumns: true,
        enableCellTextSelection: true,
        tooltipShowDelay: 0,
        rowHeight: 35,
      },

      TimeStampHeaders: [
        {
          headerName: "Logs",
          field: "message",
          minWidth: 120,
        },
        {
          headerName: "User",
          field: "added_by",
          minWidth: 120,
        },
        {
          headerName: "Date/Time",
          field: "log_timestamp",
          valueFormatter: (params) => {
            return this.$globals.formatDate(params.value, true);
          },
          minWidth: 120,
        },
      ],
    };
  },
  watch: {
    tripNumaricalStatus(newVal, oldVal) {
      this.oldtripNumaricalStatus = oldVal;
    },
  },
  methods: {
    resizeGrid() {
      if (this.gridOptions && "api" in this.gridOptions) {
        setTimeout(() => {
          this.gridOptions.api.sizeColumnsToFit();
        }, 100);
      }
    },

    async refreshTripDetails() {
      await this.getTripMatrix(this.$route.params.id);
      await this.getTripDetails(this.$route.params.id);
    },

    revertTripStatus() {
      this.tripNumaricalStatus = this.oldtripNumaricalStatus;
    },

    callBackRoute() {
      if (Object.keys(this.backRoute).length === 0) {
        this.$router.push("/app/customer/order/list");
      } else {
        this.$router.push(this.backRoute);
      }
    },

    async getTripDetails(id) {
      this.$bus.$emit("showLoader", true);
      await this.$api.trip
        .getTripObject(id)
        .then(async (res) => {
          this.tripDetails = res.data;
          if (
            this.tripDetails.mid_point &&
            this.tripDetails.destination_point
          ) {
            this.waypoints = [];
            this.waypoints.push({
              location: {
                lat: parseFloat(this.tripDetails.mid_point.latitude),
                lng: parseFloat(this.tripDetails.mid_point.longitude),
              },
              stopover: false,
            });
          } else {
            this.waypoints = [];
            this.tripDetails.destination_point = this.tripDetails.mid_point;
            this.tripDetails.mid_point = null;
          }
          this.tripNumaricalStatus = this.tripMilestones.indexOf(
            res.data.status
          );
          this.oldtripNumaricalStatus = this.tripMilestones.indexOf(
            res.data.status
          );

          if (
            ["Ready For Invoice", "Invoice Generated"].indexOf(
              res.data.status
            ) != -1
          ) {
            this.tripNumaricalStatus = 4;
            this.oldtripNumaricalStatus = 4;
          }

          this.getRoute();

          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    getRoute() {
      if (
        (this.tripDetails.source_point && this.tripDetails.destination_point) ||
        (this.tripDetails.source_point && this.tripDetails.mid_point) ||
        (this.tripDetails.source_point &&
          this.tripDetails.mid_point &&
          this.tripDetails.destination_point)
      ) {
        if (this.directionsTimeout) {
          clearTimeout(this.directionsTimeout);
        }

        this.directionsTimeout = setTimeout(() => {
          const directionConfig = {
            origin: {
              lat: parseFloat(this.tripDetails.source_point.latitude),
              lng: parseFloat(this.tripDetails.source_point.longitude),
            },
            destination: {
              lat: parseFloat(this.tripDetails.destination_point.latitude),
              lng: parseFloat(this.tripDetails.destination_point.longitude),
            },
            travelMode: "DRIVING",
            provideRouteAlternatives: true,
          };
          if (this.waypoints && this.waypoints.length) {
            directionConfig.waypoints = this.waypoints;
          }

          this.getDirections(directionConfig)
            .then((directions) => {
              this.directions = directions;
              this.selectedRoute = this.directions[0];

              this.loadRoute();
              this.loadMarkers();
            })
            .catch((err) => {
              console.error(err);
              this.$refs.transporterTripDetailsMap.removeGeoJson();
              this.loadMarkers();
            });
        }, 200);
      }
    },
    resetForm() {
      this.tripLogDetails = {};
    },

    getDirections(directionConfig) {
      return new Promise((resolve, reject) => {
        this.$refs.transporterTripDetailsMap
          .getDirections(directionConfig)
          .then(async (resp) => {
            const routes = resp.routes;
            let directions = [];

            let index = 0;
            while (routes.length > directions.length) {
              directions.push({
                name: `Route ${index + 1}`,
                distance: routes[index].legs[0].distance.text,
                route:
                  this.$refs.transporterTripDetailsMap.generateGeoJsonFromLineString(
                    await this.$refs.transporterTripDetailsMap.generateLineStringFromRoute(
                      routes[index]
                    )
                  ),
              });
              index++;
            }

            resolve(directions);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    loadRoute() {
      if (this.markerTimeout) {
        clearTimeout(this.markerTimeout);
      }
      this.markerTimeout = setTimeout(() => {
        this.tripDetails = {
          ...this.tripDetails,
          route: this.selectedRoute.route,
          distance: this.selectedRoute.distance,
        };

        this.$refs.transporterTripDetailsMap.addGeoCollection(
          this.tripDetails.route
        );

        if (
          this.driverLocationDetails &&
          this.driverLocationDetails.actual_route &&
          this.driverLocationDetails.actual_route.features &&
          this.driverLocationDetails.actual_route.features[0].properties
        ) {
          this.driverLocationDetails.actual_route.features[0].properties.actual_route = true;
          this.$refs.transporterTripDetailsMap.addGeoCollection(
            this.driverLocationDetails.actual_route,
            false
          );
        }
      }, 500);
    },

    loadMarkers() {
      const markers = [
        { ...this.tripDetails.source_point, type: "source" },
        { ...this.tripDetails.destination_point, type: "destination" },
      ];
      if (this.tripDetails.mid_point && this.tripDetails.destination_point) {
        markers.push({ ...this.tripDetails.mid_point, type: "mid" });
      }

      if (
        this.driverLocationDetails &&
        this.driverLocationDetails.last_driver_location &&
        this.driverLocationDetails.last_driver_location.location
      ) {
        markers.push({
          ...this.driverLocationDetails.last_driver_location.location,
          type: "truck",
          speed: this.driverLocationDetails.last_driver_location.speed,
          device_id: this.driverLocationDetails.last_driver_location.device_id,
          vehicle_id:
            this.driverLocationDetails.last_driver_location.vehicle_number,
        });
      }

      this.$refs.transporterTripDetailsMap.addMultipleMarkers(markers);
    },

    async getTripMatrix(id) {
      await this.$api.trip
        .getTripMatrix(id)
        .then((resp) => {
          this.driverLocationDetails = resp.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    checkQuery() {
      console.log(this.$route.query);
      if ("backRoute" in this.$route.query) {
        this.backRoute.path = this.$route.query.backRoute;
      }
      if ("currentCal" in this.$route.query) {
        if (!this.backRoute.query) {
          this.backRoute.query = {};
        }
        this.backRoute.query.currentCal = this.$route.query.currentCal;
      }
      if ("active_tab" in this.$route.query) {
        if (!this.backRoute.query) {
          this.backRoute.query = {};
        }
        this.backRoute.query.active_tab = this.$route.query.active_tab;
      }
    },
  },

  async beforeMount() {
    if (!isAuthenticated()) {
      this.$router.push("/");
    } else if (!hasRouteAccess(this.$route)) {
      this.$router.push(`/${localStorage.getItem("user_type").toLowerCase()}`);
    } else {
      const permissions = getPermissions("leg_trip");
      if (!permissions || !permissions.change) {
        this.$bus.$emit("showToastMessage", {
          message: "You don't have access to this page",
          color: "error",
        });
        this.$router.push(
          `/app/${
            localStorage.getItem("user_type")
              ? localStorage.getItem("user_type").toLowerCase()
              : "error"
          }`
        );
      } else {
        this.permissions = { ...permissions };
        await this.getTripMatrix(this.$route.params.id);
        await this.getTripDetails(this.$route.params.id);
        this.checkQuery();
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.logsContainer {
  height: 500px;
  scroll-behavior: smooth;
  overflow-y: auto;
}

.new-msg-btn {
  position: absolute;
  bottom: 78px;
  right: 32px;
  z-index: 1;
}
</style>
