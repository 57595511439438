<template>
  <v-dialog width="1200" height="800" v-model="showBulkOperationsDialog">
    <v-card>
      <v-card-title class="primary white--text rounded-0">
        <span> Update Asset Details </span>
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="
            resetbulkLegUpdateDialog(), (showBulkOperationsDialog = false)
          "
        >
          <v-icon class="white--text">mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="px-3 pb-3 pt-2">
        <v-form v-if="showBulkOperationsDialog" v-model="isValid">
          <v-row v-for="(da, index) in allDaList" :key="index">
            <v-col cols="12" sm="3">
              <v-text-field
                v-model="formData.daNumbers[index]"
                :label="'DA Number'"
                :value="da"
                disabled
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <div class="flex-container">
                <v-text-field
                  v-model="formData.drivers[index]"
                  :label="'Driver for DA ' + da.da_no"
                ></v-text-field>
                <v-btn icon @click="copyToClipboard(formData.drivers[index])">
                  <v-icon>mdi-content-copy</v-icon>
                </v-btn>
              </div>
            </v-col>
            <v-col cols="12" sm="3">
              <div class="flex-container">
                <v-text-field
                  v-model="formData.vehicles[index]"
                  :label="'Vehicle for DA ' + da.da_no"
                ></v-text-field>
                <v-btn icon @click="copyToClipboard(formData.vehicles[index])">
                  <v-icon>mdi-content-copy</v-icon>
                </v-btn>
              </div>
            </v-col>
            <v-col cols="12" sm="3">
              <div class="flex-container">
                <v-text-field
                  v-model="formData.trailers[index]"
                  :label="'Trailer for DA ' + da.da_no"
                ></v-text-field>
                <v-btn icon @click="copyToClipboard(formData.trailers[index])">
                  <v-icon>mdi-content-copy</v-icon>
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="justify-end">
        <v-btn
          depressed
          color="primary"
          :disabled="!isValid || !atLeastOneDetailEntered"
          @click="submitForm"
        >
          <span>Submit</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    subhireId: {
      type: Number,
    },
  },
  watch: {
    value(val) {
      if (val) {
        this.getSubHireDetail(this.subhireId)
          .then(() => {
            if (!this.allDaList || this.allDaList.length === 0) {
              console.error("No data available in allDaList.");
              return;
            }
            this.allDaList.forEach((da, i) => {
           
              this.formData.drivers.push(da.driver);
              this.formData.vehicles.push(da.vehicle);
              this.formData.trailers.push(da.trailer);
              this.formData.daNumbers.push(da.da_no);
              this.formData.legId.push(this.allLegId[i]);
            });
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
      } else {
        this.resetbulkLegUpdateDialog();
      }
    },
  },
  computed: {
    atLeastOneDetailEntered() {
      // Check if at least one detail is entered in any of the text fields
      return this.formData.drivers.some((driver) => !!driver) ||
        this.formData.vehicles.some((vehicle) => !!vehicle) ||
        this.formData.trailers.some((trailer) => !!trailer);
    },
    showBulkOperationsDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    context() {
      return { parentComponent: this };
    },
    offset() {
      return this.itemsPerPage * (this.pageNo - 1);
    },
  },
  data() {
    return {
      subHireData: {},
      allDaList: [],
      allLegId: [],
      formData: {
        drivers: [], // Initialize as an empty array
        vehicles: [], // Initialize as an empty array
        trailers: [], //
        daNumbers: [],
        legId: [],
      },
      subHireId: null,

      isValid: true,
    };
  },
  methods: {
    copyToClipboard(value) {
      const textField = document.createElement("textarea");
      textField.innerText = value;
      document.body.appendChild(textField);
      textField.select();
      document.execCommand("copy");
      textField.remove();

      this.$bus.$emit("showToastMessage", {
        message: "Copied to clipboard",
        color: "success",
      });
    },

    submitForm() {
      // Handle form submission
      const formDataArray = [];

      for (let i = 0; i < this.allDaList.length; i++) {
        const formDataObj = {
          daNumber: this.formData.daNumbers[i],
          driver: this.formData.drivers[i],
          vehicle: this.formData.vehicles[i],
          trailer: this.formData.trailers[i],
          legId: this.formData.legId[i],
          sub_hire_id :this.subHireData.sub_hire_id
        };
        formDataArray.push(formDataObj);
      }
      this.$bus.$emit("showLoader", true);
      this.$api.unauthoriseHsm
        .submitTransporterAssetDetails(formDataArray)
        .then((res) => {
          this.$bus.$emit("showLoader", false);
          this.showBulkOperationsDialog = false;
           this.$bus.$emit("showToastMessage", {
            message: "Asset Details Updated Successfully",
            color: "success",
          });
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },

    async getSubHireDetail(id) {
      this.$bus.$emit("showLoader", true);
      let params = {
        sub_hire_id: id,
      };

      await this.$api.unauthoriseHsm
        .getTransporterBRSubHireDetail(params)
        .then((res) => {
          this.subHireData = res.data;
         
           
          this.allDaList = res.data.sub_hire_leg.map((da) => {
            const { da_no, driver, vehicle, trailer } = da;
            return { da_no, driver, vehicle, trailer };
          });
 
          this.allLegId = res.data.sub_hire_leg.map((leg) => {
            return leg.id;
          });

          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },

    resetbulkLegUpdateDialog() {
      (this.formData = {
        drivers: [], // Initialize as an empty array
        vehicles: [], // Initialize as an empty array
        trailers: [], //
        daNumbers: [],
        legId: [],
      }),
        (this.showBulkOperationsDialog = false);
    },
  },
};
</script>
<style scoped>
.flex-container {
  display: flex;
  align-items: center;
}
.flex-container v-text-field {
  flex: 1; /* Allow text field to grow and take remaining space */
  margin-right: 8px; /* Add some spacing between text field and button */
}
</style>
