<template>
  <v-card
    v-if="Object.keys(container).length > 0"
    class="mb-3"
    :draggable="draggable"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <v-card-title
      :class="
        container.status == 'Open'
          ? 'heading py-1 primary--text'
          : 'heading py-1'
      "
    >
      <table class="Full-Width">
        <tbody>
          <tr>
            <th class="text-subtitle-1 font-weight-medium text-left">
              {{ container.container_no }}
            </th>
            <th class="text-subtitle-1 font-weight-medium text-right">
              {{ container.status }}
            </th>
          </tr>
        </tbody>
      </table>
    </v-card-title>

    <v-card-text class="mt-2 pb-0">
      <table class="Primary-Table pb-2">
        <tbody>
          <tr>
            <td>Movement Date & Time</td>
            <th>
              {{ container.movement_date | formatDate }}
              {{ container.movement_time }}
            </th>
          </tr>
          <tr>
            <td>Container Type</td>
            <th>{{ container.container_type_title }}</th>
          </tr>

          <tr>
            <td>Weight(Ton)</td>
            <th>{{ container.weight }}</th>
          </tr>

          <tr v-if="!isTransporter && container.lane_title">
            <td>Route</td>
            <th>{{ container.lane_name }}</th>
          </tr>
        </tbody>
      </table>
    </v-card-text>

    <v-card-actions class="pa-0" v-if="!isTransporter">
      <v-expansion-panels class="Expansion-Panels">
        <v-expansion-panel>
          <v-expansion-panel-header color="subHeading" class="px-5 py-3">
            Address Details
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-card elevation="0">
              <v-card-text class="pa-0">
                <div v-if="!isTransporter && container.source_address">
                  <span class="text-caption">Source</span>
                  <p class="text-subtitle-1 font-weight-medium mb-2">
                    {{ container.source_address }}
                  </p>
                </div>

                <div v-if="!isTransporter && container.mid_address">
                  <span class="text-caption">Mid</span>
                  <p class="text-subtitle-1 font-weight-medium mb-2">
                    {{ container.mid_address }}
                  </p>
                </div>

                <div v-if="!isTransporter && container.destination_address">
                  <span class="text-caption">Destination</span>
                  <p class="text-subtitle-1 font-weight-medium mb-2">
                    {{ container.destination_address }}
                  </p>
                </div>
              </v-card-text>
            </v-card>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card-actions>
  </v-card>
</template>

<script>
import Tooltip from "@/components/General/Tooltip.vue";

export default {
  components: {
    Tooltip,
  },
  props: {
    container: {
      type: Object,
      default: () => ({}),
    },
    showAddressDetails: {
      type: Boolean,
      default: true,
    },
    isTransporter: {
      type: Boolean,
      default: false,
    },
    draggable: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {},
};
</script>

<style scoped>
.Expansion-Panels .v-expansion-panel::before {
  box-shadow: initial;
}

.v-expansion-panel-header {
  min-height: 40px;
}

.v-expansion-panel--active > .v-expansion-panel-header {
  min-height: 40px;
  padding: 10px 20px;
}
</style>
