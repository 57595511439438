var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex align-baseline"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-1 mr-2 my-1",attrs:{"color":"primary"},on:{"click":_vm.viewItem}},'v-icon',attrs,false),on),[_vm._v(" mdi-eye ")])]}}])},[_c('span',[_vm._v("View")])]),(_vm.params.context.parentComponent.tab == 0)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(
            _vm.params &&
            _vm.params.context &&
            _vm.params.context.parentComponent &&
            _vm.params.context.parentComponent.permissions &&
            _vm.params.context.parentComponent.permissions.change
          )?_c('v-icon',_vm._g(_vm._b({staticClass:"ml-2 mr-2",attrs:{"color":"primary"},on:{"click":_vm.editItem}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")]):_vm._e()]}}],null,false,1940933468)},[_c('span',[_vm._v("Edit")])]):_vm._e(),(_vm.params.context.parentComponent.tab == 0)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(
            _vm.params &&
            _vm.params.context &&
            _vm.params.context.parentComponent &&
            _vm.params.context.parentComponent.permissions &&
            _vm.params.context.parentComponent.permissions.add
          )?_c('v-icon',_vm._g(_vm._b({staticClass:"ml-2 mr-2",attrs:{"color":"primary"},on:{"click":_vm.copyItem}},'v-icon',attrs,false),on),[_vm._v(" mdi-content-copy ")]):_vm._e()]}}],null,false,3164668414)},[_c('span',[_vm._v("Copy data to add form")])]):_vm._e(),(_vm.params.context.parentComponent.tab == 1)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(
            _vm.params &&
            _vm.params.context &&
            _vm.params.context.parentComponent &&
            _vm.params.context.parentComponent.permissions &&
            _vm.params.context.parentComponent.permissions.add &&
            !_vm.params.data.has_been_renewed
          )?_c('v-icon',_vm._g(_vm._b({staticClass:"ml-2 mr-2",attrs:{"color":"primary"},on:{"click":_vm.renew}},'v-icon',attrs,false),on),[_vm._v(" mdi-autorenew ")]):_vm._e()]}}],null,false,3784734459)},[_c('span',[_vm._v("Renew Contract")])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }