<template>
  <FilterParent
    module="Order"
    maxWidth="500"
    v-model="showAdminCustomerOrderFilters"
  >
    <template #filterContent>
      <v-row class="ma-0">
        <v-col cols="12">
          <MultiSelect
            hide-details="auto"
            label="Select Customer Company"
            :items="allCustomerList"
            itemText="company_name"
            itemValue="id"
            v-model="filterObject.customer"
          />
        </v-col>
        <v-col cols="12" class="Form-Columns">
          <MultiSelect
            label="Payment Terms"
            :items="paymentTerms"
            v-model="filterObject.payment_term"
          />
        </v-col>

        <v-col cols="6" class="pa-2 mt-0">
          <DateField
            :inputProps="{
              dense: true,
              label: 'Start Date ',
              'hide-details': 'auto',
            }"
            v-model="filterObject.start_date"
          />
        </v-col>

        <v-col cols="6" class="pa-2">
          <DateField
            :inputProps="{
              dense: true,
              label: 'End Date ',
              'hide-details': 'auto',
            }"
            v-model="filterObject.end_date"
          />
        </v-col>
      </v-row>
    </template>

    <template #filterAction>
      <v-btn
        color="secondary"
        depressed
        @click="$emit('resetFilters'), (showAdminCustomerOrderFilters = false)"
        >Reset</v-btn
      >
      <v-btn
        color="primary"
        class="ml-4"
        depressed
        @click="$emit('applyFilters'), (showAdminCustomerOrderFilters = false)"
        >Apply</v-btn
      >
    </template>
  </FilterParent>
</template>

<script>
import InputField from "@/components/FormBaseComponents/InputField.vue";
import MultiSelect from "@/components/FormBaseComponents/MultiSelect.vue";
import FilterParent from "@/components/General/FilterParent.vue";
import DateField from "@/components/FormBaseComponents/DateField.vue";

export default {
  components: {
    FilterParent,
    MultiSelect,
    InputField,
    DateField,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    bindingObject: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      allCustomerList: [],
      paymentTerms: [],
    };
  },
  watch: {
    value(val) {
      if (val) {
        this.getCustomerList();
        this.getCompanyOptions();
      }
    },
  },
  computed: {
    showAdminCustomerOrderFilters: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    filterObject: {
      get() {
        return this.bindingObject;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    currentProfile() {
      let obj = JSON.parse(localStorage.getItem("userProfile"));
      if (!obj) {
        return null;
      } else {
        return obj;
      }
    },
  },
  methods: {
    getCompanyOptions() {
      this.$bus.$emit("showLoader", true);
      this.$api.company
        .getCompanyOptions()
        .then((res) => {
          this.paymentTerms = res.data.actions.POST.payment_term.choices.map(
            (obj) => {
              return { text: obj.display_name, value: obj.value };
            }
          );
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    getCustomerList() {
      this.$bus.$emit("showLoader", true);
      this.$api.company
        .getCompanyList({ limit: "all", company_type: "Customer" })
        .then((res) => {
          this.allCustomerList = res.data;
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
  },
};
</script>

<style></style>
