<template>
  <v-row class="ma-0">
    <v-col cols="3" class="pa-0">
      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-icon
            class="mr-4 ml-1"
            color="primary"
            v-on="on"
            v-bind="attrs"
            @click="viewItem"
          >
            mdi-eye
          </v-icon>
        </template>
        <span>View</span>
      </v-tooltip>
    </v-col>
    <v-col cols="3" class="pa-0">
      <v-tooltip top>
        <template #activator="{ on, attrs }">
          <v-icon
            class="ml-2 mr-1"
            color="primary"
            v-on="on"
            v-bind="attrs"
            @click="viewLogs"
          >
            mdi-information
          </v-icon>
        </template>
        <span>Bid Logs</span>
      </v-tooltip>
    </v-col>
    <v-col cols="3" class="pa-0" v-if="this.params.data && this.params.data.status == 'Active'">
      <v-tooltip top>
        <template #activator="{ on, attrs }">
          <v-icon
            class="ml-2 mr-1"
            color="primary"
            v-on="on"
            v-bind="attrs"
            @click="closedBid"
          >
            mdi-close-circle-outline
          </v-icon>
        </template>
        <span>Closed</span>
      </v-tooltip>
    </v-col>
  </v-row>
</template>

<script>
export default {
  methods: {
    viewItem() {
      this.params.context.parentComponent.viewBid(this.params.data.id);
    },

    viewLogs() {
      this.params.context.parentComponent.showLogs(this.params.data);
    },
    closedBid() {
      this.params.context.parentComponent.bidClosed(this.params.data.id);
    },
  },
};
</script>

<style>
</style>