import { termsCondtionList } from "@/utils/constants";
import { handleError, handleResponse } from "@/utils/functions";

export default (axios) => ({
  getTermsConditionList(params = {}) {
    return new Promise((resolve, reject) => {
      axios
        .get(termsCondtionList.termsCondtionListBase, {
          params: params,
        })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getTermsConditionObject(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${termsCondtionList.termsCondtionListBase}${id}/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getTermsConditionOptions() {
    return new Promise((resolve, reject) => {
      axios
        .options(`${termsCondtionList.termsCondtionListBase}`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  uploadTermsConditionDocument(payload) {
    return new Promise((resolve, reject) => {
      axios
        .patch(
          `${termsCondtionList.termsCondtionListBase}${payload.id}/upload/`,
          payload.data
        )
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  addTermsCondition(data) {
    return new Promise((resolve, reject) => {
      axios
        .post(termsCondtionList.termsCondtionListBase, data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },

  deleteDocument(id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${termsCondtionList.termsCondtionListBase}${id}/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  updateTermsCondition(payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(
          `${termsCondtionList.termsCondtionListBase}${payload.id}/`,
          payload.data
        )
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },

  getTermsConditionDetails(params = {}) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${termsCondtionList.termsCondtionListBase}get_terms_condition_details/`,
          {
            params: params,
          }
        )
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
});
