import Dashboard from "@/pages/transporter/index.vue"

import ContractRoutes from "@/router/transporter/contract"

import biddingRoutes from "./commercialManagement/bidding"

import lrfqRoutes from "@/router/transporter/lrfq"

import subhirejobRoutes from "./subhirejob"


const transporterRoutes = [
  {
    path: "/",
    redirect: "/app/transporter/dashboard",
  },
  {
    path: "dashboard",
    name: "app-transporter-dashboard",
    component: Dashboard,
  },
  ...ContractRoutes,
  ...biddingRoutes,
  ...subhirejobRoutes,
  ...lrfqRoutes
  
]

export default transporterRoutes