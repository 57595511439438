import { customerContract, demoExcelSheetDownload } from "@/utils/constants";
import { handleError, handleResponse } from "@/utils/functions";

export default (axios) => ({
    getCustomerContractList(params = {}) {
        return new Promise((resolve, reject) => {
            axios
                .get(customerContract.customerContractBase, {
                    params: params,
                })
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    getCustomerContractSelectList(params = {}) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${customerContract.customerContractBase}select/`, {
                    params: params,
                })
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    addCustomerContract(data) {
        return new Promise((resolve, reject) => {
            axios
                .post(customerContract.customerContractBase, data)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    getJobTypes(data) {
        return new Promise((resolve, reject) => {
            axios
                .post(`${customerContract.customerContractBase}job_types/`, data)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    getCustomerContractObject(id) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${customerContract.customerContractBase}${id}/`)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    closeCustomerContract(payload) {
        return new Promise((resolve, reject) => {
            axios
                .post(
                    `${customerContract.customerContractBase}${payload.id}/close_contract/`,
                    payload
                )
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    getCustomerContractDetails(id) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${customerContract.customerContractBase}${id}/view/`)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    updateCustomerContract(payload) {
        return new Promise((resolve, reject) => {
            axios
                .put(
                    `${customerContract.customerContractBase}${payload.id}/`,
                    payload.data
                )
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    partiallyUpdateCustomerContract(payload) {
        return new Promise((resolve, reject) => {
            axios
                .patch(
                    `${customerContract.customerContractBase}${payload.id}/`,
                    payload.data
                )
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    downloadCustomerTariffExcel(params = {}) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${demoExcelSheetDownload.downloadCustomerContract}`, {
                    responseType: "blob",
                    params: params,
                })
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    getCustomerContractCharges(params = {}) {
        return new Promise((resolve, reject) => {
            axios
                .get(customerContract.customerContractChargeBase, {
                    params: params,
                })
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    getCustomerContractChargeSelect(params = {}) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${customerContract.customerContractChargeBase}select/`, {
                    params: params,
                })
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },

    getCustomerContractOptions() {
        return new Promise((resolve, reject) => {
            axios
                .options(customerContract.customerContractBase)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },

    getTariffStatistics(params = {}) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${customerContract.customerContractBase}tariff_statistics/`, {
                    params: params,
                })
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    addRatesToExistingContract(payload) {
        return new Promise((resolve, reject) => {
            axios
                .patch(
                    `${customerContract.customerContractBase}${payload.id}/add_container_type/`,
                    payload.data
                )
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    renewContract(payload) {
        return new Promise((resolve, reject) => {
            axios
                .post(
                    `${customerContract.customerContractBase}${payload.id}/renew_contract/`,
                    payload.data
                )
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    customerContractBulkExcelFileDownload(params = {}) {
        return new Promise((resolve, reject) => {
            axios
                .get(demoExcelSheetDownload.bulkExcelBase, {
                    params: params,
                    responseType: "blob",
                })
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    uploadCustomerContract(payload) {
        return new Promise((resolve, reject) => {
            axios
                .post(`${demoExcelSheetDownload.uploadCustomerContract}`, payload)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
});