<template>
  <ListComponent title="Check List" :totalCount="totalItems">
    <template #list>
      <v-row class="ma-0">
        <v-col cols="4" class="px-0 pt-0 pb-2">
          <v-text-field
            label="Search"
            outlined
            dense
            append-icon="mdi-magnify"
            clearable
            hide-details="auto"
            v-model="filters.search"
            @input="searchCheckList"
          ></v-text-field>
        </v-col>

        <v-col
          v-if="totalItems > 0"
          cols="8"
          class="px-0 pt-0 pb-2 d-flex justify-end"
        >
          <Pagination
            :pageNo="pageNo"
            :totalItems="totalItems"
            :pageSize="itemsPerPage"
            @itemsPerPageChange="itemsPerPageChanged"
            @prevPage="prevPage"
            @nextPage="nextPage"
          />
        </v-col>

        <v-col cols="12" class="pa-0">
          <AgGridVue
            style="width: 100%; height: calc(100vh - 172px)"
            class="ag-theme-alpine"
            id="myGrid"
            :columnDefs="headers"
            :context="context"
            @grid-ready="onGridReady"
            :defaultColDef="defaultColDef"
            :grid-options="gridOptions"
            :suppressDragLeaveHidesColumns="true"
            :rowData="allCheckListList"
          ></AgGridVue>
        </v-col>
      </v-row>
    </template>

    <template #dialogs>
      <CheckListForm
        v-model="showCheckListForm"
        :editMode="isEditing"
        :editCheckListID="editCheckListID"
        @refreshList="itemsPerPageChanged(20)"
      />

      <RemarksDialog
        v-model="showRemarksForm"
        fieldKey="remarks"
        :formDetails="deactivateChecklisRemark"
        :isRequiredRemarks="true"
        @submitRemarks="activatedeactivateChecklitSubmit"
      />
    </template>

    <template #actions>
      <v-btn
        v-if="permissions && permissions.add"
        color="primary"
        depressed
        @click="addCheckList"
      >
        <v-icon>mdi-plus</v-icon>
        <span>Add CheckList</span>
      </v-btn>
    </template></ListComponent
  >
</template>
  </ListComponent>
</template>

<script>
import ListComponent from "@/components/General/ListComponent.vue";
import { AgGridVue } from "ag-grid-vue";
import Pagination from "@/components/General/Pagination.vue";
import AdminCheckListButton from "@/components/AgGridComponents/AdminCheckListButton.vue";
import CustomHeader from "@/components/AgGridComponents/General/CustomHeader.vue";
import CheckListForm from "@/components/ModuleBased/dialogForms/CheckListForm.vue";
import RemarksDialog from "@/components/ModuleBased/dialogs/RemarksDialog.vue";

import {
  isAuthenticated,
  hasRouteAccess,
  getPermissions,
} from "@/utils/functions";

export default {
  components: {
    ListComponent,
    AgGridVue,
    Pagination,
    AdminCheckListButton,
    CheckListForm,
    agColumnHeader: CustomHeader,
    RemarksDialog,
  },
  data() {
    return {
      sorting: {},
      showRemarksForm: false,
      deactivateChecklistId: null,
      deactivateChecklisRemark: {},
      checklistStatus: null,
      headers: [
        {
          headerName: "Name",
          field: "name",
          sortable: true,
        },
        {
          headerName: "Mandatory",
          field: "is_mandatory",
        },
        {
          headerName: "Asset Type",
          field: "asset_type",
          sortable: true,
        },
        {
          headerName: "Active",
          field: "is_active",
          sortable: true,
        },
        {
          headerName: "Deactivate Remarks",
          field: "remarks",
          sortable: true,
        },
        {
          headerName: "Actions",
          field: "actions",
          cellRendererFramework: "AdminCheckListButton",
          pinned: "right",
          width: 120,
        },
      ],
      allCheckListList: [],

      // pagination vars
      pageNo: 1,
      itemsPerPage: 20,
      totalItems: 0,
      filters: {},

      permissions: null,

      // ag-grid vars
      gridApi: null,
      gridColumnApi: null,
      defaultColDef: {
        resizable: true,
      },
      gridOptions: {
        onGridSizeChanged: () => {
          this.gridOptions.api.sizeColumnsToFit();
        },
        suppressRowClickSelection: true,
        suppressDragLeaveHidesColumns: true,
        enableCellTextSelection: true,
        tooltipShowDelay: 0,
        rowHeight: 35,
      },

      //CheckList form
      showCheckListForm: false,
      isEditing: false,
      editCheckListID: null,
    };
  },
  computed: {
    context() {
      return { parentComponent: this };
    },
    currentProfile() {
      if (JSON.parse(localStorage.getItem("userProfile"))) {
        return JSON.parse(localStorage.getItem("userProfile"));
      } else {
        return null;
      }
    },
    offset() {
      return this.itemsPerPage * (this.pageNo - 1);
    },
  },
  methods: {
    activatedeactivateChecklitSubmit() {
      this.$bus.$emit("showLoader", true);
      let payload = {
        id: this.deactivateChecklistId,
        checklist_remarks: this.deactivateChecklisRemark.remarks,
        check_list_status: this.checklistStatus,
      };
      this.$api.checkList
        .activatedeactivateChecklist(payload)
        .then((res) => {
          this.getCheckListList();
          this.$bus.$emit("showToastMessage", {
            message: "Checklist Updated successfully!",
            color: "success",
          });
          this.showRemarksForm = false;
          this.$bus.$emit("showLoader", false);
          delete this.deactivateChecklisRemark.remarks;
        })
        .catch((err) => {
          this.formErrors = err.data;
          console.error(err);
          this.$bus.$emit("showToastMessage", {
            message: `${
              err.data && err.data.non_field_errors
                ? err.data.non_field_errors.join()
                : "Couldn't update checklist status!"
            }`,
            color: "error",
          });
          this.$bus.$emit("showLoader", false);
        });
    },
    activateDeactivateCheckListData(id, status) {
      this.checklistStatus = status;
      this.deactivateChecklistId = id;
      if (status == "deactivate") {
        delete this.deactivateChecklisRemark.remarks;
        this.showRemarksForm = true;
      } else {
        this.activatedeactivateChecklitSubmit();
      }
    },
    applyGridSort(key, type) {
      if (this.filters && !this.filters.ordering) {
        this.filters.ordering = [];
      }

      if (type == null) {
        delete this.sorting[key];
        this.filters.ordering.splice(
          this.filters.ordering.indexOf(`-${key}`),
          1
        );
      } else if (type == "asc") {
        this.filters.ordering.push(key);
      } else if (type == "desc") {
        this.filters.ordering.splice(
          this.filters.ordering.indexOf(key),
          1,
          `-${key}`
        );
      }
      if (this.filters.ordering.length == 0) {
        delete this.filters.ordering;
      }
      this.itemsPerPageChanged(20);
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
    },
    searchCheckList() {
      this.pageNo = 1;
      this.getCheckListList();
    },
    itemsPerPageChanged(e) {
      this.pageNo = 1;
      this.itemsPerPage = e;
      this.getCheckListList();
    },
    prevPage() {
      this.pageNo--;
      this.getCheckListList();
    },
    nextPage() {
      this.pageNo++;
      this.getCheckListList();
    },
    getCheckListList(params = {}) {
      this.$bus.$emit("showLoader", true);
      params = {
        offset: this.offset,
        limit: this.itemsPerPage,
        ...params,
        ...this.filters,
      };
      if ("ordering" in params && params.ordering.length > 0) {
        params = { ...params, ordering: params.ordering.join(",") };
      }
      this.$api.checkList
        .getCheckListList(params)
        .then((res) => {
          this.allCheckListList = res.data;
          this.totalItems = res.count;
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    addCheckList() {
      this.editCheckListID = null;
      this.isEditing = false;
      this.showCheckListForm = true;
    },
    editCheckList(id) {
      this.editCheckListID = id;
      this.isEditing = true;
      this.showCheckListForm = true;
    },
  },
  beforeMount() {
    if (!isAuthenticated()) {
      this.$router.push("/");
    } else if (!hasRouteAccess(this.$route)) {
      this.$router.push(`/${localStorage.getItem("user_type").toLowerCase()}`);
    } else {
      const permissions = getPermissions("configuration");
      if (!permissions || !permissions.view) {
        this.$bus.$emit("showToastMessage", {
          message: "You don't have access to this page",
          color: "error",
        });
        this.$router.push(
          `/app/${
            localStorage.getItem("user_type")
              ? localStorage.getItem("user_type").toLowerCase()
              : "error"
          }`
        );
      } else {
        this.permissions = { ...permissions };
        this.getCheckListList();
      }
    }
  },
};
</script>

<style>
</style>