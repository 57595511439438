<template>
  <FilterParent
    module="Customer Tariff"
    maxWidth="500"
    v-model="showAdminCustomerContractFilters"
  >
    <template #filterContent>
      <v-row class="ma-0">
        <v-col cols="12" class="Form-Columns">
          <MultiSelect
            :items="allCustomerList"
            label="Select Customer"
            itemText="company_name"
            itemValue="id"
            v-model="filterObject.customer"
          />
        </v-col>
        <v-col cols="12" class="Form-Columns mt-0">
          <Autocomplete
            :loading="listLoading"
            :totalItems="laneCount"
            :items="allLaneList"
            @updateList="getLaneList"
            label="Route *"
            itemText="title"
            itemValue="id"
            v-model="filterObject.lane"
          />
        </v-col>

        <v-col cols="12" class="Form-Columns">
          <MultiSelect
            label="Select Job Type"
            :items="allJobType"
            v-model="filterObject.job_type"
          />
        </v-col>
        <v-col cols="6" class="pa-2 mt-0">
          <DateField
            :inputProps="{
              dense: true,
              label: 'Start Date ',
              'hide-details': 'auto',
            }"
            v-model="filterObject.start_date"
          />
        </v-col>

        <v-col cols="6" class="pa-2">
          <DateField
            :inputProps="{
              dense: true,
              label: 'End Date ',
              'hide-details': 'auto',
            }"
            v-model="filterObject.end_date"
          />
        </v-col>
      </v-row>
    </template>

    <template #filterAction>
      <v-btn
        color="secondary"
        depressed
        @click="
          $emit('resetFilters');
          showAdminCustomerContractFilters = false;
        "
      >
        Reset
      </v-btn>

      <v-btn
        color="primary"
        class="ml-4"
        depressed
        @click="
          $emit('applyFilters');
          showAdminCustomerContractFilters = false;
        "
      >
        Apply
      </v-btn>
    </template>
  </FilterParent>
</template>

<script>
import InputField from "@/components/FormBaseComponents/InputField.vue";
import Autocomplete from "@/components/FormBaseComponents/Autocomplete.vue";
import MultiSelect from "@/components/FormBaseComponents/MultiSelect.vue";
import FilterParent from "@/components/General/FilterParent.vue";
import DateField from "@/components/FormBaseComponents/DateField.vue";

export default {
  components: {
    FilterParent,
    MultiSelect,
    InputField,
    Autocomplete,
    DateField,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    bindingObject: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      allJobType: [],
      allCustomerList: [],
      allLaneList: [],
      listLoading: false,
      laneCount: 0,
    };
  },
  watch: {
    value(val) {
      if (val) {
        this.getCustomerContractOptions();
        this.getCustomerList();
        this.getLaneList();
      }
    },
  },
  computed: {
    showAdminCustomerContractFilters: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    filterObject: {
      get() {
        return this.bindingObject;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    getCustomerContractOptions() {
      this.$bus.$emit("showLoader", true);
      this.$api.customerContract
        .getCustomerContractOptions()
        .then((res) => {
          this.allJobType = res.data.actions.POST.job_type.choices.map(
            (obj) => obj.value
          );
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    getCustomerList(params = {}) {
      this.$bus.$emit("showLoader", true);
      params = {
        company_type: "Customer",
        limit: "all",
        ...params,
        limited: true,
        payment_term: "Credit",
      };
      this.$api.company
        .getCompanyList(params)
        .then((res) => {
          this.$bus.$emit("showLoader", false);
          this.allCustomerList = res.data;
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    getLaneList(params = {}) {
      this.listLoading = true;
      params = {
        is_active: true,
        limited: true,
        offset: 0,
        limit: 10,
        ...params,
      };

      this.$api.lane
        .getLaneList(params)
        .then((res) => {
          this.listLoading = false;
          if ("count" in res) {
            this.laneCount = res.count;
          }

          if (params.offset == 0) {
            this.allLaneList = res.data;
          } else {
            this.allLaneList.push(...res.data);
          }
        })
        .catch((err) => {
          this.listLoading = false;
        });
    },
  },
};
</script>

<style></style>
