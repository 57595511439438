

<template>
  <v-dialog v-model="viewBidRequestInformation" max-width="500">
    <v-card>
      <v-card-title class="secondary white--text">
        {{ bidRequestId }}

        <v-spacer></v-spacer>

        <v-btn icon @click="viewBidRequestInformation = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <!-- Static content goes here -->

        <div>
          <strong>Sub Hire IDs:</strong>
          <ul>
            <li v-for="sub_hire_id in allSubHires" :key="sub_hire_id">
              {{ sub_hire_id }}
            </li>
          </ul>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<style scoped>
.info-row {
  display: flex;
  justify-content: space-between;
}

.info-item {
  flex: 1; /* Equal width for both items */
  margin: 5px 0;
}
</style>

<script>
export default {
  props: {
    value: {
      type: Boolean,
    },
    bidRequestId: {
      type: String,
    },
    bidRequestSubHires: {
      type: Array,
      default: [],
    },
    fieldKey: {
      type: String,
    },
    label: {
      type: String,
    },
    isRequiredRemarks: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    value(val) {
      if (val) {
        this.allSubHires = this.bidRequestSubHires;
        
        this.getAllSubHireIds();
      }
    },
    tab() {
      this.subHireJobLogDetailsDrawer = false;
    },
  },
  data() {
    return {
      dialog: false,
      allSubHires: [],
    };
  },
  computed: {
    viewBidRequestInformation: {
      get() {
        
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    getAllSubHireIds() {},
  },
};
</script>
