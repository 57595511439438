<template>
  <FormComponent
    title="Sent Quotation"
    backRoute="/app/admin/quotations/list"
    :nonFieldErrors="formErrors.non_field_errors"
  >
    <template #formActions>
      <v-btn color="primary" depressed :disabled="!isValid" @click="submitForm">
        Submit
      </v-btn>

      <v-btn color="secondary" class="ml-3" depressed @click="resetForm">
        Reset
      </v-btn>
    </template>

    <template #formContent>
      <v-row class="ma-0">
        <v-col cols="12" lg="12" class="Height-Class pa-0">
          <v-form v-model="isValid" ref="quotationForm">
            <v-row class="ma-0">
              <v-col cols="12" sm="6" md="4" lg="6" class="pa-2">
                <!-- <MultiSelect
                  hide-details="auto"
                  :items="allCustomerList"
                  itemText="company_name"
                  itemValue="id"
                  :rules="[(val) => !!val || 'Customer is Required!']"
                  label="Select Customer *"
                  required
                  v-model="quotationDetails.customer"
                  :error-messages="formErrors.customer"
                  @change="delete formErrors.customer"
                ></MultiSelect> -->
                <Autocomplete
                  :loading="listLoading"
                  :totalItems="customerCount"
                  :items="allCustomerList"
                  @updateList="getCustomerList"
                  label="Select Customer *"
                  itemText="company_name"
                  itemValue="id"
                  :rules="[(v) => !!v || 'Customer is Required']"
                  hide-details="auto"
                  v-model="quotationDetails.customer"
                  :error-messages="formErrors.customer"
                  @input="delete formErrors.customer"
                />
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="6" class="pa-2">
                <MultiSelect
                  hide-details="auto"
                  label="Job Type *"
                  :items="allJobType"
                  itemText="display_name"
                  itemValue="value"
                  v-model="quotationDetails.job_type"
                  :rules="[(v) => !!v || 'Job Type is Required']"
                  :error-messages="formErrors['job_type']"
                  @change="
                    delete formErrors.job_type;
                    setJobType();
                  "
                />
              </v-col>

              <v-col cols="12" sm="6" md="4" lg="6" class="pa-2">
                <DateField
                  :inputProps="{
                    dense: true,
                    label: 'Start Date *',
                    rules: [(v) => !!v || 'Start Date is Required!'],
                    'hide-details': 'auto',
                    'error-messages': formErrors.start_date,
                  }"
                  :min="today"
                  required
                  v-model="quotationDetails.start_date"
                  @input="delete formErrors.start_date"
                />
              </v-col>

              <v-col cols="12" sm="6" md="4" lg="6" class="pa-2">
                <DateField
                  :inputProps="{
                    dense: true,
                    label: 'End Date *',
                    'hide-details': 'auto',
                    'error-messages': formErrors.end_date,
                    rules: [(v) => !!v || 'End Date is Required!'],
                  }"
                  :min="quotationDetails.start_date"
                  required
                  v-model="quotationDetails.end_date"
                  @input="delete formErrors.end_date"
                />
              </v-col>

              <v-col cols="12" sm="6" md="4" lg="6" class="pa-2">
                <v-text-field
                  label="Source Location Name *"
                  id="sourceLocation"
                  ref="sourceLocation"
                  hide-details="auto"
                  dense
                  outlined
                  :rules="[(v) => !!v || 'Source Location Name is Required!']"
                  required
                  v-model="quotationDetails.source_name"
                  :error-messages="formErrors.source_name"
                  @input="delete formErrors.source_name"
                  @click="delete formErrors.source_name"
                />
              </v-col>

              <v-col cols="12" sm="6" md="4" lg="6" class="pa-2">
                <v-text-field
                  label="Destination Location Name *"
                  id="destinationLocation"
                  ref="destinationLocation"
                  hide-details="auto"
                  dense
                  outlined
                  :rules="[
                    (v) => !!v || 'Destination Location Name is Required!',
                  ]"
                  required
                  v-model="quotationDetails.destination_name"
                  :error-messages="formErrors.destination_name"
                  @input="delete formErrors.destination_name"
                  @click="delete formErrors.destination_name"
                />
              </v-col>

              <v-col cols="12" sm="6" md="4" lg="6" class="pa-2">
                <v-text-field
                  label="Source State"
                  hide-details="auto"
                  dense
                  outlined
                  readonly
                  :clearable="false"
                  v-model="quotationDetails.source_state"
                />
              </v-col>

              <v-col cols="12" sm="6" md="4" lg="6" class="pa-2">
                <v-text-field
                  label="Destination State"
                  hide-details="auto"
                  dense
                  outlined
                  readonly
                  :clearable="false"
                  v-model="quotationDetails.destination_state"
                />
              </v-col>

              <v-col cols="12" sm="6" md="4" lg="6" class="pa-2">
                <v-text-field
                  label="Source Country Code"
                  hide-details="auto"
                  dense
                  outlined
                  readonly
                  :clearable="false"
                  v-model="quotationDetails.source_country_code"
                />
              </v-col>

              <v-col cols="12" sm="6" md="4" lg="6" class="pa-2">
                <v-text-field
                  label="Destination Country Code"
                  hide-details="auto"
                  dense
                  outlined
                  readonly
                  :clearable="false"
                  v-model="quotationDetails.destination_country_code"
                />
              </v-col>

              <v-col cols="12" sm="6" md="4" lg="6" class="pa-2">
                <v-text-field
                  label="Source Country"
                  hide-details="auto"
                  dense
                  outlined
                  readonly
                  :clearable="false"
                  v-model="quotationDetails.source_country"
                />
              </v-col>

              <v-col cols="12" sm="6" md="4" lg="6" class="pa-2">
                <v-text-field
                  label="Destination Country"
                  hide-details="auto"
                  dense
                  outlined
                  readonly
                  :clearable="false"
                  v-model="quotationDetails.destination_country"
                />
              </v-col>

              <v-col cols="12" sm="6" md="6" class="pa-2">
                <div class="d-flex justify-left">
                  <v-switch
                    :label="`Is Source Port : ${
                      quotationDetails.is_source_port == 'Yes' ? 'Yes' : 'No'
                    }`"
                    true-value="Yes"
                    false-value="No"
                    hide-details="auto"
                    class="mt-1"
                    tabindex="2"
                    v-model="quotationDetails.is_source_port"
                    :disabled="portDisabled"
                  ></v-switch>
                </div>
              </v-col>

              <v-col cols="12" sm="6" md="6" class="pa-2">
                <div class="d-flex justify-left">
                  <v-switch
                    :label="`Is Destination Port : ${
                      quotationDetails.is_destination_port == 'Yes'
                        ? 'Yes'
                        : 'No'
                    }`"
                    true-value="Yes"
                    false-value="No"
                    hide-details="auto"
                    class="mt-1"
                    tabindex="2"
                    v-model="quotationDetails.is_destination_port"
                    :disabled="portDisabled"
                  ></v-switch>
                </div>
              </v-col>
              <v-col cols="12" sm="6" md="6" class="pa-2">
                <v-text-field
                  label="Distance *"
                  hide-details="auto"
                  dense
                  outlined
                  :clearable="false"
                  :rules="[(v) => !!v || 'Distance is Required']"
                  v-model="quotationDetails.distance"
                />
              </v-col>
              <v-col cols="12" class="px-2">
                <v-row
                  v-for="(rate, i) in quotationDetails.quotation_rates"
                  :key="i"
                  class="ma-0 heading rounded-lg mb-3"
                >
                  <v-col cols="12">
                    <div class="d-flex">
                      <span class="text-h6">
                        Container Details {{ i + 1 }}
                      </span>

                      <v-spacer></v-spacer>

                      <v-btn
                        color="primary"
                        depressed
                        small
                        class="mr-2"
                        @click="addContainer"
                      >
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>

                      <v-btn
                        color="secondary"
                        depressed
                        small
                        :disabled="quotationDetails.quotation_rates.length <= 1"
                        @click="removeContainer(i)"
                      >
                        <v-icon>mdi-minus</v-icon>
                      </v-btn>
                    </div>
                  </v-col>

                  <v-col cols="12" class="py-0">
                    <v-divider></v-divider>
                  </v-col>

                  <v-col cols="12" sm="4">
                    <MultiSelect
                      hide-details="auto"
                      label="Container Type *"
                      :items="allContainersType"
                      itemText="title"
                      itemValue="id"
                      :rules="[(v) => !!v || 'Container Type is Required']"
                      v-model="rate.container_type"
                      :error-messages="
                        formErrors &&
                        formErrors.quotation_rates &&
                        formErrors.quotation_rates[i] &&
                        formErrors.quotation_rates[i].container_type
                          ? formErrors.quotation_rates[i].container_type
                          : null
                      "
                      @input="
                        formErrors &&
                        formErrors.quotation_rates &&
                        formErrors.quotation_rates[i] &&
                        formErrors.quotation_rates[i].container_type
                          ? (delete formErrors.quotation_rates[i]
                              .container_type,
                            checkErrors())
                          : null
                      "
                    />
                  </v-col>

                  <v-col cols="12" sm="4">
                    <InputField
                      hide-details="auto"
                      type="number"
                      label="Container Count *"
                      v-model="rate.container_count"
                      min="1"
                      max="4"
                      :rules="[
                        (v) => !!v || 'Container Count is Required!',
                        (v) =>
                          v > 0 || 'Container count can not be less than 1',
                        (v) =>
                          v < 5 || 'Container count can not be more than 4',
                      ]"
                      :error-messages="
                        formErrors &&
                        formErrors.quotation_rates &&
                        formErrors.quotation_rates[i] &&
                        formErrors.quotation_rates[i].container_count
                          ? formErrors.quotation_rates[i].container_count
                          : null
                      "
                      @input="
                        formErrors &&
                        formErrors.quotation_rates &&
                        formErrors.quotation_rates[i] &&
                        formErrors.quotation_rates[i].container_count
                          ? (delete formErrors.quotation_rates[i]
                              .container_count,
                            checkErrors())
                          : null
                      "
                    />
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <InputField
                      hide-details="auto"
                      type="number"
                      :label="`Rate (${$globals.currency}) *`"
                      :rules="[(v) => !!v || 'Rate is Required!']"
                      required
                      v-model="rate.rate"
                      :error-messages="
                        formErrors &&
                        formErrors.quotation_rates &&
                        formErrors.quotation_rates[i] &&
                        formErrors.quotation_rates[i].rate
                          ? formErrors.quotation_rates[i].rate
                          : null
                      "
                      @input="
                        formErrors &&
                        formErrors.quotation_rates &&
                        formErrors.quotation_rates[i] &&
                        formErrors.quotation_rates[i].rate
                          ? (delete formErrors.quotation_rates[i].rate,
                            checkErrors())
                          : null
                      "
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
    </template>
  </FormComponent>
</template>

<script>
import InputField from "@/components/FormBaseComponents/InputField.vue";
import MultiSelect from "@/components/FormBaseComponents/MultiSelect.vue";
import FormComponent from "@/components/General/FormComponent.vue";
import GoogleMap from "@/components/General/GoogleMap.vue";
import DateField from "@/components/FormBaseComponents/DateField.vue";
import Autocomplete from "@/components/FormBaseComponents/Autocomplete.vue";
import { sourceMarker, destinationMarker } from "@/assets/images/mapIcons.js";
import {
  isAuthenticated,
  hasRouteAccess,
  getPermissions,
} from "@/utils/functions";

export default {
  components: {
    InputField,
    MultiSelect,
    FormComponent,
    GoogleMap,
    DateField,
    Autocomplete,
  },

  data() {
    return {
      sourceMarker,
      destinationMarker,
      isValid: true,
      quotationDetails: {
        round_trip: "No",
        quotation_rates: [{}],
        is_destination_port: "No",
        is_source_port: "No",
      },
      formErrors: {},

      // autocomplete vars
      sourceLocationAutocomplete: null,
      markerInstance: null,

      locationTypeList: [],
      allContainersType: [],
      allJobType: [],
      allCustomerList: [],
      customerCount: 0,
      listLoading: false,

      today: new Date().toISOString().slice(0, 10),

      directions: [],
      directionsTimeout: null,
      selectedRoute: {},
      markerTimeout: null,
      portDisabled: false,
    };
  },

  computed: {
    currentProfile() {
      let obj = JSON.parse(localStorage.getItem("userProfile"));
      if (obj) {
        return obj;
      } else {
        return null;
      }
    },
  },

  methods: {
    resetForm() {
      this.$refs.quotationForm.reset();
    },

    addContainer() {
      this.quotationDetails.quotation_rates.push({});
    },

    removeContainer(index) {
      this.quotationDetails.quotation_rates.splice(index, 1);
    },

    getContainersType() {
      this.$bus.$emit("showLoader", true);
      this.$api.containerType
        .getContainerTypeList({ limit: "all" })
        .then((res) => {
          this.allContainersType = res.data;
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },

    checkErrors() {
      if (
        this.formErrors &&
        this.formErrors.quotation_rates &&
        (this.formErrors.quotation_rates.length == 0 ||
          this.formErrors.quotation_rates.every(
            (obj) => Object.keys(obj).length == 0
          ))
      ) {
        delete this.formErrors.quotation_rates;
      }
    },

    submitForm() {
      this.$bus.$emit("showLoader", true);
      this.quotationDetails.status = "Sent";

      let data = {
        ...this.quotationDetails,
        is_quotation_created_by_admin: true,
      };

      if (data.distance) {
        data.distance = parseFloat(data.distance.replace(/\,/g, ""));
      }

      if (data.customer && typeof data.customer == "object") {
        data.customer = data.customer.id;
      }

      this.$api.quotation
        .addQuotation(data)
        .then((res) => {
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message: "Quotation requested!",
            color: "success",
          });
          this.$router.push("/app/admin/quotations/list");
        })
        .catch((err) => {
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message: "Couldn't request quotation!",
            color: "error",
          });
          this.formErrors = err.data;
        });
    },

    getAutoComplete() {
      const interval = setInterval(() => {
        if (
          this.$refs.destinationLocation &&
          this.$refs.sourceLocation &&
          window.google &&
          window.google.maps
        ) {
          clearInterval(interval);

          let sourceLocationInput = document.getElementById("sourceLocation");
          let destinationLocationInput = document.getElementById(
            "destinationLocation"
          );

          this.sourceLocationAutocomplete =
            new window.google.maps.places.Autocomplete(sourceLocationInput);
          this.destinationLocationAutocomplete =
            new window.google.maps.places.Autocomplete(
              destinationLocationInput
            );

          sourceLocationInput.setAttribute("placeholder", "");
          destinationLocationInput.setAttribute("placeholder", "");

          this.sourceLocationAutocomplete.addListener("place_changed", () => {
            let place = this.sourceLocationAutocomplete.getPlace();
            let obj = { ...this.quotationDetails };
            obj.source_coordinates = {
              latitude: place.geometry.location.lat(),
              longitude: place.geometry.location.lng(),
            };

            obj.source_state =
              place.address_components[
                place.address_components.length - 2
              ].long_name;
            obj.source_name = place.name;
            obj.source_formatted_address = place.formatted_address;

            for (var i = 0; i < place.address_components.length; i++) {
              var addressType = place.address_components[i].types[0];

              if (addressType == "country") {
                obj.source_country_code =
                  place.address_components[i].short_name;

                obj.source_country = place.address_components[i].long_name;
              }
            }
            this.quotationDetails = obj;
          });
          this.destinationLocationAutocomplete.addListener(
            "place_changed",
            () => {
              let place = this.destinationLocationAutocomplete.getPlace();
              let obj = { ...this.quotationDetails };
              obj.destination_coordinates = {
                latitude: place.geometry.location.lat(),
                longitude: place.geometry.location.lng(),
              };

              obj.destination_state =
                place.address_components[
                  place.address_components.length - 2
                ].long_name;
              obj.destination_name = place.name;
              obj.destination_formatted_address = place.formatted_address;

              for (var i = 0; i < place.address_components.length; i++) {
                var addressType = place.address_components[i].types[0];

                if (addressType == "country") {
                  obj.destination_country_code =
                    place.address_components[i].short_name;

                  obj.destination_country =
                    place.address_components[i].long_name;
                }
              }
              this.quotationDetails = obj;
            }
          );
        }
      }, 100);
    },
    loadRoute() {
      if (this.markerTimeout) {
        clearTimeout(this.markerTimeout);
      }
      this.markerTimeout = setTimeout(() => {
        this.quotationDetails = {
          ...this.quotationDetails,
          route: this.selectedRoute.route,
          distance: this.selectedRoute.distance,
        };

        this.$refs.quotationMap.addGeoCollection(this.quotationDetails.route);
      }, 500);
    },

    loadMarkers() {
      this.$refs.quotationMap.addMultipleMarkers([
        { ...this.quotationDetails.source_coordinates, type: "source" },
        {
          ...this.quotationDetails.destination_coordinates,
          type: "destination",
        },
      ]);
    },
    getJobTypeOptions() {
      this.$bus.$emit("showLoader", true);
      this.$api.bmr
        .getBMROptions()
        .then((res) => {
          let jobType = res.data.actions.POST.job_type.choices;
          this.allJobType = jobType.filter(
            (job) => job.display_name !== "Empty" && job.display_name !== "LCL"
          );
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    setJobType() {
      if (
        this.quotationDetails.job_type == "Import" ||
        this.quotationDetails.job_type == "Export"
      ) {
        this.quotationDetails.is_source_port = "Yes";
        this.portDisabled = true;
      } else {
        this.quotationDetails.is_source_port = "No";
        this.portDisabled = false;
      }
    },
    getCustomerList(params = {}) {
      this.listLoading = true;
      params = {
        company_type: "Customer",
        offset: 0,
        limit: 10,
        ...params,
      };
      this.$api.company
        .getCompanySelectList(params)
        .then((res) => {
          this.listLoading = false;
          if ("count" in res) {
            this.customerCount = res.count;
          }

          if (params.offset == 0) {
            this.allCustomerList = res.data;
          } else {
            this.allCustomerList.push(...res.data);
          }
        })
        .catch(() => {
          this.listLoading = false;
        });
    },
  },

  beforeMount() {
    if (!isAuthenticated()) {
      this.$router.push("/");
    } else if (!hasRouteAccess(this.$route)) {
      this.$router.push(`/${localStorage.getItem("user_type").toLowerCase()}`);
    } else {
      const permissions = getPermissions("quotation");
      if (!permissions || !permissions.add) {
        this.$bus.$emit("showToastMessage", {
          message: "You don't have access to this page",
          color: "error",
        });
        this.$router.push(
          `/app/${
            localStorage.getItem("user_type")
              ? localStorage.getItem("user_type").toLowerCase()
              : "error"
          }`
        );
      }
    }
  },
  mounted() {
    this.getAutoComplete();
    this.getContainersType();
    this.getJobTypeOptions();
    this.getCustomerList();
  },
};
</script>

<style scoped>
.Map-Height {
  height: calc(100vh - 221px);
}

.Height-Class {
  height: calc(100vh - 16px);
  overflow-y: auto;
}
</style>
