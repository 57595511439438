<template>
  <FilterParent v-model="showAdminUserFilters" module="Transporter">
    <template #filterContent>
      <v-row class="ma-0">
        <v-col cols="12" lg="12" class="pa-2">
          <MultiSelect
            hide-details="auto"
            :items="paymentTerms"
            label="Select Payment Term"
            class="ml-2 mr-2"
            v-model="filterObject.payment_term"
          />
          <v-col cols="12" lg="12" class="pa-2">
            <MultiSelect
              hide-details="auto"
              :items="booleanValues"
              label="Internal Company"
              clearable
              v-model="filterObject.is_internal_company"
            />
          </v-col>
          <v-col cols="12" lg="12" class="pa-2">
            <MultiSelect
              hide-details="auto"
              :items="booleanValues"
              label="Is Special Customer"
              clearable
              v-model="filterObject.is_special"
            />
          </v-col>
          <v-col cols="12" lg="12" class="pa-2">
            <MultiSelect
              hide-details="auto"
              :items="booleanValues"
              label="Is Local"
              clearable
              v-model="filterObject.is_local"
            />
          </v-col>
          <v-col cols="12" lg="12" class="pa-2">
            <MultiSelect
              hide-details="auto"
              :items="booleanValues"
              label="Is Cross Border"
              clearable
              v-model="filterObject.is_cross_border"
            />
          </v-col>
          <v-col cols="12" lg="12" class="pa-2">
            <MultiSelect
              hide-details="auto"
              :items="booleanValues"
              label="VAT Applicability"
              clearable
              v-model="filterObject.vat_applicability"
            />
          </v-col>
          <v-col cols="12" lg="12" class="pa-2">
            <MultiSelect
              hide-details="auto"
              :items="allContainerCategoryList"
              label="Select Container Category"
              itemText="name"
              itemValue="id"
              v-model="filterObject.container_category"
            />
          </v-col>
        </v-col>
      </v-row>
    </template>
    <template #filterAction>
      <v-btn
        depressed
        @click="$emit('resetFilters'), (showAdminUserFilters = false)"
        >Reset</v-btn
      >
      <v-btn
        color="primary"
        class="ml-4"
        depressed
        @click="$emit('applyFilters'), (showAdminUserFilters = false)"
        >Apply</v-btn
      >
    </template>
  </FilterParent>
</template>

<script>
import MultiSelect from "@/components/FormBaseComponents/MultiSelect.vue";
import FilterParent from "@/components/General/FilterParent.vue";

export default {
  components: {
    FilterParent,
    MultiSelect,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    bindingObject: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      paymentTerms: [],
      allContainerCategoryList: [],
      booleanValues: [
        {
          text: "Yes",
          value: "true",
        },
        {
          text: "No",
          value: "false",
        },
      ],
    };
  },
  watch: {
    value(val) {
      if (val) {
        this.getCompanyOptions();
        this.getContainerCategoryList();
      }
    },
  },
  computed: {
    showAdminUserFilters: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    filterObject: {
      get() {
        return this.bindingObject;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    currentProfile() {
      let obj = JSON.parse(localStorage.getItem("userProfile"));
      if (!obj) {
        return null;
      } else {
        return obj;
      }
    },
  },
  methods: {
    getContainerCategoryList(params = {}) {
      this.$bus.$emit("showLoader", true);
      params = {
        limit: "all",
      };

      this.$api.containerCategory
        .getContainerCategoryList(params)
        .then((res) => {
          this.$bus.$emit("showLoader", false);
          this.allContainerCategoryList = res.data;
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    getCompanyOptions() {
      this.$bus.$emit("showLoader", true);
      this.$api.company
        .getCompanyOptions()
        .then((res) => {
          this.paymentTerms = res.data.actions.POST.payment_term.choices.map(
            (obj) => {
              return { text: obj.display_name, value: obj.value };
            }
          );
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
  },
};
</script>

<style></style>
