<template>
  <div class="d-flex align-baseline">
    <v-tooltip bottom>
      <template #activator="{ on, attrs }">
        <v-icon
          color="primary"
          v-on="on"
          v-bind="attrs"
          @click="viewItem"
          class="ml-1 mr-2 my-1"
        >
          mdi-eye
        </v-icon>
      </template>
      <span>View</span>
    </v-tooltip>

    <v-tooltip bottom v-if="params.context.parentComponent.tab == 0">
      <template #activator="{ on, attrs }">
        <v-icon
          v-if="
            params &&
            params.context &&
            params.context.parentComponent &&
            params.context.parentComponent.permissions &&
            params.context.parentComponent.permissions.change
          "
          color="primary"
          v-on="on"
          v-bind="attrs"
          @click="editItem"
          class="ml-2 mr-2"
        >
          mdi-pencil
        </v-icon>
      </template>
      <span>Edit</span>
    </v-tooltip>

    <v-tooltip bottom v-if="params.context.parentComponent.tab == 0">
      <template #activator="{ on, attrs }">
        <v-icon
          v-if="
            params &&
            params.context &&
            params.context.parentComponent &&
            params.context.parentComponent.permissions &&
            params.context.parentComponent.permissions.add
          "
          color="primary"
          v-on="on"
          v-bind="attrs"
          @click="copyItem"
          class="ml-2 mr-2"
        >
          mdi-content-copy
        </v-icon>
      </template>
      <span>Copy data to add form</span>
    </v-tooltip>

    <!-- <v-tooltip bottom v-if="params.context.parentComponent.tab = 0">
      <template #activator="{ on, attrs }">
        <v-icon
          color="success"
          v-on="on"
          v-bind="attrs"
          @click="updateStatus(params.data, 'Active')"
          class="ml-2 mr-2"
        >
          mdi-check
        </v-icon>
      </template>
      <span>Accept</span>
    </v-tooltip> -->
<!-- 
    <v-tooltip bottom v-if="params.context.parentComponent.tab == 0">
      <template #activator="{ on, attrs }">
        <v-icon
          class="ml-2 mr-1"
          color="error"
          v-on="on"
          v-bind="attrs"
          @click="updateStatus(params.data, 'Rejected')"
        >
          mdi-close
        </v-icon>
      </template>
      <span>Reject</span>
    </v-tooltip> -->

    <v-tooltip bottom v-if="params.context.parentComponent.tab == 1">
      <template #activator="{ on, attrs }">
        <v-icon
          v-if="
            params &&
            params.context &&
            params.context.parentComponent &&
            params.context.parentComponent.permissions &&
            params.context.parentComponent.permissions.add &&
            !params.data.has_been_renewed
          "
          color="primary"
          v-on="on"
          v-bind="attrs"
          @click="renew"
          class="ml-2 mr-2"
        >
          mdi-autorenew
        </v-icon>
      </template>
      <span>Renew Contract</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  methods: {
    viewItem() {
      this.params.context.parentComponent.viewContract(this.params.data.id);
    },
    editItem() {
      this.params.context.parentComponent.editContract(this.params.data.id);
    },
    copyItem() {
      this.params.context.parentComponent.addContract(this.params.data.id);
    },
    updateStatus(contract, status) {
      this.params.context.parentComponent.openRemarksForm(contract, status);
    },
    renew() {
      this.params.context.parentComponent.showRenewDialog(this.params.data.id);
    },
  },
};
</script>

<style>
</style>