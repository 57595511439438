<template>
  <v-app>
    <!-- Header -->
    <v-app-bar app>
      <img
        class="Brand-Logo"
        alt="Brand Logo"
        src="@/assets/images/brand-logo.png"
      />
    </v-app-bar>
    <v-container class="mt-16">
      <v-row>
        <v-col cols="12" sm="12">
          <v-card style="max-width: 600px">
            <v-card-title class="primary white--text">
              <span>Enter Rate - {{ biddingDetails.status }} </span>

              <v-spacer></v-spacer>
            </v-card-title>

            <v-card-text class="pt-2">
              <div>
                <div class="info-item info-row">
                  <strong>Container Type:</strong>
                  {{ biddingDetails.container_name }}
                </div>
                <div class="info-item info-row">
                  <strong>Container Count:</strong>
                  {{ biddingDetails.bid_container_count }}
                </div>
              </div>
              <div class="info-item info-row">
                <strong>Expected Rate:</strong>
                {{ biddingDetails.expected_rate }}
              </div>
              <div class="info-item info-row">
                <strong>End Date:</strong>
                {{ biddingDetails.end_date }}
              </div>
              <v-form v-model="isValid" ref="bidRequestForm">
                <v-row class="my-0">
                  <v-col cols="12" class="Form-Columns-Dense">
                    <InputField
                      hide-details="auto"
                      type="number"
                      step=".01"
                      :label="`Rate (${$globals.currency}) *`"
                      required
                      :readonly="
                        biddingDetails.status == 'Participated' ||
                        biddingDetails.status == 'Expired'
                      "
                      :rules="[
                        (value) =>
                          (value != null && value != undefined) ||
                          'Rate is required',
                        (val) => !!val || 'Rate is Required!',
                      ]"
                      :clearable="false"
                      v-model="formDataPOST.rate"
                      :error-messages="formErrors.rate"
                      @input="delete formErrors.rate"
                    />
                  </v-col>
                  <v-col cols="12" class="Form-Columns-Dense">
                    <DateField
                      :inputProps="{
                        dense: true,
                        label: 'Rate Expiry Date *',
                        rules: [
                          (val) => !!val || 'Rate Expiry Date is Required!',
                        ],
                        'hide-details': 'auto',
                        'error-messages': formErrors.rate_expiry_date,
                      }"
                      required
                      :readonly="
                        biddingDetails.status == 'Participated' ||
                        biddingDetails.status == 'Expired'
                      "
                      v-model="formDataPOST.rate_expiry_date"
                      @input="delete formErrors.rate_expiry_date"
                    />
                  </v-col>
                  <v-col cols="12" class="Form-Columns">
                    <InputField
                      hide-details="auto"
                      type="number"
                      label="Container Count *"
                      required
                      :rules="[(val) => !!val || 'Container Count is Required']"
                      :clearable="false"
                      v-model="formDataPOST.container_count"
                      :error-messages="formErrors.container_count"
                      @input="delete formErrors.container_count"
                    />
                  </v-col>
                  <v-col cols="12" class="Form-Columns-Dense">
                    <InputField
                      label="Remarks"
                      :readonly="
                        biddingDetails.status == 'Participated' ||
                        biddingDetails.status == 'Expired'
                      "
                      hide-details="auto"
                      v-model="formDataPOST.transporter_remarks"
                    />
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>

            <v-divider></v-divider>
            <v-card-actions class="justify-end">
              <v-btn
                depressed
                color="primary"
                :disabled="!isValid"
                @click="submitForm"
              >
                <span>Submit</span>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import InputField from "@/components/FormBaseComponents/InputField.vue";
import DateField from "@/components/FormBaseComponents/DateField.vue";

export default {
  components: { InputField, DateField },

  data() {
    return {
      formErrors: {},
      isValid: true,
      formDataPOST: {},
      biddingDetails: {},
    };
  },

  computed: {
    formData: {
      get() {
        return this.formDetails;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    resetFormValidation() {
      if (this.$refs.bidRequestForm) {
        this.$refs.bidRequestForm.resetValidation();
      }
    },
    async submitForm() {
      this.$bus.$emit("showLoader", true);
      let payload = {
        id: this.$route.params.id,
        rate: this.formDataPOST.rate,
        transporter_remarks: this.formDataPOST.transporter_remarks,
        rate_expiry_date: this.formDataPOST.rate_expiry_date,
        status: "Participated",
      };

      this.$api.unauthoriseHsm
        .submitBidRequestRate(payload)
        .then((res) => {
          if (res && res.data && res.data.message) {
            this.$bus.$emit("showToastMessage", {
              message: res.data.message,
              color: "success",
              hasActions: true,
            });
          }
          this.$router.push("/app/bid/hsm/success");
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message: `${
              err.data && err.data.non_field_errors
                ? err.data.non_field_errors.join()
                : "Couldn't update rate!"
            }`,
            color: "error",
          });
          this.formErrors = err.data;
        });
    },
    getBiddingDetails(params = {}) {
      this.$bus.$emit("showLoader", true);
      this.$api.unauthoriseHsm
        .getBiddingDetails(this.$route.params.id)
        .then((res) => {
          this.biddingDetails = res.data;
          this.allBidList = res.data.bid_request;
          this.$bus.$emit("showLoader", false);

          // Check if status is expired
          
          if (this.biddingDetails.status === "Expired") {
            // Redirect to the expired page
            this.$router.push("/app/bid/hsm/expire"); // Replace 'expired-page' with your actual route name
          }
           if (this.biddingDetails.status === "Won") {
            // Redirect to the expired page
            this.$router.push("/app/bid/hsm/won"); // Replace 'expired-page' with your actual route name
          }
        })
        .catch((err) => {
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message: "Bid Detail not found!",
            color: "error",
          });
        });
    },
  },
  mounted() {
    this.getBiddingDetails();
    // Logic to be executed when the component is mounted
  },
};
</script>
<style scoped>
.flex-container {
  display: flex;
  align-items: center;
}
.flex-container v-text-field {
  flex: 1; /* Allow text field to grow and take remaining space */
  margin-right: 8px; /* Add some spacing between text field and button */
}
</style>
 I