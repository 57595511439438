<template>
  <v-container class="Details-View">
    <v-row class="heading px-2 pt-2">
      <v-col cols="12" md="5">
        <div class="d-flex">
          <Tooltip
            tooltipTitle="Company Code"
            v-if="transporterDetails.company_code"
            :tooltipText="transporterDetails.company_code"
            top
            tooltipClass="text-h5 font-weight-bold"
          />
        </div>
      </v-col>

      <v-col cols="12" md="6">
        <Tooltip
          tooltipTitle="Company Name"
          v-if="transporterDetails.company_name"
          :tooltipText="transporterDetails.company_name"
          top
          tooltipClass="text-h5 font-weight-bold"
        />

        <div
          v-if="transporterDetails && transporterDetails.address_detail"
          class="text-subtitle-1 font-weight-medium d-flex align-baseline"
        >
          <Tooltip
            tooltipTitle="Country"
            v-if="transporterDetails.address_detail.Country"
            :tooltipText="transporterDetails.address_detail.Country"
            left
            tooltipClass="mr-1"
          />
          |
          <Tooltip
            tooltipTitle="City"
            v-if="transporterDetails.address_detail.city"
            :tooltipText="transporterDetails.address_detail.city"
            right
            tooltipClass="ml-1"
          />
        </div>
      </v-col>
    </v-row>

    <v-row class="subHeading px-2">
      <v-col cols="12" md="6" lg="5">
        <div class="d-flex align-center mr-4">
          <v-icon>mdi-email</v-icon>
          <h4 class="mb-0 ml-2 text-Body-1 Word-Break">
            {{ transporterDetails.company_email }}
          </h4>
        </div>
      </v-col>

      <v-col cols="12" md="6" lg="5">
        <div class="d-flex align-center">
          <v-icon>mdi-phone</v-icon>
          <h4 class="mb-0 ml-2 text-Body-1">
            {{ transporterDetails.full_company_contact_number }}
          </h4>
        </div>
      </v-col>
    </v-row>

    <v-tabs fixed-tabs v-model="detailsTab" class="mt-3">
      <v-tabs-slider color="white"></v-tabs-slider>
      <v-tab>Detail View</v-tab>
      <v-tab v-if="!readOnly">User List</v-tab>
      <v-tab v-if="!readOnly">Documents</v-tab>
      <v-tab v-if="!readOnly">Logs</v-tab>
    </v-tabs>

    <v-tabs-items v-model="detailsTab" class="px-2">
      <v-tab-item class="py-4">
        <v-expansion-panels multiple focusable v-model="companyDetailsView">
          <v-expansion-panel
            class="Card-Details-Expansion"
            v-if="
              transporterDetails.contact_details &&
              transporterDetails.contact_details.length > 0
            "
          >
            <v-expansion-panel-header class="py-1 px-3 subHeading">
              <span class="text-subtitle-1 font-weight-medium"
                >Contact Details</span
              >
            </v-expansion-panel-header>
            <v-expansion-panel-content class="pt-3 subHeading">
              <v-list-item
                three-line
                v-for="(contact, i) in transporterDetails.contact_details"
                :key="i"
              >
                <v-list-item-content>
                  <v-list-item-title>{{
                    `${contact.contact_person} - ${contact.department_name}`
                  }}</v-list-item-title>
                  <v-list-item-subtitle>
                    {{ contact.contact_email }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle>
                    {{ `+${contact.phone_code} ${contact.contact_number}` }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <CardDetails
            v-if="transporterDetails.address_detail"
            title="Address Details"
            :displayObject="transporterDetails.address_detail"
          />
          <CardDetails
            v-if="transporterDetails.markup_markdown_detail"
            title="Markup Markdown Details"
            :displayObject="transporterDetails.markup_markdown_detail"
          />
          <CardDetails
            v-if="transporterDetails.payment_detail"
            title="Payment Details"
            :displayObject="transporterDetails.payment_detail"
          />
          <CardDetails
            v-if="transporterDetails.other_detail"
            title="Other Details"
            :displayObject="transporterDetails.other_detail"
          />
        </v-expansion-panels>
      </v-tab-item>
      <v-tab-item v-if="!readOnly">
        <div class="text-right mx-4 my-2">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                icon
                depressed
                @click="addTransporterUser"
                v-bind="attrs"
                v-on="on"
                small
                v-if="userPermissions && userPermissions.add"
                class="Details-Icon"
              >
                <v-icon>mdi-account-plus</v-icon>
              </v-btn>
            </template>
            <span>Add User</span>
          </v-tooltip>
        </div>

        <v-divider></v-divider>

        <v-list>
          <v-list-item v-for="(t_user, i) in transporterCompanyUsers" :key="i">
            <v-list-item-icon>
              <v-icon>mdi-account</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title class="text-subtitle-1 font-weight-medium">
                {{ t_user.first_name }} {{ t_user.last_name }}
              </v-list-item-title>

              <v-list-item-subtitle>
                <Tooltip
                  tooltipTitle="Username"
                  :tooltipText="t_user.username"
                  top
                  tooltipClass="text-body-2"
                />
              </v-list-item-subtitle>

              <v-list-item-subtitle>
                <Tooltip
                  tooltipTitle="Email"
                  :tooltipText="t_user.email"
                  top
                  tooltipClass="text-body-2"
                />

                <Tooltip
                  tooltipTitle="Contact Number"
                  :tooltipText="t_user.full_contact_number"
                  top
                  tooltipClass="text-body-2 ml-3"
                />
              </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-icon>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="primary"
                    @click="editTransporterUser(t_user.id)"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-pencil
                  </v-icon>
                </template>
                <span>Edit</span>
              </v-tooltip>
            </v-list-item-icon>
          </v-list-item>
        </v-list>
      </v-tab-item>

      <v-tab-item v-if="!readOnly">
        <DocumentList :documentList="transporterDetails.company_documents" />
      </v-tab-item>
      <v-tab-item v-if="!readOnly">
        <v-sheet
          style="
            height: calc(100vh - 280px);
            overflow-y: auto;
            overflow-x: hidden;
          "
        >
          <v-timeline v-if="logs && logs.length" class="px-0" align-top dense>
            <v-timeline-item
              v-for="(log, index) in logs"
              :key="index"
              color="primary"
              icon="mdi-circle"
              small
            >
              <v-row class="pt-1">
                <v-col>
                  <strong>{{ log.created | formatDate(true) }}</strong>
                  <div class="text-body-2">{{ log.message }}</div>
                  <div class="text-caption">- {{ log.updated_by }}</div>
                </v-col>
              </v-row>
            </v-timeline-item>
          </v-timeline>
          <div v-else>No Logs to show</div>
        </v-sheet>
      </v-tab-item>
    </v-tabs-items>

    <UserForm
      v-model="showTransporterUserForm"
      module="transporter"
      :editMode="editMode"
      :user="transporterDetails"
      :companyUserId="editCompanyId"
      @closeUserForm="closeTransporterUserForm"
      @refreshList="getTransporterCompanyUsers"
    />
  </v-container>
</template>

<script>
import UserForm from "../dialogForms/UserForm.vue";
import CardDetails from "@/components/General/CardDetails.vue";
import Tooltip from "@/components/General/Tooltip.vue";
import DocumentList from "@/components/General/DocumentList.vue";

export default {
  components: { UserForm, CardDetails, Tooltip, DocumentList },
  props: {
    transporterId: {
      type: Number,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    permissions: {
      type: Object,
      default: () => ({}),
    },
    userPermissions: {
      type: Object,
      default: () => ({}),
    },
  },
  watch: {
    transporterId(val) {
      this.logs = [];
      this.getTransporterDetails(val);
      this.companyActivityLogs();
      this.getTransporterCompanyUsers();
    },
  },
  data() {
    return {
      transporterDetails: {},
      transporterCompanyUsers: [],

      showTransporterUserForm: false,
      editMode: false,
      editCompanyId: null,

      companyDetailsView: [0, 1, 2, 3, 4],
      detailsTab: null,

      logs: [],
    };
  },
  methods: {
    closeTransporterUserForm() {
      this.editMode = false;
      this.editCompanyId = null;
      this.showTransporterUserForm = false;
    },
    getTransporterDetails(id) {
      this.$api.company
        .getCompanyDetails(id)
        .then((res) => {
          this.transporterDetails = res.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getTransporterCompanyUsers(params = {}) {
      params = {
        ...params,
        company: this.transporterId,
        limit: "all",
      };
      this.$api.user
        .getUserList(params)
        .then((res) => {
          this.transporterCompanyUsers = res.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    companyActivityLogs(params = {}) {
      params = {
        ...params,
        company: this.transporterId,
        limit: "50",
        offset: this.logs.length,
      };
      this.$api.activityLogs
        .companyActivityLogs(params)
        .then((res) => {
          this.logs.push(...res.data);
        })
        .catch((err) => {
          console.error(err);
        });
    },
    addTransporterUser() {
      this.editMode = false;
      this.showTransporterUserForm = true;
    },
    editTransporterUser(id) {
      this.editCompanyId = id;
      this.editMode = true;
      this.showTransporterUserForm = true;
    },
  },
  mounted() {
    this.logs = [];
    this.getTransporterDetails(this.transporterId);
    this.getTransporterCompanyUsers();
    this.companyActivityLogs();
  },
};
</script>
