import Blank from "@/layouts/blank.vue"

import BiddingList from "@/pages/transporter/commercials/bidding/BiddingList.vue"
 import BidRequestSubHireDetail from "@/pages/transporter/commercials/bidding/BidRequestSubHireDetail.vue";



const biddingRoutes = [
  {
    path: "bidding",
    name: "app-transporter-bidding",
    component: Blank,
    children: [
      {
        path: "/",
        redirect: "/app/transporter/bidding/list",
      },
      {
        path: "list",
        name: "app-transporter-bidding-list",
        component: BiddingList,
      },
      {
        path: "detail",
        name: "app-transporter-bidding-detail",
        component: Blank,
        children: [
          {
            path: "/",
            redirect: "/app/transporter/bidding/list",
          },
        ]
      },
      {
        path: "subhiredetail",
        name: "app-transporter-bidding-subhire-detail",
        component: Blank,
        children: [
          {
            path: "/",
            redirect: "/app/transporter/bidding/list",
          },
          {
            path: ":id",
            name: "app-transporter-bidding-subhire-detail-id",
            component: BidRequestSubHireDetail,
          }
        ]
      },
    ]
  },
]

export default biddingRoutes