import Blank from "@/layouts/blank.vue"

import SpotRoutes from "@/router/customer/rate/spot"
import QuotationRoutes from "@/router/customer/rate/quotation"
import TermsConditionRoutes from "@/router/customer/rate/terms_and_condition"


const rateRoutes = [
  {
    path: "rate",
    name: "app-customer-rate",
    component: Blank,
    children: [
      ...SpotRoutes,
      ...QuotationRoutes,
      ...TermsConditionRoutes
    ]
  },
]

export default rateRoutes