import Blank from "@/layouts/blank.vue"

import SubHireApprovedList from "@/pages/admin/sub-hire/SubHireApprovedList.vue";
import SubHireCompletedList from "@/pages/admin/sub-hire/SubHireCompletedList.vue";
import SubHireInvoiceUpdatedList from "@/pages/admin/sub-hire/SubHireInvoiceUpdatedList.vue";
import SubHirePendingList from "@/pages/admin/sub-hire/SubHirePendingList.vue";
import SubHireCancelledList from "@/pages/admin/sub-hire/SubHireCancelledList.vue";
import SubHireCreatedList from "@/pages/admin/sub-hire/SubHireCreatedList.vue";

const subHireRoutes = [
  {
    path: "sub-hire",
    name: "app-admin-sub-hire",
    component: Blank,
    children: [
      {
        path: "/",
        redirect: "/app/admin/dashboard",
      },
      {
        path: "created",
        name: "app-admin-sub-hire-created",
        component: SubHireCreatedList,
      },
      {
        path: "pending",
        name: "app-admin-sub-hire-pending",
        component: SubHirePendingList,
      },
      {
        path: "approved",
        name: "app-admin-sub-hire-approved",
        component: SubHireApprovedList,
      },
      {
        path: "completed",
        name: "app-admin-sub-hire-completed",
        component: SubHireCompletedList,
      },
      {
        path: "invoice-updated",
        name: "app-admin-sub-hire-invoice-updated",
        component: SubHireInvoiceUpdatedList,
      },
    
      {
        path: "cancelled",
        name: "app-admin-sub-hire-cancelled",
        component: SubHireCancelledList,
      },
    ]
  },
]

export default subHireRoutes