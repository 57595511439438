<template>
  <div>
    <v-card style="background-color: #ffece0">
      <v-card-title>
        <v-row>
          <v-col cols="12" class="d-flex align-center py-1">
            <v-btn
              class="primary text-capitalize"
              style="width: 100%"
              @click="updateTripStatus"
              >{{
                tripDetails.possible_status &&
                tripDetails.possible_status.length >= 0
                  ? tripDetails.possible_status[0]
                  : ""
              }}</v-btn
            >
          </v-col>
          <v-col cols="12" class="d-flex align-center py-1">
            <MultiSelect
              hide-details="auto"
              showAll
              :label="`Update Milestone status`"
              class="mr-2 white"
              :placeholder="`Select Status`"
              :items="mileStoneList"
              itemText="milestone_name"
              itemValue="id"
              v-model="milestone"
            ></MultiSelect>

            <v-btn
              @click="addDriverAppTripMilestone"
              :disabled="!milestone"
              class="primary text-capitalize"
              style="width: 100px"
              >Update</v-btn
            >
          </v-col>
        </v-row>
      </v-card-title>
    </v-card>
    <v-row no-gutters class="py-3 px-4">
      <v-col cols="12">
        <v-timeline align-top dense color="blue">
          <v-timeline-item
            small
            v-for="(trip_log, j) in tripDetails.trip_logs"
            :key="j"
          >
            <h5>{{ trip_log.message }}</h5>
            <h6 class="font-weigh-light">{{ trip_log.created }}</h6>
          </v-timeline-item>
        </v-timeline>
      </v-col>
    </v-row>
    <div>
      <BaseDriverDialog
        fullscreen
        v-model="odometerReadingDialog"
        title="Odometer Reading"
      >
        <template #information>
          <DriverAppOdoMeterReadingDialog
            :odometerReadingDialog="odometerReadingDialog"
            :odometerReadingType="odometerReadingType"
            :tripDetails="tripDetails"
            @completeTrip="completeTrip()"
            @closeOdometerReadingDialog="closeOdometerReadingDialog()"
            @refreshTripDetails="getDriverAppTripDetails()"
          />
        </template>
      </BaseDriverDialog>
    </div>
  </div>
</template>
  
  <script>
import BaseDriverDialog from "@/components/General/BaseDriverDialog.vue";
import DriverAppOdoMeterReadingDialog from "@/components/ModuleBased/details/driverApp/DriverAppOdoMeterReadingDialog.vue";
import MultiSelect from "@/components/FormBaseComponents/MultiSelect.vue";

export default {
  props: {
    mileStoneDialog: {
      type: Boolean,
    },
    tripDetails: {
      required: true,
    },
  },
  data() {
    return {
      milestone: null,
      odometerReadingDialog: false,
      mileStoneList: [],
      odometerReadingType: "end",
    };
  },
  components: {
    BaseDriverDialog,
    DriverAppOdoMeterReadingDialog,
    MultiSelect,
  },

  watch: {
    mileStoneDialog(val) {
      if (val) {
        this.getDriverAppTripMilestone();
      }
    },
  },
  methods: {
    getDriverAppTripDetails() {
      this.$bus.$emit("showLoader", true);
      this.$api.driverApp
        .getDriverAppTripDetails()
        .then((res) => {
          this.$bus.$emit("showLoader", false);
          this.tripDetails = res.data;

          let destinationCoords = null;
          let midCoords = null;
          let sourceCoords = `${this.tripDetails.source_point.latitude},${this.tripDetails.source_point.longitude}`;
          if (this.tripDetails.destination_address) {
            destinationCoords = `${this.tripDetails.destination_point.latitude},${this.tripDetails.destination_point.longitude}`;
            midCoords = `${this.tripDetails.mid_point.latitude},${this.tripDetails.mid_point.longitude}`;
          } else {
            destinationCoords = `${this.tripDetails.mid_point.latitude},${this.tripDetails.mid_point.longitude}`;
          }
          this.googleMapsRedirectLink = createGoogleMapsDirectionLink(
            sourceCoords,
            destinationCoords,
            midCoords
          );
        })
        .catch((err) => {
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message: "Couldn't get driver's trip details!",
            color: "error",
            hasActions: true,
          });
        });
    },
    addDriverAppTripMilestone(payload = {}) {
      this.$bus.$emit("showLoader", true);
      payload.id = this.milestone;
      this.$api.driverApp
        .addDriverAppTripMilestone(payload)
        .then((res) => {
          this.milestone = null;
          this.$emit("refreshTripDetails");
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message: "Trip milestone updated successfully!",
            color: "success",
            hasActions: true,
          });
        })
        .catch((err) => {
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message: "Couldn't update milestone!",
            color: "error",
            hasActions: true,
          });
        });
    },
    getDriverAppTripMilestone(params = {}) {
      this.$bus.$emit("showLoader", true);
      params.leg = this.selectedLegId;
      this.$api.driverApp
        .getDriverAppTripMilestone(params)
        .then((res) => {
          this.mileStoneList = res.data;
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message: "Couldn't get milestone!",
            color: "error",
            hasActions: true,
          });
        });
    },
    completeTrip() {
      this.$bus.$emit("showLoader", true);

      let payload = {
        request_type: "update_status",
        status: "Completed",
      };
      this.$api.driverApp
        .updateDriverAppTrip(payload)
        .then((res) => {
          this.odometerReadingDialog = false;
          this.$emit("closeMileStoneDialog");
          this.$emit("refreshTripDetails");
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message: "Trip completed successfully!",
            color: "success",
            hasActions: true,
          });
        })
        .catch((err) => {
          console.error(err.data);
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message: "Couldn't complete trip!",
            color: "error",
            hasActions: true,
          });
        });
    },
    updateTripStatus() {
      if (confirm("Do you want to update milestone ?")) {
        this.$bus.$emit("showLoader", true);

        let payload = {
          request_type: "update_status",
          status: this.tripDetails.possible_status[0],
        };
        this.$api.driverApp
          .updateDriverAppTrip(payload)
          .then((res) => {
            if (
              this.tripDetails &&
              this.tripDetails.possible_status &&
              this.tripDetails.possible_status.length &&
              this.tripDetails.possible_status[0] && 
              this.tripDetails.possible_status[0]  == "Completed"
            ) {
              this.$emit("closeMileStoneDialog");
            }
            this.$emit("refreshTripDetails");
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message: "Trip milestone updated successfully!",
              color: "success",
              hasActions: true,
            });
          })
          .catch((err) => {
            console.error(err.data);
            this.$bus.$emit("showLoader", false);

            if (
              err.data &&
              err.data.error &&
              err.data.error.odometer_reading &&
              err.data.error.odometer_reading[0] == false
            ) {
              this.odometerReadingDialog = true;
              this.$bus.$emit("showToastMessage", {
                message: "Please odometer reading first!",
                color: "error",
                hasActions: true,
              });
            } else {
              this.$bus.$emit("showToastMessage", {
                message: "Couldn't update trip!",
                color: "error",
                hasActions: true,
              });
            }
          });
      }
    },
  },
  mounted() {
    this.getDriverAppTripMilestone();
  },
};
</script>
    
    
    <style scoped lang="scss">
.card-outlined {
  border: 1px solid #0000004f !important;
  border-radius: 6px;
}
</style>