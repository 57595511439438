import Blank from "@/layouts/blank.vue"

import LrfqList from "@/pages/transporter/commercials/lrfq/LrfqList.vue"
import LrfqDetails from "@/pages/transporter/commercials/lrfq/LrfqDetails.vue";



const lrfqRoutes = [
    {
        path: "lrfq",
        name: "app-transporter-lrfq",
        component: Blank,
        children: [
            {
                path: "/",
                redirect: "/app/transporter/lrfq/list",
            },
            {
                path: "list",
                name: "app-transporter-lrfq-list",
                component: LrfqList,
            },
            {
                path: "detail",
                name: "app-transporter-lrfq-detail",
                component: Blank,
                children: [
                    {
                        path: "/",
                        redirect: "/app/transporter/lrfq/list",
                    },
                    {
                        path: ":id",
                        name: "app-transporter-lrfq-detail-id",
                        component: LrfqDetails,
                    }
                ]
            },

        ]
    },
]

export default lrfqRoutes