<template>
  <ListComponent title="Driver" :totalCount="totalItems">
    <template #list>
      <v-row class="ma-0">
        <v-col cols="4" class="px-0 pt-0 pb-2 d-flex align-center">
          <v-text-field
            label="Search"
            outlined
            dense
            append-icon="mdi-magnify"
            clearable
            hide-details="auto"
            v-model="filters.search"
            @input="searchDrivers"
            class="mr-2 search-field"
          />

          <v-tooltip top>
            <template #activator="{ on, attrs }">
              <v-btn
                depressed
                icon
                v-on="on"
                v-bind="attrs"
                @click="showFilterDialog = true"
                class="mx-2"
              >
                <v-badge
                  dot
                  color="primary"
                  :value="Object.keys(filters).length"
                >
                  <v-icon>mdi-filter</v-icon>
                </v-badge>
              </v-btn>
            </template>

            <span v-if="Object.keys(filters).length == 0">Filters</span>

            <v-row v-else no-gutters>
              <v-col cols="12" class="font-weight-bold">Applied Filters</v-col>
              <v-col cols="12">
                <span
                  v-for="(value, key) in filters"
                  :key="key"
                  class="text-capitalize"
                  >{{ key.replace("_", " ") }}
                  <br />
                </span>
              </v-col>
            </v-row>
          </v-tooltip>

          <Columns :headers="headers" :gridColInstance="gridColumnApi" />
        </v-col>

        <v-col
          v-if="totalItems > 0"
          cols="8"
          class="px-0 pt-0 pb-2 d-flex justify-end"
        >
          <Pagination
            :pageNo="pageNo"
            :totalItems="totalItems"
            :pageSize="itemsPerPage"
            @itemsPerPageChange="itemsPerPageChanged"
            @prevPage="prevPage"
            @nextPage="nextPage"
          />
        </v-col>

        <v-col cols="12" class="pa-0">
          <AgGridVue
            style="width: 100%; height: calc(100vh - 172px)"
            class="ag-theme-alpine"
            id="adminDriversList"
            :columnDefs="headers"
            :context="context"
            @grid-ready="onGridReady"
            @columnMoved="resizeGrid"
            @columnVisible="resizeGrid"
            :defaultColDef="defaultColDef"
            :grid-options="gridOptions"
            :suppressDragLeaveHidesColumns="true"
            :rowData="allDriverList"
          />
        </v-col>
      </v-row>

      <AdminDriverFilters
        v-model="showFilterDialog"
        :bindingObject="filters"
        @resetFilters="resetFilters"
        @applyFilters="itemsPerPageChanged(20)"
      />

      <DetailsParent :resizable="true" v-model="detailsDrawer">
        <template #details>
          <AdminDriverDetails
            :driverId="selectedDriverId"
            :permissions="permissions"
          />
        </template>
      </DetailsParent>
    </template>
    <template #dialogs>
      <BulkUpload
        v-model="driverBulkUploadDialog"
        :uploadTo="uploadType"
        @refreshList="itemsPerPageChanged(20)"
      />
    </template>
    <template #actions>
      <v-btn
        v-if="permissions && permissions.add"
        color="primary"
        depressed
        @click="addDriver"
      >
        <v-icon>mdi-plus</v-icon>
        <span>Add Driver</span>
      </v-btn>
      <v-btn
        class="ml-3"
        v-if="permissions && permissions.add"
        @click="openDriverBulkUpload('driver')"
        color="primary"
        depressed
      >
        <v-icon>mdi-plus</v-icon>
        <span>Bulk Upload</span>
      </v-btn>
    </template>
  </ListComponent>
</template>

<script>
import ListComponent from "@/components/General/ListComponent.vue";
import Columns from "@/components/General/Columns.vue";
import { AgGridVue } from "ag-grid-vue";
import Pagination from "@/components/General/Pagination.vue";
import DriverButton from "@/components/AgGridComponents/DriverButton.vue";
import CustomHeader from "@/components/AgGridComponents/General/CustomHeader.vue";
import DetailsParent from "@/components/General/DetailsParent.vue";
import AdminDriverDetails from "@/components/ModuleBased/details/AdminDriverDetails.vue";
import TripGoToButton from "@/components/AgGridComponents/TripGoToButton.vue";
import BulkUpload from "@/components/General/BulkUpload.vue";
import AdminDriverFilters from "@/components/ModuleBased/dialogs/filters/AdminDriverFilters.vue";

import {
  isAuthenticated,
  hasRouteAccess,
  getPermissions,
} from "@/utils/functions";

export default {
  components: {
    ListComponent,
    Columns,
    AgGridVue,
    Pagination,
    DriverButton,
    TripGoToButton,
    agColumnHeader: CustomHeader,
    DetailsParent,
    AdminDriverDetails,
    BulkUpload,
    AdminDriverFilters,
  },

  data() {
    return {
      sorting: {},
      headers: [
        {
          headerName: "Driver Name",
          field: "driver_name",
          sortable: true,
          tooltipField: "driver_name",
        },
        {
          headerName: "Username",
          field: "username",
          sortable: true,
          tooltipField: "username",
        },
        {
          headerName: "Employee ID",
          field: "employee_id",
          sortable: true,
        },
        {
          headerName: "Depot",
          field: "depot",
          sortable: true,
        },
        {
          headerName: "Designation",
          field: "designation",
          sortable: true,
          tooltipField: "designation",
        },
        {
          headerName: "License Number",
          field: "license_number",
          sortable: true,
        },
        {
          headerName: "Status",
          field: "status",
        },
        {
          headerName: "Contact Number",
          field: "full_contact_number",
        },
        {
          headerName: "Active Trip",
          field: "active_trip.trip_id",
          cellRendererFramework: "TripGoToButton",
        },
        {
          headerName: "Assigned Vehicle",
          field: "assigned_vehicle_trip",
        },
        {
          headerName: "Created By",
          field: "created_by",
        },
        {
          headerName: "Actions",
          field: "actions",
          cellRendererFramework: "DriverButton",
          pinned: "right",
          minWidth: 110,
        },
      ],

      allDriverList: [],

      // Details vars
      selectedDriverId: null,
      detailsDrawer: false,
      showFilterDialog: false,

      // pagination vars
      pageNo: 1,
      itemsPerPage: 20,
      totalItems: 0,
      filters: {},

      // bulk create
      uploadType: "driver",
      driverBulkUploadDialog: false,

      permissions: null,

      // ag-grid vars
      gridApi: null,
      gridColumnApi: null,
      defaultColDef: {
        resizable: true,
      },
      gridOptions: {
        onGridSizeChanged: () => {
          this.resizeGrid();
        },
        suppressRowClickSelection: true,
        suppressDragLeaveHidesColumns: true,
        enableCellTextSelection: true,
        tooltipShowDelay: 0,
        rowHeight: 35,
      },
    };
  },

  computed: {
    context() {
      return { parentComponent: this };
    },
    currentProfile() {
      let obj = JSON.parse(localStorage.getItem("userProfile"));
      if (!obj) {
        return null;
      } else {
        return obj;
      }
    },
    offset() {
      return this.itemsPerPage * (this.pageNo - 1);
    },
  },

  methods: {
    checkFilters() {
      const filters = localStorage.getItem("driver_filters");
      const user_data = JSON.parse(localStorage.getItem("userProfile"));

      if (filters) {
        this.filters = JSON.parse(filters);
        if (this.filters.sorting) {
          this.sorting = { ...this.filters.sorting };
          this.headers.map((obj) => {
            if (obj.field in this.sorting) {
              if (this.sorting[obj.field] == false) {
                obj.sort = "desc";
              } else if (this.sorting[obj.field]) {
                obj.sort = "asc";
              }
            }
          });
        }
      }
    },
    setFilters() {
      if (
        this.filters &&
        Object.keys(this.filters).length > 0 &&
        Object.keys(this.filters).indexOf("ordering")
      ) {
        delete this.filters.ordering;
      }
      localStorage.setItem(
        "driver_filters",
        JSON.stringify({ ...this.filters, sorting: this.sorting })
      );
    },
    resetFilters() {
      this.filters = {};
      this.itemsPerPageChanged(20);
    },
    openDriverBulkUpload(type) {
      this.uploadType = type;
      this.driverBulkUploadDialog = true;
    },
    resizeGrid() {
      if (this.gridOptions && this.gridOptions.api) {
        setTimeout(() => {
          this.gridOptions.api.sizeColumnsToFit();
        }, 100);
      }
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.resizeGrid();
    },
    searchDrivers() {
      this.filters = {};
      this.pageNo = 1;
      this.getDriverList();
    },
    itemsPerPageChanged(e) {
      this.pageNo = 1;
      this.itemsPerPage = e;
      this.getDriverList();
    },
    prevPage() {
      this.pageNo--;
      this.getDriverList();
    },
    nextPage() {
      this.pageNo++;
      this.getDriverList();
    },
    applyGridSort(key, type) {
      if (this.filters && !this.filters.ordering) {
        this.filters.ordering = [];
      }

      if (type == null) {
        delete this.sorting[key];
        this.filters.ordering.splice(
          this.filters.ordering.indexOf(`-${key}`),
          1
        );
      } else if (type == "asc") {
        this.filters.ordering.push(key);
      } else if (type == "desc") {
        this.filters.ordering.splice(
          this.filters.ordering.indexOf(key),
          1,
          `-${key}`
        );
      }
      if (this.filters.ordering.length == 0) {
        delete this.filters.ordering;
      }
      this.itemsPerPageChanged(20);
    },
    getDriverList(params = {}) {
      this.$bus.$emit("showLoader", true);
      params = {
        offset: this.offset,
        limit: this.itemsPerPage,
        company: this.currentProfile.company,
        ...params,
        ...this.filters,
      };
      if ("ordering" in params && params.ordering.length > 0) {
        params = { ...params, ordering: params.ordering.join(",") };
      }
      this.setFilters();
      this.$api.driver
        .getDriverList(params)
        .then((res) => {
          this.allDriverList = res.data;
          this.totalItems = res.count;
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },

    addDriver() {
      this.$router.push("/app/admin/asset-management/driver/add");
    },

    editDriver(id) {
      this.$router.push(`/app/admin/asset-management/driver/edit/${id}`);
    },

    goToTrip(trip_id) {
      this.$router.push({
        path: `/app/admin/trip/details/${trip_id}`,
        query: {
          backRoute: "/app/admin/asset-management/driver/list",
        },
      });
    },

    viewDriver(id) {
      this.selectedDriverId = id;
      this.detailsDrawer = true;
    },

    deleteRole(role) {
      this.$bus.$emit("showToastMessage", {
        message: "This is a test",
      });
    },
  },

  beforeMount() {
    if (!isAuthenticated()) {
      this.$router.push("/");
    } else if (!hasRouteAccess(this.$route)) {
      this.$router.push(`/${localStorage.getItem("user_type").toLowerCase()}`);
    } else {
      const permissions = getPermissions("driver");
      if (!permissions || !permissions.view) {
        this.$bus.$emit("showToastMessage", {
          message: "You don't have access to this page",
          color: "error",
        });
        this.$router.push(
          `/app/${
            localStorage.getItem("user_type")
              ? localStorage.getItem("user_type").toLowerCase()
              : "error"
          }`
        );
      } else {
        this.permissions = { ...permissions };
        this.checkFilters();
        this.getDriverList();
      }
    }
  },
};
</script>
