import Blank from "@/layouts/blank.vue"

import LeaseDriverList from "@/pages/admin/lease-driver/LeaseDriverList.vue";



const leaseDriverRoutes = [ 
    {
        path: "lease-driver",
        name: "app-admin-lease-driver",
        component: Blank,
        children: [
          {
            path: "/",
            redirect: "/app/admin/lease-driver/list",
          },
          {
            path: "list",
            name: "app-admin-lease-driver-list",
            component: LeaseDriverList,
          },
        ]
    }
]

export default leaseDriverRoutes