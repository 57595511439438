<template>
  <v-dialog width="500" v-model="showLeaseDriverForm">
    <v-card>
      <v-card-title class="secondary white--text">
        <span> {{ "Update" }} Driver Bonus </span>

        <v-spacer></v-spacer>

        <v-btn icon @click="resetForm(), (showLeaseDriverForm = false)">
          <v-icon class="white--text">mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="pt-2">
        <v-form
          ref="driverLeaseForm"
          v-if="showLeaseDriverForm"
          v-model="isValid"
        >
          <v-row class="my-0">
            <v-col cols="12" class="Form-Columns">
              <InputField
                label="Employee Name *"
                required
                :clearable="false"
                hide-details="auto"
                v-model="leaseDriverDetails.employee_name"
                readonly
              />
            </v-col>
            <v-col cols="12" class="Form-Columns">
              <InputField
                label="Employee Id *"
                required
                hide-details="auto"
                :clearable="false"
                v-model="leaseDriverDetails.emp_id"
                readonly
              />
            </v-col>
            <v-col cols="12" class="Form-Columns">
              <InputField
                label="No Of Trips *"
                required
                hide-details="auto"
                :clearable="true"
                v-model="leaseDriverDetails.no_of_trips"
              />
            </v-col>
            <v-col cols="12" class="Form-Columns">
              <InputField
                label="Bonus *"
                required
                hide-details="auto"
                :clearable="true"
                v-model="leaseDriverDetails.total_bonus"
              />
            </v-col>
            <v-col cols="12" class="Form-Columns">
              <DateField
                ref="tripDate"
                :inputProps="{
                  dense: true,
                  label: 'Trip Date *',
                  rules: [(val) => !!val || 'Date is Required!'],
                  'hide-details': 'auto',
                  'error-messages': formErrors.trip_date,
                }"
                required
                v-model="leaseDriverDetails.trip_date"
                @input="delete formErrors.trip_date"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-divider></v-divider>
      <v-card-actions class="justify-end">
        <v-btn
          color="primary"
          depressed
          :disabled="!isValid"
          @click="submitForm"
        >
          Submit
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import InputField from "@/components/FormBaseComponents/InputField.vue";
import MultiSelect from "@/components/FormBaseComponents/MultiSelect.vue";
import DateField from "@/components/FormBaseComponents/DateField.vue";

export default {
  components: {
    InputField,
    MultiSelect,
    DateField,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },

    editLeaseDriverID: {
      type: [Number, null],
      default: null,
    },
  },
  data() {
    return {
      isValid: true,
      isTextInput: false,
      formErrors: {},
      leaseDriverDetails: {},
    };
  },

  computed: {
    showLeaseDriverForm: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },

  watch: {
    showLeaseDriverForm(val) {
      if (val) {
        this.getLeaseDriverObject(this.editLeaseDriverID);
      }
    },
  },
  methods: {
    submitForm() {
      this.$bus.$emit("showLoader", true);

      this.$api.leaseDriverBonus
        .updateLeaseDriver({
          id: this.editLeaseDriverID,
          data: this.leaseDriverDetails,
        })
        .then((res) => {
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message: "Lease Driver Details updated successfully!",
            color: "success",
          });
          this.resetForm();
          this.showLeaseDriverForm = false;
          this.$emit("refreshList");
        })
        .catch((err) => {
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message: `${
              err.data && err.data.non_field_errors
                ? err.data.non_field_errors.join()
                : "Couldn't update Lease Driver"
            }`,
            color: "error",
          });

          this.formErrors = err.data;
        });
    },
    resetForm() {
      if (this.$refs.driverLeaseForm) {
        this.$refs.driverLeaseForm.reset();
      }
      this.leaseDriverDetails = {};
    },

    getLeaseDriverObject(id) {
      this.$bus.$emit("showLoader", true);
      this.$api.leaseDriverBonus
        .getLeaseDriverObject(id)
        .then((res) => {
          this.$bus.$emit("showLoader", false);
          this.leaseDriverDetails = res.data;
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
  },
};
</script>
