import { lrfq, lrfqRequestBase, transporterLrfqBase } from "@/utils/constants";
import { handleError, handleResponse } from "@/utils/functions";

export default (axios) => ({
    getLrfqList(params = {}) {
        return new Promise((resolve, reject) => {
            axios
                .get(lrfq.lrfqBase, {
                    params: params,
                })
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    getTransporterLrfqList(params = {}) {
        return new Promise((resolve, reject) => {
            axios
                .get(lrfq.transporterLrfqBase, {
                    params: params,
                })
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },

    addLrfq(data) {
        return new Promise((resolve, reject) => {
            axios
                .post(lrfq.lrfqBase, data)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    bulkAddLrfq(data) {
        return new Promise((resolve, reject) => {
            axios
                .post(`${lrfq.lrfqBase}bulk_create_lrfq/`, data)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    getLrfqDetails(id) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${lrfq.lrfqBase}${id}/view/`)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    getLrfqRequestList(params = {}) {
        return new Promise((resolve, reject) => {
            axios
                .get(lrfq.lrfqRequestBase, {
                    params: params,
                })
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    getTransporterLrfqDetails(id, params = {}) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${lrfq.transporterLrfqBase}${id}/view/`, {
                    params: params
                })
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    getAdminLrfqRequestBlopObject(id) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${lrfq.lrfqBase}${id}/download/`, {
                    responseType: "blob",

                })
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    fetchAdminLrfqRequestGroupedBlop(params = {}) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${lrfq.lrfqBase}get_grouped_lrfq_request/`, {
                    responseType: "blob",
                    params: params
                })
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },

    getLrfqRequestBlopObject(id, params = {}) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${lrfq.transporterLrfqBase}download`, {
                    responseType: "blob",
                    params: params
                })
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    getLrfqListForSubHire(params = {}) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${lrfq.lrfqBase}lrfq_rate/`, {
                    params: params,
                })
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    acceptOrRejectRate(params) {
        return new Promise((resolve, reject) => {
            axios
                .patch(`${lrfq.lrfqRequestBase}admin_accept_or_reject_rate/`, params)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },


    getLrfqObject(id) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${lrfq.lrfqBase}${id}/`)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },

    updateLrfq(payload) {
        return new Promise((resolve, reject) => {
            axios
                .put(
                    `${lrfq.lrfqBase}${payload.id}/`,
                    payload.data
                )
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },

    updateLrfqLaneRates(payload) {

        return new Promise((resolve, reject) => {
            axios
                .patch(
                    `${lrfq.lrfqRequestBase}submit_rate/`,
                    payload
                )
                .then((res) => {
                    resolve(handleResponse(res))
                })
                .catch((err) => {
                    reject(handleError(err))
                })
        })
    },
    getLrfqStatistics(params = {}) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${lrfq.lrfqBase}lrfq_statistics/`, {
                    params: params,
                })
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },

    getLrfqLogs(id) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${lrfq.lrfqBase}${id}/lrfq_logs/`)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    //used
    closedLrfq(id) {
        return new Promise((resolve, reject) => {
            axios
                .patch(`${lrfq.lrfqBase}${id}/close_lrfq/`)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },

});