import SubHireList from "@/pages/transporter/sub-hire/SubHireList.vue"
import Blank from "@/layouts/blank.vue"

const subhirejobRoutes = [
    {
        path: "subhirejobs",
        name: "app-transporter-subhirejobs",
        component: Blank,
        children: [
            {
                path: "/",
                redirect: "/app/transporter/subhirejobs/list",
            },
            {
                path: "list",
                name: "app-transporter-subhirejobs-list",
                component: SubHireList,
            },
        ]
    },
]

export default subhirejobRoutes