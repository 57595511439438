<template>
  <ListComponent title="Reports" :totalCount="totalItems">
    <template #actions>
      <v-tooltip top>
        <template #activator="{ on, attrs }">
          <v-btn
            class="mr-2"
            depressed
            color="primary"
            v-on="on"
            v-bind="attrs"
            @click="getReportList()"
          >
            <v-icon> mdi-refresh </v-icon>
          </v-btn>
        </template>

        <span>Refresh</span>
      </v-tooltip>

      <v-tooltip top>
        <template #activator="{ on, attrs }">
          <v-btn
            v-if="permissions && permissions.add"
            depressed
            color="primary"
            v-on="on"
            v-bind="attrs"
            @click="showFilterDialog = true"
          >
            Create Report
          </v-btn>
        </template>

        <span v-if="Object.keys(filters).length == 0">Filters</span>

        <v-row v-else no-gutters>
          <v-col cols="12" class="font-weight-bold">Applied Filters</v-col>
          <v-col cols="12">
            <span
              v-for="(value, key) in filters"
              :key="key"
              class="text-capitalize"
              >{{ key.replace("_", " ") }}
              <br />
            </span>
          </v-col>
        </v-row>
      </v-tooltip>
    </template>

    <template #list>
      <v-row class="ma-0">
        <v-col cols="4" class="px-0 pt-0 pb-2 d-flex align-center">
          <v-text-field
            label="Search"
            outlined
            dense
            append-icon="mdi-magnify"
            clearable
            hide-details="auto"
            v-model="filters.search"
            @input="searchCustomers()"
          ></v-text-field>
        </v-col>
        <v-col cols="12" class="pa-0">
          <AgGridVue
            style="width: 100%; height: calc(100vh - 172px)"
            class="ag-theme-alpine"
            id="myGrid"
            :columnDefs="headers"
            :context="context"
            @grid-ready="onGridReady"
            :defaultColDef="defaultColDef"
            :grid-options="gridOptions"
            :suppressDragLeaveHidesColumns="true"
            :rowData="allReportData"
          ></AgGridVue>
        </v-col>

        <v-col
          v-if="totalItems > 0"
          cols="12"
          class="pa-0 mt-2 d-flex justify-end"
        >
          <Pagination
            :pageNo="pageNo"
            :totalItems="totalItems"
            :pageSize="itemsPerPage"
            @itemsPerPageChange="itemsPerPageChanged"
            @prevPage="prevPage"
            @nextPage="nextPage"
          />
        </v-col>
      </v-row>
    </template>
    <template #dialogs>
      <AdminReportForm
        v-model="showFilterDialog"
        @resetFilters="resetFilters"
        :bindingObject="filters"
        @applyFilters="itemsPerPageChanged(50)"
        @refreshList="itemsPerPageChanged(50)"
      >
      </AdminReportForm>
      <AdminReportInfo
        v-model="showAdminReportInfo"
        :showReportDialogInfo="showReportDialogInfo"
      />
      <ReportsDetail
        v-model="showReportsDetailView"
        :reportObjId="reportId"
        :reportType="reportTypeName"
      />
    </template>
  </ListComponent>
</template>

<script>
import ListComponent from "@/components/General/ListComponent.vue";
import { AgGridVue } from "ag-grid-vue";
import Pagination from "@/components/General/Pagination.vue";
import CustomHeader from "@/components/AgGridComponents/General/CustomHeader.vue";
import AdminReportForm from "@/components/ModuleBased/dialogs/filters/AdminReportForm.vue";
import ReportDownloadButton from "@/components/AgGridComponents/ReportDownloadButton.vue";
import AdminReportInfo from "@/components/ModuleBased/dialogs/AdminReportInfo.vue";
import ReportsDetail from "@/components/ModuleBased/details/ReportsDetail";
import {
  isAuthenticated,
  hasRouteAccess,
  getPermissions,
} from "@/utils/functions";
import { ReportType } from "@/utils/choices";

export default {
  components: {
    ListComponent,
    AgGridVue,
    Pagination,
    agColumnHeader: CustomHeader,
    AdminReportForm,
    ReportDownloadButton,
    AdminReportInfo,
    ReportsDetail,
  },

  watch: {},
  data() {
    return {
      sorting: {},
      showCustomers: false,
      allCustomers: [],
      allReportData: [],
      all: true,

      allReports: ReportType,
      showAttachmentsDialog: false,

      tabs: null,
      showAdminTripForm: false,
      report_type: null,
      lastEndDate: null,
      totalItems: 0,

      allTransporter: [],

      isDownloadEnabled: false,

      // pagination vars
      pageNo: 1,
      itemsPerPage: 50,

      // ag-grid vars
      gridApi: null,
      gridColumnApi: null,
      defaultColDef: {
        resizable: true,
        flex: 1,
      },
      headerSelected: [],

      gridOptions: {
        onGridSizeChanged: () => {
          this.resizeGrid();
        },
        suppressRowClickSelection: true,
        suppressDragLeaveHidesColumns: true,
        enableCellTextSelection: true,
        tooltipShowDelay: 0,
        rowHeight: 35,
      },
      // Filter vars
      filters: {},
      showFilterDialog: false,
      showReportsDetailView: false,
      reportId: null,
      reportTypeName: null,
      permissions: null,

      showAdminReportInfo: false,
      showReportDialogInfo: {},
    };
  },

  computed: {
    headers() {
      let reportListHeaders = [];
      reportListHeaders = [
        {
          headerName: "Report Id",
          field: "report_id",
          minWidth: 70,
        },
        {
          headerName: "Report Type",
          sortable: true,
          field: "report_type",
          minWidth: 100,
        },
        {
          headerName: "Requested By",
          sortable: true,
          field: "requested_user_name",
          minWidth: 70,
        },
        {
          headerName: "Status",
          field: "status",
          minWidth: 70,
        },
        {
          headerName: "Created",
          field: "created",
          sortable: true,
          minWidth: 70,
          valueFormatter: (params) => {
            return this.$globals.formatDate(params.value);
          },
        },
        {
          headerName: "Total Records",
          field: "total_records",
          minWidth: 70,
        },
        {
          headerName: "Actions",
          field: "actions",
          cellRendererFramework: "ReportDownloadButton",
          pinned: "right",
          minWidth: 50,
        },
      ];
      return reportListHeaders;
    },
    context() {
      return { parentComponent: this };
    },
    offset() {
      return this.itemsPerPage * (this.pageNo - 1);
    },
  },

  methods: {
    searchCustomers() {
      this.pageNo = 1;
      this.getReportList();
    },
    viewReports(id, typeOfReport) {
      this.reportId = id;
      this.reportTypeName = typeOfReport;
      this.showReportsDetailView = true;
    },
    applyGridSort(key, type) {
      if (this.filters && !this.filters.ordering) {
        this.filters.ordering = [];
      }

      if (type == null) {
        delete this.sorting[key];
        this.filters.ordering.splice(
          this.filters.ordering.indexOf(`-${key}`),
          1
        );
      } else if (type == "asc") {
        this.filters.ordering.push(key);
      } else if (type == "desc") {
        this.filters.ordering.splice(
          this.filters.ordering.indexOf(key),
          1,
          `-${key}`
        );
      }
      if (this.filters.ordering.length == 0) {
        delete this.filters.ordering;
      }
      this.itemsPerPageChanged(50);
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.resizeGrid();
    },
    headersChanged(value) {
      this.headerSelected = value;
      this.resizeGrid();
    },

    getData() {
      this.pageNo = 1;
    },
    itemsPerPageChanged(e) {
      this.pageNo = 1;
      this.itemsPerPage = e;
      this.getReportList();
    },
    prevPage() {
      this.pageNo--;
      this.getReportList();
    },
    nextPage() {
      this.pageNo++;
      this.getReportList();
    },

    checkDates() {
      if (this.filters.start_date) {
        let currentDate = new Date(this.filters.start_date);
        let endDate = currentDate;
        endDate.setDate(currentDate.getDate() + 5);
        this.lastEndDate = endDate.toISOString().slice(0, 10);
        this.isDownloadEnabled = true;
      } else {
        this.isDownloadEnabled = false;
        this.filters.end_date = null;
      }
    },
    checkDownloadButton() {
      if (this.filters.start_date && this.filters.end_date) {
        if (
          this.report_type == "da_pending_to_invoice_report" &&
          !this.filters.customer
        ) {
          this.isDownloadEnabled = false;
        } else {
          this.isDownloadEnabled = true;
        }
      } else {
        this.isDownloadEnabled = false;
      }
      this.allReportData = [];
      this.headerSelected = [];
    },
    getReportList() {
      let params = {
        offset: this.offset,
        limit: this.itemsPerPage,
      };
      this.getReportList(params);
    },

    resizeGrid() {
      if (this.gridOptions && this.gridOptions.api) {
        setTimeout(() => {
          this.gridOptions.api.sizeColumnsToFit();
        }, 100);
      }
    },
    closeTripForm() {
      this.showAdminTripForm = false;
    },
    resetFilters() {
      this.filters = {
        status: [],
      };
      this.itemsPerPageChanged(50);
    },
    download(response, fileName) {
      var a = document.createElement("a");
      let url = URL.createObjectURL(response);
      a.href = url;
      a.download = `${fileName}.xlsx`;
      a.click();
      URL.revokeObjectURL(url);
    },
    s2ab(s) {
      var buf = new ArrayBuffer(s.length);
      var view = new Uint8Array(buf);
      for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
      return buf;
    },

    getReportList(params = {}, headers = {}, responseType = "json") {
      this.$bus.$emit("showLoader", true);
      params = {
        offset: this.offset,
        limit: this.itemsPerPage,
        ...this.filters,
        ...params,
      };
      if ("ordering" in params && params.ordering.length > 0) {
        params = { ...params, ordering: params.ordering.join(",") };
      }

      this.$api.report
        .getReportList(params, headers, responseType)
        .then((res) => {
          if (Object.keys(headers).length) {
            let fileName = `getReportList${this.filters.start_date}_TO_${this.filters.end_date}`;
            this.download(res.data, fileName);
          } else {
            this.allReportData = res.data;
            this.totalItems = res.count;
          }
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          this.$bus.$emit("showToastMessage", {
            message: `${
              err.data && err.data.non_field_errors
                ? err.data.non_field_errors.join()
                : "Data not found"
            }`,
            color: "error",
          });
          this.$bus.$emit("showLoader", false);
        });
    },

    viewReportsInfo(data) {
      this.showAdminReportInfo = true;
      this.showReportDialogInfo = data;
    },

    async downloadReport(data) {
      this.$bus.$emit("showLoader", true);
      await this.$api.report
        .getReportBlobObject(data.id)
        .then(async (response) => {
          this.$bus.$emit("showLoader", false);
          var blob = response.data;

          var downloadLink = window.document.createElement("a");
          downloadLink.href = window.URL.createObjectURL(
            new Blob([blob], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            })
          );
          downloadLink.download =
            data.report_type + "_" + data.report_id + ".xlsx";
          downloadLink.click();
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showToastMessage", {
            message: `${
              err.data && err.data.non_field_errors
                ? err.data.non_field_errors.join()
                : "Data not found"
            }`,
            color: "error",
          });

          this.$bus.$emit("showLoader", false);
        });
    },

    async getReportPdfBlobObject(id) {
      this.$bus.$emit("showLoader", true);
      await this.$api.report
        .getReportPdfBlobObject(id)
        .then(async (response) => {
          this.$bus.$emit("showLoader", false);
          let blob = new Blob(["\ufeff", response.data], {
            type: "application/octet-stream",
          });
          let link = document.createElement("a");
          const objUrl = window.URL.createObjectURL(blob);
          link.href = objUrl;
          link.download = "DriverBonusSummaryReport.pdf";
          link.click();
          window.URL.revokeObjectURL(objUrl);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },

    onMessage(ev) {
      if (ev && ev.data) {
        const data =
          typeof ev.data == typeof "" ? JSON.parse(ev.data) : ev.data;
        if (data.type && ["report_notification"].indexOf(data.type) > -1) {
          this.getReportList();
        }
      }
    },

    checkQuery() {
      if ("active_tab" in this.$route.query) {
        this.tabs = parseInt(this.$route.query.active_tab);
      }
    },
  },
  created() {
    this.$bus.$on("socketMessageReceived", (data) => {
      this.onMessage({ data: data });
    });
  },
  beforeMount() {
    if (!isAuthenticated()) {
      this.$router.push("/");
    } else if (!hasRouteAccess(this.$route)) {
      this.$router.push(`/${localStorage.getItem("user_type").toLowerCase()}`);
    } else {
      const permissions = getPermissions("reports");

      if (!permissions || !permissions.view) {
        this.$bus.$emit("showToastMessage", {
          message: "You don't have access to this page",
          color: "error",
        });
        this.$router.push(
          `/app/${
            localStorage.getItem("user_type")
              ? localStorage.getItem("user_type").toLowerCase()
              : "error"
          }`
        );
      } else {
        this.permissions = { ...permissions };
      }
    }
  },
  mounted() {
    this.getReportList();
  },
};
</script>
