<template>
  <v-row class="justify-center align-center ma-0 Screen-Height">
    <v-col cols="12" sm="8" md="6" lg="4">
      <v-form @submit.prevent="submitForm" ref="loginForm" v-model="isValid">
        <v-card
          class="pa-2"
          :class="{
            'elevation-0': $vuetify.breakpoint.mobile,
          }"
        >
          <v-card-title class="d-flex justify-center align-center">
            <img
              class="Brand-Logo mb-10"
              alt="Brand Logo"
              src="@/assets/images/brand-logo.png"
            />
          </v-card-title>

          <v-card-text>
            <v-row>
              <v-col cols="12">
                <InputField
                  label="Username"
                  autocomplete="off"
                  required
                  hide-details
                  :dense="false"
                  :rules="[(val) => !!val || 'Username is required']"
                  v-model="userDetails.username"
                />
              </v-col>

              <v-col cols="12">
                <InputField
                  label="Password"
                  autocomplete="off"
                  required
                  hide-details
                  :dense="false"
                  :rules="[(val) => !!val || 'Password is required']"
                  :append-icon="passwordEye ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="passwordEye ? 'text' : 'password'"
                  v-model="userDetails.password"
                  @click:append="passwordEye = !passwordEye"
                />
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions>
            <v-row class="ma-0">
              <v-col class="d-flex justify-center">
                <v-btn
                  type="submit"
                  color="primary"
                  depressed
                  :disabled="!isValid"
                  :loading="btnLoading"
                  large
                  class="mx-auto"
                >
                  Login
                </v-btn></v-col
              >
              <v-col class="d-flex">
                <v-btn
                  @click="forgotPassword()"
                  text
                  type="button"
                  color="primary"
                  depressed
                  large
                  class="mx-auto"
                >
                  Forgot Password?
                </v-btn>

                <v-btn
                  @click="forgotUsername()"
                  text
                  type="button"
                  color="primary"
                  depressed
                  large
                  class="mx-auto"
                >
                  Forgot Username?
                </v-btn>

                <v-btn
                  @click="signUp()"
                  text
                  type="button"
                  color="primary"
                  depressed
                  large
                  class="mx-auto"
                >
                  New User Request.
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
import InputField from "@/components/FormBaseComponents/InputField.vue";
import { encryptText } from "@/utils/functions";

export default {
  components: { InputField },
  layout: "login",
  data() {
    return {
      userDetails: {},
      btnLoading: false,
      password: "Password",
      passwordEye: false,
      isValid: false,
    };
  },
  methods: {
    submitForm() {
      this.btnLoading = true;
      let payload = {
        ...this.userDetails,
      };
      if (this.userDetails && this.userDetails.password) {
        payload.password = encryptText(this.userDetails.password);
      }
      this.$api.authentication
        .login(payload)
        .then(({ data }) => {
          localStorage.setItem("user_type", data.user_type);
          localStorage.setItem("user", data.token);
          localStorage.setItem(
            "permissions",
            JSON.stringify(data.detail.permissions)
          );
          this.$api.authentication
            .getProfileInfo()
            .then((res) => {
              this.$bus.$emit("showLoader", false);
              localStorage.setItem("userProfile", JSON.stringify(res.data));

              const preference =
                res && res.data && res.data.user_preferences
                  ? res.data.user_preferences
                  : {};

              localStorage.setItem(
                "userPreferences",
                JSON.stringify(preference)
              );
              setTimeout(() => {
                if (
                  this.$route &&
                  this.$route.query &&
                  this.$route.query.redirectUrl
                ) {
                  this.$router.push(this.$route.query.redirectUrl);
                } else {
                  this.$router.push(`/app/${data.user_type.toLowerCase()}`);
                }
              }, 500);
            })
            .catch((err) => {
              this.$bus.$emit("showLoader", false);
            });

          this.btnLoading = false;
        })
        .catch((err) => {
          this.btnLoading = false;
          if (err && err.data && err.data.non_field_errors) {
            this.$bus.$emit("showToastMessage", {
              message: err.data.non_field_errors[0],
              color: "error",
              hasActions: true,
            });
          } else {
            this.$bus.$emit("showToastMessage", {
              message: "Unable to log in with provided credentials.",
              color: "error",
            });
          }
        });
    },
    forgotPassword() {
      this.$router.push("/app/forgot-password");
    },
    forgotUsername() {
      this.$router.push("/app/forgot-username");
    },
    signUp() {
      this.$router.push("/app/signup");
    },
  },
};
</script>

<style scoped>
.Screen-Height {
  height: 100vh;
}
.Brand-Logo {
  width: 250px;
}
</style>
