<template>
  <ListComponent title="Users" :totalCount="totalItems">
    <template #list>
      <v-row class="ma-0">
        <v-col cols="4" class="px-0 pt-0 pb-2 d-flex align-center">
          <v-text-field
            label="Search"
            outlined
            dense
            append-icon="mdi-magnify"
            clearable
            hide-details="auto"
            v-model="filters.search"
            @input="searchUsers"
            class="mr-2"
          ></v-text-field>

          <v-tooltip top>
            <template #activator="{ on, attrs }">
              <v-btn
                depressed
                icon
                v-on="on"
                v-bind="attrs"
                @click="showFilterDialog = true"
                class="mx-2"
              >
                <v-badge
                  dot
                  color="primary"
                  :value="Object.keys(filters).length"
                >
                  <v-icon>mdi-filter</v-icon>
                </v-badge>
              </v-btn>
            </template>
            <span v-if="Object.keys(filters).length == 0">Filters</span>

            <v-row v-else no-gutters>
              <v-col cols="12" class="font-weight-bold">Applied Filters</v-col>
              <v-col cols="12">
                <span
                  v-for="(value, key) in filters"
                  :key="key"
                  class="text-capitalize"
                  >{{ key.replace("_", " ") }}
                  <br />
                </span>
              </v-col>
            </v-row>
          </v-tooltip>

          <Columns
            :headers="headersForCurrentTab"
            :gridColInstance="gridColumnApi"
          />
        </v-col>

        <v-col cols="4" class="px-0 pt-0 pb-2">
          <v-tabs height="35" v-model="tab" centered>
            <v-tabs-slider></v-tabs-slider>
            <v-tab>Admin
              <v-badge
                v-if="totalUserStatistics.admin_count"
                top
                :content="totalUserStatistics.admin_count"
                overlap
                class="mb-4 ml-3"
              >
              </v-badge>
            </v-tab>
            <v-tab>Customer
              <v-badge
                v-if="totalUserStatistics.customer_count"
                top
                :content="totalUserStatistics.customer_count"
                overlap
                class="mb-4 ml-3"
              >
              </v-badge>
            </v-tab>
            <v-tab>Transporter
              <v-badge
                v-if="totalUserStatistics.transporter_count"
                top
                :content="totalUserStatistics.transporter_count"
                overlap
                class="mb-4 ml-3"
              >
              </v-badge>
            </v-tab>
            <v-tab>New Request</v-tab>
          </v-tabs>
        </v-col>

        <v-col
          v-if="totalItems > 0"
          cols="4"
          class="px-0 pt-0 pb-2 d-flex justify-end"
        >
          <Pagination
            :pageNo="pageNo"
            :totalItems="totalItems"
            :pageSize="itemsPerPage"
            @itemsPerPageChange="itemsPerPageChanged"
            @prevPage="prevPage"
            @nextPage="nextPage"
          />
        </v-col>
      </v-row>

      <v-col cols="12" class="pa-0">
        <AgGridVue
          style="width: 100%; height: calc(100vh - 172px)"
          class="ag-theme-alpine"
          id="myGrid"
          :columnDefs="headersForCurrentTab"
          :context="context"
          @grid-ready="onGridReady"
          @columnMoved="resizeGrid"
          @columnVisible="resizeGrid"
          :defaultColDef="defaultColDef"
          :grid-options="gridOptions"
          :suppressDragLeaveHidesColumns="true"
          :rowData="allUserList"
        ></AgGridVue>
      </v-col>

      <DetailsParent :resizable="true" v-model="detailsDrawer">
        <template #details>
          <UserDetails :userId="selectedUserId" />
        </template>
      </DetailsParent>
    </template>

    <template #dialogs>
      <UserManagementFilters
        v-model="showFilterDialog"
        :bindingObject="filters"
        @resetFilters="resetFilters"
        @applyFilters="itemsPerPageChanged(20)"
      />
      <NewUserForm
        v-model="showNewUserForm"
        module="customer"
        :newUserId="newUserId"
        @closeUserForm="closeCustomerUserForm"
      />
    </template>

    <template #actions>
      <v-btn
        v-if="permissions && permissions.add && tab == 0"
        color="primary"
        depressed
        @click="addUser"
      >
        <v-icon>mdi-plus</v-icon>
        <span>Add User</span>
      </v-btn>
    </template>
  </ListComponent>
</template>

<script>
import Columns from "@/components/General/Columns.vue";
import ListComponent from "@/components/General/ListComponent.vue";
import { AgGridVue } from "ag-grid-vue";
import Pagination from "@/components/General/Pagination.vue";
import AdminUserButton from "@/components/AgGridComponents/AdminUserButton.vue";
import AdminNewUserButton from "@/components/AgGridComponents/AdminNewUserButton.vue";
import NewUserForm from "@/components/ModuleBased/dialogForms/NewUserForm.vue";
import CustomHeader from "@/components/AgGridComponents/General/CustomHeader.vue";
import AdminUserFilters from "@/components/ModuleBased/dialogs/filters/AdminUserFilters.vue";
import DetailsParent from "@/components/General/DetailsParent.vue";
import UserDetails from "@/components/ModuleBased/details/UserDetails.vue";
import {
  isAuthenticated,
  hasRouteAccess,
  getPermissions,
} from "@/utils/functions";

import UserManagementFilters from "@/components/ModuleBased/dialogs/filters/UserManagementFilters.vue";

export default {
  components: {
    ListComponent,
    Columns,
    AgGridVue,
    Pagination,
    AdminUserButton,
    agColumnHeader: CustomHeader,
    AdminUserFilters,
    DetailsParent,
    UserDetails,
    AdminNewUserButton,
    NewUserForm,
    UserManagementFilters,
  },
  watch: {
    tab() {
      if (this.tab == 3) {
        this.getNewUserList();
      } else {
        this.getUserList();
      }
    },
  },
  data() {
    return {
      sorting: {},
      showFilterDialog: false,
      newUserDetails: {},
      showNewUserForm: false,
      tabHeaders: [
        [
          // ... headers for the 'admin' tab ...
          {
            headerName: "Username",
            field: "username",
            tooltipField: "username",
            sortable: true,
            minWidth: 120,
          },
          {
            headerName: "First Name",
            field: "first_name",
            tooltipField: "first_name",
            sortable: true,
            minWidth: 120,
          },
          {
            headerName: "Last Name",
            field: "last_name",
            tooltipField: "last_name",
            sortable: true,
            minWidth: 120,
          },
          { headerName: "Primary", field: "is_primary", minWidth: 100 },
          {
            headerName: "Role",
            field: "role_name",
            tooltipField: "role_name",
            sortable: true,
            minWidth: 120,
          },
          {
            headerName: "Designation",
            field: "designation",
            tooltipField: "designation",
            sortable: true,
            minWidth: 120,
          },
          {
            headerName: "Email",
            field: "email",
            tooltipField: "email",
            sortable: true,
            minWidth: 140,
          },
          {
            headerName: "Contact Number",
            field: "full_contact_number",
            tooltipField: "contact_number",
            sortable: true,
            minWidth: 140,
          },
          {
            headerName: "Actions",
            field: "actions",
            cellRendererFramework: "AdminUserButton",
            pinned: "right",
            minWidth: 110,
          },
        ],

        [
          // ... headers for the 'newRequest' tab ...

          {
            headerName: "First Name",
            field: "first_name",
            tooltipField: "first_name",
            sortable: true,
            minWidth: 300,
          },
          {
            headerName: "Last Name",
            field: "last_name",
            tooltipField: "last_name",
            sortable: true,
            minWidth: 300,
          },

          {
            headerName: "Designation",
            field: "designation",
            tooltipField: "designation",
            sortable: true,
            minWidth: 300,
          },
          {
            headerName: "Email",
            field: "email",
            tooltipField: "email",
            sortable: true,
            minWidth: 300,
          },
          {
            headerName: "Contact Number",
            field: "full_contact_number",
            tooltipField: "contact_number",
            sortable: true,
            minWidth: 200,
          },
          {
            headerName: "Is Active",
            field: "is_active",
            tooltipField: "is_active",
            sortable: true,
            minWidth: 200,
          },
          {
            headerName: "Actions",
            field: "actions",
            cellRendererFramework: "AdminNewUserButton",
            pinned: "right",
            minWidth: 200,
          },
        ],
      ],

      allUserList: [],
      // pagination vars
      itemsPerPage: 20,
      pageNo: 1,
      totalItems: 0,

      // Filter vars
      filters: {},

      tab: null,
      permissions: null,
      searchUserStatisticTimeout: null,
      totalUserStatistics: [],

      // ag-grid vars
      gridApi: null,
      gridColumnApi: null,
      defaultColDef: {
        resizable: true,
      },
      gridOptions: {
        onGridSizeChanged: () => {
          this.resizeGrid();
        },
        suppressRowClickSelection: true,
        suppressDragLeaveHidesColumns: true,
        enableCellTextSelection: true,
        tooltipShowDelay: 0,
        rowHeight: 35,
      },

      selectedUserId: null,
      detailsDrawer: false,
      showNewUserForm: false,
      newUserId: null,
    };
  },
  computed: {
    headersForCurrentTab() {
      if (this.tab == 0 || this.tab == 1 || this.tab == 2) {
        return this.tabHeaders[0] || [];
      } else {
        console.log(this.tabHeaders[1], "this.tabHeaders[3]");
        return this.tabHeaders[1] || [];
      }
    },
    context() {
      return { parentComponent: this };
    },
    currentProfile() {
      let obj = JSON.parse(localStorage.getItem("userProfile"));
      if (!obj) {
        return null;
      } else {
        return obj;
      }
    },
    offset() {
      return this.itemsPerPage * (this.pageNo - 1);
    },
  },
  methods: {
    getUserAdminCount(params) {
      if (this.searchUserStatisticTimeout) {
        clearTimeout(this.searchUserStatisticTimeout);
      }
      this.searchUserStatisticTimeout = setTimeout(() => {
        params = { ...this.filters };
        delete params.limit;
        delete params.offset;
        delete params.statuses;
        this.$api.user
          .getUserStatistics(params)
          .then((res) => {
            this.totalUserStatistics = res.data;
          })
          .catch((err) => {
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message: "Couldn't  User!",
              color: "error",
            });
          });
      }, 1000);
    },
    closeCustomerUserForm() {
      this.showNewUserForm = false;
    },
    newUser(id) {
      this.newUserId = id;
      this.showNewUserForm = true;
    },
    resizeGrid() {
      if (this.gridOptions && this.gridOptions.api) {
        setTimeout(() => {
          this.gridOptions.api.sizeColumnsToFit();
        }, 100);
      }
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.resizeGrid();
    },
    resetFilters() {
      this.filters = {};
      this.itemsPerPageChanged(20);
    },
    searchUsers() {
      this.pageNo = 1;
      this.getUserList();
      this.getUserAdminCount();
      this.filters = {};
    },
    itemsPerPageChanged(e) {
      this.pageNo = 1;
      this.itemsPerPage = e;
      this.getUserList();
    },
    prevPage() {
      this.pageNo--;
      this.getUserList();
    },
    nextPage() {
      this.pageNo++;
      this.getUserList();
    },
    applyGridSort(key, type) {
      if (this.filters && !this.filters.ordering) {
        this.filters.ordering = [];
      }

      if (type == null) {
        delete this.sorting[key];
        this.filters.ordering.splice(
          this.filters.ordering.indexOf(`-${key}`),
          1
        );
      } else if (type == "asc") {
        this.filters.ordering.push(key);
      } else if (type == "desc") {
        this.filters.ordering.splice(
          this.filters.ordering.indexOf(key),
          1,
          `-${key}`
        );
      }
      if (this.filters.ordering.length == 0) {
        delete this.filters.ordering;
      }
      this.itemsPerPageChanged(20);
    },
    getNewUserList(params = {}) {
      this.$bus.$emit("showLoader", true);

      params = {
        offset: this.offset,
        limit: this.itemsPerPage,
        is_active: false,
        ...params,
        ...this.filters,
      };

      if ("ordering" in params && params.ordering.length > 0) {
        params = { ...params, ordering: params.ordering.join(",") };
      }
      this.$api.unauthorisedApi
        .getNewUserList(params)
        .then((res) => {
          this.allUserList = res.data;

          this.totalItems = res.count;
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },

    getUserList(params = {}) {
      this.$bus.$emit("showLoader", true);
      let userType = null;
      if (this.tab == 0) {
        userType = "Admin";
      } else if (this.tab == 1) {
        userType = "Customer";
      } else if (this.tab == 2) {
        userType = "Transporter";
      }
      params = {
        offset: this.offset,
        limit: this.itemsPerPage,
        user_type: userType,

        ...params,
        ...this.filters,
      };

      if ("ordering" in params && params.ordering.length > 0) {
        params = { ...params, ordering: params.ordering.join(",") };
      }
      this.$api.user
        .getUserList(params)
        .then((res) => {
          this.allUserList = res.data;

          this.totalItems = res.count;
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    addUser() {
      this.$router.push("/app/admin/user-management/add");
    },
    editUser(id) {
      this.$router.push(`/app/admin/user-management/edit/${id}`);
    },

    viewDetails(id) {
      this.selectedUserId = id;
      this.detailsDrawer = true;
    },
  },
  mounted() {
    this.getUserAdminCount();
  },
  beforeMount() {
    if (!isAuthenticated()) {
      this.$router.push("/");
    } else if (!hasRouteAccess(this.$route)) {
      this.$router.push(`/${localStorage.getItem("user_type").toLowerCase()}`);
    } else {
      const permissions = getPermissions("user");
      if (!permissions || !permissions.view) {
        this.$bus.$emit("showToastMessage", {
          message: "You don't have access to this page",
          color: "error",
        });
        this.$router.push(
          `/app/${
            localStorage.getItem("user_type")
              ? localStorage.getItem("user_type").toLowerCase()
              : "error"
          }`
        );
      } else {
        this.permissions = { ...permissions };
        this.getUserList();
      }
    }
  },
};
</script>
