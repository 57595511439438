import { checkList } from "@/utils/constants";
import { handleError, handleResponse } from "@/utils/functions";

export default (axios) => ({
  getCheckListList(params = {}) {
    return new Promise((resolve, reject) => {
      axios
        .get(checkList.checkListBase, {
          params: params,
        })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getCheckListObject(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${checkList.checkListBase}${id}/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getCheckListOptions() {
    return new Promise((resolve, reject) => {
      axios
        .options(`${checkList.checkListBase}`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  addCheckList(data) {
    return new Promise((resolve, reject) => {
      axios
        .post(checkList.checkListBase, data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  updateCheckList(payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${checkList.checkListBase}${payload.id}/`, payload.data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  partiallyUpdateCheckList(payload) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`${checkList.checkListBase}${payload.id}/`, payload.data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  activatedeactivateChecklist(payload) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`${checkList.checkListBase}${payload.id}/deactivate_checklist/`, payload)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  }
});
