var render = function render(){var _vm=this,_c=_vm._self._c;return _c('FilterParent',{attrs:{"module":"Vehicle Type"},scopedSlots:_vm._u([{key:"filterContent",fn:function(){return [_c('v-row',{staticClass:"ma-0 pb-3"},[_c('v-col',{staticClass:"Form-Columns",attrs:{"cols":"12","lg":"6"}},[_c('v-switch',{staticClass:"mt-1",attrs:{"hide-details":"auto","label":`Temperature Controlled : ${
            _vm.filterObject.temperature_controlled ? 'Yes' : 'No'
          }`},model:{value:(_vm.filterObject.temperature_controlled),callback:function ($$v) {_vm.$set(_vm.filterObject, "temperature_controlled", $$v)},expression:"filterObject.temperature_controlled"}})],1),_c('v-col',{staticClass:"Form-Columns",attrs:{"cols":"12","lg":"6"}},[_c('v-switch',{staticClass:"mt-1",attrs:{"hide-details":"auto","label":`Attach Trailer : ${
            _vm.filterObject.attach_trailer ? 'Yes' : 'No'
          }`},model:{value:(_vm.filterObject.attach_trailer),callback:function ($$v) {_vm.$set(_vm.filterObject, "attach_trailer", $$v)},expression:"filterObject.attach_trailer"}})],1)],1)]},proxy:true},{key:"filterAction",fn:function(){return [_c('v-btn',{attrs:{"color":"secondary","depressed":""},on:{"click":function($event){_vm.$emit('resetFilters');
        _vm.showAdminVehicleTypeFilters = false;}}},[_vm._v(" Reset ")]),_c('v-btn',{staticClass:"ml-4",attrs:{"color":"primary","depressed":""},on:{"click":function($event){_vm.$emit('applyFilters');
        _vm.showAdminVehicleTypeFilters = false;}}},[_vm._v(" Apply ")])]},proxy:true}]),model:{value:(_vm.showAdminVehicleTypeFilters),callback:function ($$v) {_vm.showAdminVehicleTypeFilters=$$v},expression:"showAdminVehicleTypeFilters"}})
}
var staticRenderFns = []

export { render, staticRenderFns }