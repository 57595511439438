<template>
  <v-dialog width="500" v-model="showLegMovementDateForm">
    <v-card>
      <v-card-title class="secondary white--text">
        <span> Update Movement Date </span>
        <v-menu open-on-hover right offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-on="on" v-bind="attrs" class="mx-4" color="white">
              mdi-information
            </v-icon>
          </template>
          <v-card>
            <v-card-text class="mt-3">
              On changing the Leg Movement date
              <b>then Movement Date & Time of DA </b>also change if DA has only
              <b>ONE</b> Legs.
            </v-card-text>
          </v-card>
        </v-menu>

        <v-spacer></v-spacer>
        <v-btn icon @click="showLegMovementDateForm = false">
          <v-icon class="white--text">mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="pa-2">
        <v-form v-if="showLegMovementDateForm" v-model="isValid">
          <v-row class="ma-0">
            <v-col cols="12" class="Form-Columns">
              <DateField
                ref="movementDate"
                :inputProps="{
                  dense: true,
                  label: 'Movement Date *',
                  rules: [(val) => !!val || 'Date is Required!'],
                  'hide-details': 'auto',
                  'error-messages': formErrors.movement_date,
                }"
                required
                v-model="formDetails.movement_date"
                @input="delete formErrors.movement_date"
              />
            </v-col>
            <v-col cols="12" class="Form-Columns">
              <InputField
                type="time"
                label="Movement Time *"
                required
                :rules="[(val) => !!val || 'Movement Time is Required!']"
                hide-details="auto"
                v-model="formDetails.movement_time"
                :error-messages="formErrors.movement_time"
                @input="delete formErrors.movement_time"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="justify-end">
        <v-btn
          color="primary"
          depressed
          :disabled="!isValid"
          @click="submitForm"
        >
          Submit
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import DateField from "@/components/FormBaseComponents/DateField.vue";
import InputField from "@/components/FormBaseComponents/InputField.vue";

export default {
  components: {
    DateField,
    InputField,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    legs: {
      type: [Array, null],
    },
    module: {
      type: String,
      default: null,
    },
  },
  computed: {
    showLegMovementDateForm: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  data() {
    return {
      module_type: null,
      isValid: true,
      formErrors: {},
      formDetails: {},
    };
  },
  watch: {
    value(val) {
      if (val) {
        this.module_type = this.module;
        if (this.module_type && this.module_type == "delivery_advice") {
          setTimeout(() => {
            this.formDetails = {
              movement_date: this.legs[0].movement_date,
              movement_time: this.legs[0].movement_time,
            };

            const field = this.$refs.movementDate;
          }, 20);
        } else {
          setTimeout(() => {
            if (this.legs && this.legs.length == 1) {
              this.formDetails = {
                movement_date: this.legs[0].da_movement_date,
                movement_time: this.legs[0].da_movement_time,
              };
              const field = this.$refs.movementDate;
              if (field) {
                field.setDefaultDateTime();
              }
            }
          }, 20);
        }
      } else {
        this.formDetails = {};
      }
    },
  },
  methods: {
    submitForm() {
      let payload = {
        ...this.formDetails,
        legs: [...new Set(this.legs.map((leg) => leg.id))],
        module_name: this.module_type,
      };

      this.$bus.$emit("showLoader", true);
      this.$api.trip
        .bulkUpdateLegMovementDate(payload)
        .then((res) => {
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message: "Movement date and time updated successfully!",
            color: "success",
          });
          this.showLegMovementDateForm = false;
          this.$emit("refreshList");
        })
        .catch((err) => {
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message: `${
              err.data && err.data.non_field_errors
                ? err.data.non_field_errors.join()
                : "Couldn't update movement date and time!"
            }`,
            color: "error",
          });
          this.formErrors = err.data;
        });
    },
  },
};
</script>
