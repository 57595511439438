import axios from "axios";

import router from "@/router/index";

let config = {
    baseURL: process.env.VUE_APP_BASE_URL || ""
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
    function (req) {
        return req
    },
    function (error) {
        // Do something with request error
        return Promise.reject(error);
    }
);

// Add a response interceptor
_axios.interceptors.response.use(
    function (response) {
        // Do something with response data
        return response;
    },
    function (error) {
        // Do something with response error
        return Promise.reject(error);
    }
);

export default _axios;
