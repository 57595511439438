<template>
  <v-row class="justify-center align-center Login-Section">
    <v-col cols="12" sm="8" md="6" lg="4">
      <v-form
        @submit.prevent="submitForm"
        ref="forgotPasswordForm"
        v-model="isValid"
      >
        <v-card elevation="10" class="pa-2">
          <v-card-title class="d-flex justify-center">
            <img
              class="Brand-Logo mb-10"
              alt="Brand Logo"
              src="@/assets/images/brand-logo.png"
            />
          </v-card-title>

          <v-card-text>
            <v-row class="justify-center">
              <v-col cols="12">
                <InputField
                  hide-details="auto"
                  label="Email Id *"
                  autocomplete="off"
                  required
                  :dense="false"
                  :rules="[(val) => !!val || 'Email Id is required']"
                  v-model="userDetails.email_id"
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-row>
              <v-col cols="12" class="d-flex justify-center">
                <v-btn
                  type="submit"
                  color="primary"
                  depressed
                  :disabled="
                    !isValid
                  "
                  :loading="btnLoading"
                  large
                >
                  Submit
                </v-btn>
              </v-col>
              <v-col cols="12" class="d-flex justify-center">
                <v-btn
                  @click="goToLogin"
                  type="button"
                  text
                  color="primary"
                  depressed
                  large
                >
                  Go To Login
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
import InputField from "@/components/FormBaseComponents/InputField.vue";
export default {
  components: { InputField },
  layout: "login",
  data() {
    return {
      userDetails: {},
      btnLoading: false,
      password: "Password",
      passwordEye: false,
      isValid: true,
      timer: 30,
      interval: null,
      disableOtpBtn: false,
    };
  },
  methods: {
    goToLogin() {
      this.$router.push("/app/login");
    },
    submitForm() {
      this.btnLoading = true;
      console.log(this.userDetails);
      this.$api.authentication
        .getUsername(this.userDetails)
        .then(({ data }) => {
          this.$router.push("/app/login");
          this.btnLoading = false;
        })
        .catch((err) => {
          console.log(err);
          this.btnLoading = false;
          this.$bus.$emit("showToastMessage", {
            message: `${
              err.data && err.data.non_field_errors
                ? err.data.non_field_errors.join()
                : "Email Id does not exist"
            }`,
            color: "error",
            hasActions: true,
          });
        });
    },
   
  
  },
};
</script>

<style scoped>
.Login-Section {
  height: 100vh;
}

.Login-Section .Brand-Logo {
  width: 250px;
}
</style>
