<template>
  <div>
    <v-tooltip top>
      <template #activator="{ on, attrs }">
        <v-icon
          class="ml-1 mr-2"
          color="primary"
          v-on="on"
          v-bind="attrs"
          @click="viewItem"
        >
          mdi-eye
        </v-icon>
      </template>
      <span>View</span>
    </v-tooltip>

  </div>
</template>

<script>
export default {
  methods: {
    viewItem() {
      this.params.context.parentComponent.viewLeaseDriverBonusList(this.params.data.id);
    },
  },
};
</script>

<style></style>
