<template>
  <ListComponent title="Sub Hire Job" :totalCount="totalItems">
    <template #list>
      <v-row class="ma-0">
        <v-col cols="4" class="px-0 pt-0 pb-2 d-flex align-center">
          <v-text-field
            label="Search On Enter"
            outlined
            dense
            append-icon="mdi-magnify"
            clearable
            hide-details="auto"
            @click:clear="onEnter()"
            v-on:keyup.enter="onEnter"
            v-model="filters.search"
          />
          <Columns :headers="headers" :gridColInstance="gridColumnApi" />
        </v-col>

        <v-col
          v-if="totalItems > 0"
          cols="8"
          class="px-0 pt-0 pb-2 d-flex justify-end"
        >
          <Pagination
            :pageNo="pageNo"
            :totalItems="totalItems"
            :pageSize="itemsPerPage"
            @itemsPerPageChange="itemsPerPageChanged"
            @prevPage="prevPage"
            @nextPage="nextPage"
          />
        </v-col>

        <v-col cols="12" class="pa-0">
          <AgGridVue
            style="width: 100%; height: calc(100vh - 172px)"
            class="ag-theme-alpine"
            id="myGrid"
            :columnDefs="headers"
            :context="context"
            @grid-ready="onGridReady"
            @columnMoved="resizeGrid"
            @columnVisible="resizeGrid"
            :defaultColDef="defaultColDef"
            :grid-options="gridOptions"
            :suppressDragLeaveHidesColumns="true"
            :rowData="subHireList"
          ></AgGridVue>
        </v-col>
        <TransporterAssetAllocateForm
          v-model="showBulkOperationsDialog"
          :subhireId="subHireJobId"
          @refreshList="getSubHireList()"
        />
      </v-row>
    </template>

    <template #actions> </template>
  </ListComponent>
</template>

<script>
import ListComponent from "@/components/General/ListComponent.vue";
import Columns from "@/components/General/Columns.vue";
import { AgGridVue } from "ag-grid-vue";
import Pagination from "@/components/General/Pagination.vue";
import TransporterSubHireButton from "@/components/AgGridComponents/TransporterSubHireButton.vue";
import CustomHeader from "@/components/AgGridComponents/General/CustomHeader.vue";
import TransporterAssetAllocateForm from "@/components/ModuleBased/dialogForms/TransporterAssetAllocateForm.vue";

import {
  isAuthenticated,
  hasRouteAccess,
  getPermissions,
} from "@/utils/functions";

export default {
  components: {
    ListComponent,
    Columns,
    AgGridVue,
    Pagination,
    TransporterSubHireButton,
    agColumnHeader: CustomHeader,
    TransporterAssetAllocateForm,
  },

  data() {
    return {
      sorting: {},
      showBulkOperationsDialog: false,
      subHireJobId: null,

      headers: [
        {
          headerName: "Bid Request ID",
          field: "bid_request_id",
          sortable: true,
          minWidth: 120,
        },
         {
          headerName: "Lrfq Request ID",
          field: "lrfq_request_id",
          sortable: true,
          minWidth: 120,
        },
        {
          headerName: "Sub Hire ID",
          field: "sub_hire_id",
          sortable: true,
          minWidth: 120,
        },
        {
          headerName: "Creation Date",
          field: "created",
          tooltipField: "created",
          sortable: true,
          minWidth: 120,
          valueFormatter: (params) => {
            return this.$globals.formatDate(params.value);
          },
        },

        {
          headerName: "Route",
          field: "lane_title",
          tooltipField: "lane_title",
          sortable: true,
          minWidth: 100,
        },

        {
          headerName: `Cost (${this.$globals.currency})`,
          field: "total_cost",
          tooltipField: "total_cost",
          sortable: true,
          minWidth: 100,
        },
        {
          headerName: `Tax Amount(${this.$globals.currency})`,
          field: "total_taxable_amount",
          sortable: true,
          minWidth: 120,
        },

        {
          headerName: "Actions",
          field: "actions",
          cellRendererFramework: "TransporterSubHireButton",
          pinned: "right",
          minWidth: 200,
        },
      ],

      subHireList: [],
      subhirejobid: null,

      permissions: null,

      // pagination vars
      pageNo: 1,
      itemsPerPage: 20,
      totalItems: 0,

      // Filter vars
      filters: {},
      showFilterDialog: false,
      defaultLegId: null,

      // ag-grid vars
      gridApi: null,
      gridColumnApi: null,
      defaultColDef: {
        resizable: true,
      },
      gridOptions: {
        onGridSizeChanged: () => {
          this.resizeGrid();
        },
        suppressRowClickSelection: true,
        suppressDragLeaveHidesColumns: true,
        enableCellTextSelection: true,
        tooltipShowDelay: 0,
        rowHeight: 35,
      },
    };
  },

  computed: {
    context() {
      return { parentComponent: this };
    },
    currentProfile() {
      let obj = JSON.parse(localStorage.getItem("userProfile"));
      if (!obj) {
        return null;
      } else {
        return obj;
      }
    },
    offset() {
      return this.itemsPerPage * (this.pageNo - 1);
    },
  },

  methods: {
    allocateAsset(id) {
        
      this.showBulkOperationsDialog = true;
      this.subHireJobId = id
      
    },
    resizeGrid() {
      if (this.gridOptions && this.gridOptions.api) {
        setTimeout(() => {
          this.gridOptions.api.sizeColumnsToFit();
        }, 100);
      }
    },
    resetFilters() {
      this.filters = {};
      this.itemsPerPageChanged(20);
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.resizeGrid();
    },
    onEnter() {
      this.pageNo = 1;
      setTimeout(() => {
        this.getSubHireList();
      }, 10);
    },
    itemsPerPageChanged(e) {
      this.pageNo = 1;
      this.itemsPerPage = e;
      this.getSubHireList();
    },
    prevPage() {
      this.pageNo--;
      this.getSubHireList();
    },
    nextPage() {
      this.pageNo++;
      this.getSubHireList();
    },
    applyGridSort(key, type) {
      if (this.filters && !this.filters.ordering) {
        this.filters.ordering = [];
      }

      if (type == null) {
        delete this.sorting[key];
        this.filters.ordering.splice(
          this.filters.ordering.indexOf(`-${key}`),
          1
        );
      } else if (type == "asc") {
        this.filters.ordering.push(key);
      } else if (type == "desc") {
        this.filters.ordering.splice(
          this.filters.ordering.indexOf(key),
          1,
          `-${key}`
        );
      }
      if (this.filters.ordering.length == 0) {
        delete this.filters.ordering;
      }
      this.itemsPerPageChanged(20);
    },

    getSubHireList(params = {}) {
      this.$bus.$emit("showLoader", true);
      params = {
        transporter: this.currentProfile.company,
        offset: this.offset,
        limit: this.itemsPerPage,
        ...params,
        ...this.filters,
      };
      if ("ordering" in params && params.ordering.length > 0) {
        params = { ...params, ordering: params.ordering.join(",") };
      }
      this.$api.subHire
        .getTransporterSubHireList(params)
        .then((res) => {
          this.subHireList = res.data;
          this.totalItems = res.count;
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
  },

  beforeMount() {
    this.getSubHireList();
  },
};
</script>
