<template>
  <div>
    <div v-if="params.data.charge_type != 'Other'">
      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-btn
            depressed
            v-if="
              params.context &&
              params.context.parentComponent &&
              params.context.parentComponent.invoiceDetails &&
              params.context.parentComponent.invoiceDetails.status != 'Accepted'
            "
            icon
            v-on="on"
            v-bind="attrs"
            class="ml-1 mr-2"
            @click="addCharges"
          >
            <v-icon color="primary">mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>Add Charge</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-btn
            v-if="
              params.context &&
              params.context.parentComponent &&
              params.context.parentComponent.invoiceDetails &&
              params.context.parentComponent.invoiceDetails.status != 'Accepted' &&
              params.context.parentComponent.invoiceDetails.total_invoice_das >  1
            "
            depressed
            icon
            v-on="on"
            v-bind="attrs"
            class="ml-2 mr-1"
            @click="removeDa"
          >
            <v-icon color="error">mdi-trash-can</v-icon>
          </v-btn>
        </template>
        <span>Remove DA</span>
      </v-tooltip>
    </div>

    <div v-if="params.data.charge_type == 'Other'">
      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-btn
            v-if="
              params.context &&
              params.context.parentComponent &&
              params.context.parentComponent.invoiceDetails &&
              params.context.parentComponent.invoiceDetails.status != 'Accepted'
            "
            depressed
            icon
            v-on="on"
            v-bind="attrs"
            class="ml-1 mr-2"
            @click="editCharges"
          >
            <v-icon color="primary">mdi-pencil</v-icon>
          </v-btn>
        </template>
        <span>Edit Charge</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-btn
            v-if="
              params.context &&
              params.context.parentComponent &&
              params.context.parentComponent.invoiceDetails &&
              params.context.parentComponent.invoiceDetails.status != 'Accepted'
            "
            depressed
            icon
            v-on="on"
            v-bind="attrs"
            class="ml-2 mr-1"
            @click="deleteCharge"
          >
            <v-icon color="secondary"> mdi-trash-can-outline </v-icon>
          </v-btn>
          <!-- <v-icon
            v-if="params.data.is_additional"
            class="ml-2"
            color="primary"
            v-on="on"
            v-bind="attrs"
            @click="deleteItem"
            >mdi-delete</v-icon
          > -->
        </template>
        <span>Delete Charge</span>
      </v-tooltip>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    removeDa() {
      this.params.context.parentComponent.removeDA(
        this.params.data.delivery_advice
      );
    },
    addCharges() {
      this.params.context.parentComponent.addAdditionalCharges(
        this.params.data
      );
    },
    editCharges() {
      this.params.context.parentComponent.updateAdditionalCharges(
        this.params.data.id
      );
    },
    deleteCharge() {
      this.params.context.parentComponent.deleteAdditionalCharges(
        this.params.data.id
      );
    },

    editItem() {
      if (this.params.data.is_additional) {
        this.params.context.parentComponent.updateAdditionalCharges(
          this.params.data,
          this.params.node
        );
      } else {
        this.params.context.parentComponent.partiallyUpdateAdditionalCharges(
          this.params.data,
          this.params.node
        );
      }
    },
    // deleteItem() {
    //   this.params.context.parentComponent.deleteAdditionalCharges(
    //     this.params.data,
    //     this.params.rowIndex
    //   );
    // },
  },
};
</script>

<style>
</style>