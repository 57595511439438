<template>
  <ListComponent title="UN Classes" :totalCount="totalItems">
    <template #list>
      <v-row class="ma-0">
        <v-col cols="4" class="px-0 pt-0 pb-2">
          <v-text-field
            label="Search"
            outlined
            dense
            append-icon="mdi-magnify"
            clearable
            hide-details="auto"
            v-model="filters.search"
            @input="searchUNClass"
          ></v-text-field>
        </v-col>

        <v-col
          v-if="totalItems > 0"
          cols="8"
          class="px-0 pt-0 pb-2 d-flex justify-end"
        >
          <Pagination
            :pageNo="pageNo"
            :totalItems="totalItems"
            :pageSize="itemsPerPage"
            @itemsPerPageChange="itemsPerPageChanged"
            @prevPage="prevPage"
            @nextPage="nextPage"
          />
        </v-col>

        <v-col cols="12" class="pa-0">
          <AgGridVue
            style="width: 100%; height: calc(100vh - 172px)"
            class="ag-theme-alpine"
            id="myGrid"
            :columnDefs="headers"
            :context="context"
            @grid-ready="onGridReady"
            :defaultColDef="defaultColDef"
            :grid-options="gridOptions"
            :suppressDragLeaveHidesColumns="true"
            :rowData="allRowList"
          ></AgGridVue>
        </v-col>
      </v-row>
    </template>

    <template #dialogs>
      <UNClassForm
        v-if="permissions && (permissions.add || permissions.change)"
        v-model="showUNClassForm"
        :editMode="isEditing"
        :editUNClassID="editUNClassID"
        @refreshList="itemsPerPageChanged(20)"
      />
      <RemarksDialog
        v-model="showRemarksForm"
        fieldKey="remarks"
        :formDetails="unClassRemark"
        :isRequiredRemarks="true"
        @submitRemarks="activateDeactivateUnclassResult"
      />
    </template>

    <template #actions>
      <v-btn
        v-if="permissions && permissions.add"
        color="primary"
        depressed
        @click="addUNClass"
      >
        <v-icon>mdi-plus</v-icon>
        <span>Add UNClass</span>
      </v-btn>
    </template>
  </ListComponent>
</template>

<script>
import ListComponent from "@/components/General/ListComponent.vue";
import { AgGridVue } from "ag-grid-vue";
import Pagination from "@/components/General/Pagination.vue";
import UNClassButton from "@/components/AgGridComponents/UNClassButton.vue";
import CustomHeader from "@/components/AgGridComponents/General/CustomHeader.vue";
import UNClassForm from "@/components/ModuleBased/dialogForms/UNClassForm.vue";
import RemarksDialog from "@/components/ModuleBased/dialogs/RemarksDialog.vue";

import {
  isAuthenticated,
  hasRouteAccess,
  getPermissions,
} from "@/utils/functions";

export default {
  components: {
    ListComponent,
    AgGridVue,
    Pagination,
    UNClassButton,
    agColumnHeader: CustomHeader,
    UNClassForm,
    RemarksDialog,
  },
  data() {
    return {
      sorting: {},
      headers: [
        {
          headerName: "UN No.",
          field: "un_no",
          sortable: true,
        },
        {
          headerName: "UN Class",
          field: "un_class",
          sortable: true,
        },
        {
          headerName: "UN Description",
          field: "un_description",
        },

        {
          headerName: "Actions",
          field: "actions",
          cellRendererFramework: "UNClassButton",
          pinned: "right",
          width: 90,
        },
      ],
      allRowList: [],
      permissions: null,

      // pagination vars
      pageNo: 1,
      itemsPerPage: 20,
      totalItems: 0,
      filters: {},

      // ag-grid vars
      gridApi: null,
      gridColumnApi: null,
      defaultColDef: {
        resizable: true,
      },
      gridOptions: {
        onGridSizeChanged: () => {
          this.gridOptions.api.sizeColumnsToFit();
        },
        suppressRowClickSelection: true,
        suppressDragLeaveHidesColumns: true,
        enableCellTextSelection: true,
        tooltipShowDelay: 0,
        rowHeight: 35,
      },

      //UNClass form
      showUNClassForm: false,
      isEditing: false,
      editUNClassID: null,
      unClassRemark:{},
      unClassStatus: null,
      unClassId: null,
      unClassRemark: {},
      showRemarksForm: false,
    };
  },
  computed: {
    context() {
      return { parentComponent: this };
    },
    currentProfile() {
      if (JSON.parse(localStorage.getItem("userProfile"))) {
        return JSON.parse(localStorage.getItem("userProfile"));
      } else {
        return null;
      }
    },
    offset() {
      return this.itemsPerPage * (this.pageNo - 1);
    },
  },
  methods: {
    applyGridSort(key, type) {
      if (this.filters && !this.filters.ordering) {
        this.filters.ordering = [];
      }

      if (type == null) {
        delete this.sorting[key];
        this.filters.ordering.splice(
          this.filters.ordering.indexOf(`-${key}`),
          1
        );
      } else if (type == "asc") {
        this.filters.ordering.push(key);
      } else if (type == "desc") {
        this.filters.ordering.splice(
          this.filters.ordering.indexOf(key),
          1,
          `-${key}`
        );
      }
      if (this.filters.ordering.length == 0) {
        delete this.filters.ordering;
      }
      this.itemsPerPageChanged(20);
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
    },
    searchUNClass() {
      this.pageNo = 1;
      this.getUNClassList();
    },
    itemsPerPageChanged(e) {
      this.pageNo = 1;
      this.itemsPerPage = e;
      this.getUNClassList();
    },
    prevPage() {
      this.pageNo--;
      this.getUNClassList();
    },
    nextPage() {
      this.pageNo++;
      this.getUNClassList();
    },
    getUNClassList(params = {}) {
      this.$bus.$emit("showLoader", true);
      params = {
        offset: this.offset,
        limit: this.itemsPerPage,
        ...params,
        ...this.filters,
      };
      if ("ordering" in params && params.ordering.length > 0) {
        params = { ...params, ordering: params.ordering.join(",") };
      }
      this.$api.unClass
        .getUNClassList(params)
        .then((res) => {
          this.allRowList = res.data;
          this.totalItems = res.count;
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    activateDeactivateUnclassResult() {
      this.$bus.$emit("showLoader", true);
      let payload = {
        id: this.unClassId,
        unClass_remarks: this.unClassRemark.remarks,
        unClass_status: this.unClassStatus,
      };
      this.$api.unClass
        .activateDeactivateunClass(payload)
        .then((res) => {
          this.getUNClassList();
          this.$bus.$emit("showToastMessage", {
            message: "UNClass Updated successfully!",
            color: "success",
          });
          this.showRemarksForm = false;
          this.$bus.$emit("showLoader", false);
          delete this.unClassRemark.remarks;
        })
        .catch((err) => {
          this.formErrors = err.data;
          console.error(err);
          this.$bus.$emit("showToastMessage", {
            message: `${
              err.data && err.data.non_field_errors
                ? err.data.non_field_errors.join()
                : "Couldn't update unclass status!"
            }`,
            color: "error",
          });
          this.$bus.$emit("showLoader", false);
        });
    },
    unClassActivateDeactivate(id,status){
     this.unClassStatus = status;
     this.unClassId = id;
      if (status == "deactivate") {
        delete this.unClassRemark.remarks;
        this.showRemarksForm = true;
      } else {
        this.activateDeactivateUnclassResult();
    }
    },
    addUNClass() {
      this.editUNClassID = null;
      this.isEditing = false;
      this.showUNClassForm = true;
    },
    editUNClass(id) {
      this.editUNClassID = id;
      this.isEditing = true;
      this.showUNClassForm = true;
    },
  },
  beforeMount() {
    if (!isAuthenticated()) {
      this.$router.push("/");
    } else if (!hasRouteAccess(this.$route)) {
      this.$router.push(`/${localStorage.getItem("user_type").toLowerCase()}`);
    } else {
      const permissions = getPermissions("configuration");
      if (!permissions || !permissions.view) {
        this.$bus.$emit("showToastMessage", {
          message: "You don't have access to this page",
          color: "error",
        });
        this.$router.push(
          `/app/${
            localStorage.getItem("user_type")
              ? localStorage.getItem("user_type").toLowerCase()
              : "error"
          }`
        );
      } else {
        this.permissions = { ...permissions };
        this.getUNClassList();
      }
    }
  },
};
</script>
