<template>
  <v-dialog max-width="60%" v-model="showAdminReportFilter">
    <v-card>
      <v-card-title class="secondary white--text py-2 px-4">
        <span> Reports </span>

        <v-spacer></v-spacer>

        <v-btn
          depressed
          icon
          @click="
            resetForm();
            showAdminReportFilter = false;
          "
        >
          <v-icon class="white--text">mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="pa-2">
        <v-row class="ma-0" justify="center">
          <v-col
            v-for="(report, index) in reportTypes"
            :key="index"
            cols="12"
            sm="6"
            md="4"
            lg="3"
          >
            <!-- <v-item v-slot="{ toggle }"> -->
            <v-card
              :class="{
                'Lighten-Primary': selectedReportCategory == report.value,
              }"
              class="d-flex align-center"
              height="100"
              @click="toggleSelection(report.value)"
            >
              <!-- @click="toggle" -->
              <!-- {{toggle}} -->
              <v-card-text class="text-center">
                <p>{{ report.display_name }}</p>
                <v-icon v-if="report.value == 'Finance Reports'">
                  mdi-finance
                </v-icon>
                <v-icon v-if="report.value == 'Delivery Advice Reports'">
                  mdi-truck-delivery
                </v-icon>
                <v-icon v-if="report.value == 'Vehicle Reports'">
                  mdi-car-pickup
                </v-icon>
                <v-icon v-if="report.value == 'Driver Reports'">
                  mdi-clipboard-account
                </v-icon>
                <v-icon v-if="report.value == 'Driver Bonus Report'">
                  mdi-clipboard-account
                </v-icon>
                <v-icon v-if="report.value == 'Sub Hire Reports'">
                  mdi-clipboard-text
                </v-icon>
                <v-icon v-if="report.value == 'Other Reports'">
                  mdi-clipboard-text
                </v-icon>
              </v-card-text>
            </v-card>
            <!-- </v-item> -->
          </v-col>
        </v-row>
        <v-form v-model="isValid" ref="reportForm">
          <v-row class="ma-0">
            <v-col cols="12" class="Form-Columns">
              <MultiSelect
                label="Select Report *"
                :items="reportList"
                itemText="display_name"
                itemValue="value"
                hide-details="auto"
                :rules="[(val) => !!val || 'Report is Required!']"
                v-model="filtersObject.report_type"
                :error-messages="formErrors.report_type"
                @input="
                  delete formErrors.report_type;
                  getReportDetail();
                "
              >
              </MultiSelect>
            </v-col>
            <v-col cols="12" md="5" class="Form-Columns">
              <DateField
                :inputProps="{
                  dense: true,
                  label: 'Start Date *',
                  'hide-details': 'auto',
                  rules: [(val) => !!val || 'Date is Required!'],
                  'error-messages': formErrors.start_date,
                }"
                required
                :disabled="!filtersObject.report_type"
                :max="today"
                v-model="filtersObject.start_date"
                @input="
                  delete formErrors.start_date;
                  checkDates();
                "
              />
            </v-col>

            <v-col cols="10" md="5" class="Form-Columns">
              <DateField
                :inputProps="{
                  dense: true,
                  label: 'End Date *',
                  'hide-details': 'auto',
                  rules: [(val) => !!val || 'End Date is Required!'],
                  'error-messages': formErrors.end_date,
                }"
                required
                :disabled="!filtersObject.start_date"
                :min="filtersObject.start_date"
                v-model="filtersObject.end_date"
                @input="delete formErrors.end_date"
              />
            </v-col>

            <v-col>
              <v-menu open-on-hover left offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-on="on" v-bind="attrs" class="mx-2">
                    mdi-information
                  </v-icon>
                </template>
                <v-card>
                  <v-card-title
                    class="secondary py-1 text-subtitle-1 white--text font-weight-medium"
                  >
                    Filter Information
                  </v-card-title>
                  <v-card-text
                    class="mt-2"
                    v-if="
                      this.filtersObject.report_type ==
                        'Invoice Da Detail Report' ||
                      this.filtersObject.report_type ==
                        'Customer Invoice Detailed Report' ||
                      this.filtersObject.report_type == 'Invoice List' ||
                      this.filtersObject.report_type ==
                        'Credit Note Detailed Report'
                    "
                  >
                    <table class="Normal-Table">
                      <tbody>
                        <tr>
                          <th>Start Date</th>
                          <th class="px-2">:</th>
                          <td>Invoice Date of Customer Invoice</td>
                        </tr>
                        <tr>
                          <th>End Date</th>
                          <th class="px-2">:</th>
                          <td>Invoice Date of Customer Invoice</td>
                        </tr>
                      </tbody>
                    </table>
                  </v-card-text>
                  <v-card-text
                    class="mt-2"
                    v-if="
                      this.filtersObject.report_type ==
                        'DA Pending To Invoice Report' ||
                      this.filtersObject.report_type ==
                        'Customer Delivery Advice Summary Report' ||
                      this.filtersObject.report_type ==
                        'Delivery Advice Time Stamp Report' ||
                      this.filtersObject.report_type ==
                        'Delivery Advice List Report' ||
                      this.filtersObject.report_type ==
                        'Hazard Delivery Advice Report' ||
                      this.filtersObject.report_type ==
                        'DA Profit Detail Report' ||
                      this.filtersObject.report_type ==
                        'DA Profit Summary Report'
                    "
                  >
                    <table class="Normal-Table">
                      <tbody>
                        <tr>
                          <th>Start Date</th>
                          <th class="px-2">:</th>
                          <td>Movement Date of Delivery Advice</td>
                        </tr>
                        <tr>
                          <th>End Date</th>
                          <th class="px-2">:</th>
                          <td>Movement Date of Delivery Advice</td>
                        </tr>
                      </tbody>
                    </table>
                  </v-card-text>
                  <v-card-text
                    class="mt-2"
                    v-if="
                      this.filtersObject.report_type == 'Payment Receipt Report'
                    "
                  >
                    <table class="Normal-Table">
                      <tbody>
                        <tr>
                          <th>Start Date</th>
                          <th class="px-2">:</th>
                          <td>Movement Date of Payment Receipt</td>
                        </tr>
                        <tr>
                          <th>End Date</th>
                          <th class="px-2">:</th>
                          <td>Movement Date of Payment Receipt</td>
                        </tr>
                      </tbody>
                    </table>
                  </v-card-text>
                  <v-card-text
                    class="mt-2"
                    v-if="
                      this.filtersObject.report_type ==
                        'Delivery Advice Summary Report' ||
                      this.filtersObject.report_type ==
                        'Location Summary Report'
                    "
                  >
                    <table class="Normal-Table">
                      <tbody>
                        <tr>
                          <th>Start Date</th>
                          <th class="px-2">:</th>
                          <td>Creation Date of Customer Invoice</td>
                        </tr>
                        <tr>
                          <th>End Date</th>
                          <th class="px-2">:</th>
                          <td>Creation Date of Customer Invoice</td>
                        </tr>
                      </tbody>
                    </table>
                  </v-card-text>
                  <v-card-text
                    class="mt-2"
                    v-if="
                      this.filtersObject.report_type == 'Vehicle Summary Report'
                    "
                  >
                    <table class="Normal-Table">
                      <tbody>
                        <tr>
                          <th>Start Date</th>
                          <th class="px-2">:</th>
                          <td>Created Date of Trips</td>
                        </tr>
                        <tr>
                          <th>End Date</th>
                          <th class="px-2">:</th>
                          <td>Created Date of Trips</td>
                        </tr>
                      </tbody>
                    </table>
                  </v-card-text>
                  <v-card-text
                    class="mt-2"
                    v-if="
                      this.filtersObject.report_type == 'Vehicle Report' ||
                      this.filtersObject.report_type == 'Driver Report'
                    "
                  >
                    <table class="Normal-Table">
                      <tbody>
                        <tr>
                          <th>Start Date</th>
                          <th class="px-2">:</th>
                          <td>Created Date of Leg</td>
                        </tr>
                        <tr>
                          <th>End Date</th>
                          <th class="px-2">:</th>
                          <td>Created Date of Leg</td>
                        </tr>
                      </tbody>
                    </table>
                  </v-card-text>
                  <v-card-text
                    class="mt-2"
                    v-if="
                      this.filtersObject.report_type == 'Driver Summary Report'
                    "
                  >
                    <table class="Normal-Table">
                      <tbody>
                        <tr>
                          <th>Start Date</th>
                          <th class="px-2">:</th>
                          <td>Created Date of Driver Trips</td>
                        </tr>
                        <tr>
                          <th>End Date</th>
                          <th class="px-2">:</th>
                          <td>Created Date of Driver Trips</td>
                        </tr>
                      </tbody>
                    </table>
                  </v-card-text>
                  <v-card-text
                    class="mt-2"
                    v-if="
                      this.filtersObject.report_type == 'Driver Bonus Report' ||
                      this.filtersObject.report_type ==
                        'Driver Bonus Summary Report' ||
                      this.filtersObject.report_type ==
                        'Driver Bonus Detailed Report' ||
                      this.filtersObject.report_type ==
                        'SAP Driver Bonus Report'
                    "
                  >
                    <table class="Normal-Table">
                      <tbody>
                        <tr>
                          <th>Start Date</th>
                          <th class="px-1">:</th>
                          <td>Completion date of trips</td>
                        </tr>
                        <tr>
                          <th>End Date</th>
                          <th class="px-1">:</th>
                          <td>Completion date of trips</td>
                        </tr>
                      </tbody>
                    </table>
                  </v-card-text>
                  <v-card-text
                    class="mt-2"
                    v-if="
                      this.filtersObject.report_type == 'Trip CheckList Log'
                    "
                  >
                    <table class="Normal-Table">
                      <tbody>
                        <tr>
                          <th>Start Date</th>
                          <th class="px-1">:</th>
                          <td>Trip Date of Trips</td>
                        </tr>
                        <tr>
                          <th>End Date</th>
                          <th class="px-1">:</th>
                          <td>Trip Date of Trips</td>
                        </tr>
                      </tbody>
                    </table>
                  </v-card-text>
                  <v-card-text
                    class="mt-2"
                    v-if="
                      this.filtersObject.report_type ==
                        'Sub Hire Jobs Summary Report' ||
                      this.filtersObject.report_type ==
                        'Sub Hire Jobs Detail Report' ||
                      this.filtersObject.report_type ==
                        'Sub Hire Jobs Completed Report'
                    "
                  >
                    <table class="Normal-Table">
                      <tbody>
                        <tr>
                          <th>Start Date</th>
                          <th class="px-1">:</th>
                          <td>Created Date of Sub-Hire</td>
                        </tr>
                        <tr>
                          <th>End Date</th>
                          <th class="px-1">:</th>
                          <td>Created Date of Sub-Hire</td>
                        </tr>
                      </tbody>
                    </table>
                  </v-card-text>
                  <v-card-text
                    class="mt-2"
                    v-if="
                      this.filtersObject.report_type ==
                      'Defective Checklist Report'
                    "
                  >
                    <table class="Normal-Table">
                      <tbody>
                        <tr>
                          <th>Start Date</th>
                          <th class="px-1">:</th>
                          <td>Statistic Date of Trip Defects</td>
                        </tr>
                        <tr>
                          <th>End Date</th>
                          <th class="px-1">:</th>
                          <td>Statistic Date of Trip Defects</td>
                        </tr>
                      </tbody>
                    </table>
                  </v-card-text>
                </v-card>
              </v-menu>
            </v-col>

            <v-col
              cols="12"
              md="6"
              class="Form-Columns"
              v-if="showInvoiceNumber"
            >
              <InputField
                type="text"
                label="Invoice Number From"
                hide-details="auto"
                v-model="filtersObject.invoice_no_from"
              />
            </v-col>
            <v-col
              cols="12"
              md="6"
              class="Form-Columns"
              v-if="showInvoiceNumber"
            >
              <InputField
                type="text"
                label="Invoice Number To"
                hide-details="auto"
                v-model="filtersObject.invoice_no_to"
              />
            </v-col>

            <v-col cols="12" lg="6" v-if="showJobType" class="Form-Columns">
              <MultiSelect
                label="Job Type "
                :items="allJobType"
                itemText="display_name"
                itemValue="value"
                hide-details="auto"
                v-model="filtersObject.job_type"
              />
            </v-col>
            <v-col
              cols="12"
              lg="6"
              class="Form-Columns"
              v-if="filtersObject.report_type == 'Invoice List'"
            >
              <MultiSelect
                label="Select Invoice Type"
                clearable
                :items="allInvoiceType"
                v-model="filtersObject.invoice_type"
                itemText="company_name"
                itemValue="id"
                hide-details="auto"
              />
            </v-col>

            <v-col
              cols="12"
              lg="6"
              class="Form-Columns"
              v-if="
                filtersObject.report_type == 'Invoice List' ||
                filtersObject.report_type ==
                  'Customer Invoice Detailed Report' ||
                filtersObject.report_type == 'Invoice Da Detail Report' ||
                filtersObject.report_type == 'DA Pending To Invoice Report' ||
                filtersObject.report_type ==
                  'Customer Delivery Advice Summary Report' ||
                filtersObject.report_type == 'Payment Receipt Report'
              "
            >
              <MultiSelect
                label="Select Branch"
                clearable
                :items="allBranchist"
                v-model="filtersObject.branch"
                itemText="branch_name"
                itemValue="id"
                hide-details="auto"
                :returnObject="true"
              />
            </v-col>

            <!-- DA List FILTER -->

            <!-- <v-col
              cols="12"
              lg="6"
              class="Form-Columns-Dense"
              v-if="showContainerType"
            >
              <MultiSelect
                label="Container Type "
                :items="allContainersType"
                itemText="title"
                itemValue="id"
                :returnObject="true"
                hide-details="auto"
                v-model="filtersObject.container_type"
              />
            </v-col> -->
            <v-col
              cols="12"
              lg="6"
              class="Form-Columns-Dense"
              v-if="showStatus"
            >
              <MultiSelect
                label="Status"
                :items="invoicestatusList"
                itemText="status_da"
                itemValue="id"
                hide-details="auto"
                v-model="filtersObject.invoice_da_status"
              />
            </v-col>
            <v-col
              cols="12"
              lg="6"
              class="Form-Columns-Dense"
              v-if="showDaListFields"
            >
              <MultiSelect
                label="Status"
                :items="statusList"
                itemText="title"
                itemValue="id"
                hide-details="auto"
                v-model="filtersObject.da_status"
              />
            </v-col>

            <v-col cols="12" md="6" class="Form-Columns" v-if="showBOE">
              <InputField type="text" label="BOE" v-model="filtersObject.boe" />
            </v-col>

            <v-col cols="6" class="Form-Columns-Dense" v-if="showDaListFields">
              <MultiSelect
                label="Payment Mode"
                :items="paymentTerms"
                hide-details="auto"
                itemText="display_name"
                itemValue="value"
                v-model="filtersObject.payment_term"
                :error-messages="formErrors.payment_term"
              ></MultiSelect>
            </v-col>

            <v-col cols="12" lg="6" v-if="showDrivers" class="Form-Columns">
              <MultiSelect
                label="Select Driver *"
                :items="allDriverList"
                itemText="driver_name"
                itemValue="id"
                :returnObject="true"
                :clearable="true"
                hide-details="auto"
                :rules="[(val) => !!val || 'Driver is Required!']"
                v-model="filtersObject.driver"
              />
            </v-col>
            <v-col
              cols="12"
              lg="6"
              v-if="showDriversNotMandatory"
              class="Form-Columns"
            >
              <MultiSelect
                label="Select Driver "
                :items="allDriverList"
                itemText="driver_name"
                itemValue="id"
                :returnObject="true"
                :clearable="true"
                hide-details="auto"
                v-model="filtersObject.driver"
              />
            </v-col>

            <v-col
              cols="12"
              lg="6"
              class="Form-Columns-Dense"
              v-if="
                showDepot ||
                this.filtersObject.report_type == 'Vehicle Summary Report' ||
                this.filtersObject.report_type == 'Driver Bonus Report' ||
                this.filtersObject.report_type ==
                  'Delivery Advice Summary Report' ||
                this.filtersObject.report_tye ===
                  'Customer Delivery Advice Summary Report' ||
                this.filtersObject.report_type ==
                  'Sub Hire Jobs Summary Report' ||
                this.filtersObject.report_type ==
                  'Sub Hire Jobs Detail Report' ||
                this.filtersObject.report_type ==
                  'Delivery Advice Time Stamp Report' ||
                this.filtersObject.report_type == 'Driver Summary Report' ||
                this.filtersObject.report_type ==
                  'Driver Bonus Detailed Report' ||
                this.filtersObject.report_type == 'SAP Driver Bonus Report' ||
                this.filtersObject.report_type ==
                  'Driver Bonus Summary Report' ||
                this.filtersObject.report_type ==
                  'Sub Hire Jobs Completed Report'
              "
            >
              <MultiSelect
                label="Select Depot"
                :items="allDepotList"
                itemText="depot_name"
                itemValue="id"
                hide-details="auto"
                :returnObject="true"
                v-model="filtersObject.depot"
              ></MultiSelect>
            </v-col>

            <v-col
              cols="12"
              lg="6"
              class="Form-Columns-Dense"
              v-if="showPaymentModes"
            >
              <MultiSelect
                label="Select Payment Mode"
                :items="allpaymentModes"
                itemText="display_name"
                itemValue="value"
                hide-details="auto"
                v-model="filtersObject.payment_mode"
              ></MultiSelect>
            </v-col>

            <v-col cols="12" lg="6" v-if="showCustomers" class="Form-Columns">
              <MultiSelect
                :label="customeLabel"
                :items="allCustomers"
                itemText="company_name"
                itemValue="id"
                :returnObject="true"
                :clearable="true"
                hide-details="auto"
                :rules="customerRules"
                v-model="filtersObject.customer"
                :error-messages="formErrors.customer"
                @input="delete formErrors.customer"
              >
              </MultiSelect>
            </v-col>

            <v-col
              cols="12"
              lg="6"
              v-if="showTransporters"
              class="Form-Columns"
            >
              <MultiSelect
                :label="transporterLabel"
                :items="allTransporters"
                itemText="company_name"
                itemValue="id"
                :returnObject="true"
                :clearable="true"
                hide-details="auto"
                v-model="filtersObject.transporter"
              >
              </MultiSelect>
            </v-col>

            <v-col
              cols="12"
              lg="6"
              class="Form-Columns-Dense"
              v-if="showSubHireStatus"
            >
              <MultiSelect
                label="Status"
                :items="subhireStatusList"
                itemText="title"
                itemValue="id"
                hide-details="auto"
                v-model="filtersObject.sub_hire_status"
              />
            </v-col>

            <v-col
              cols="12"
              md="6"
              class="Form-Columns"
              v-if="
                showSubHireStatus &&
                filtersObject.sub_hire_status &&
                filtersObject.sub_hire_status != 'Pending'
              "
            >
              <DateField
                :inputProps="{
                  dense: true,
                  label: 'Status Start Date',
                  'hide-details': 'auto',
                }"
                :disabled="!filtersObject.report_type"
                :min="filtersObject.start_date"
                :max="filtersObject.end_date"
                v-model="filtersObject.status_start_date"
                @input="
                  delete formErrors.status_start_date;
                  checkDates();
                "
              />
            </v-col>

            <v-col
              cols="12"
              md="6"
              class="Form-Columns"
              v-if="
                showSubHireStatus &&
                filtersObject.sub_hire_status &&
                filtersObject.sub_hire_status != 'Pending'
              "
            >
              <DateField
                :inputProps="{
                  dense: true,
                  label: 'Status End Date',
                  'hide-details': 'auto',
                }"
                :min="filtersObject.status_start_date"
                :max="filtersObject.end_date"
                v-model="filtersObject.status_end_date"
                @input="delete formErrors.status_end_date"
              />
            </v-col>

            <v-col cols="12" v-if="showVehicles" class="Form-Columns">
              <MultiSelect
                :label="`Select Vehicle`"
                :items="allVehicleList"
                itemText="inventory_number"
                itemValue="id"
                :returnObject="true"
                :clearable="true"
                hide-details="auto"
                v-model="filtersObject.vehicle"
              />
            </v-col>
            <v-col
              cols="12"
              lg="4"
              class="Form-Columns-Dense"
              v-if="showLocation"
            >
              <MultiSelect
                hide-details="auto"
                label="Source Location"
                :items="sourceList"
                itemText="location_code"
                itemValue="id"
                :item-disabled="
                  (item) => {
                    return item == filtersObject.mid_location;
                  }
                "
                :returnObject="true"
                v-model="filtersObject.source_location"
                :error-messages="formErrors.source_location"
                @change="delete formErrors.source_location"
              />
            </v-col>
            <v-col
              cols="12"
              lg="4"
              class="Form-Columns-Dense"
              v-if="showLocation"
            >
              <MultiSelect
                hide-details="auto"
                label="Mid Location"
                :items="midList"
                itemText="location_code"
                itemValue="id"
                :returnObject="true"
                :item-disabled="
                  (item) => {
                    return (
                      item == filtersObject.source_location ||
                      item == filtersObject.destination_location
                    );
                  }
                "
                v-model="filtersObject.mid_location"
                :error-messages="formErrors.mid_location"
                @change="delete formErrors.mid_location"
              />
            </v-col>
            <v-col
              cols="12"
              lg="4"
              class="Form-Columns-Dense"
              v-if="showLocation"
            >
              <MultiSelect
                hide-details="auto"
                label="Destination Location"
                :items="destinationList"
                itemText="location_code"
                itemValue="id"
                :returnObject="true"
                :item-disabled="
                  (item) => {
                    return item == filtersObject.mid_location;
                  }
                "
                :clearable="true"
                v-model="filtersObject.destination_location"
                :error-messages="formErrors.destination_location"
                @change="delete formErrors.destination_location"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions class="d-flex justify-end">
        <v-btn color="secondary" depressed @click="resetForm()"> Reset </v-btn>
        <v-btn
          color="primary"
          class="ml-4"
          depressed
          @click="
            submitForm();
            showAdminReportFilter = false;
          "
          :disabled="!isValid"
          >Submit</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import InputField from "@/components/FormBaseComponents/InputField.vue";
import MultiSelect from "@/components/FormBaseComponents/MultiSelect.vue";
import DateField from "@/components/FormBaseComponents/DateField.vue";
import { ReportCategory } from "@/utils/choices";

export default {
  components: {
    MultiSelect,
    DateField,
    InputField,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      filtersObject: {},
      allReports: [],
      allpaymentModes: [],
      allBranchist: [],
      vehicleRules: [],
      // vehicleLabel: "Select Vehicle",
      customerRules: [],
      customeLabel: "Select Customer",
      transporterLabel: "Select Transporter",
      allVehicleList: [],
      allDriverList: [],
      reportTypes: ReportCategory,
      today: new Date().toISOString().slice(0, 16),
      showPaymentModes: false,
      showCustomers: false,
      showTransporters: false,
      showVehicles: false,
      showDrivers: false,
      showDriversNotMandatory: false,
      showJobType: false,
      showLocation: false,
      showDepot: false,
      showLegDate: false,
      showBOE: false,
      showContainerType: false,
      showDaListFields: false,
      showSubHireStatus: false,
      showStatus: false,
      showInvoiceNumber: true,
      allCustomers: [],
      allTransporters: [],
      isValid: true,
      formErrors: {},
      reportList: [],
      financeReportList: [],
      daReportList: [],
      vehicleReportList: [],
      driverReportList: [],
      subHireReportList: [],
      driverBonusReportList: [],
      otherReportList: [],
      selectedReportCategory: null,
      allJobType: [],
      allInvoiceType: ["General", "Bill Of Entry"],
      paymentTerms: ["Cash", "Credit"],
      // Arrays for Select
      sourceList: [],
      midList: [],
      destinationList: [],
      allDepotList: [],
      allContainersType: [],
      statusList: [
        "Created",
        "Scheduled",
        "Active",
        "Completed",
        "Completed",
        "Ready For Invoice",
        "Invoice Generated",
        "Cancelled",
      ],
      subhireStatusList: [
        "Pending",
        "Approved",
        "Invoice Updated",
        "Completed",
      ],
      invoicestatusList: ["Pending", "Accepted"],
    };
  },
  watch: {
    value(val) {
      if (val) {
        this.getReportOptions();
      } else {
        this.resetForm();
      }
    },
  },

  computed: {
    showAdminReportFilter: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    getPaymentReceiptOptions() {
      return this.$api.paymentReceipt
        .getPaymentReceiptOptions()
        .then((res) => {
          this.allpaymentModes = res.data.actions.POST.payment_mode.choices;
          this.showPaymentModes = true;
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    toggleSelection(val) {
      this.resetData();

      if (val == this.selectedReportCategory) {
        this.selectedReportCategory = null;
      } else {
        this.selectedReportCategory = val;
      }

      if (this.selectedReportCategory == "Finance Reports") {
        this.reportList = this.financeReportList;
      }
      if (this.selectedReportCategory == "Delivery Advice Reports") {
        this.reportList = this.daReportList;
      }
      if (this.selectedReportCategory == "Vehicle Reports") {
        this.reportList = this.vehicleReportList;
      }
      if (this.selectedReportCategory == "Driver Reports") {
        this.reportList = this.driverReportList;
      }
      if (this.selectedReportCategory == "Driver Bonus Reports") {
        this.reportList = this.driverBonusReportList;
      }
      if (this.selectedReportCategory == "Sub Hire Reports") {
        this.reportList = this.subHireReportList;
      }
      if (this.selectedReportCategory == "Other Reports") {
        this.reportList = this.otherReportList;
      }
    },

    resetData() {
      this.reportList = [];
      this.showCustomers = false;
      this.showTransporters = false;
      this.showDrivers = false;
      this.showDriversNotMandatory = false;
      this.showVehicles = false;
      this.resetForm();
    },

    getReportDetail() {
      // if (
      //   this.filtersObject &&
      //   this.filtersObject.report_type == "Vehicle Report"
      // ) {
      //   this.vehicleLabel = "Select Vehicle *";
      //   this.vehicleRules = [(val) => !!val || "Vehicle is Required!"];
      // } else {
      //   this.vehicleRules = [];
      //   this.vehicleLabel = "Select Vehicle";
      // }

      if (
        this.filtersObject &&
        [
          "Customer Invoice Detailed Report",
          "DA Pending To Invoice Report",
        ].includes(this.filtersObject.report_type)
      ) {
        this.customeLabel = "Select Customer";
      } else {
        this.customerRules = [];
        this.customeLabel = "Select Customer";
      }
      // for customer
      if (
        this.filtersObject &&
        [
          "Invoice Da Detail Report",
          "Customer Delivery Advice Summary Report",
          "DA Pending To Invoice Report",
          "Customer Invoice Detailed Report",
          "Customer Delivery Advice Summary Report",
          "Invoice List",
          "Delivery Advice List Report",
          "Delivery Advice Summary Report",
          // "DA Cost Report",
          "DA Profit Summary Report",
          "DA Profit Detail Report",
          "Payment Receipt Report",
          "Sub Hire Jobs Detail Report",
          "Sub Hire Jobs Summary Report",
          "Sub Hire Jobs Completed Report",
        ].includes(this.filtersObject.report_type)
      ) {
        if (!this.allCustomers.length) {
          this.getCustomerList();
        }
        if (!this.allDepotList.length) {
          this.getDepotList();
        }

        this.showCustomers = true;
        if (this.filtersObject.report_type == "Payment Receipt Report") {
          if (!this.allpaymentModes.length) {
            this.getPaymentReceiptOptions();
          }
          this.showPaymentModes = true;
        } else {
          this.showPaymentModes = false;
        }
      } else {
        this.showPaymentModes = false;
        this.showCustomers = false;
      }
      // for invoice status
      if (
        this.filtersObject &&
        ["Invoice Da Detail Report"].includes(this.filtersObject.report_type)
      ) {
        if (!this.allCustomers.length) {
          this.getCustomerList();
        }
        this.showStatus = true;
      } else {
        this.showStatus = false;
      }

      //for sub hire status
      if (
        this.filtersObject &&
        ["Sub Hire Jobs Detail Report"].includes(this.filtersObject.report_type)
      ) {
        if (!this.allTransporters.length) {
          this.getTransporterList();
        }
        this.showSubHireStatus = true;
      } else {
        this.showSubHireStatus = false;
      }

      //for customer and transporter
      if (
        this.filtersObject &&
        [
          "Sub Hire Jobs Detail Report",
          "Sub Hire Jobs Summary Report",
          "Sub Hire Jobs Completed Report",
          // "DA Cost Report",
          "DA Profit Summary Report",
          "DA Profit Detail Report",
        ].includes(this.filtersObject.report_type)
      ) {
        if (!this.allTransporters.length) {
          this.getTransporterList();
        }
        if (!this.allDepotList.length) {
          this.getDepotList();
        }
        this.showTransporters = true;
      } else {
        this.showTransporters = false;
      }

      //for invoice list
      if (
        this.filtersObject &&
        ["Invoice List", "Delivery Advice List Report"].includes(
          this.filtersObject.report_type
        )
      ) {
        if (!this.allJobType.length) {
          this.getJobTypeOptions();
        }
        this.showJobType = true;
      } else {
        this.showJobType = false;
      }

      // for driver
      if (
        this.filtersObject &&
        this.filtersObject.report_type == "Driver Report"
      ) {
        if (!this.allDriverList.length) {
          this.getDriverList();
        }
        this.showDrivers = true;
      } else {
        this.showDrivers = false;
      }
      // for not mandatory drivers
      if (
        (this.filtersObject &&
          this.filtersObject.report_type == "Driver Summary Report") ||
        this.filtersObject.report_type == "Driver Bonus Report" ||
        this.filtersObject.report_type == "Driver Bonus Summary Report" ||
        this.filtersObject.report_type == "Driver Bonus Detailed Report"
      ) {
        if (!this.allDriverList.length) {
          this.getDriverList();
        }
        this.showDriversNotMandatory = true;
      } else {
        this.showDriversNotMandatory = false;
      }
      // for invoice list
      if (
        this.filtersObject &&
        ["Invoice List"].includes(this.filtersObject.report_type)
      ) {
        this.showInvoiceNumber = true;
      } else {
        this.showInvoiceNumber = false;
      }
      // for vehicle
      if (
        (this.filtersObject &&
          (this.filtersObject.report_type == "Driver Summary Report" ||
            this.filtersObject.report_type == "Vehicle Report")) ||
        this.filtersObject.report_type == "Driver Bonus Report" ||
        this.filtersObject.report_type == "Vehicle Summary Report" ||
        this.filtersObject.report_type == "Driver Report"
      ) {
        if (!this.allVehicleList.length) {
          this.getVehicleList();
        }
        if (!this.allDepotList.length) {
          this.getDepotList();
        }

        this.showVehicles = true;
      } else {
        this.showVehicles = false;
      }

      //for vehicle and driver
      if (
        this.filtersObject &&
        (this.filtersObject.report_type == "Driver Report" ||
          this.filtersObject.report_type == "Vehicle Report")
      ) {
        this.showLegDate = true;
      } else {
        this.showLegDate = false;
      }

      //for driver list and da list report
      if (
        this.filtersObject &&
        [
          "Driver Report",
          "Delivery Advice List Report",
          "Vehicle Report",
        ].includes(this.filtersObject.report_type)
      ) {
        if (!this.sourceList.length) {
          this.getLocationList();
        }
        this.showLocation = true;
      } else {
        this.showLocation = false;
      }
      //da list report
      if (
        this.filtersObject &&
        ["Delivery Advice List Report"].includes(this.filtersObject.report_type)
      ) {
        this.showDaListFields = true;
        this.showBOE = true;
      } else {
        this.showDaListFields = false;
        this.showBOE = false;
      }

      if (
        this.filtersObject &&
        [
          "Driver Report",
          "Delivery Advice List Report",
          "Customer Delivery Advice Summary Report",
          "Vehicle Report",
          "Hazard Delivery Advice Report",
          "Delivery Advice Time Stamp Report",
          "Driver Summary Report",
          "Driver Bonus Detailed Report",
          "SAP Driver Bonus Report",
          "Driver Bonus Summary Report",
          "Sub Hire Jobs Completed Report",
        ].includes(this.filtersObject.report_type)
      ) {
        if (!this.sourceList.length) {
          this.getDepotList();
        }

        this.showDepot = true;
      } else {
        this.showDepot = false;
      }
    },
    resetForm() {
      if (this.$refs.reportForm) {
        this.$refs.reportForm.reset();
      }
      this.showCustomers = false;
      this.filtersObject = {};
      this.reportList = [];
      this.selectedReportCategory = null;
    },
    submitForm() {
      this.$bus.$emit("showLoader", true);
      let data = {
        ...this.filtersObject,
      };
      //customer
      if (
        this.filtersObject.customer &&
        this.filtersObject.customer.company_name &&
        typeof this.filtersObject.customer === "object"
      ) {
        data.customer_name = this.filtersObject.customer.company_name;
      }
      if (
        this.filtersObject.customer &&
        this.filtersObject.customer.id &&
        typeof this.filtersObject.customer === "object"
      ) {
        data.customer = this.filtersObject.customer.id;
      }
      //transporter
      if (
        this.filtersObject.transporter &&
        this.filtersObject.transporter.company_name &&
        typeof this.filtersObject.transporter === "object"
      ) {
        data.transporter_name = this.filtersObject.transporter.company_name;
      }
      if (
        this.filtersObject.transporter &&
        this.filtersObject.transporter.id &&
        typeof this.filtersObject.transporter === "object"
      ) {
        data.transporter = this.filtersObject.transporter.id;
      }

      if (
        this.filtersObject.branch &&
        this.filtersObject.branch.branch_name &&
        typeof this.filtersObject.branch === "object"
      ) {
        data.branch_name = this.filtersObject.branch.branch_name;
      }

      if (
        this.filtersObject.invoice_da_status &&
        typeof this.filtersObject.invoice_da_status === "object"
      ) {
        data.status_da = this.filtersObject.status_da;
      }

      if (
        this.filtersObject.branch &&
        this.filtersObject.branch.id &&
        typeof this.filtersObject.branch === "object"
      ) {
        data.branch = this.filtersObject.branch.id;
      }

      if (
        this.filtersObject.vehicle &&
        typeof this.filtersObject.vehicle === "object"
      ) {
        if (this.filtersObject.vehicle.id) {
          data.vehicle = this.filtersObject.vehicle.id;
        }
        if (this.filtersObject.vehicle.inventory_number) {
          data.vehicle_name = this.filtersObject.vehicle.inventory_number;
        }
      }

      if (
        this.filtersObject.driver &&
        typeof this.filtersObject.driver === "object"
      ) {
        if (this.filtersObject.driver.id) {
          data.driver = this.filtersObject.driver.id;
        }
        if (this.filtersObject.driver.driver_name) {
          data.driver_name = this.filtersObject.driver.driver_name;
        }
      }
      if (
        this.filtersObject.source_location &&
        typeof this.filtersObject.source_location === "object"
      ) {
        if (this.filtersObject.source_location.id) {
          data.source_location = this.filtersObject.source_location.id;
        }
        if (this.filtersObject.source_location.title) {
          data.source_location_name = this.filtersObject.source_location.title;
        }
      }
      if (
        this.filtersObject.mid_location &&
        typeof this.filtersObject.mid_location === "object"
      ) {
        if (this.filtersObject.mid_location.id) {
          data.mid_location = this.filtersObject.mid_location.id;
        }
        if (this.filtersObject.mid_location.title) {
          data.mid_location_name = this.filtersObject.mid_location.title;
        }
      }
      if (
        this.filtersObject.destination_location &&
        typeof this.filtersObject.destination_location === "object"
      ) {
        if (this.filtersObject.destination_location.id) {
          data.destination_location =
            this.filtersObject.destination_location.id;
        }
        if (this.filtersObject.destination_location.title) {
          data.destination_location_name =
            this.filtersObject.destination_location.title;
        }
      }
      if (
        this.filtersObject.depot &&
        typeof this.filtersObject.depot === "object"
      ) {
        if (this.filtersObject.depot.id) {
          data.depot = this.filtersObject.depot.id;
        }
        if (this.filtersObject.depot.depot_name) {
          data.depot_name = this.filtersObject.depot.depot_name;
        }
      }
      this.$api.report
        .getReportData(data)
        .then((res) => {
          this.$bus.$emit("showLoader", false);
          this.$emit("refreshList");
          this.resetForm();
        })
        .catch((err) => {
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message: "Request is not accepted",
            color: "error",
          });
          this.formErrors = err.data;
        });
    },
    getReportOptions() {
      this.$bus.$emit("showLoader", true);
      this.$api.report
        .getReportOptions()
        .then((res) => {
          this.allReports = res.data.actions.POST.report_type.choices;
          this.allReports.forEach((element) => {
            if (
              [
                "Customer Delivery Advice Summary Report",
                "Delivery Advice Summary Report",
                "Delivery Advice Time Stamp Report",
                "Delivery Advice List Report",
                // "DA Cost Report",
                "DA Profit Summary Report",
                "DA Profit Detail Report",
                "Hazard Delivery Advice Report",
              ].includes(element.value)
            ) {
              this.daReportList.push(element);
            }
            if (
              [
                "Invoice Da Detail Report",
                "Customer Invoice Detailed Report",
                "Invoice List",
                "DA Pending To Invoice Report",
                "Payment Receipt Report",
                "Credit Note Detailed Report",
              ].includes(element.value)
            ) {
              this.financeReportList.push(element);
              this.getBranchList();
            }
            if (
              ["Vehicle Summary Report", "Vehicle Report"].includes(
                element.value
              )
            ) {
              this.vehicleReportList.push(element);
            }
            if (
              ["Driver Summary Report", "Driver Report"].includes(element.value)
            ) {
              this.driverReportList.push(element);
            }
            if (
              [
                "Driver Bonus Report",
                "Driver Bonus Detailed Report",
                "Driver Bonus Summary Report",
                "SAP Driver Bonus Report",
              ].includes(element.value)
            ) {
              this.driverBonusReportList.push(element);
            }
            if (
              [
                "Sub Hire Jobs Summary Report",
                "Sub Hire Jobs Detail Report",
                "Sub Hire Jobs Completed Report",
              ].includes(element.value)
            ) {
              this.subHireReportList.push(element);
            }
            if (
              [
                "Defective Checklist Report",
                "Trip CheckList Log",
                "Location Summary Report",
              ].includes(element.value)
            ) {
              this.otherReportList.push(element);
            }
          });
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    getVehicleList() {
      this.$bus.$emit("showLoader", true);
      this.$api.vehicle
        .getVehicleSelectList({
          limit: "all",
        })
        .then((res) => {
          this.allVehicleList = res.data;
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    getDriverList() {
      this.$bus.$emit("showLoader", true);
      this.$api.driver
        .getDriverSelectList({
          limit: "all",
        })
        .then((res) => {
          this.allDriverList = res.data;
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    getBranchList(params = {}) {
      this.$bus.$emit("showLoader", true);
      params = {
        limit: "all",
        ...params,
      };
      this.$api.branch
        .getBranchSelectList(params)
        .then((res) => {
          this.allBranchist = res.data;
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    checkDates() {
      if (this.filtersObject.start_date) {
        let currentDate = new Date(this.filtersObject.start_date);
        let endDate = currentDate;
        endDate.setDate(currentDate.getDate() + 5);
        this.lastEndDate = endDate.toISOString().slice(0, 10);
      } else {
        this.filtersObject.end_date = null;
      }
    },
    getCustomerList(params = {}) {
      this.$bus.$emit("showLoader", true);
      params = {
        company_type: "Customer",
        limit: "all",
      };
      this.$api.company
        .getCompanyList(params)
        .then((res) => {
          this.allCustomers = res.data;
          this.showCustomers = true;
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    getTransporterList(params = {}) {
      this.$bus.$emit("showLoader", true);
      params = {
        company_type: "Transporter",
        limit: "all",
      };
      this.$api.company
        .getCompanyList(params)
        .then((res) => {
          this.allTransporters = res.data;
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    getJobTypeOptions() {
      this.$bus.$emit("showLoader", true);
      this.$api.bmr
        .getBMROptions()
        .then((res) => {
          this.allJobType = res.data.actions.POST.job_type.choices;
          this.showJobType = true;
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },

    getLocationList() {
      this.$bus.$emit("showLoader", true);

      this.$api.location
        .getLocationSelectList({ limit: "all" })
        .then((res) => {
          this.$bus.$emit("showLoader", false);
          this.sourceList = res.data;
          this.midList = res.data;
          this.destinationList = res.data;
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    getDepotList(params = {}) {
      this.$bus.$emit("showLoader", true);
      params = {
        limit: "all",
        ...params,
      };
      this.$api.depot
        .getDepotSelectList(params)
        .then((res) => {
          this.$bus.$emit("showLoader", false);
          this.allDepotList = res.data;
          // if (res && res.data && res.data.length) {
          //   this.directDaDetails.depot = res.data[0].id;
          // }
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    getContainersType() {
      this.$bus.$emit("showLoader", true);
      this.$api.containerType
        .getContainerTypeList({ limit: "all" })
        .then((res) => {
          this.allContainersType = res.data;
          this.$bus.$emit("showLoader", false);
        })
        .catch(() => {
          this.$bus.$emit("showLoader", false);
        });
    },
  },
};
</script>

<style scoped>
.Lighten-Primary {
  background-color: rgba(240, 112, 37, 0.32) !important;
}
</style>
