<template>
  <v-dialog max-width="60%" v-model="showSplitLegRateForm" persistent>
    <v-card class="rounded-lg" variant="outlined">
      <v-card-title class="secondary white--text px-4 py-2">
        <span class="text-capitalize">
          Delivery Advice No.

          {{ this.daDetails ? this.daDetails.da_no : "" }}
        </span>

        <v-spacer></v-spacer>

        <v-btn icon @click="resetForm(), (showSplitLegRateForm = false)">
          <v-icon class="white--text">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      
      <v-card-text class="pt-0" v-if="type && type == 'Base'">
        <v-form ref="chargeForm" v-if="showSplitLegRateForm" v-model="isValid">
          <v-row
            class="my-0 mb-2"
            style="font-size: 18px; background-color: #f0f0f0"
          >
            <v-col cols="12" lg="4" class="Form-Columns">Leg No.</v-col>
            <v-col cols="12" lg="4" class="Form-Columns ">Leg Lane Title</v-col>
            <v-col cols="12" lg="4" class="Form-Columns">Leg Rate</v-col>
          </v-row>
          <v-row class="my-0">
            <v-col cols="12" lg="2" class="Form-Columns">
              <span class="font-weight-bold" style="font-size: 18px">{{
                legNo
              }}</span>
            </v-col>
            <v-col cols="12" lg="5" class="Form-Columns text-center">
              <span class="font-weight-bold pl-5" style="font-size: 18px">{{
               daDetails.lane_title
              }}</span>
            </v-col>
            <v-col cols="12" lg="5" class="Form-Columns">
              <InputField
                type="number"
                :label="`Leg Rate (${$globals.currency}) *`"
                required
                hide-details="auto"
                readonly
                v-model="splitRate"
                disabled
                :error-messages="formErrors.rate"
                @input="delete formErrors.rate"
              />
            </v-col>
            <v-col cols="12" lg="5" class="Form-Columns">
              <span class="font-weight-bold" style="font-size: 18px"
                >Split DA Rate</span
              >
            </v-col>
            <v-col cols="12" lg="7" class="Form-Columns">
              <InputField
                type="number"
                :label="`Split Rate (${$globals.currency}) `"
                required
                :rules="[
                  (val) => !!val || 'Rate is required!',
                  (val) => {
                    return (
                      Number(val) < Number(this.daDetails.rate) ||
                      'Split rate cannot be greater than the base rate!'
                    );
                  },
                ]"
                hide-details="auto"
                v-model="splitLegRateDetails.rate"
                :error-messages="formErrors.rate"
                @input="delete formErrors.rate, updateTotalRate()"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-text class="pt-0" v-else>
        <v-row
          class="my-0 mb-2"
          style="font-size: 18px; background-color: #f0f0f0"
        >
          <v-col cols="12" lg="3" class="Form-Columns">DA Route</v-col>
          <v-col cols="12" lg="4" class="Form-Columns">Leg Lane Title</v-col>
          <v-col cols="12" lg="5" class="Form-Columns">Leg Rate</v-col>
        </v-row>
        <v-form ref="chargeForm" v-if="showSplitLegRateForm" v-model="isValid">
          <v-row
            class="my-0 mt-2 pt-3"
            v-for="(item, i) in legDetails"
            :key="i"
          >
            <v-col cols="12" lg="3" class="Form-Columns">
              <span class="font-weight-bold" style="font-size: 18px">{{
                item.leg_id
              }}</span>
            </v-col>
            <v-col cols="12" lg="4" class="Form-Columns">
              <span class="font-weight-bold" style="font-size: 18px">{{
                item.leg_lane_title
              }}</span>
            </v-col>

            <v-col cols="12" lg="5" class="Form-Columns">
              <InputField
                type="number"
                :label="`Leg Rate (${$globals.currency}) *`"
                required
                hide-details="auto"
                v-model="item.rate"
                :rules="[
                  (val) => !!val || 'Rate is required!',
                  (val) => {
                    return (
                      totalRate() <= Number(daDetails.rate) ||
                      'Total rate cannot exceed the base rate!'
                    );
                  },
                ]"
                :error-messages="formErrors[`rate_${i}`]"
                @input="updateTotalRate, delete formErrors[`rate_${i}`]"
              />
            </v-col>
          </v-row>
          <v-row class="my-0">
            <v-col cols="12" lg="5" class="Form-Columns">
              <span class="font-weight-bold" style="font-size: 18px"
                >Split DA Rate</span
              >
            </v-col>
            <v-col cols="12" lg="7" class="Form-Columns">
              <InputField
                type="number"
                :label="`Split Rate (${$globals.currency}) *`"
                required
                :rules="[
                  (val) => !!val || 'Rate is required!',
                  (val) => {
                    return (
                      Number(val) <= Number(this.daDetails.rate) ||
                      'Split rate cannot be greater than the base rate!'
                    );
                  },
                  (val) => {
                    return (
                      this.totalRate() <= Number(this.daDetails.rate) ||
                      'Total rate cannot exceed the base rate!'
                    );
                  },
                ]"
                hide-details="auto"
                v-model="splitLegRateDetails.rate"
                :error-messages="formErrors.splitRate"
                @input="updateTotalRate, delete formErrors.splitRate"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>

      <v-card-actions class="justify-end">
        <v-btn
          v-if="type && type == 'Base'"
          color="primary"
          depressed
          :disabled="isBaseAddButtonDisabled"
          @click="submitForm()"
        >
          Add
        </v-btn>
        <v-btn
          v-else
          color="primary"
          depressed
          :disabled="isAddButtonDisabled"
          @click="gatherSplitRateData()"
        >
          Add
        </v-btn>

        <v-btn class="ml-3" color="secondary" depressed @click="resetForm">
          Reset
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import InputField from "@/components/FormBaseComponents/InputField.vue";
import MultiSelect from "@/components/FormBaseComponents/MultiSelect.vue";
import SelectField from "@/components/FormBaseComponents/SelectField.vue";

export default {
  components: {
    InputField,
    SelectField,
    MultiSelect,
  },
  data() {
    return {
      adjustedRate: null,

      legNo: null,
    };
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "Base",
    },
    legDetails: [],

    daDetails: {
      default: false,
    },
  },
  watch: {
    value(val) {
      if (val) {
        this.splitRate = this.daDetails.rate;
        this.legDetails.forEach((leg) => {
          this.legNo = leg.leg_id;
        });
      } else {
        this.resetForm();
      }
    },
  },
  computed: {
    showSplitLegRateForm: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    adjustedRate() {
      return this.daDetails.rate - (this.splitLegRateDetails.rate || 0);
    },
    isBaseAddButtonDisabled() {
      const splitRate = this.splitLegRateDetails.rate;
      const daRate = Number(this.daDetails.rate);
      return !splitRate || Number(splitRate) > daRate;
    },
    isAddButtonDisabled() {
      const splitRate =
        this.splitLegRateDetails.rate !== null
          ? Number(this.splitLegRateDetails.rate)
          : null;
      const daRate = Number(this.daDetails.rate);

      // Calculate the total leg rates
      const totalLegRates = this.legDetails.reduce(
        (total, leg) => total + Number(leg.rate),
        0
      );

      // Check if all leg rates are filled and valid
      const allRatesFilled = this.legDetails.every(
        (leg) => leg.rate && Number(leg.rate) <= daRate
      );

      // Calculate the total rate
      const totalRate = totalLegRates + (splitRate || 0);

      // Disable the button if:
      // - splitRate is null
      // - splitRate is greater than the DA rate
      // - not all leg rates are filled and valid
      // - the total rate exceeds the DA rate
      // - the total rate is less than the DA rate
      return (
        splitRate === null ||
        splitRate > daRate ||
        !allRatesFilled ||
        totalRate > daRate ||
        totalRate < daRate
      );
    },
  },
  data() {
    return {
      formErrors: {},
      splitRate: null,
      isValid: true,
      splitLegRateDetails: {},
    };
  },
  methods: {
    updateTotalRate() {
      this.splitRate =
        this.daDetails.rate - (this.splitLegRateDetails.rate || 0);
    },
    totalRate() {
      return (
        this.legDetails.reduce((sum, leg) => sum + Number(leg.rate || 0), 0) +
        Number(this.splitLegRateDetails.rate || 0)
      );
    },
    gatherSplitRateData() {
      const splitDataRate = {
        splitRate: this.splitLegRateDetails.rate,
        legRates: this.legDetails.map((leg) => ({
          id: leg.id,
          rate: leg.rate,
        })),
      };
      this.$emit("splitRateData", splitDataRate);
     
    },

    submitForm() {
      this.$bus.$emit("showLoader", true);
      this.formErrors = {};

      let data = {
        ...this.splitLegRateDetails,
      };
      data.adjustedRate = this.splitRate;

      this.$api.deliveryAdvice
        .splitSubHireLegs(this.daDetails.id, data)
        .then(() => {
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message: "SubHire Leg Splitted successfully!",
            color: "success",
          });
          this.resetForm();
          this.$emit("refreshList");
          this.showSplitLegRateForm = false;
        })
        .catch((err) => {
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message: "Couldn't split SubHire!",
            color: "error",
          });
          this.formErrors = err.data;
        });
    },

    resetForm() {
      this.splitLegRateDetails = {};
      (this.formErrors = {}), (this.legDetails = []);
      if (this.$refs.chargeForm) {
        this.$refs.chargeForm.reset();
      }
      this.$emit("refreshList");
    },
  },
};
</script>
