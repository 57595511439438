import Contract from "@/pages/transporter/contract/Contract.vue";

const contractRoutes = [
  {
    path: "contract",
    name: "app-transporter-contract",
    component: Contract,
  },
]

export default contractRoutes