import { bidding, } from "@/utils/constants";
import { handleError, handleResponse } from "@/utils/functions";

export default (axios) => ({
    getBidList(params = {}) {
        return new Promise((resolve, reject) => {
            axios
                .get(bidding.bidBase, {
                    params: params,
                })
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },

    addBid(data) {
        return new Promise((resolve, reject) => {
            axios
                .post(bidding.bidBase, data)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },

    getBidObject(id) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${bidding.bidBase}${id}/`)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },

    getBidDetails(id) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${bidding.bidBase}${id}/view/`)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    updateBid(payload) {
        return new Promise((resolve, reject) => {
            axios
                .put(
                    `${bidding.bidBase}${payload.id}/`,
                    payload.data
                )
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    getBidStatistics(params = {}) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${bidding.bidBase}bid_statistics/`, {
                    params: params,
                })
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },


    getBidRequestStatistics(params = {}) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${bidding.transporterBidBase}bid_request_statistics/`, {
                    params: params,
                })
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    getBidLogs(id) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${bidding.bidBase}${id}/bid_logs/`)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    getTransporterBidList(params = {}) {
        return new Promise((resolve, reject) => {
            axios
                .get(bidding.transporterBidBase, {
                    params: params,
                })
                .then((res) => {
                    resolve(handleResponse(res))
                })
                .catch((err) => {
                    reject(handleError(err))
                })
        })
    },
    getTransporterBidRequestDetail(id) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${bidding.transporterBidBase}${id}/view/`)
                .then((res) => {
                    resolve(handleResponse(res))
                })
                .catch((err) => {
                    reject(handleError(err))
                })
        })
    },
   
    submitBidRequestRate(payload) {
        return new Promise((resolve, reject) => {
            axios
                .patch(`${bidding.transporterBidBase}${payload.id}/transporter_submit_rate_bid_request/`, payload)
                .then((res) => {
                    resolve(handleResponse(res))
                })
                .catch((err) => {
                    reject(handleError(err))
                })
        })
    },
    getBidListForSubHire(params = {}) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${bidding.bidBase}bid_rate/`, {
                    params: params,
                })
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    getHsmBidDetails(id) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${bidding.hsmtransporterBidRate}${id}`)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    console.log(err);
                    reject(handleError(err));
                });
        });
    },
    submitRateHsm(id) {
        return new Promise((resolve, reject) => {
            axios
                .patch(`${bidding.hsmtransporterBidRate}${id}`)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    console.log(err);
                    reject(handleError(err));
                });
        });
    },
    closedBid(id) {
        return new Promise((resolve, reject) => {
            axios
                .patch(`${bidding.bidBase}${id}/closed_bid/`)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },

});