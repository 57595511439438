<template>
  <FormComponent
    title="Edit Route"
    backRoute="/app/admin/lane-management/lane/list"
    :nonFieldErrors="formErrors.non_field_errors"
  >
    <template #formActions>
      <v-btn color="primary" depressed :disabled="!isValid" @click="submitForm">
        Update
      </v-btn>
    </template>

    <template #formContent>
      <v-form v-model="isValid" ref="laneForm">
        <v-row class="ma-0">
          <v-col cols="12" lg="3" class="Form-Columns-Dense">
            <MultiSelect
              label="Source Location *"
              readonly
              class="grey lighten-3"
              :items="sourceList"
              itemText="location_code"
              itemValue="id"
              :item-disabled="
                (item) => {
                  return item.id == midId;
                }
              "
              :returnObject="true"
              :rules="[(v) => !!v || 'Source Location is Required!']"
              hide-details="auto"
              v-model="laneDetails.source_location"
              :error-messages="formErrors.source_location"
              @change="
                delete formErrors.source_location;
                checkData();
              "
            />
          </v-col>
          <v-col cols="12" lg="3" class="Form-Columns-Dense">
            <MultiSelect
              label="Mid Location *"
              readonly
              class="grey lighten-3"
              :items="midList"
              itemText="location_code"
              itemValue="id"
              :returnObject="true"
              :item-disabled="
                (item) => {
                  return item.id == sourceId || item.id == destinationId;
                }
              "
              :rules="[(v) => !!v || 'Mid Location is Required!']"
              hide-details="auto"
              v-model="laneDetails.mid_location"
              :error-messages="formErrors.mid_location"
              @change="
                delete formErrors.mid_location;
                checkData();
              "
            />
          </v-col>
          <v-col cols="12" lg="3" class="Form-Columns-Dense">
            <MultiSelect
              label="Destination Location"
              readonly
              class="grey lighten-3"
              :items="destinationList"
              itemText="location_code"
              itemValue="id"
              :returnObject="true"
              :item-disabled="
                (item) => {
                  return item.id == midId;
                }
              "
              :clearable="false"
              hide-details="auto"
              v-model="laneDetails.destination_location"
              :error-messages="formErrors.destination_location"
              @change="
                delete formErrors.destination_location;
                checkData();
              "
            />
          </v-col>
          <v-col cols="12" lg="3" class="Form-Columns-Dense">
            <MultiSelect
              v-if="
                directions &&
                directions.length &&
                !(waypoints && waypoints.length)
              "
              label="Route Options"
              :items="directions"
              itemText="name"
              itemValue="route"
              :returnObject="true"
              hide-details="auto"
              v-model="selectedRoute"
              @change="loadRoute"
            />
          </v-col>
          <v-col cols="12" lg="3" class="Form-Columns-Dense">
            <InputField
              label="Distance"
              :clearable="false"
              hide-details="auto"
              v-model="laneDetails.distance"
              :error-messages="formErrors.distance"
              @input="delete formErrors.distance"
            />
          </v-col>
          <v-col cols="12" lg="3" class="Form-Columns-Dense">
            <InputField
              type="number"
              label="Total Time to reach (Minutes)"
              :clearable="true"
              hide-details="auto"
              v-model="laneDetails.duration"
              :error-messages="formErrors.duration"
              @input="delete formErrors.duration"
            />
          </v-col>
          <v-col cols="12" lg="3" class="Form-Columns-Dense">
            <InputField
              hide-details="auto"
              type="number"
              label="Bonus Amount"
              :clearable="true"
              v-model="laneDetails.bonus"
              :error-messages="formErrors.bonus"
              @input="delete formErrors.bonus"
            />
          </v-col>
          <v-col cols="12" lg="3" class="Form-Columns-Dense">
            <v-switch
              :label="`Active : ${laneDetails.is_active}`"
              class="mt-0"
              true-value="Yes"
              false-value="No"
              hide-details="auto"
              v-model="laneDetails.is_active"
              :error-messages="formErrors.is_active"
              @input="delete formErrors.is_active"
            />
          </v-col>

          <!-- <v-col
            v-if="!(directions && directions.length)"
            cols="12"
            sm="6"
            md="4"
            lg="3"
            class="Form-Columns"
          >
            <v-btn
              color="primary"
              depressed
              :disabled="!isValid"
              @click="clearRoute"
            >
              Select New Route
            </v-btn>
          </v-col> -->

          <!-- <v-col
            v-show="
              sourceCoordinates &&
              destinationCoordinates &&
              directions &&
              directions.length
            "
            cols="12"
            sm="6"
            md="4"
            lg="3"
            class="Form-Columns"
          >
            <v-text-field
              label="Add Waypoints"
              ref="autocomplete"
              id="autocomplete"
              dense
              outlined
              tabindex="1"
              v-model="autoCompleteLocation"
            />
          </v-col> -->

          <v-col
            cols="12"
            lg="3"
            class="Form-Columns-Dense"
            v-if="waypointNames.length"
          >
            <span class="text-subtitle-1 font-weight-medium">Waypoints</span>
            <v-chip
              class="ma-1"
              v-for="(point, index) in waypointNames"
              :key="index"
              close
              @click:close="removeWaypoint(index)"
            >
              {{ point.name }}
            </v-chip>
          </v-col>

          <v-col cols="12" class="Form-Columns-Dense">
            <GoogleMap
              mapWidth="100%"
              mapHeight="100%"
              ref="LaneMap"
              class="Map-Height"
              :hasMapInfo="true"
            >
              <template #mapInfo>
                <v-card-text>
                  <v-row>
                    <v-col cols="12" class="d-flex align-center pa-2">
                      <img style="width: 18px" :src="sourceMarker" />
                      <span class="ml-4 text-body-2 font-weight-bold"
                        >Source</span
                      >
                    </v-col>
                    <v-col cols="12" class="d-flex align-center pa-2">
                      <img style="width: 18px" :src="midMarker" />
                      <span class="ml-4 text-body-2 font-weight-bold">Mid</span>
                    </v-col>
                    <v-col cols="12" class="d-flex align-center pa-2">
                      <img style="width: 18px" :src="destinationMarker" />
                      <span class="ml-4 text-body-2 font-weight-bold"
                        >Destination</span
                      >
                    </v-col>
                  </v-row>
                </v-card-text>
              </template>
            </GoogleMap>
          </v-col>
        </v-row>
      </v-form>
    </template>
  </FormComponent>
</template>

<script>
import InputField from "@/components/FormBaseComponents/InputField.vue";
import MultiSelect from "@/components/FormBaseComponents/MultiSelect.vue";
import FormComponent from "@/components/General/FormComponent.vue";
import GoogleMap from "@/components/General/GoogleMap.vue";
import {
  sourceMarker,
  destinationMarker,
  midMarker,
} from "@/assets/images/mapIcons.js";

import {
  isAuthenticated,
  hasRouteAccess,
  getPermissions,
} from "@/utils/functions";

export default {
  components: {
    FormComponent,
    InputField,
    MultiSelect,
    GoogleMap,
  },
  data() {
    return {
      sourceMarker,
      destinationMarker,
      midMarker,
      isValid: true,
      laneDetails: {},
      formErrors: {},

      sourceCoordinates: null,
      midCoordinates: null,
      destinationCoordinates: null,
      directionsTimeout: null,

      // Arrays for Select
      sourceList: [],
      midList: [],
      destinationList: [],
      directions: [],

      selectedRoute: {},
      autocomplete: null,
      autoCompleteLocation: null,
      waypoints: [],
      waypointNames: [],
      sourceId: null,
      midId: null,
      destinationId: null,
    };
  },
  methods: {
    checkData() {
      if (this.laneDetails.source_location) {
        if (typeof this.laneDetails.source_location == typeof {}) {
          this.sourceId = this.laneDetails.source_location.id;
          this.sourceCoordinates = this.laneDetails.source_location.coordinates;
        } else {
          this.sourceCoordinates = this.laneDetails.source_coordinates;
        }
      }
      if (
        this.laneDetails.mid_location &&
        typeof this.laneDetails.mid_location == typeof {}
      ) {
        this.midId = this.laneDetails.mid_location.id;
        if (!this.laneDetails.destination_location) {
          this.destinationCoordinates =
            this.laneDetails.mid_location.coordinates;

          this.waypoints = [];
          this.midCoordinates = null;
        } else {
          this.waypoints = [];
          this.waypoints.push({
            location: {
              lat: this.laneDetails.mid_location.coordinates.latitude,
              lng: this.laneDetails.mid_location.coordinates.longitude,
            },
            stopover: false,
          });
          this.midCoordinates = this.laneDetails.mid_location.coordinates;
        }
      } else {
        if (
          this.laneDetails.mid_location &&
          typeof this.laneDetails.mid_location == "number"
        ) {
          if (!this.laneDetails.destination_location) {
            this.destinationCoordinates = this.laneDetails.mid_coordinates;
            this.waypoints = [];
            this.midCoordinates = null;
          } else {
            this.destinationId = this.laneDetails.destination_location;
            this.waypoints = [];
            this.waypoints.push({
              location: {
                lat: this.laneDetails.mid_coordinates.latitude,
                lng: this.laneDetails.mid_coordinates.longitude,
              },
              stopover: false,
            });
            this.midCoordinates = this.laneDetails.mid_coordinates;
          }
        }
      }
      if (this.laneDetails.destination_location) {
        if (typeof this.laneDetails.destination_location == typeof {}) {
          this.destinationId = this.laneDetails.destination_location.id;
          this.destinationCoordinates =
            this.laneDetails.destination_location.coordinates;
        } else if (this.laneDetails.destination_coordinates) {
          this.destinationCoordinates =
            this.laneDetails.destination_coordinates;
        }
      }

      this.waypointNames = [];
      this.getRoute();
    },

    submitForm() {
      this.$bus.$emit("showLoader", true);
      let data = {
        ...this.laneDetails,
      };
      if (
        this.laneDetails.source_location &&
        typeof this.laneDetails.source_location == typeof {}
      ) {
        data.source_location = this.laneDetails.source_location.id;
      }
      if (
        this.laneDetails.mid_location &&
        typeof this.laneDetails.mid_location == typeof {}
      ) {
        data.mid_location = this.laneDetails.mid_location.id;
      }
      if (
        this.laneDetails.destination_location &&
        typeof this.laneDetails.destination_location == typeof {}
      ) {
        data.destination_location = this.laneDetails.destination_location.id;
      }

      if (data.route) {
        data.route = data.route.features[0].geometry;
      }

      if (data.distance) {
        data.distance = parseFloat(data.distance.replace(/\,/g, ""));
      }

      const payload = {
        id: this.laneDetails.id,
        data: data,
      };
      this.$api.lane
        .updateLane(payload)
        .then((res) => {
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message: "Route updated successfully!",
            color: "success",
          });
          this.$router.push("/app/admin/lane-management/lane/list");
        })
        .catch((err) => {
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message: "Couldn't update Route!",
            color: "error",
          });
          this.formErrors = err.data;
        });
    },

    getLocationList() {
      this.$bus.$emit("showLoader", true);

      this.$api.location
        .getLocationSelectList({ limit: "all" })
        .then((res) => {
          this.$bus.$emit("showLoader", false);
          this.sourceList = res.data;
          this.midList = res.data;
          this.destinationList = res.data;
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },

    getRoute() {
      if (this.sourceCoordinates && this.destinationCoordinates) {
        if (this.directionsTimeout) {
          clearTimeout(this.directionsTimeout);
        }

        this.directionsTimeout = setTimeout(() => {
          this.$bus.$emit("showLoader", true);

          const directionConfig = {
            origin: {
              lat: this.sourceCoordinates.latitude,
              lng: this.sourceCoordinates.longitude,
            },
            destination: {
              lat: this.destinationCoordinates.latitude,
              lng: this.destinationCoordinates.longitude,
            },
            travelMode: "DRIVING",
            provideRouteAlternatives: true,
          };

          if (this.waypoints && this.waypoints.length) {
            directionConfig.waypoints = this.waypoints;
          }

          this.$refs.LaneMap.getDirections(directionConfig)
            .then(async (resp) => {
              const directions = resp.routes;
              this.$bus.$emit("showLoader", false);
              this.directions = [];

              let index = 0;
              while (directions.length > this.directions.length) {
                let durationInMins = 0;
                let durationInSeconds =
                  directions[index].legs[0].duration.value;
                if (durationInSeconds) {
                  durationInMins = Math.round(durationInSeconds / 60);
                }
                this.directions.push({
                  name: `Route ${index + 1}`,
                  duration: durationInMins,
                  distance: directions[index].legs[0].distance.text,
                  route: this.$refs.LaneMap.generateGeoJsonFromLineString(
                    await this.$refs.LaneMap.generateLineStringFromRoute(
                      directions[index]
                    )
                  ),
                });
                index++;
              }

              this.selectedRoute = this.directions[0];

              this.loadMarkers();
              this.loadRoute();
            })
            .catch((err) => {
              console.error(err);
              this.$bus.$emit("showLoader", false);
            });
        }, 200);
      }
    },

    getAutoComplete() {
      const interval = setInterval(() => {
        if (this.$refs.autocomplete && window.google && window.google.maps) {
          clearInterval(interval);

          let input = document.getElementById("autocomplete");

          this.autocomplete = new window.google.maps.places.Autocomplete(input);
          input.setAttribute("placeholder", "");

          this.autocomplete.addListener("place_changed", () => {
            try {
              let place = this.autocomplete.getPlace();
              const coordinates = {
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng(),
              };

              this.waypoints.push({
                location: coordinates,
                stopover: false,
              });

              this.waypointNames.push({
                name: place.formatted_address,
              });

              this.autoCompleteLocation = null;

              this.getRoute();
            } catch (error) {
              console.error(error);
            }
          });
        }
      }, 100);
    },

    removeWaypoint(index) {
      this.waypoints.splice(index, 1);
      this.waypointNames.splice(index, 1);
      this.getRoute();
    },

    getLaneObject(id) {
      this.$bus.$emit("showLoader", true);
      this.$api.lane
        .getLaneObject(id)
        .then((res) => {
          this.laneDetails = res.data;
          this.sourceId = this.laneDetails && this.laneDetails.source_location;
          this.midId = this.laneDetails && this.laneDetails.mid_location;
          this.destinationId =
            this.laneDetails && this.laneDetails.destination_location;
          this.sourceCoordinates = res.data.source_coordinates;
          if (res.data.mid_coordinates && res.data.destination_coordinates) {
            this.waypoints = [];
            this.waypoints.push({
              location: {
                lat: res.data.mid_coordinates.latitude,
                lng: res.data.mid_coordinates.longitude,
              },
              stopover: true,
            });
            this.midCoordinates = res.data.mid_coordinates;
            this.destinationCoordinates = res.data.destination_coordinates;
          } else if (
            res.data.mid_coordinates &&
            !res.data.destination_coordinates
          ) {
            this.destinationCoordinates = res.data.mid_coordinates;
          }
          this.$bus.$emit("showLoader", false);

          if (this.selectedRoute) {
            this.selectedRoute.route =
              this.$refs.LaneMap.generateGeoJsonFromLineString(
                this.laneDetails.route
              );

            this.selectedRoute.distance = this.laneDetails.distance;
            this.selectedRoute.duration = this.laneDetails.duration;
          }

          this.loadMarkers();
          this.loadRoute();
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },

    loadRoute() {
      if (this.markerTimeout) {
        clearTimeout(this.markerTimeout);
      }
      this.markerTimeout = setTimeout(() => {
        this.laneDetails = {
          ...this.laneDetails,
          route: this.selectedRoute.route,
          distance: this.selectedRoute.distance,
          duration: this.selectedRoute.duration,
        };

        this.$refs.LaneMap.addGeoCollection(this.laneDetails.route);
      }, 500);
    },

    loadMarkers() {
      const interval = setInterval(() => {
        if (google && google.maps) {
          clearInterval(interval);
          let markers = [
            { ...this.sourceCoordinates, type: "source" },
            { ...this.destinationCoordinates, type: "destination" },
          ];
          if (this.midCoordinates && typeof this.midCoordinates == typeof {}) {
            markers.push({ ...this.midCoordinates, type: "mid" });
          }

          this.$refs.LaneMap.addMultipleMarkers(markers);
        }
      }, 400);
    },

    clearRoute() {
      this.$refs.LaneMap.removeGeoJson();
      this.getRoute();
    },
  },
  beforeMount() {
    if (!isAuthenticated()) {
      this.$router.push("/");
    } else if (!hasRouteAccess(this.$route)) {
      this.$router.push(`/${localStorage.getItem("user_type").toLowerCase()}`);
    } else {
      const permissions = getPermissions("lane");
      if (!permissions || !permissions.change) {
        this.$bus.$emit("showToastMessage", {
          message: "You don't have access to this page",
          color: "error",
        });
        this.$router.push(
          `/app/${
            localStorage.getItem("user_type")
              ? localStorage.getItem("user_type").toLowerCase()
              : "error"
          }`
        );
      } else {
        this.getLocationList();
        this.getAutoComplete();
        this.getLaneObject(this.$route.params.id);
      }
    }
  },
};
</script>

<style scoped>
.Map-Height {
  height: calc(100vh - 253px);
}
</style>
