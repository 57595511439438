import { role } from "@/utils/constants";
import { handleError, handleResponse } from "@/utils/functions";

export default (axios) => ({
  getRoleList(params = {}) {
    return new Promise((resolve, reject) => {
      axios
        .get(role.roleBase, {
          params: params,
        })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getRoleObject(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${role.roleBase}${id}/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getRoleDetails(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${role.roleBase}${id}/view/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getPermissions() {
    return new Promise((resolve, reject) => {
      axios
        .get(`api/v1/permissions/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  addRole(data) {
    return new Promise((resolve, reject) => {
      axios
        .post(role.roleBase, data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  updateRole(payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${role.roleBase}${payload.id}/`, payload.data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  partiallyUpdateRole(payload) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`${role.roleBase}${payload.id}/`, payload.data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
});
