

<template>
  <v-dialog
    v-model="vehicleBulkUploadDialog"
    persistent
    scrollable
    width="90%"
    max-width="1200px"
  >
    <v-card class="pa-4">
      <v-card-title class="d-flex justify-space-between">
        <span
          class="text-lg-subtitle-1 text-xl-h6 text-uppercase font-weight-black primary--text"
        >
          {{ `${getTextChange(uploadTo)}` }}
        </span>
        <v-btn depressed fab class="mt-n3" small @click="closeDialog()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row class="pt-8">
          <v-col cols="5" class="d-flex">
            <v-file-input
              v-model="file"
              hide-details
              dense
              :label="`${'upload document'}`"
              prepend-inner-icon="mdi-attachment mdi-rotate-90"
              class="mr-4"
              prepend-icon=""
              outlined
              @change="getFileData($event)"
              accept=".xlsx, .xls, .csv"
            />
            <v-icon color="primary" @click="openInstructionDialog(uploadTo)">
              mdi-information
            </v-icon>
          </v-col>
          <v-col cols="7" class="d-flex justify-end text-capitalize">
            <v-btn
              v-if="
                uploadTo == 'items_update' ||
                uploadTo == 'drivers_update' ||
                uploadTo == 'vehicles_update' ||
                uploadTo == 'customers_update' ||
                uploadTo == 'lane_rates_update' ||
                uploadTo == 'location_update' ||
                uploadTo == 'driver_bonus_update'
              "
              class="primary"
              depressed
              @click="downloadSampleExcel()"
            >
              {{ "download sample excel file" }}
            </v-btn>
            <v-btn
              v-else-if="uploadTo == 'route'"
              class="primary"
              depressed
              @click="downloadRouteDataExcel('route')"
            >
              {{ "download excel file to update bonus" }}
            </v-btn>
            <v-btn
              v-else
              class="primary"
              depressed
              @click="downloadSampleExcel()"
            >
              {{ "download sample excel file" }}
            </v-btn>
          </v-col>
          <v-col cols="12" class="d-flex justify-space-between pt-0">
            <span class="text-capitalize">
              {{ "total records" }} : {{ totalRecordCount }}</span
            >
            <span> {{ "records with error" }} : {{ rowData.length }}</span>
          </v-col>
          <v-col v-if="distinctErrors.length > 0" class="py-0">
            <v-alert border="left" colored-border color="red">
              <v-row no-gutters>
                <v-col class="grow"> </v-col>
                <v-col class="shrink">
                  <v-btn
                    class="error"
                    depressed
                    @click="removeAllRecordWithError"
                  >
                    {{ "remove all with error" }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-alert>
          </v-col>
          <v-col cols="12" v-if="rowData.length > 0">
            <AgGridVue
              @grid-ready="gridReady"
              :grid-options="gridOptions"
              :column-defs="columnDefs"
              :default-col-def="defaultColDef"
              :context="context"
              :row-data="rowData"
              style="width: 100%; height: 400px"
              class="ag-theme-material"
            >
            </AgGridVue>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="pa-4 d-flex justify-end">
        <v-btn
          color="primary"
          :loading="loading"
          :disabled="!file || rowData.length != 0"
          @click="submitData()"
        >
          {{ "submit" }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <Instruction
      v-model="instructionDialog"
      :instructionList="instructionList"
    />
  </v-dialog>
</template>

<script>
import { read, utils } from "xlsx";
import {
  customerTariffInstruction,
  locationInstruction,
  driverInstruction,
  vehicleInstruction,
  trailerInstruction,
  transporterCompanyInstruction,
  companyInstruction,
  leaseDriverBonusInstruction
} from "@/utils/instruction.js";
import { AgGridVue } from "ag-grid-vue";
import Instruction from "@/components/General/Instruction.vue";
import RemoveRowButtonBulkUpload from "@/components/General/RemoveRowButtonBulkUpload.vue";
import { downloadBlobData } from "@/utils/functions.js";
import BulkUploadErrorTooltip from "@/components/General/BulkUploadErrorTooltip.vue";
import { Logger } from "ag-grid-community";

export default {
  components: {
    AgGridVue,
    Instruction,
    RemoveRowButtonBulkUpload,
    BulkUploadErrorTooltip,
  },
  props: {
    value: Boolean,
    uploadTo: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      loading: false,
      file: null,
      fulldata: [],
      rowData: [],
      columnDefs: [],
      _reqFields: [],
      _allFields: null,
      gridApi: null,
      columnApi: null,
      gridOptions: {
        headerHeight: 40,
        rowHeight: 40,
        rowSelection: "multiple",
        suppressRowClickSelection: true,
        suppressDragLeaveHidesColumns: true,
        enableCellTextSelection: true,
      },
      defaultColDef: {
        lockPosition: true,
      },
      requiredHeaders: [],
      serverErrors: [],

      // For Instructions Dialog
      instructionList: [],
      instructionDialog: false,
      dataObject: [],
      excelFile: null,
    };
  },
  computed: {
    context() {
      return { parentComponent: this };
    },
    totalRecordCount() {
      return this.dataObject.filter((item) => Object.keys(item).length > 0)
        .length;
    },
    vehicleBulkUploadDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    distinctErrors() {
      let err = [];

      this.serverErrors
        .filter((e) => {
          return Object.keys(e).length > 0;
        })
        .map((m) => {
          return Object.keys(m).map((k) => {
            return m[k];
          });
        })
        .forEach((r) => {
          r.forEach((s) => {
            err = [...new Set([...err, ...s])];
          });
        });
      return err;
    },
  },
  methods: {
    getTextChange(type) {
      switch (type) {
        case "vehicle":
          return "bulk upload vehicle";
        case "customer_tariff":
          return "bulk upload customer tariff";
        case "trailer":
          return "bulk upload trailer";
        case "driver":
          return "bulk upload driver";
        case "vehicle":
          return "bulk upload vehicle";
        case "location":
          return "bulk upload location";
        case "company":
          return "bulk upload customer";
        case "transporterCompany":
          return "bulk upload transporter";
        case "route":
          return "update bonus";
        case "driver_bonus":
          return "bulk upload driver bonus";
      }
    },
    openInstructionDialog(uploadTo) {
      if (this.uploadTo == "customer_tariff") {
        this.instructionList = customerTariffInstruction;
      } else if (this.uploadTo == "customer_tariff_update") {
        this.instructionList = customerTariffInstruction;
      }
      if (this.uploadTo == "location") {
        this.instructionList = locationInstruction;
      } else if (this.uploadTo == "location_update") {
        this.instructionList = locationInstruction;
      }
      if (this.uploadTo == "driver") {
        this.instructionList = driverInstruction;
      }
      if (this.uploadTo == "vehicle") {
        this.instructionList = vehicleInstruction;
      }
      if (this.uploadTo == "trailer") {
        this.instructionList = trailerInstruction;
      }
      if (this.uploadTo == "company") {
        this.instructionList = companyInstruction;
      }
      if (this.uploadTo == "transporterCompany") {
        this.instructionList = transporterCompanyInstruction;
      }
      if (this.uploadTo == "driverBonus") {
        this.instructionList = transporterCompanyInstruction;
      }
      if(this.uploadTo == "driver_bonus"){
         this.instructionList = leaseDriverBonusInstruction;
      }
      this.instructionDialog = true;
    },
    gridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
    },
    getFileData(file) {
      if (file) {
        this.excelFile = file;
        let reader = new FileReader();
        reader.onload = async () => {
          this.excelData = [];
          let fileData = reader.result;
          let wb = read(fileData, {
            type: "binary",
            cellText: false,
            cellDates: true,
          });
          let rowData = utils.sheet_to_row_object_array(
            wb.Sheets[wb.SheetNames[0]],
            {
              header: 0,
              defval: "",
              raw: false,
              dateNF: "yyyy-mm-dd",
            }
          );

          this.dataObject = rowData.map((row, index) => {
            let newObject = {};
            newObject.oldIndex = index;
            for (const [key, value] of Object.entries(row)) {
              let newKey = key
                .toString()
                .toLowerCase()
                .trim()
                .replaceAll(" ", "_");
              newObject[newKey] = value;
            }

            return newObject;
          });
        };

        reader.readAsBinaryString(file);
      } else {
        this.clearDialogData();
      }
    },
    removeErrorRow(index, oldIndex) {
      setTimeout(() => {
        this.serverErrors.splice(index, 1);
        this.rowData.splice(index, 1);

        this.dataObject.splice(
          this.dataObject.findIndex((item) => item.oldIndex == oldIndex),
          1
        );
      }, 200);
    },
    dataChange(param) {
      let field = param.colDef.field;
      let localError = this.serverErrors[param.node.rowIndex];
      if (param && param.newValue != param.oldValue) {
        delete localError[field];
      }
      let newIndex = this.dataObject.findIndex(
        (item) => item.oldIndex == param.data.oldIndex
      );
      this.dataObject[newIndex] = param.data;

      if (
        (localError && localError.non_field_errors) ||
        (localError &&
          Object.keys(localError) &&
          Object.keys(localError).length == 0)
      ) {
        setTimeout(() => {
          this.serverErrors.splice(param.node.rowIndex, 1);
          this.rowData.splice(param.node.rowIndex, 1);
        }, 200);
      }

      return param.newValue;
    },
    cellStyle(param) {
      if (
        param &&
        param.colDef &&
        param.colDef.field &&
        this.serverErrors &&
        this.serverErrors[param.rowIndex] &&
        this.serverErrors[param.rowIndex][param.colDef.field]
      ) {
        return {
          backgroundColor: "red",
        };
      } else {
        return {
          backgroundColor: "white",
        };
      }
    },
    removeAllRecordWithError() {
      while (this.rowData && this.rowData.length > 0) {
        this.removeDataFromRow(this.rowData[0].oldIndex, 0);
      }
    },
    removeDataFromRow(oldIndex, currentIndex) {
      this.dataObject.splice(oldIndex, 1, {});
      this.rowData.splice(currentIndex, 1);

      let data = this.dataObject.filter((obj) => Object.keys(obj).length !== 0);

      if (data.length == 0) {
        this.clearDialogData();
      }

      if (this.rowData.length == 0) {
        this.serverErrors = [];
      }
    },
    downloadSampleExcel() {
     
      let newUrl = null;
      if (this.uploadTo == "vehicle" || this.uploadTo == "vehicle_update") {
        newUrl = "bulk_vehicle";
      } else if (
        this.uploadTo == "trailer" ||
        this.uploadTo == "trailer_update"
      ) {
        newUrl = "bulk_trailer";
      } else if (
        this.uploadTo == "customer_tariff" ||
        this.uploadTo == "customer_tariff_update"
      ) {
        newUrl = "bulk_customer_contract";
      } else if (
        this.uploadTo == "driver" ||
        this.uploadTo == "driver_update"
      ) {
        newUrl = "bulk_driver";
      } else if (
        this.uploadTo == "location" ||
        this.uploadTo == "location_update"
      ) {
        newUrl = "bulk_location";
      } else if (
        this.uploadTo == "company" ||
        this.uploadTo == "company_update"
      ) {
        newUrl = "bulk_company";
      } else if (
        this.uploadTo == "transporterCompany" ||
        this.uploadTo == "transporterCompany_update"
      ) {
        newUrl = "bulk_transporter_company";
      } else if (
        this.uploadTo == "driver_bonus" ||
        this.uploadTo == "driver_bonus_update"
      ) {
        newUrl = "bulk_driver_bonus";
      } else if (
        this.uploadTo == "lrfq_rates" ||
        this.uploadTo == "lrfq_rates_update"
      ) {
        newUrl = "bulk_lrfq_rates_update";
      }
      else {
        newUrl = this.uploadTo;
        
      }
      const params = {
        excel_type: newUrl,
      };
      this.$api.bulkUpload
        .downloadSampleFile(params)
        .then((res) => {
          downloadBlobData(res, `Sample ${this.uploadTo} excel file.xls`);
          this.$bus.$emit("showToastMessage", {
            message: "Download file successfully",
            color: "success",
          });
        })
        .catch((err) => {
          this.$bus.$emit("showToastMessage", {
            message: "Can't download file!",
            color: "error",
          });
        });
    },
    async downloadRouteDataExcel(downloadModule) {
      let params = {
        download_type: downloadModule,
      };
      this.$bus.$emit("showLoader", true);
      await this.$api.lane
        .downloadRouteExcel(params)
        .then(async (response) => {
          this.$bus.$emit("showLoader", false);
          var blob = response.data;
          var downloadLink = window.document.createElement("a");
          downloadLink.href = window.URL.createObjectURL(
            new Blob([blob], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            })
          );
          downloadLink.download = downloadModule + ".xlsx";
          downloadLink.click();
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showToastMessage", {
            message: `${
              err.data && err.data.non_field_errors
                ? err.data.non_field_errors.join()
                : "Data not found"
            }`,
            color: "error",
          });
          this.$bus.$emit("showLoader", false);
        });
    },

    getUploadUrl(type) {
      switch (type) {
        case "lane_rates":
          return "/api/v1/lane_rate/bulk_create/";
        case "lane_rates_update":
          return "/api/v1/lane_rate/bulk_update/";
        case "vehicle":
          return "/api/v2/vehicle/bulk_create/";
        case "vehicle_update":
          return "/api/v1/vehicle/bulk_update/";
        case "customer_tariff":
          return "/api/v1/bulk_customer_contract/";
        case "customer_tariff":
          return "/api/v1/customer_contract/bulk_update/";
        case "trailer":
          return "/api/v2/trailer/bulk_create/";
        case "trailer_update":
          return "/api/v1/trailer/bulk_update/";
        case "driver":
          return "/api/v2/driver/bulk_create/";
        case "driver_bonus":
          return "/api/v2/bulk_upload/";
        case "driver_update":
          return "/api/v1/driver/bulk_update/";
        case "transporter_lrfq":
          return "/api/v2/transporter_lrfq/bulk_update/";
        case "location":
          return "/api/v2/location/bulk_create/";
        case "location_update":
          return "/api/v1/location/bulk_update/";
        case "company":
          return "/api/v1/company/bulk_create/";
        case "transporterCompany":
          return "/api/v1/company/bulk_create/";
        case "company_update":
          return "/api/v1/company/bulk_update/";
        case "route":
          return "/api/v1/bulk_upload/";
      }
    },
    async uploadDriverBonusDocuments(url, id) {
      let newForm = new FormData();
      newForm.append("document", this.excelFile);
      newForm.append("lease_upload_id", id);
      await this.$api.bulkUpload.bulkUploadFile(url, newForm);
    },

    submitData(payload = {}) {
      this.serverErrors = [];
      let url;
      url = this.getUploadUrl(this.uploadTo);

      if (this.dataObject && this.dataObject.length > 0) {
        if (this.uploadTo == "route") {
          payload.upload_type = "bulk_route";
          payload.bulk_data = this.dataObject.filter(
            (item) => Object.keys(item).length > 0
          );
        } else if (this.uploadTo == "driver_bonus") {
          payload.upload_type = "bulk_driver_bonus";

          payload.bulk_data = this.dataObject.filter(
            (item) => Object.keys(item).length > 0
          );
        } else {
          payload = this.dataObject.filter(
            (item) => Object.keys(item).length > 0
          );
          if (this.uploadTo == "company") {
            let data = [];
            data.data = [...payload];
            data.company_type = "Customer";
            payload = { ...data };
          } else if (this.uploadTo == "transporterCompany" || this.uploadTo == "lrfq_rates_update") {
            let data = {};
            data.data = [...payload];
            data.company_type = "Transporter";
            payload = { ...data };
          }
        }

        this.$bus.$emit("showLoader", true);
        this.$api.bulkUpload
          .bulkUploadFile(url, payload)
          .then((resp) => {
            if (this.uploadTo == "driver_bonus") {
              this.uploadDriverBonusDocuments(url, resp.data.id);
            }
            this.$bus.$emit("showLoader", false);
            this.$emit("refreshList");
            this.loading = false;
            this.$bus.$emit("showToastMessage", {
              message: "Successfully uploaded file",
              color: "success",
            });
            this.serverErrors = [];
            this.closeDialog();
          })
          .catch((err) => {
            this.$bus.$emit("showLoader", false);
            if (typeof err == "string") {
              this.$bus.$emit("showToastMessage", {
                message: err,
                color: "error",
              });
            } else if (
              (err && typeof err == "object") ||
              typeof err == "object"
            ) {
              this.serverErrors = err;
              this.generateErrorRow(err);
              this.$bus.$emit("showToastMessage", {
                message: "Error uploading sheet!",
                color: "error",
              });
            }

            this.loading = false;
          });
      } else {
        this.$bus.$emit("showToastMessage", {
          message: "Data not found!",
          color: "error",
        });
      }
    },
    generateErrorRow(errorRow) {
      let errorIndexRow = errorRow.map((item, index) => {
        return Object.keys(item).length > 0 ? true : false;
      });
      this.columnDefs = Object.keys(this.dataObject[0]).map((item, index) => {
        return {
          headerName: `${item.replaceAll("_", " ")}`
            .toLowerCase()
            .toUpperCase(),
          field: item,
          editable: "oldIndex" != item ? true : false,
          hide: "oldIndex" == item || "formError" == item ? true : false,
          valueParser: this.dataChange,
          cellStyle: this.cellStyle,
        };
      });

      this.columnDefs.unshift({
        pinned: "left",
        headerName: "errors",
        width: 90,
        cellRendererFramework: "BulkUploadErrorTooltip",
      });

      this.columnDefs.push({
        headerName: "actions",
        pinned: "right",
        width: 90,
        cellRendererFramework: "RemoveRowButtonBulkUpload",
      });
      this.serverErrors = this.serverErrors.filter((item, index) => {
        if (errorIndexRow[index]) {
          return item;
        }
      });

      this.rowData = this.dataObject.filter((item, index) => {
        if (errorIndexRow[index]) {
          return item;
        }
      });
      this.rowData = this.rowData.map((row, index) => {
        let error = this.serverErrors[index];
        return { ...row, formError: error };
      });
    },
    closeDialog() {
      this.vehicleBulkUploadDialog = false;
      this.clearDialogData();
    },
    clearDialogData() {
      this.file = null;
      this.fulldata = [];
      this.rowData = [];
      this.columnDefs = [];
      this.dataObject = [];
      this._reqFields = [];
      this.serverErrors = [];
    },
  },
};
</script>

