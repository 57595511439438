<template>
  <v-dialog width="750" v-model="showLeaseDriverLogDialog">
    <v-card>
      <v-card-title class="heading py-2">
        <span class="text-h6 font-weight-medium"> Logs </span>
      </v-card-title>
      <v-card-text class="pa-2 Cards-Height-Class">
        <v-timeline dense clipped>
          <v-timeline-item
            v-for="(log, i) in allLogsList"
            :key="i"
            icon-color="primary"
            small
          >
            <v-card class="elevation-2">
              <v-card-text>
                <table class="Full-Width">
                  <tbody>
                    <tr>
                      <th class="text-left">{{ log.updated_by }}</th>
                      <td class="text-caption text-right">
                        {{ log.created | formatDate(true) }}
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2">{{ log.message }}</td>
                    </tr>
                  </tbody>
                </table>
              </v-card-text>
            </v-card>
          </v-timeline-item>
        </v-timeline>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  components: {},
  props: {
    value: {
      type: Boolean,
    },
    leaseDriverLogID: {
      type: [Number, null],
      default: null,
    },
  },
  data() {
    return {
      allLogsList: [],
      leaseDriverId: null,
    };
  },
  computed: {
    showLeaseDriverLogDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  watch: {
    value(val) {
      if (val) {
        this.leaseDriverId = this.leaseDriverLogID;
        this.getLeaseDriverLogs(this.leaseDriverId);
      } else {
      }
    },
  },
  methods: {
    getLeaseDriverLogs(id) {
      this.$bus.$emit("showLoader", false);
      this.$api.leaseDriverBonus
        .getLeaseDriverLogs(id)
        .then((res) => {
          this.allLogsList = res.data;
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message: "Couldn't fetch sub hire job logs!",
            color: "error",
          });
          this.formErrors = err.data;
        });
    },
  },
};
</script>

<style>
</style>