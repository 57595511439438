var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',[_c('v-app-bar',{attrs:{"app":""}},[_c('img',{staticClass:"Brand-Logo",attrs:{"alt":"Brand Logo","src":require("@/assets/images/brand-logo.png")}})]),_c('v-container',{staticClass:"mt-16"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-card',{staticStyle:{"max-width":"600px"}},[_c('v-card-title',{staticClass:"primary white--text"},[_c('span',[_vm._v("Enter Rate - "+_vm._s(_vm.biddingDetails.status)+" ")]),_c('v-spacer')],1),_c('v-card-text',{staticClass:"pt-2"},[_c('div',[_c('div',{staticClass:"info-item info-row"},[_c('strong',[_vm._v("Container Type:")]),_vm._v(" "+_vm._s(_vm.biddingDetails.container_name)+" ")]),_c('div',{staticClass:"info-item info-row"},[_c('strong',[_vm._v("Container Count:")]),_vm._v(" "+_vm._s(_vm.biddingDetails.bid_container_count)+" ")])]),_c('div',{staticClass:"info-item info-row"},[_c('strong',[_vm._v("Expected Rate:")]),_vm._v(" "+_vm._s(_vm.biddingDetails.expected_rate)+" ")]),_c('div',{staticClass:"info-item info-row"},[_c('strong',[_vm._v("End Date:")]),_vm._v(" "+_vm._s(_vm.biddingDetails.end_date)+" ")]),_c('v-form',{ref:"bidRequestForm",model:{value:(_vm.isValid),callback:function ($$v) {_vm.isValid=$$v},expression:"isValid"}},[_c('v-row',{staticClass:"my-0"},[_c('v-col',{staticClass:"Form-Columns-Dense",attrs:{"cols":"12"}},[_c('InputField',{attrs:{"hide-details":"auto","type":"number","step":".01","label":`Rate (${_vm.$globals.currency}) *`,"required":"","readonly":_vm.biddingDetails.status == 'Participated' ||
                      _vm.biddingDetails.status == 'Expired',"rules":[
                      (value) =>
                        (value != null && value != undefined) ||
                        'Rate is required',
                      (val) => !!val || 'Rate is Required!',
                    ],"clearable":false,"error-messages":_vm.formErrors.rate},on:{"input":function($event){delete _vm.formErrors.rate}},model:{value:(_vm.formDataPOST.rate),callback:function ($$v) {_vm.$set(_vm.formDataPOST, "rate", $$v)},expression:"formDataPOST.rate"}})],1),_c('v-col',{staticClass:"Form-Columns-Dense",attrs:{"cols":"12"}},[_c('DateField',{attrs:{"inputProps":{
                      dense: true,
                      label: 'Rate Expiry Date *',
                      rules: [
                        (val) => !!val || 'Rate Expiry Date is Required!',
                      ],
                      'hide-details': 'auto',
                      'error-messages': _vm.formErrors.rate_expiry_date,
                    },"required":"","readonly":_vm.biddingDetails.status == 'Participated' ||
                      _vm.biddingDetails.status == 'Expired'},on:{"input":function($event){delete _vm.formErrors.rate_expiry_date}},model:{value:(_vm.formDataPOST.rate_expiry_date),callback:function ($$v) {_vm.$set(_vm.formDataPOST, "rate_expiry_date", $$v)},expression:"formDataPOST.rate_expiry_date"}})],1),_c('v-col',{staticClass:"Form-Columns",attrs:{"cols":"12"}},[_c('InputField',{attrs:{"hide-details":"auto","type":"number","label":"Container Count *","required":"","rules":[(val) => !!val || 'Container Count is Required'],"clearable":false,"error-messages":_vm.formErrors.container_count},on:{"input":function($event){delete _vm.formErrors.container_count}},model:{value:(_vm.formDataPOST.container_count),callback:function ($$v) {_vm.$set(_vm.formDataPOST, "container_count", $$v)},expression:"formDataPOST.container_count"}})],1),_c('v-col',{staticClass:"Form-Columns-Dense",attrs:{"cols":"12"}},[_c('InputField',{attrs:{"label":"Remarks","readonly":_vm.biddingDetails.status == 'Participated' ||
                      _vm.biddingDetails.status == 'Expired',"hide-details":"auto"},model:{value:(_vm.formDataPOST.transporter_remarks),callback:function ($$v) {_vm.$set(_vm.formDataPOST, "transporter_remarks", $$v)},expression:"formDataPOST.transporter_remarks"}})],1)],1)],1)],1),_c('v-divider'),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"depressed":"","color":"primary","disabled":!_vm.isValid},on:{"click":_vm.submitForm}},[_c('span',[_vm._v("Submit")])])],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }