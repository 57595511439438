<template>
  <v-dialog width="60%" v-model="showTripForm">
    <v-card v-if="showTripForm">
      <v-card-title class="secondary white--text px-4 py-2">
        <span v-if="leg && leg.leg_id">{{ leg.leg_id }}</span>

        <v-chip small v-if="leg && leg.da_movement_date_time" class="ml-4">
          {{ leg.da_movement_date_time | formatDate(true) }}
        </v-chip>

        <v-chip small v-if="leg && leg.source_location_name" class="ml-4">
          {{ leg.source_location_name }}
        </v-chip>

        <span class="mdi mdi-arrow-right-thin"></span>
        <v-chip small v-if="leg && leg.mid_location_name">
          {{ leg.mid_location_name }}
        </v-chip>

        <span
          class="mdi mdi-arrow-right-thin"
          v-if="leg && leg.destination_location_name"
        ></span>
        <v-chip small v-if="leg.destination_location_name">
          {{ leg.destination_location_name }}
        </v-chip>

        <v-menu open-on-hover right offset-y v-if="leg">
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-on="on" v-bind="attrs" color="white" class="ml-4">
              mdi-map-marker-distance
            </v-icon>
          </template>
          <v-card>
            <v-card-title
              class="heading text-subtitle-1 font-weight-medium py-1"
            >
              Route Address
            </v-card-title>
            <v-card-text class="mt-2">
              <table class="Normal-Table">
                <tbody>
                  <tr v-if="leg.source_address">
                    <th>Source Address</th>
                    <th class="px-2">:</th>
                    <td>
                      {{ leg.source_address }}
                    </td>
                  </tr>
                  <tr v-if="leg.mid_address">
                    <th>Mid Point Address</th>
                    <th class="px-2">:</th>
                    <td>
                      {{ leg.mid_address }}
                    </td>
                  </tr>
                  <tr v-if="leg.destination_address">
                    <th>Destination Address</th>
                    <th class="px-2">:</th>
                    <td>
                      {{ leg.destination_address }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </v-card-text>
          </v-card>
        </v-menu>

        <v-spacer></v-spacer>

        <v-btn
          icon
          @click="$emit('closeTripForm'), (formErrors = {})"
          class="float-lg-end"
        >
          <v-icon class="white--text">mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="pt-2">
        <v-row class="my-0">
          <FormComponent
            :nonFieldErrors="formErrors.non_field_errors"
            :title="`Asset Allocation ${
              formDetails.trip_id ? '#' + formDetails.trip_id : ''
            }`"
            :stickyHeader="true"
          >
            <template #formActions>
              <v-btn
                color="primary"
                depressed
                :disabled="(formDetails && !formDetails.id) || isTripActive"
                @click="deleteLegTrip()"
                class="mr-1"
              >
                UnAllocate
              </v-btn>
              <v-btn
                color="primary"
                depressed
                :disabled="!isValid"
                @click="submitForm"
              >
                Submit
              </v-btn>
            </template>

            <template #formContent>
              <v-form ref="adminTripForm" v-model="isValid">
                <v-row class="ma-0">
                  <v-col cols="12" lg="6" class="Form-Columns-Dense">
                    <MultiSelect
                      :hideSelectAll="true"
                      hide-details="auto"
                      label="Select Containers *"
                      :showAll="true"
                      :readonly="isTripActive"
                      :items="leg.order_containers"
                      itemText="container_no"
                      itemValue="id"
                      :multiple="true"
                      :chips="true"
                      :rules="[
                        (val) => !!val || 'Containers is required',
                        (val) =>
                          !!(Array.isArray(val) && val.length) ||
                          'Containers is required',
                      ]"
                      v-model="formDetails.leg_containers"
                      :error-messages="formErrors.leg_containers"
                      @change="delete formErrors.leg_containers"
                    />
                  </v-col>
                  <v-col cols="12" lg="6" class="Form-Columns-Dense">
                    <DateField
                      ref="TripDateField"
                      :inputProps="{
                        dense: true,
                        label: 'Trip Date *',
                        rules: [(val) => !!val || 'Trip Date is Required!'],
                        'hide-details': 'auto',
                        'error-messages': formErrors.trip_date,
                        disabled: isTripActive,
                      }"
                      :min="today"
                      v-model="formDetails.trip_date"
                      @change="
                        delete formErrors.trip_date;
                        refreshFormDetails();
                        checkAssetType();
                      "
                    />
                  </v-col>
                  <v-col cols="12" lg="3" class="Form-Columns-Dense">
                    <MultiSelect
                      label="Select Driver *"
                      :items="driverList"
                      itemText="driver_name"
                      itemValue="id"
                      :disabled="!formDetails.trip_date"
                      :rules="[(val) => !!val || 'Driver is required']"
                      hide-details="auto"
                      v-model="formDetails.driver"
                      :error-messages="formErrors.driver"
                      @change="
                        delete formErrors.driver;
                        vehicleAssigned();
                      "
                    />
                  </v-col>
                  <v-col cols="12" lg="3" class="Form-Columns-Dense">
                    <MultiSelect
                      label="Select Vehicle *"
                      :items="vehicleList"
                      itemText="inventory_number"
                      itemValue="id"
                      :disabled="!formDetails.trip_date"
                      :rules="[(val) => !!val || 'Vehicle is required']"
                      hide-details="auto"
                      v-model="formDetails.vehicle"
                      :error-messages="formErrors.vehicle"
                      @change="delete formErrors.vehicle"
                    />
                  </v-col>

                  <v-col cols="12" lg="3" class="Form-Columns-Dense">
                    <MultiSelect
                      label="Select Trailer 1*"
                      :items="trailer1List"
                      itemText="inventory_number"
                      itemValue="id"
                      :disabled="!formDetails.trip_date"
                      :rules="[(val) => !!val || 'Trailer 1 is required']"
                      hide-details="auto"
                      v-model="formDetails.trailer_1"
                      :error-messages="formErrors.trailer_1"
                      @change="delete formErrors.trailer_1"
                    />
                  </v-col>

                  <v-col cols="12" lg="3" class="Form-Columns-Dense">
                    <MultiSelect
                      label="Select Trailer 2"
                      :items="trailer2List"
                      itemText="inventory_number"
                      itemValue="id"
                      :item-disabled="
                        (item) => {
                          return item.id == formDetails.trailer_1;
                        }
                      "
                      :clearable="true"
                      :disabled="
                        !formDetails.trip_date || !formDetails.trailer_1
                      "
                      hide-details="auto"
                      v-model="formDetails.trailer_2"
                      :error-messages="formErrors.trailer_2"
                      @change="delete formErrors.trailer_2"
                    />
                  </v-col>

                  <v-col
                    cols="12"
                    v-if="
                      formDetails.trip_tags && formDetails.trip_tags.length > 0
                    "
                    class="d-flex align-center"
                  >
                    <span class="text-h6 font-weight-bold">Trip Tags :</span>
                    <v-chip
                      v-for="(tag, i) in formDetails.trip_tags"
                      :key="i"
                      class="ml-2"
                      color="primary"
                      label
                      text-color="white"
                    >
                      <v-icon left> mdi-label </v-icon>
                      {{ tag.tag_name }}
                    </v-chip>
                  </v-col>
                </v-row>
              </v-form>
            </template>
          </FormComponent>
        </v-row>
      </v-card-text>
    </v-card>

    <v-dialog v-model="viewSelectedContract" width="70%">
      <v-card
        v-if="displayingContract && Object.keys(displayingContract).length > 0"
      >
        <v-card-title class="secondary white--text">
          {{ displayingContract.contract_id }} Details
          <v-spacer></v-spacer>

          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="white"
                icon
                @click="viewSelectedContract = false"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </template>
            <span>Close</span>
          </v-tooltip>
        </v-card-title>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import InputField from "@/components/FormBaseComponents/InputField.vue";
import MultiSelect from "@/components/FormBaseComponents/MultiSelect.vue";
import ContainerCard from "@/components/General/ContainerCard.vue";
import FormComponent from "@/components/General/FormComponent.vue";
import DateField from "@/components/FormBaseComponents/DateField.vue";

export default {
  components: {
    InputField,
    MultiSelect,
    ContainerCard,
    FormComponent,
    DateField,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    legId: {
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
      autoFillSlotTimeout: null,
      isTripActive: false,
      trailerListTimeout: null,
      driverListTimeout: null,
      vehicleListTimeout: null,
      today: new Date().toISOString().slice(0, 16),

      isValid: true,
      isAssignmentFormValid: true,

      leg: {},
      formErrors: {},
      formDetails: {
        tags: [],
        auto_assign_driver: true,
      },
      routeDuration: null,

      trailer1List: [],
      trailer2List: [],
      vehicleList: [],
      driverList: [],

      displayingContract: {},
      viewSelectedContract: false,
      getListCalled: false,
    };
  },
  watch: {
    value(val) {
      if (val) {
        this.getLegListObject(this.legId);
      } else {
        this.isTripActive = false;
        this.formDetails = {};
      }
    },
  },
  computed: {
    showTripForm: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    currentProfile() {
      let obj = localStorage.getItem("userProfile");
      return !obj ? obj : JSON.parse(obj);
    },
  },
  methods: {
    getLegListObject(id) {
      this.$bus.$emit("showLoader", true);
      this.$api.trip
        .getLegListObject(id)
        .then((res) => {
          this.$bus.$emit("showLoader", false);
          this.leg = res.data;
          this.getAllocatedAssets();
          if (
            this.leg &&
            this.leg.trip_status &&
            [
              "Enroute",
              "Reached Pickup Location",
              "Reached Drop Location",
            ].indexOf(this.leg.trip_status) > -1
          ) {
            this.isTripActive = true;
          } else {
            this.isTripActive = false;
          }
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    refreshFormDetails() {
      this.formDetails = { ...this.formDetails };
    },
    deleteLegTrip() {
      if (confirm("Are you sure you want to unallocate ?")) {
        this.$bus.$emit("showLoader", true);
        this.$api.trip
          .deleteLegTrip(this.leg.id)
          .then(() => {
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message: "Leg's trip deleted successfully!",
              color: "success",
            });
            this.resetForm();
            this.$emit("refreshList");
          })
          .catch((err) => {
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message: "Couldn't delete leg's trip!",
              color: "error",
            });
          });
      }
    },
    vehicleAssigned() {
      let assignedVehicle = this.driverList.find(
        (dri) => dri.id == this.formDetails.driver
      )?.assigned_vehicle;
      let vehicleExist = this.vehicleList.find(
        (obj) => obj.id == assignedVehicle
      );
      if (vehicleExist) {
        this.formDetails = {
          ...this.formDetails,
          vehicle: assignedVehicle,
        };
      }
    },
    getLists() {
      this.$bus.$emit("showLoader", true);
      return new Promise((resolve, reject) => {
        Promise.all([
          this.getAssetList("Trailer"),
          this.getAssetList("Driver"),
          this.getAssetList("Vehicle"),
        ])
          .then((val) => {
            this.getListCalled = false;
            this.$bus.$emit("showLoader", false);
            resolve();
          })
          .catch((err) => {
            this.getListCalled = false;
            this.$bus.$emit("showLoader", false);
            reject();
          });
      });
    },

    resetForm() {
      this.formDetails = {};
      this.showTripForm = false;
      this.resetFormValidation();
    },
    resetFormValidation() {
      let form = this.$refs.adminTripForm;
      if (form) {
        form.resetValidation();
      }
    },

    checkAssetType() {
      this.formDetails.trailer_1 = null;
      this.formDetails.trailer_2 = null;
      this.formDetails.vehicle = null;
      this.formDetails.driver = null;
      this.resetFormValidation();
      if (this.formDetails.trip_date) {
        if (!this.getListCalled) {
          this.getListCalled = true;
          this.getLists();
        }
      }
    },

    async getAssetList(assetType) {
      const params = {
        ...params,
        asset_type: assetType,
        trip_date: this.formDetails.trip_date,
        leg: this.leg.id,
        tagIds:
          this.formDetails.tags &&
          Array.isArray(this.formDetails.tags) &&
          this.formDetails.tags.length > 0
            ? [...this.formDetails.tags].join()
            : null,
      };

      if (this.leg && this.leg.company) {
        params.company = this.leg.company;
      }

      if (this.formDetails.id) {
        params.trip = this.formDetails.id;
      }

      return this.$api.trip
        .getTripAssetList(this.legId, params)
        .then((res) => {
          if (assetType == "Trailer") {
            this.trailer1List = res.data.trailer1;
            this.trailer2List = res.data.trailer2;
          }
          if (assetType == "Vehicle") {
            this.vehicleList = res.data;
          }
          if (assetType == "Driver") {
            this.driverList = res.data;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },

    getAllocatedAssets() {
      this.$api.trip
        .getAllocatedAssets({ leg: this.leg.id })
        .then((res) => {
          this.formDetails = {
            tags: [],
            ...res.data,
            leg: this.leg.id,
          };
          if (this.formDetails.duration) {
            this.routeDuration = this.formDetails.duration;
          }

          if (this.formDetails.trip_date) {
            this.formDetails.trip_date = res.data.trip_date.replace(" ", "T");
          } else {
            this.formDetails.trip_date = this.leg.da_movement_date_time.slice(
              0,
              10
            );
          }
          setTimeout(() => {
            const field = this.$refs.TripDateField;
            if (field) {
              field.setDefaultDateTime();
            }
          }, 20);

          if (
            this.leg &&
            this.leg.order_containers &&
            this.leg.order_containers.length > 0
          ) {
            this.formDetails.leg_containers = this.leg.order_containers.map(
              (item) => item.id
            );
          }
          if (this.formDetails.trip_date) {
            if (!this.getListCalled) {
              this.getListCalled = true;
              this.getLists()
                .then(() => {
                  this.formDetails.vehicle = res.data.vehicle;
                  this.formDetails.trailer_1 = res.data.trailer_1;
                  this.formDetails.trailer_2 = res.data.trailer_2;
                  this.formDetails.driver = res.data.driver;
                })
                .catch((err) => {
                  console.error(err);
                });
            }
          }
          this.$forceUpdate();
          this.resetFormValidation();
        })
        .catch((err) => {
          console.error(err);
        });
    },

    submitForm() {
      let payload = {
        ...this.formDetails,
      };
      this.$api.trip
        .updateAllocatedAssets(payload)
        .then((res) => {
          this.showTripForm = false;
          this.$emit("refreshList");
          this.$bus.$emit("showToastMessage", {
            message: "Assets updated successfully!",
            color: "success",
          });
        })
        .catch((err) => {
          this.formErrors = err.data;
        });
    },
  },
  beforeDestroy() {
    this.resetFormValidation();
  },
};
</script>

