import {
    transporterContract
  } from "@/utils/constants";
  import {
    handleError,
    handleResponse
  } from "@/utils/functions";
  
  export default (axios) => ({
    getTransporterContractList(params = {}) {
      return new Promise((resolve, reject) => {
        axios
          .get(transporterContract.transporterContractBase, {
            params: params,
          })
          .then((res) => {
            resolve(handleResponse(res));
          })
          .catch((err) => {
            reject(handleError(err));
          });
      });
    },
    addTransporterContract(data) {
      return new Promise((resolve, reject) => {
        axios
          .post(transporterContract.transporterContractBase, data)
          .then((res) => {
            resolve(handleResponse(res));
          })
          .catch((err) => {
            reject(handleError(err));
          });
      });
    },
    closeTransporterContract(payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${transporterContract.transporterContractBase}${payload.id}/close_contract/`, payload)
          .then((res) => {
            resolve(handleResponse(res));
          })
          .catch((err) => {
            reject(handleError(err));
          });
      });
    },
    getTransporterContractObject(id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${transporterContract.transporterContractBase}${id}/`)
          .then((res) => {
            resolve(handleResponse(res));
          })
          .catch((err) => {
            reject(handleError(err));
          });
      });
    },
    getTransporterContractDetails(id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${transporterContract.transporterContractBase}${id}/view/`)
          .then((res) => {
            resolve(handleResponse(res));
          })
          .catch((err) => {
            reject(handleError(err));
          });
      });
    },
    updateTransporterContract(payload) {
      return new Promise((resolve, reject) => {
        axios
          .put(
            `${transporterContract.transporterContractBase}${payload.id}/`,
            payload.data
          )
          .then((res) => {
            resolve(handleResponse(res));
          })
          .catch((err) => {
            reject(handleError(err));
          });
      });
    },
    partiallyUpdateTransporterContract(payload) {
      return new Promise((resolve, reject) => {
        axios
          .patch(
            `${transporterContract.transporterContractBase}${payload.id}/`,
            payload.data
          )
          .then((res) => {
            resolve(handleResponse(res));
          })
          .catch((err) => {
            reject(handleError(err));
          });
      });
    },
    getTransporterContractOptions() {
      return new Promise((resolve, reject) => {
        axios
          .options(transporterContract.transporterContractBase)
          .then((res) => {
            resolve(handleResponse(res));
          })
          .catch((err) => {
            reject(handleError(err));
          });
      });
    },
    renewContract(payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${transporterContract.transporterContractBase}${payload.id}/renew_contract/`,
            payload.data
          )
          .then((res) => {
            resolve(handleResponse(res));
          })
          .catch((err) => {
            reject(handleError(err));
          });
      });
    },
  });