<template>
  <v-row class="pa-0">
    <v-col>
      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-icon
            class="mr-2 ml-1"
            color="primary"
            v-on="on"
            v-bind="attrs"
            @click="viewItem"
          >
            mdi-eye
          </v-icon>
        </template>
        <span>View</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-icon
            class="ml-2 mr-2"
            color="primary"
            v-on="on"
            v-bind="attrs"
            @click="download()"
          >
            mdi-file-download
          </v-icon>
        </template>
        <span>Export</span>
      </v-tooltip>

      <v-tooltip top>
        <template #activator="{ on, attrs }">
          <v-icon
            class="ml-2 mr-1"
            color="primary"
            v-on="on"
            v-bind="attrs"
            @click="viewLogs"
          >
            mdi-information
          </v-icon>
        </template>
        <span>Lrfq Logs</span>
      </v-tooltip>

      <v-tooltip
        top
        v-if="this.params.data && this.params.data.status == 'Active'"
      >
        <template #activator="{ on, attrs }">
          <v-icon
            class="ml-2 mr-1"
            color="primary"
            v-on="on"
            v-bind="attrs"
            @click="closedRFQ"
          >
            mdi-close-circle-outline
          </v-icon>
        </template>
        <span>Closed</span>
      </v-tooltip>
    </v-col>
  </v-row>
</template>

<script>
export default {
  methods: {
    viewItem() {
      this.params.context.parentComponent.viewBid(this.params.data.id);
    },

    viewLogs() {
      this.params.context.parentComponent.showLogs(this.params.data);
    },
    closedRFQ() {
      this.params.context.parentComponent.rfqClosed(this.params.data.id);
    },
    download() {
      this.params.context.parentComponent.downloadLrfqRequest(
        this.params.data.id
      );
    },
  },
};
</script>

<style>
</style>