<template>
  <div>
    <v-tooltip bottom>
      <template #activator="{ on, attrs }">
        <v-btn
          v-if="params && params.data && !params.data.fullInvoiceCreidit"
          depressed
          icon
          v-on="on"
          v-bind="attrs"
          class="ml-1 mr-2"
          @click="editCharges"
        >
          <v-icon color="primary">mdi-pencil</v-icon>
        </v-btn>
      </template>
      <span>Edit Charge</span>
    </v-tooltip>

    <!-- <v-tooltip bottom>
      <template #activator="{ on, attrs }">
        <v-btn
          v-if="params && params.data && !params.data.fullInvoiceCreidit"
          depressed
          icon
          v-on="on"
          v-bind="attrs"
          class="ml-2 mr-1"
          @click="removeDa"
        >
          <v-icon color="error">mdi-trash-can</v-icon>
        </v-btn>
      </template>
      <span>Remove From Credit Note</span>
    </v-tooltip> -->
  </div>
</template>

<script>
export default {
  methods: {
    removeDa() {
      this.params.context.parentComponent.removeDA(this.params.data);
    },

    editCharges() {
      this.params.context.parentComponent.updateAdditionalCharges(
        this.params.data
      );
    },
  },
};
</script>

<style></style>
