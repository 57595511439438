<template>
  <div>
    <v-dialog fullscreen v-model="showLeaseDriverDialog">
      <v-card>
        <v-card-title class="secondary white--text mb-4 rounded-0">
          <span> Lease Driver Bonus </span>

          <v-spacer></v-spacer>

          <v-btn icon @click="resetForm(), (showLeaseDriverDialog = false)">
            <v-icon class="white--text">mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <AgGridVue
            style="width: 100%; height: calc(100vh - 320px)"
            class="ag-theme-alpine"
            id="myGrid"
            :columnDefs="headers"
            :context="context"
            @grid-ready="onGridReady"
            @columnMoved="resizeGrid"
            @columnVisible="resizeGrid"
            :defaultColDef="defaultColDef"
            :grid-options="gridOptions"
            :suppressDragLeaveHidesColumns="true"
            :rowData="allRowList"
          ></AgGridVue>

          <v-col v-if="totalItems > 0" cols="12" class="d-flex justify-end">
            <Pagination
              :pageNo="pageNo"
              :totalItems="totalItems"
              :pageSize="itemsPerPage"
              @itemsPerPageChange="itemsPerPageChanged"
              @prevPage="prevPage"
              @nextPage="nextPage"
            />
          </v-col>
        </v-card-text>
      </v-card>
    </v-dialog>
    <LeaseDriverForm
      v-model="showLeaseDriverForm"
      :editLeaseDriverID="editLeaseDriverID"
      @refreshList="itemsPerPageChanged(20)"
    />
    <DriverLeaseLogDialog
      v-model="showLeaseDriverLogDialog"
      :leaseDriverLogID="editLeaseDriverID"
    />
  </div>
</template>

<script>
import InputField from "@/components/FormBaseComponents/InputField.vue";
import MultiSelect from "@/components/FormBaseComponents/MultiSelect.vue";
import SelectField from "@/components/FormBaseComponents/SelectField.vue";
import { AgGridVue } from "ag-grid-vue";
import DateField from "@/components/FormBaseComponents/DateField.vue";
import Pagination from "@/components/General/Pagination.vue";
import LeaseDriverForm from "@/components/ModuleBased/dialogForms/LeaseDriverForm.vue";
import LeaseDriverButton from "@/components/AgGridComponents/LeaseDriverButton.vue";
import DriverLeaseLogDialog from '@/components/ModuleBased/dialogs/DriverLeaseLogDialog.vue'

export default {
  components: {
    AgGridVue,
    InputField,
    SelectField,
    DateField,
    MultiSelect,
    Pagination,
    LeaseDriverForm,
    LeaseDriverButton,
    DriverLeaseLogDialog
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    leaseDriverUploadId: {
      type: Number,
      default: null,
    },
  },
  watch: {
    value(val) {
      if (val) {
        this.leaseDriverid = this.leaseDriverUploadId;
        this.getLeaseDriverList();
      } else {
        this.resetForm();
      }
    },
  },
  computed: {
    showLeaseDriverDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    context() {
      return { parentComponent: this };
    },
    offset() {
      return this.itemsPerPage * (this.pageNo - 1);
    },
  },
  data() {
    return {
      selectedRows: [],
      leaseDriverid: null,

      countDa: 0,

      formErrors: {},

      showSecondDialog: false,
      allRowList: [],

      showLeaseDriverForm: false,
      showLeaseDriverLogDialog: false,
      editLeaseDriverID: null,

      sorting: {},
      filters: {},
      // pagination vars
      pageNo: 1,
      itemsPerPage: 20,
      totalItems: 0,
      headerSelected: [],
      tab: null,
      // ag-grid vars
      defaultColDef: {
        resizable: true,
      },
      gridOptions: {
        onGridSizeChanged: () => {
          this.resizeGrid();
        },

        suppressRowClickSelection: true,
        suppressDragLeaveHidesColumns: true,
        enableCellTextSelection: true,
        tooltipShowDelay: 0,
        rowHeight: 35,
      },
      headers: [
        {
          headerName: "Employee Name",
          field: "employee_name",
          minWidth: 60,
          sortable: true,
        },
        {
          headerName: "Employee Id",
          field: "emp_id",
          minWidth: 60,
          sortable: true,
        },
        {
          headerName: "No Of Trips",
          field: "no_of_trips",
          minWidth: 60,
          sortable: true,
        },

        {
          headerName: "Total Bonus",
          field: "total_bonus",
          sortable: true,
          minWidth: 90,
        },
        {
          headerName: "Trip Date",
          field: "trip_date",
          sortable: true,
          minWidth: 90,
        },
        {
          headerName: "Actions",
          field: "actions",
          cellRendererFramework: "LeaseDriverButton",
          pinned: "right",
          width: 100,
        },
      ],
    };
  },
  methods: {
    viewLeaseDriverLogs(id) {
      this.editLeaseDriverID = id;
      this.showLeaseDriverLogDialog = true;
    },
    editLeaseDriver(id) {
      this.editLeaseDriverID = id;
      setTimeout(() => {
        this.showLeaseDriverForm = true;
      }, 100);
    },
    resizeGrid() {
      if (this.gridOptions && "api" in this.gridOptions) {
        setTimeout(() => {
          this.gridOptions.api.sizeColumnsToFit();
        }, 100);
      }
    },

    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.resizeGrid();
    },
    headersChanged(value) {
      this.headerSelected = value;
      this.resizeGrid();
    },
    applyGridSort(key, type) {
      if (this.filters && !this.filters.ordering) {
        this.filters.ordering = [];
      }

      if (type == null) {
        delete this.sorting[key];
        this.filters.ordering.splice(
          this.filters.ordering.indexOf(`-${key}`),
          1
        );
      } else if (type == "asc") {
        this.filters.ordering.push(key);
      } else if (type == "desc") {
        this.filters.ordering.splice(
          this.filters.ordering.indexOf(key),
          1,
          `-${key}`
        );
      }
      if (this.filters.ordering.length == 0) {
        delete this.filters.ordering;
      }
      this.itemsPerPageChanged(20);
    },

    searchList() {
      this.countDa = 0;
      this.pageNo = 1;
      this.getLeaseDriverList();
    },
    itemsPerPageChanged(e) {
      this.countDa = 0;
      this.pageNo = 1;
      this.itemsPerPage = e;
      this.getLeaseDriverList();
    },
    prevPage() {
      this.countDa = 0;
      this.pageNo--;
      this.getLeaseDriverList();
    },
    nextPage() {
      this.countDa = 0;
      this.pageNo++;
      this.getLeaseDriverList();
    },
    resetForm() {
      this.allRowList = null;
      this.filters = {};
      this.headerSelected = [];
    },

    getLeaseDriverList() {
      this.$bus.$emit("showLoader", true);
      let params = {
        offset: this.offset,
        limit: this.itemsPerPage,
        ...params,
        ...this.filters,
        lease_driver: this.leaseDriverid,
      };
      if ("ordering" in params && params.ordering.length > 0) {
        params = { ...params, ordering: params.ordering.join(",") };
      }

      this.$api.leaseDriverBonus
        .getLeaseDriverList(params)
        .then((res) => {
          this.allRowList = res.data;
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          this.$bus.$emit("showLoader", false);
          console.error(err);
        });
    },
  },
};
</script>
