var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"persistent":"","max-width":"92%"},model:{value:(_vm.customerInvoiceFormDialog),callback:function ($$v) {_vm.customerInvoiceFormDialog=$$v},expression:"customerInvoiceFormDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"secondary white--text px-4 py-2"},[_c('span',[_vm._v(_vm._s(_vm.isEditing ? "Edit" : "Add")+" Charge")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.customerInvoiceFormDialog = false}}},[_c('v-icon',{staticClass:"white--text"},[_vm._v("mdi-close")])],1)],1),_c('v-card-text',{staticClass:"pa-2"},[_c('v-form',{ref:"customerInvoiceChargeForm",model:{value:(_vm.chargesValid),callback:function ($$v) {_vm.chargesValid=$$v},expression:"chargesValid"}},[_c('v-row',{staticClass:"ma-0"},[_c('v-col',{staticClass:"Form-Columns-Dense",attrs:{"cols":"2"}},[_c('MultiSelect',{attrs:{"hide-details":"auto","items":_vm.allChargeList,"itemText":"charge_title","itemValue":"charge_title","label":"Charge Item *","rules":[(val) => !!val || 'Charge Item is Required!'],"returnObject":true,"readonly":_vm.isReadOnly,"clearable":false,"error-messages":_vm.formErrors.charge_title},on:{"change":function($event){delete _vm.formErrors.charge_title;
                _vm.chargeUpdated($event);}},model:{value:(_vm.daInvoiceCharge.charge_title),callback:function ($$v) {_vm.$set(_vm.daInvoiceCharge, "charge_title", $$v)},expression:"daInvoiceCharge.charge_title"}})],1),_c('v-col',{staticClass:"Form-Columns-Dense",attrs:{"cols":"3"}},[_c('InputField',{attrs:{"hide-details":"auto","type":"text","label":"Description *","readonly":_vm.daInvoiceCharge.charge_type == 'Freight' || _vm.isReadOnly,"clearable":false,"required":"","rules":[(val) => !!val || 'Charge Description is Required!'],"error-messages":_vm.formErrors.description},on:{"input":function($event){delete _vm.formErrors.description}},model:{value:(_vm.daInvoiceCharge.description),callback:function ($$v) {_vm.$set(_vm.daInvoiceCharge, "description", $$v)},expression:"daInvoiceCharge.description"}})],1),_c('v-col',{staticClass:"Form-Columns-Dense",attrs:{"cols":"1"}},[_c('InputField',{attrs:{"hide-details":"auto","type":"text","label":"Revenue GL No.","readonly":"","clearable":false,"required":"","rules":[(val) => !!val || 'Revenue GL No is Required!'],"error-messages":_vm.formErrors.gl_no},on:{"input":function($event){delete _vm.formErrors.gl_no}},model:{value:(_vm.daInvoiceCharge.gl_no),callback:function ($$v) {_vm.$set(_vm.daInvoiceCharge, "gl_no", $$v)},expression:"daInvoiceCharge.gl_no"}})],1),_c('v-col',{staticClass:"Form-Columns-Dense",attrs:{"cols":"1"}},[_c('InputField',{attrs:{"hide-details":"auto","type":"number","step":".01","label":`Rate (${_vm.$globals.currency}) *`,"required":"","rules":[
                (value) =>
                  (value != null && value != undefined) || 'Rate is required',
                (val) => !!val || 'Rate is Required!',
              ],"clearable":false,"error-messages":_vm.formErrors.rate},on:{"input":function($event){delete _vm.formErrors.rate;
                _vm.chargeDataChanged();}},model:{value:(_vm.daInvoiceCharge.rate),callback:function ($$v) {_vm.$set(_vm.daInvoiceCharge, "rate", $$v)},expression:"daInvoiceCharge.rate"}})],1),_c('v-col',{staticClass:"Form-Columns-Dense",attrs:{"cols":"1"}},[_c('InputField',{attrs:{"hide-details":"auto","type":"number","step":".01","label":`Tax (%)`,"clearable":false,"required":"","readonly":"","rules":[(value) => value >= 0 || 'Tax can not be less than 0'],"error-messages":_vm.formErrors.tax_percentage},on:{"input":function($event){delete _vm.formErrors.tax_percentage}},model:{value:(_vm.daInvoiceCharge.tax_percentage),callback:function ($$v) {_vm.$set(_vm.daInvoiceCharge, "tax_percentage", $$v)},expression:"daInvoiceCharge.tax_percentage"}})],1),_c('v-col',{staticClass:"Form-Columns-Dense",attrs:{"cols":"2"}},[_c('InputField',{attrs:{"hide-details":"auto","type":"number","label":`Tax Amount (${_vm.$globals.currency})`,"clearable":false,"readonly":"","value":_vm.daInvoiceCharge.tax_amount}})],1),_c('v-col',{staticClass:"Form-Columns-Dense",attrs:{"cols":"2"}},[_c('InputField',{attrs:{"hide-details":"auto","type":"number","label":`Amount (${_vm.$globals.currency})`,"clearable":false,"readonly":"","value":_vm.daInvoiceCharge.amount_with_tax}})],1)],1)],1)],1),_c('v-divider'),_c('v-card-actions',{staticClass:"d-flex justify-end"},[(!_vm.isEditing)?_c('v-btn',{attrs:{"text":"","depressed":"","color":"primary"},on:{"click":function($event){return _vm.resetForm()}}},[_vm._v(" "+_vm._s(_vm.isEditing ? "Cancel" : "Reset")+" ")]):_vm._e(),_c('v-btn',{attrs:{"disabled":!_vm.chargesValid,"depressed":"","color":"primary"},on:{"click":_vm.submit}},[_vm._v(" "+_vm._s(_vm.isEditing ? "Update" : "Add")+" ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }