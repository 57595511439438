<template>
  <div>
    <v-tooltip top>
      <template #activator="{ on, attrs }">
        <v-icon
          v-if="
            params &&
            params.context &&
            params.context.parentComponent &&
            params.context.parentComponent.permissions &&
            params.context.parentComponent.permissions.change
          "
          class="ml-1 mr-2"
          color="primary"
          v-on="on"
          v-bind="attrs"
          @click="editItem"
        >
          mdi-eye
        </v-icon>
      </template>
      <span>View</span>
    </v-tooltip>

    <v-tooltip top>
      <template #activator="{ on, attrs }">
        <v-icon
          class="ml-2 mr-1"
          color="primary"
          v-on="on"
          v-bind="attrs"
          @click="showLogs"
        >
          mdi-history
        </v-icon>
      </template>
      <span>Logs</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  methods: {
    editItem() {
      this.params.context.parentComponent.editLocation(this.params.data.id);
    },
    showLogs() {
      this.params.context.parentComponent.logs = [];
      this.params.context.parentComponent.locationActivityLogs(
        this.params.data
      );
    },
  },
};
</script>
