<template>
  <v-app>
    <!-- Header -->
    <v-app-bar app>
      <img
        class="Brand-Logo"
        alt="Brand Logo"
        src="@/assets/images/brand-logo.png"
      />
    </v-app-bar>
    <v-container class="mt-16">
      <v-row>
        <v-col cols="12" sm="12">
          <v-card>
            <v-card-title class="primary white--text">
              <span>Asset Details for {{ subHireData.sub_hire_id }}</span>
            </v-card-title>
            <v-card-text>
              <v-form v-model="isValid" ref="bidRequestForm">
                <v-row v-for="(da, index) in allDaList" :key="index">
                  <v-col cols="12" sm="3">
                    <v-text-field
                      v-model="formData.daNumbers[index]"
                      :label="'DA Number'"
                      :value="da"
                      disabled
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3">
                    <div class="flex-container">
                      <v-text-field
                        v-model="formData.drivers[index]"
                        :label="'Driver for DA ' + da"
                      ></v-text-field>
                      <v-btn
                        icon
                        @click="copyToClipboard(formData.drivers[index])"
                      >
                        <v-icon>mdi-content-copy</v-icon>
                      </v-btn>
                    </div>
                  </v-col>
                  <v-col cols="12" sm="3">
                    <div class="flex-container">
                      <v-text-field
                        v-model="formData.vehicles[index]"
                        :label="'Vehicle for DA ' + da"
                      ></v-text-field>
                      <v-btn
                        icon
                        @click="copyToClipboard(formData.vehicles[index])"
                      >
                        <v-icon>mdi-content-copy</v-icon>
                      </v-btn>
                    </div>
                  </v-col>
                  <v-col cols="12" sm="3">
                    <div class="flex-container">
                      <v-text-field
                        v-model="formData.trailers[index]"
                        :label="'Trailer for DA ' + da"
                      ></v-text-field>
                      <v-btn
                        icon
                        @click="copyToClipboard(formData.trailers[index])"
                      >
                        <v-icon>mdi-content-copy</v-icon>
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="justify-end">
              <v-btn
                depressed
                color="primary"
                :disabled="!isValid"
                @click="submitForm"
              >
                <span>Submit</span>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
export default {
  data() {
    return {
      isValid: true,
      subHireData: {},
      allDaList: [],
      allLegId: [],
      formData: {
        drivers: [], // Initialize as an empty array
        vehicles: [], // Initialize as an empty array
        trailers: [], //
        daNumbers: [],
        legId: [],
      },
    };
  },
  methods: {
    copyToClipboard(value) {
      const textField = document.createElement("textarea");
      textField.innerText = value;
      document.body.appendChild(textField);
      textField.select();
      document.execCommand("copy");
      textField.remove();

      this.$bus.$emit("showToastMessage", {
        message: "Copied to clipboard",
        color: "success",
      });
    },
    submitForm() {
      // Handle form submission
      const formDataArray = [];

      for (let i = 0; i < this.allDaList.length; i++) {
        const formDataObj = {
          daNumber: this.formData.daNumbers[i],
          driver: this.formData.drivers[i],
          vehicle: this.formData.vehicles[i],
          trailer: this.formData.trailers[i],
          legId: this.formData.legId[i],
        };
        formDataArray.push(formDataObj);
      }
      this.$bus.$emit("showLoader", true);
      this.$api.unauthoriseHsm
        .submitTransporterAssetDetails(formDataArray)
        .then((res) => {
          this.$bus.$emit("showLoader", false);
          this.$router.push("/app/bid/hsm/success");
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    async getSubHireDetail(params) {
      this.$bus.$emit("showLoader", true);
      params = {
        sub_hire_id: this.$route.params.id,
      };

      await this.$api.unauthoriseHsm
        .getTransporterBRSubHireDetail(params)
        .then((res) => {
          this.subHireData = res.data;
          
          if (this.subHireData.is_asset_updated) {
            // Redirect to the expired page
            this.$router.push("/app/bid/hsm/assetDetailUpdated"); // Replace 'expired-page' with your actual route name
          }
          this.allDaList = res.data.sub_hire_leg.map((da) => {
            return da.da_no;
          });
          this.allLegId = res.data.sub_hire_leg.map((leg) => {
            return leg.id;
          });

          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
  },
  mounted() {
    this.getSubHireDetail()
      .then(() => {
        if (!this.allDaList || this.allDaList.length === 0) {
          console.error("No data available in allDaList.");
          return;
        }
        for (let i = 0; i < this.allDaList.length; i++) {
          console.log(this.allDaList, "this.allDaList");
          this.formData.drivers.push("");
          this.formData.vehicles.push("");
          this.formData.trailers.push("");
          this.formData.daNumbers.push(this.allDaList[i]);
          this.formData.legId.push(this.allLegId[i]);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  },
};
</script>
<style scoped>
.flex-container {
  display: flex;
  align-items: center;
}
.flex-container v-text-field {
  flex: 1; /* Allow text field to grow and take remaining space */
  margin-right: 8px; /* Add some spacing between text field and button */
}
</style>
 





