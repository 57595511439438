<template>
  <FormComponent
    title="Edit Credit DA"
    :backRoute="backRoute"
    :nonFieldErrors="formErrors.non_field_errors"
    :daID="directDaDetails.da_no"
  >
    <template #formActions>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            v-on="on"
            v-bind="attrs"
            class="mx-2"
            @click="getEditDaDetails('previous')"
          >
            mdi-arrow-left-drop-circle
          </v-icon>
        </template>
        <span>Previous</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            v-on="on"
            v-bind="attrs"
            class="mx-2"
            @click="getEditDaDetails('next')"
          >
            mdi-arrow-right-drop-circle
          </v-icon>
        </template>
        <span>Next</span>
      </v-tooltip>

      <v-btn
        color="primary "
        depressed
        :disabled="!allFormValidation"
        @click="submitForm"
      >
        Update
      </v-btn>
    </template>

    <template #formContent>
      <v-stepper v-model="stepper" non-linear flat>
        <v-stepper-header class="elevation-0">
          <v-stepper-step
            :rules="[() => (Object.keys(formErrors).length ? valid1 : true)]"
            step="1"
            editable
          >
            Basic Details
          </v-stepper-step>
          <v-divider class="ma-0"></v-divider>

          <v-stepper-step
            :rules="[() => (Object.keys(formErrors).length ? valid2 : true)]"
            step="2"
            editable
          >
            Document Details
          </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1" class="pa-0">
            <v-form ref="stepperForm1" v-model="valid1">
              <v-row class="mx-2 my-0">
                <v-col cols="12" lg="6" class="Form-Columns-Dense">
                  <span class="text-h6 font-weight-regular">Basic Details</span>
                </v-col>

                <v-col cols="12" lg="6" class="Form-Columns-Dense text-right">
                  <v-btn
                    small
                    v-if="directDaDetails.customer"
                    @click="showCustomerAddressForm = true"
                    color="primary"
                    depressed
                  >
                    Add Address
                  </v-btn>
                </v-col>

                <v-col cols="12" lg="4" class="Form-Columns-Dense">
                  <InputField
                    type="text"
                    label="BOE Number/ Reference Number *"
                    :rules="[(val) => !!val || 'BOE Number is Required!']"
                    required
                    hide-details="auto"
                    v-model="directDaDetails.boe"
                    :error-messages="formErrors.boe"
                    @input="delete formErrors.boe"
                  />
                </v-col>

                <v-col cols="12" lg="4" class="Form-Columns-Dense">
                  <MultiSelect
                    :class="
                      (directDaDetails &&
                        directDaDetails.status &&
                        directDaDetails.status == 'Active') ||
                      directDaDetails.status == 'Completed' ||
                      directDaDetails.sub_hire_bid
                        ? 'grey lighten-3'
                        : ''
                    "
                    label="Select Customer *"
                    :items="allCustomerList"
                    itemText="company_name"
                    itemValue="id"
                    :readonly="
                      (directDaDetails &&
                        directDaDetails.status &&
                        directDaDetails.status == 'Active') ||
                      directDaDetails.status == 'Completed' ||
                      directDaDetails.sub_hire_bid
                    "
                    :returnObject="true"
                    :rules="[(val) => !!val || 'Customer is Required!']"
                    hide-details="auto"
                    v-model="directDaDetails.customer"
                    :error-messages="formErrors.customer"
                    @change="
                      delete formErrors.customer;
                      getCustomerAddressList();
                      getContractList('no');
                      checkCustomer();
                    "
                  />
                </v-col>

                <v-col cols="12" lg="4" class="Form-Columns-Dense">
                  <MultiSelect
                    :class="
                      (directDaDetails &&
                        directDaDetails.status &&
                        directDaDetails.status == 'Active') ||
                      directDaDetails.status == 'Completed' ||
                      directDaDetails.sub_hire_bid
                        ? 'grey lighten-3'
                        : ''
                    "
                    hide-details="auto"
                    label="Job Type *"
                    :items="allJobType"
                    itemText="display_name"
                    itemValue="value"
                    :readonly="
                      (directDaDetails &&
                        directDaDetails.status &&
                        directDaDetails.status == 'Active') ||
                      directDaDetails.status == 'Completed' ||
                      directDaDetails.sub_hire_bid
                    "
                    v-model="directDaDetails.job_type"
                    :rules="[(v) => !!v || 'Job Type is Required']"
                    :error-messages="formErrors.job_type"
                    @change="
                      delete formErrors.job_type;
                      setJobType();
                    "
                  />
                </v-col>

                <v-col cols="12" lg="8" class="Form-Columns-Dense d-flex">
                  <MultiSelect
                    label="Source -> Mid Point -> Destination *"
                    :class="
                      (directDaDetails.customer_contract &&
                        directDaDetails.status &&
                        directDaDetails.status == 'Active') ||
                      directDaDetails.status == 'Completed' ||
                      directDaDetails.sub_hire_bid
                        ? 'contract-field-width float-left grey lighten-3'
                        : ''
                    "
                    :items="allCustomerContractList"
                    itemText="customer_contract_lane_title"
                    itemValue="id"
                    :readonly="
                      (directDaDetails &&
                        directDaDetails.status &&
                        directDaDetails.status == 'Active') ||
                      directDaDetails.status == 'Completed' ||
                      directDaDetails.sub_hire_bid
                    "
                    :returnObject="true"
                    :rules="[(v) => !!v || 'Location is Required']"
                    hide-details="auto"
                    v-model="directDaDetails.customer_contract"
                    :error-messages="formErrors.customer_contract"
                    @change="
                      delete formErrors.customer_contract;
                      setContainersType();
                      updateselectedContract();
                    "
                  />

                  <div v-if="directDaDetails.customer_contract">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          depressed
                          color="primary text-white"
                          v-bind="attrs"
                          v-on="on"
                          @click="customerContractDetails = true"
                          class="ml-3"
                        >
                          {{ directDaDetails.customer_contract.contract_id }}
                        </v-btn>
                      </template>
                      <span>Tariff Details</span>
                    </v-tooltip>
                  </div>
                </v-col>
                <v-col cols="12" lg="3" class="Form-Columns-Dense">
                  <v-btn
                    color="secondary"
                    depressed
                    @click="showAddCustomerContractDialog = true"
                    class="ml-auto"
                    v-if="
                      directDaDetails.job_type &&
                      directDaDetails.customer &&
                      contrPermissions &&
                      contrPermissions.add
                    "
                  >
                    Add Tariff
                  </v-btn>
                </v-col>

                <v-col cols="12" v-if="directDaDetails.customer_contract">
                  <v-row>
                    <v-col
                      v-if="
                        directDaDetails.customer_contract &&
                        directDaDetails.customer_contract.need_addresses_for &&
                        directDaDetails.customer_contract.need_addresses_for
                          .source
                      "
                      cols="12"
                      lg="3"
                      class="Form-Columns-Dense"
                    >
                      <div class="d-flex">
                        <MultiSelect
                          :class="
                            (directDaDetails &&
                              directDaDetails.status &&
                              directDaDetails.status == 'Active') ||
                            directDaDetails.status == 'Completed'
                              ? 'grey lighten-3'
                              : ''
                          "
                          ref="`assigned_source_address"
                          label="Source Address *"
                          :items="allCustomerAddressList"
                          itemText="name"
                          itemValue="id"
                          :returnObject="true"
                          :readonly="
                            (directDaDetails &&
                              directDaDetails.status &&
                              directDaDetails.status == 'Active') ||
                            directDaDetails.status == 'Completed'
                          "
                          :rules="[
                            (val) => !!val || 'Source Address is Required!',
                          ]"
                          :item-disabled="
                            (item) => {
                              return item.id == midId;
                            }
                          "
                          hide-details="auto"
                          v-model="directDaDetails.assigned_source_address"
                          :error-messages="formErrors.assigned_source_address"
                          @change="
                            delete formErrors.assigned_source_address;
                            checkData();
                          "
                        />
                        <v-menu
                          open-on-hover
                          left
                          offset-y
                          v-if="directDaDetails.assigned_source_address"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon v-on="on" v-bind="attrs" class="mx-2">
                              mdi-information
                            </v-icon>
                          </template>
                          <v-card>
                            <v-card-title
                              class="secondary py-1 text-subtitle-1 white--text font-weight-medium"
                            >
                              Source Address Information
                            </v-card-title>
                            <v-card-text class="mt-2">
                              <table class="Normal-Table">
                                <tbody>
                                  <tr
                                    v-if="
                                      directDaDetails.assigned_source_address
                                        .address
                                    "
                                  >
                                    <th>Address</th>
                                    <th class="px-2">:</th>
                                    <td>
                                      {{
                                        directDaDetails.assigned_source_address
                                          .address
                                      }}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </v-card-text>
                          </v-card>
                        </v-menu>
                      </div>
                    </v-col>

                    <v-col
                      v-if="
                        directDaDetails.customer_contract &&
                        directDaDetails.customer_contract.need_addresses_for &&
                        directDaDetails.customer_contract.need_addresses_for.mid
                      "
                      cols="12"
                      lg="3"
                      class="Form-Columns-Dense"
                    >
                      <div class="d-flex">
                        <MultiSelect
                          :class="
                            (directDaDetails &&
                              directDaDetails.status &&
                              directDaDetails.status == 'Active') ||
                            directDaDetails.status == 'Completed'
                              ? 'grey lighten-3'
                              : ''
                          "
                          label="Mid Point Address *"
                          :items="allCustomerAddressList"
                          itemText="name"
                          itemValue="id"
                          :returnObject="true"
                          :readonly="
                            (directDaDetails &&
                              directDaDetails.status &&
                              directDaDetails.status == 'Active') ||
                            directDaDetails.status == 'Completed'
                          "
                          :rules="[
                            (val) => !!val || 'Mid Point Address is Required!',
                          ]"
                          :item-disabled="
                            (item) => {
                              return (
                                item.id == sourceId || item.id == destinationId
                              );
                            }
                          "
                          hide-details="auto"
                          v-model="directDaDetails.assigned_mid_address"
                          :error-messages="formErrors.assigned_mid_address"
                          @change="
                            delete formErrors.assigned_mid_address;
                            checkData();
                          "
                          @input="delete formErrors.assigned_mid_address"
                        />

                        <v-menu
                          open-on-hover
                          left
                          offset-y
                          v-if="directDaDetails.assigned_mid_address"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon v-on="on" v-bind="attrs" class="mx-2">
                              mdi-information
                            </v-icon>
                          </template>
                          <v-card>
                            <v-card-title
                              class="secondary py-1 text-subtitle-1 white--text font-weight-medium"
                            >
                              Mid Point Address Information
                            </v-card-title>
                            <v-card-text class="mt-2">
                              <table class="Normal-Table">
                                <tbody>
                                  <tr
                                    v-if="
                                      directDaDetails.assigned_mid_address
                                        .address
                                    "
                                  >
                                    <th>Address</th>
                                    <th class="px-2">:</th>
                                    <td>
                                      {{
                                        directDaDetails.assigned_mid_address
                                          .address
                                      }}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </v-card-text>
                          </v-card>
                        </v-menu>
                      </div>
                    </v-col>

                    <v-col
                      v-if="
                        directDaDetails.customer_contract &&
                        directDaDetails.customer_contract.need_addresses_for &&
                        directDaDetails.customer_contract.need_addresses_for
                          .destination
                      "
                      cols="12"
                      lg="3"
                      class="Form-Columns-Dense"
                    >
                      <div class="d-flex">
                        <MultiSelect
                          :class="
                            (directDaDetails &&
                              directDaDetails.status &&
                              directDaDetails.status == 'Active') ||
                            directDaDetails.status == 'Completed'
                              ? 'grey lighten-3'
                              : ''
                          "
                          label="Destination Address *"
                          :items="allCustomerAddressList"
                          itemText="name"
                          itemValue="id"
                          :returnObject="true"
                          :readonly="
                            (directDaDetails &&
                              directDaDetails.status &&
                              directDaDetails.status == 'Active') ||
                            directDaDetails.status == 'Completed'
                          "
                          :rules="[
                            (val) =>
                              !!val || 'Destination Address is Required!',
                          ]"
                          :item-disabled="
                            (item) => {
                              return item.id == midId;
                            }
                          "
                          hide-details="auto"
                          v-model="directDaDetails.assigned_destination_address"
                          :error-messages="
                            formErrors.assigned_destination_address
                          "
                          @change="
                            formErrors.assigned_destination_address = null;
                            checkData();
                          "
                        />

                        <v-menu
                          open-on-hover
                          left
                          offset-y
                          v-if="directDaDetails.assigned_destination_address"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon v-on="on" v-bind="attrs" class="mx-2">
                              mdi-information
                            </v-icon>
                          </template>
                          <v-card>
                            <v-card-title
                              class="secondary py-1 text-subtitle-1 white--text font-weight-medium"
                            >
                              Destination Address Information
                            </v-card-title>
                            <v-card-text class="mt-2">
                              <table class="Normal-Table">
                                <tbody>
                                  <tr
                                    v-if="
                                      directDaDetails
                                        .assigned_destination_address.address
                                    "
                                  >
                                    <th>Address</th>
                                    <th class="px-2">:</th>
                                    <td>
                                      {{
                                        directDaDetails
                                          .assigned_destination_address.address
                                      }}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </v-card-text>
                          </v-card>
                        </v-menu>
                      </div>
                    </v-col>
                    <v-col cols="12" lg="3" class="Form-Columns-Dense">
                      <MultiSelect
                        :class="
                          (directDaDetails &&
                            directDaDetails.status &&
                            directDaDetails.status == 'Active') ||
                          directDaDetails.status == 'Completed'
                            ? 'grey lighten-3'
                            : ''
                        "
                        :items="milestoneList"
                        itemText="milestone_name"
                        itemValue="id"
                        label="Select Milestones"
                        required
                        :multiple="true"
                        :chips="true"
                        hide-details="auto"
                        :readonly="
                          (directDaDetails &&
                            directDaDetails.status &&
                            directDaDetails.status == 'Active') ||
                          directDaDetails.status == 'Completed'
                        "
                        v-model="directDaDetails.milestones"
                        :error-messages="formErrors.milestones"
                        @change="delete formErrors.milestones"
                      ></MultiSelect>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="12" lg="2" class="Form-Columns-Dense">
                  <v-switch
                    :label="`Genset : ${directDaDetails.has_genset}`"
                    true-value="Yes"
                    false-value="No"
                    hide-details="auto"
                    :readonly="
                      (directDaDetails &&
                        directDaDetails.status &&
                        directDaDetails.status == 'Active') ||
                      directDaDetails.status == 'Completed'
                    "
                    v-model="directDaDetails.has_genset"
                    :error-messages="formErrors.has_genset"
                    @input="formErrors.has_genset = null"
                    class="mt-1"
                  />
                </v-col>

                <v-col cols="12" lg="2">
                  <v-row>
                    <v-col cols="12" lg="9" class="Form-Columns-Dense">
                      <v-switch
                        :label="`Hazmat : ${directDaDetails.is_hazmat}`"
                        true-value="Yes"
                        false-value="No"
                        hide-details="auto"
                        :readonly="
                          (directDaDetails &&
                            directDaDetails.status &&
                            directDaDetails.status == 'Active') ||
                          directDaDetails.status == 'Completed'
                        "
                        v-model="directDaDetails.is_hazmat"
                        :error-messages="formErrors.is_hazmat"
                        @input="formErrors.is_hazmat = null"
                        class="mt-1"
                      />
                    </v-col>
                    <v-col cols="12" lg="3" class="Form-Columns-Dense">
                      <v-btn
                        icon
                        class="red--text"
                        v-if="directDaDetails.is_hazmat == 'Yes'"
                        @click="showHazardousDialog = true"
                      >
                        <v-icon>mdi-alert</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="12" lg="2" class="Form-Columns-Dense">
                  <MultiSelect
                    :class="
                      (directDaDetails &&
                        directDaDetails.status &&
                        directDaDetails.status == 'Active') ||
                      directDaDetails.status == 'Completed'
                        ? 'grey lighten-3'
                        : ''
                    "
                    label="Currency *"
                    :items="currencyList"
                    itemText="currency_code"
                    itemValue="currency_code"
                    :readonly="
                      (directDaDetails &&
                        directDaDetails.status &&
                        directDaDetails.status == 'Active') ||
                      directDaDetails.status == 'Completed'
                    "
                    :rules="[(val) => !!val || 'Currency is Required!']"
                    hide-details="auto"
                    v-model="directDaDetails.currency"
                    :error-messages="formErrors.currency"
                    @change="formErrors.currency = null"
                  ></MultiSelect>
                </v-col>

                <v-col cols="12" lg="2" class="Form-Columns-Dense">
                  <DateField
                    :inputProps="{
                      dense: true,
                      label: 'Movement Date *',
                      rules: [(val) => !!val || 'Movement Date is Required!'],
                      'hide-details': 'auto',
                      'error-messages': formErrors.movement_date,
                      class:
                        (directDaDetails &&
                          directDaDetails.status &&
                          directDaDetails.status == 'Active') ||
                        directDaDetails.status == 'Completed'
                          ? 'grey lighten-3'
                          : '',
                    }"
                    :readonly="
                      (directDaDetails &&
                        directDaDetails.status &&
                        directDaDetails.status == 'Active') ||
                      directDaDetails.status == 'Completed'
                    "
                    required
                    v-model="directDaDetails.movement_date"
                    @input="delete formErrors.movement_date"
                  />
                </v-col>

                <v-col cols="12" lg="2" class="Form-Columns-Dense">
                  <InputField
                    :class="
                      (directDaDetails &&
                        directDaDetails.status &&
                        directDaDetails.status == 'Active') ||
                      directDaDetails.status == 'Completed'
                        ? 'grey lighten-3'
                        : ''
                    "
                    type="time"
                    label="Movement Time *"
                    :rules="[(val) => !!val || 'Movement Time is Required!']"
                    required
                    hide-details="auto"
                    :readonly="
                      (directDaDetails &&
                        directDaDetails.status &&
                        directDaDetails.status == 'Active') ||
                      directDaDetails.status == 'Completed'
                    "
                    v-model="directDaDetails.movement_time"
                    :error-messages="formErrors.movement_time"
                    @input="delete formErrors.movement_time"
                  />
                </v-col>

                <v-col cols="12" lg="2" class="Form-Columns-Dense">
                  <MultiSelect
                    label="Select Branch *"
                    :items="allBranchList"
                    itemText="branch_title"
                    itemValue="id"
                    :rules="[(val) => !!val || 'Branch is Required!']"
                    hide-details="auto"
                    v-model="directDaDetails.branch"
                    :error-messages="formErrors.branch"
                    @change="delete formErrors.branch"
                  ></MultiSelect>
                </v-col>

                <v-col cols="12" lg="2" class="Form-Columns-Dense">
                  <MultiSelect
                    label="Select Depot *"
                    :items="allDepotList"
                    itemText="depot_name"
                    itemValue="id"
                    :rules="[(val) => !!val || 'Depot is Required!']"
                    hide-details="auto"
                    v-model="directDaDetails.depot"
                    :error-messages="formErrors.depot"
                    @change="delete formErrors.depot"
                  ></MultiSelect>
                </v-col>

                <v-col cols="12" lg="2" class="Form-Columns-Dense">
                  <InputField
                    :class="
                      (directDaDetails &&
                        directDaDetails.status &&
                        directDaDetails.status == 'Active') ||
                      directDaDetails.status == 'Completed'
                        ? 'grey lighten-3'
                        : ''
                    "
                    type="text"
                    label="Purchase Order"
                    hide-details="auto"
                    :readonly="
                      (directDaDetails &&
                        directDaDetails.status &&
                        directDaDetails.status == 'Active') ||
                      directDaDetails.status == 'Completed'
                    "
                    v-model="directDaDetails.po_no"
                    :error-messages="formErrors.po_no"
                    @input="delete formErrors.po_no"
                  />
                </v-col>

                <v-col cols="12" lg="2" class="Form-Columns-Dense">
                  <InputField
                    :class="
                      (directDaDetails &&
                        directDaDetails.status &&
                        directDaDetails.status == 'Active') ||
                      directDaDetails.status == 'Completed'
                        ? 'grey lighten-3'
                        : ''
                    "
                    type="text"
                    label="Shipment Number"
                    hide-details="auto"
                    :readonly="
                      (directDaDetails &&
                        directDaDetails.status &&
                        directDaDetails.status == 'Active') ||
                      directDaDetails.status == 'Completed'
                    "
                    v-model="directDaDetails.shipment_no"
                    :error-messages="formErrors.shipment_no"
                    @input="delete formErrors.shipment_no"
                  />
                </v-col>

                <v-col cols="12" lg="2" class="Form-Columns-Dense">
                  <InputField
                    :class="
                      (directDaDetails &&
                        directDaDetails.status &&
                        directDaDetails.status == 'Active') ||
                      directDaDetails.status == 'Completed'
                        ? 'grey lighten-3'
                        : ''
                    "
                    type="text"
                    label="Vessel Number"
                    hide-details="auto"
                    :readonly="
                      (directDaDetails &&
                        directDaDetails.status &&
                        directDaDetails.status == 'Active') ||
                      directDaDetails.status == 'Completed'
                    "
                    v-model="directDaDetails.vessel_no"
                    :error-messages="formErrors.vessel_no"
                    @input="delete formErrors.vessel_no"
                  />
                </v-col>

                <v-col
                  cols="12"
                  lg="2"
                  class="Form-Columns-Dense"
                  v-if="directDaDetails && directDaDetails.sub_hire_checklist"
                >
                  <v-switch
                    hide-details="auto"
                    label="Sub Hire"
                    true-value="Yes"
                    false-value="No"
                    class="mt-1"
                    :readonly="
                      (directDaDetails &&
                        directDaDetails.status &&
                        directDaDetails.status == 'Active') ||
                      directDaDetails.status == 'Completed' ||
                      directDaDetails.sub_hire_bid
                    "
                    v-model="directDaDetails.is_sub_hire"
                    :error-messages="formErrors.is_sub_hire"
                    @change="delete formErrors.is_sub_hire"
                  ></v-switch>
                </v-col>
                <v-col cols="12" lg="2" class="Form-Columns-Dense">
                  <InputField
                    type="number"
                    label="Cost Rate"
                    hide-details="auto"
                    v-model="directDaDetails.cost_rate"
                    :error-messages="formErrors.cost_rate"
                    @input="delete formErrors.cost_rate"
                  />
                </v-col>
                <v-col cols="12" lg="2" class="Form-Columns-Dense">
                  <InputField
                    type="text"
                    label="Sales Representative *"
                    :clearable="false"
                    hide-details="auto"
                    v-model="directDaDetails.sales_representative"
                    :error-messages="formErrors.sales_representative"
                    @input="delete formErrors.sales_representative"
                  />
                </v-col>
              </v-row>

              <v-col cols="12">
                <div class="d-flex">
                  <span class="text-h6 font-weight-regular">
                    Container Details
                  </span>
                  <v-spacer />
                  <v-btn
                    color="primary"
                    depressed
                    small
                    @click="
                      directDaDetails.order_containers.push({});
                      formFieldHints.push({});
                    "
                    :disabled="
                      directDaDetails.order_containers.length >= 4 ||
                      (directDaDetails.status &&
                        directDaDetails.status == 'Active') ||
                      directDaDetails.status == 'Completed' ||
                      directDaDetails.sub_hire_bid
                    "
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </div>
                <v-divider class="mt-1" />
              </v-col>

              <v-col cols="12">
                <v-row
                  v-for="(con, i) in directDaDetails.order_containers"
                  :key="i"
                >
                  <v-col cols="1" class="d-flex align-center">
                    <span class="text-h6 font-weight-regular">
                      # {{ i + 1 }}
                    </span>
                  </v-col>

                  <v-col cols="10">
                    <v-row>
                      <v-col cols="12" lg="4" class="Form-Columns-Dense">
                        <InputField
                          type="text"
                          placeholder="ABCD1234567"
                          label="Container Number *"
                          :rules="[
                            (v) => !!v || 'Container Number is Required',
                          ]"
                          hide-details="auto"
                          persistent-hint
                          :hint="formFieldHints[i].container_no"
                          v-model="
                            directDaDetails.order_containers[i].container_no
                          "
                          :error-messages="
                            formErrors &&
                            formErrors.order_containers &&
                            formErrors.order_containers[i] &&
                            formErrors.order_containers[i].container_no
                              ? formErrors.order_containers[i].container_no
                              : []
                          "
                          @input="
                            formErrors &&
                            formErrors.order_containers &&
                            formErrors.order_containers[i] &&
                            formErrors.order_containers[i].container_no
                              ? (delete formErrors.order_containers[i][
                                  'container_no'
                                ],
                                checkFormErrors())
                              : []
                          "
                        />
                      </v-col>

                      <v-col cols="12" lg="4" class="Form-Columns-Dense">
                        <MultiSelect
                          :class="
                            (directDaDetails &&
                              directDaDetails.status &&
                              directDaDetails.status == 'Active') ||
                            directDaDetails.status == 'Completed' ||
                            directDaDetails.sub_hire_bid
                              ? 'grey lighten-3'
                              : ''
                          "
                          label="Container Type *"
                          :items="allContainersType"
                          itemText="container_type_title"
                          itemValue="container_type"
                          :rules="[(v) => !!v || 'Container Type is Required']"
                          hide-details="auto"
                          :readonly="
                            (directDaDetails &&
                              directDaDetails.status &&
                              directDaDetails.status == 'Active') ||
                            directDaDetails.status == 'Completed' ||
                            directDaDetails.sub_hire_bid
                          "
                          v-model="
                            directDaDetails.order_containers[i].container_type
                          "
                          :error-messages="
                            formErrors &&
                            formErrors.order_containers &&
                            formErrors.order_containers[i] &&
                            formErrors.order_containers[i].container_type
                              ? formErrors.order_containers[i].container_type
                              : []
                          "
                          @change="
                            formErrors &&
                            formErrors.order_containers &&
                            formErrors.order_containers[i] &&
                            formErrors.order_containers[i].container_type
                              ? (delete formErrors.order_containers[i]
                                  .container_type,
                                checkFormErrors())
                              : []
                          "
                        />
                      </v-col>

                      <v-col cols="12" lg="4" class="Form-Columns-Dense">
                        <InputField
                          type="number"
                          label="Container Weight (Tons) *"
                          step=".01"
                          :rules="[
                            (v) => !!v || 'Container Weight is Required',
                            (v) =>
                              v > 0 ||
                              'Container Weight can not be less than 0',
                            (v) =>
                              $globals.containerWeightFormatRegExp.test(v) ||
                              'Container Weight must have the format 00.00',
                          ]"
                          hide-details="auto"
                          v-model="directDaDetails.order_containers[i].weight"
                          :error-messages="
                            formErrors &&
                            formErrors.order_containers &&
                            formErrors.order_containers[i] &&
                            formErrors.order_containers[i].weight
                              ? formErrors.order_containers[i].weight
                              : []
                          "
                          @input="
                            formErrors &&
                            formErrors.order_containers &&
                            formErrors.order_containers[i] &&
                            formErrors.order_containers[i].weight
                              ? (delete formErrors.order_containers[i].weight,
                                checkFormErrors())
                              : []
                          "
                        />
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="12" lg="1" class="align-self-center text-right">
                    <v-btn
                      color="secondary"
                      depressed
                      small
                      :disabled="
                        i == 0 ||
                        (directDaDetails.status &&
                          directDaDetails.status == 'Active') ||
                        directDaDetails.status == 'Completed' ||
                        directDaDetails.sub_hire_bid
                      "
                      @click="removeContainer(i)"
                    >
                      <v-icon>mdi-minus</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" class="mt-6">
                <v-btn color="primary" @click="stepper = 2" :disabled="!valid1">
                  Next
                </v-btn>
              </v-col>
            </v-form>
          </v-stepper-content>

          <v-stepper-content step="2" class="pa-0">
            <v-form ref="stepperForm2" v-model="valid2">
              <v-row class="mx-2 my-0">
                <v-col cols="12" class="Form-Columns-Dense">
                  <span class="text-h6 font-weight-regular">
                    Upload Documents
                  </span>
                </v-col>

                <v-col cols="12" sm="6" md="4" class="Form-Columns-Dense">
                  <InputField
                    type="text"
                    label="Document Type"
                    hide-details="auto"
                    v-model="document_type"
                    :error-messages="formErrors.document_type"
                    @input="delete formErrors.document_type"
                  />
                </v-col>

                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                  lg="7"
                  class="Form-Columns-Dense"
                >
                  <v-file-input
                    label="Document"
                    outlined
                    dense
                    :rules="[
                      (file) =>
                        !file ||
                        file.size < 2e6 ||
                        'File size should be less than 2 MB!',
                    ]"
                    accept="image/x-png,image/jpg,image/jpeg,application/pdf"
                    hide-details="auto"
                    v-model="document"
                    :error-messages="formErrors.document"
                    @input="delete formErrors.document"
                  />
                </v-col>

                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                  lg="1"
                  class="Form-Columns-Dense"
                >
                  <v-btn
                    color="primary"
                    depressed
                    :disabled="!document || !document_type || !valid2"
                    @click="addDocument"
                  >
                    Add
                  </v-btn>
                </v-col>

                <v-col cols="12" class="Form-Columns-Dense my-6">
                  <v-btn color="secondary" depressed @click="stepper = 1">
                    Previous
                  </v-btn>
                </v-col>

                <v-col
                  v-show="
                    directDaDetails.da_documents &&
                    directDaDetails.da_documents.length
                  "
                  cols="12"
                  class="px-2 pt-2 pb-0"
                >
                  <span class="text-h6 font-weight-regular"> Uploaded </span>
                </v-col>

                <v-col
                  v-show="
                    directDaDetails.da_documents &&
                    directDaDetails.da_documents.length
                  "
                  cols="12"
                  class="pa-2"
                >
                  <v-card elevation="0" outlined>
                    <v-card-text class="py-1 px-0">
                      <v-row
                        class="ma-0"
                        v-for="(data, i) in directDaDetails.da_documents"
                        :key="i"
                      >
                        <v-col cols="12" lg="5" class="py-2">
                          <a :href="data.document" target="_black">
                            <span>{{ data.document_type }}</span>
                          </a>
                        </v-col>

                        <v-col cols="12" lg="3" class="py-2">
                          Created on : {{ data.created | formatDate(true) }}
                        </v-col>

                        <v-col cols="12" lg="3" class="py-2">
                          Added By : {{ data.added_by }}
                        </v-col>

                        <v-col cols="12" lg="1" class="text-right py-2">
                          <v-icon
                            small
                            color="red"
                            @click="deleteDocument(data, i)"
                          >
                            mdi-close
                          </v-icon>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>

                <v-col
                  v-show="daDocuments && daDocuments.length"
                  cols="12"
                  class="px-2 pt-2 pb-0"
                >
                  <span class="text-h6 font-weight-regular"> New Upload </span>
                </v-col>

                <v-col
                  v-show="daDocuments && daDocuments.length"
                  cols="12"
                  class="pa-2"
                >
                  <v-card elevation="0" outlined>
                    <v-card-text class="py-1 px-0">
                      <v-row
                        class="ma-0"
                        v-for="(data, i) in daDocuments"
                        :key="i"
                      >
                        <v-col cols="12" lg="5" class="py-2">
                          <a :href="generateUrl(data.document)" target="_black">
                            <span>{{ data.document_type }}</span>
                          </a>
                        </v-col>

                        <v-col cols="12" lg="5" class="py-2">
                          <a :href="generateUrl(data.document)" target="_black">
                            <span>{{ data.document.name }}</span>
                          </a>
                        </v-col>

                        <v-col cols="6" lg="1" class="py-2">
                          <span>{{ getSize(data.document.size) }}</span>
                        </v-col>

                        <v-col cols="6" lg="1" class="text-right py-2">
                          <v-icon small color="primary" @click="removeFile(i)">
                            mdi-close
                          </v-icon>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-form>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>

      <!-- <v-dialog v-model="customerContractDetails" width="500">
        <v-card v-if="directDaDetails.customer_contract">
          <v-card-title class="secondary white--text">
            Tariff Id -
            {{ directDaDetails.customer_contract.contract_id }}

            <v-spacer></v-spacer>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="white"
                  icon
                  @click="customerContractDetails = false"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </template>
              <span>Close</span>
            </v-tooltip>
          </v-card-title>

          <v-card-text>
            <table class="Normal-Table my-2">
              <tbody>
                <tr>
                  <td>Customer Name</td>
                  <th class="px-2">:</th>
                  <th>
                    {{ directDaDetails.customer_contract.customer_name }}
                  </th>
                </tr>

                <tr v-if="directDaDetails.customer_contract.source_name">
                  <td>Source</td>
                  <th class="px-2">:</th>
                  <th>
                    {{ directDaDetails.customer_contract.source_name }}
                  </th>
                </tr>

                <tr v-if="directDaDetails.customer_contract.mid_point_name">
                  <td>Mid Point</td>
                  <th class="px-2">:</th>
                  <th>
                    {{ directDaDetails.customer_contract.mid_point_name }}
                  </th>
                </tr>

                <tr v-if="directDaDetails.customer_contract.destination_name">
                  <td>Destination</td>
                  <th class="px-2">:</th>
                  <th>
                    {{ directDaDetails.customer_contract.destination_name }}
                  </th>
                </tr>

                <tr>
                  <td>Job Type</td>
                  <th class="px-2">:</th>
                  <th>
                    {{ directDaDetails.customer_contract.job_type }}
                  </th>
                </tr>

                <tr>
                  <td>Start Date</td>
                  <th class="px-2">:</th>
                  <th>
                    {{
                      directDaDetails.customer_contract.start_date | formatDate
                    }}
                  </th>
                </tr>

                <tr>
                  <td>End Date</td>
                  <th class="px-2">:</th>
                  <th>
                    {{
                      directDaDetails.customer_contract.end_date | formatDate
                    }}
                  </th>
                </tr>
              </tbody>
            </table>

            <v-divider></v-divider>

            <table class="Full-Width">
              <tbody>
                <tr>
                  <td>
                    <div class="my-2">
                      <span>Remarks</span>
                      <p class="font-weight-bold mb-0">
                        {{ directDaDetails.customer_contract.admin_remarks }}
                      </p>
                    </div>
                  </td>
                </tr>

                <tr>
                  <td>
                    <v-divider></v-divider>
                    <table class="Full-Width text-left mt-2">
                      <thead>
                        <tr>
                          <th>Container Type</th>
                          <th class="text-right">Container Count</th>
                          <th class="text-right">
                            Container Rate ({{ $globals.currency }})
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(container, i) in directDaDetails
                            .customer_contract.container_types"
                          :key="i"
                        >
                          <td>{{ container.container_type_title }}</td>
                          <td class="text-right">
                            {{ container.container_count }}
                          </td>
                          <td class="text-right">{{ container.rate }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </v-card-text>
        </v-card>
      </v-dialog> -->
      <CustomerContractDetailsDialog
        v-model="customerContractDetails"
        :customerContractId="selectedContractId"
      />
      <CustomerContractDialog
        v-model="showAddCustomerContractDialog"
        :customerId="directDaDetails.customer"
        :jobType="directDaDetails.job_type"
        @closeAddressForm="showAddCustomerContractDialog = false"
        @refreshList="getContractList('no')"
      />
      <CustomerAddressForm
        v-model="showCustomerAddressForm"
        :customerId="directDaDetails.customer"
        module="Customer"
        @closeAddressForm="showCustomerAddressForm = false"
        @refreshList="getCustomerAddressList"
      />
      <AdminDAHazardousForm
        v-model="showHazardousDialog"
        dialogTitle="DA Hazardous Cargo"
        :daHazardousFormDetails="daHazardousFormDetails"
        @saveDAHazardousForm="saveDAHazardousForm"
      />
    </template>
  </FormComponent>
</template>

<script>
import FormComponent from "@/components/General/FormComponent.vue";
import InputField from "@/components/FormBaseComponents/InputField.vue";
import MultiSelect from "@/components/FormBaseComponents/MultiSelect.vue";
import Tooltip from "@/components/General/Tooltip.vue";
import CustomerAddressForm from "@/components/ModuleBased/dialogForms/CustomerAddressForm.vue";
import CustomerContractDialog from "@/components/ModuleBased/dialogForms/CustomerContractDialog.vue";
import DateField from "@/components/FormBaseComponents/DateField.vue";
import AdminDAHazardousForm from "@/components/ModuleBased/dialogForms/AdminDAHazardousForm.vue";
import CustomerContractDetailsDialog from "@/components/ModuleBased/dialogs/CustomerContractDetailsDialog.vue";

import {
  isAuthenticated,
  hasRouteAccess,
  getPermissions,
} from "@/utils/functions";

export default {
  components: {
    FormComponent,
    InputField,
    MultiSelect,
    Tooltip,
    CustomerContractDialog,
    CustomerAddressForm,
    DateField,
    AdminDAHazardousForm,
    CustomerContractDetailsDialog,
  },

  data() {
    return {
      getListCalled: false,
      showHazardousDialog: false,
      hazardousId: null,
      daHazardousFormDetails: {},

      formFieldHints: [{}],

      callTariffObj: false,

      showAddCustomerContractDialog: false,
      customerContractDetails: false,
      showCustomerAddressForm: false,
      directOrderId: null,
      selectedContractId: null,

      directDaDetails: {
        has_genset: "No",
        is_hazmat: "No",
        order_containers: [{}],
        milestones: [],
      },

      formErrors: {},

      allCustomerContractList: [],
      allCustomerAddressList: [],

      displayDetails: [{}],
      allContainersType: [],
      allBranchList: [],
      allCustomerList: [],
      milestoneList: [],
      currencyList: [],
      allJobType: [],
      allDepotList: [],

      document_type: null,
      document: null,
      daDocuments: [],

      stepper: "1",
      valid1: true,
      valid2: true,
      jobType: null,

      sourceId: null,
      midId: null,
      destinationId: null,
      backRoute: { path: "/app/admin/order/list" },

      //to check container type changed
      defaultData: {
        has_genset: "No",
        is_hazmat: "No",
        order_containers: [{}],
        milestones: [],
      },
    };
  },

  computed: {
    currentProfile() {
      let obj = JSON.parse(localStorage.getItem("userProfile"));
      if (!obj) {
        return null;
      } else {
        return obj;
      }
    },

    allFormValidation() {
      return this.valid1 && this.valid2;
    },
  },
  watch: {
    directDaDetails: {
      handler(val) {
        if (val.order_containers && val.order_containers.length > 0) {
          val.order_containers.forEach((item, index) => {
            if (
              item.container_no &&
              !this.$globals.containerNumberRegExp.test(item.container_no)
            ) {
              this.formFieldHints[index]["container_no"] =
                "Invalid Container No!";
            } else {
              delete this.formFieldHints[index]["container_no"];
            }
          });
        }
      },
      deep: true,
    },
  },

  methods: {
    getEditDaDetails(changeKey) {
      this.$bus.$emit("showLoader", true);
      let payload = {
        id: this.directDaDetails.id,
        da_change: changeKey,
      };
      this.$api.deliveryAdvice
        .prevNextEditDa(payload)
        .then((res) => {
          this.editviewDeliveryAdvicesDetails(res.data);
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message: "DA not found!",
            color: "error",
          });
        });
    },
    editviewDeliveryAdvicesDetails(data) {
      if (data && data.payment_term == "Cash") {
        this.$router
          .push({
            path: `/app/admin/delivery-advice/cash-da-edit/${data.da_id}`,
            query: {
              backRoute: `/app/admin/delivery-advice/list`,
            },
          })
          .catch(() => {});
      } else {
        this.$router
          .push({
            path: `/app/admin/delivery-advice/direct-da-edit/${data.order_id}`,
            query: {
              backRoute: `/app/admin/delivery-advice/list`,
            },
          })
          .catch(() => {});
      }
      setTimeout(() => {
        this.permissions = getPermissions("delivery_advice");
        this.getDepotList();
        this.getCurrencyList();
        this.getCustomerAddressList();
        // this.getContractList();
        this.getCustomerList();
        this.getContainersType();
        this.getBranchList();
        this.getMilestoneList();
        this.getDirectDeliveryAdviceObject(this.$route.params.id);
        this.getOrderObject(data.order_id);
        this.getJobTypeOptions();
      }, 20);
    },
    checkData() {
      if (this.directDaDetails.assigned_source_address) {
        if (typeof this.directDaDetails.assigned_source_address == typeof {}) {
          this.sourceId = this.directDaDetails.assigned_source_address.id;
        }
      }
      if (
        this.directDaDetails.assigned_mid_address &&
        typeof this.directDaDetails.assigned_mid_address == typeof {}
      ) {
        this.midId = this.directDaDetails.assigned_mid_address.id;
      }
      if (this.directDaDetails.assigned_destination_address) {
        if (
          typeof this.directDaDetails.assigned_destination_address == typeof {}
        ) {
          this.destinationId =
            this.directDaDetails.assigned_destination_address.id;
        }
      }
    },
    updateselectedContract() {
      if (
        this.directDaDetails.customer_contract &&
        typeof this.directDaDetails.customer_contract == "object"
      ) {
        this.selectedContractId = this.directDaDetails.customer_contract.id;
      } else if (
        this.directDaDetails.customer_contract &&
        typeof this.directDaDetails.customer_contract == "number"
      ) {
        this.selectedContractId = this.directDaDetails.customer_contract;
      }
    },
    checkCustomer() {
      if (this.directDaDetails && this.directDaDetails.customer) {
        this.directDaDetails.currency = this.directDaDetails.customer.currency;
      }
    },
    saveDAHazardousForm(data) {
      this.daHazardousFormDetails = { ...data };
    },
    getTariffObject(id) {
      this.$api.customerContract
        .getCustomerContractObject(id)
        .then((res) => {
          this.$bus.$emit("showLoader", false);

          this.allCustomerContractList.push(res.data);
          this.directDaDetails.customer_contract = res.data;
          this.setContainersType();
        })
        .catch((err) => {
          this.$bus.$emit("showLoader", false);
        });
    },
    updateDAHazardousCargo() {
      this.$api.daHazardousCargo
        .updateDAHazardousCargo({
          id: this.hazardousId,
          data: this.daHazardousFormDetails,
        })
        .then((res) => {})
        .catch((err) => {
          console.log(err);
        });
    },
    addContractPageRedirect() {
      const path = {
        path: "/app/admin/customer-contract/add",
      };
      path.query = {
        backRoute: this.$route.path,
        da_id: this.$route.query.da_id,
      };
      this.$router.push(path);
    },
    getDepotList(params = {}) {
      params = {
        limit: "all",
        ...params,
      };
      this.$api.depot
        .getDepotSelectList(params)
        .then((res) => {
          this.allDepotList = res.data;
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    deleteDocument(document, index) {
      if (
        confirm(`Are you sure you want to delete ${document.document_type} ?`)
      ) {
        this.$bus.$emit("showLoader", true);
        this.$api.deliveryAdvice
          .deleteDeliveryAdviceDocument(document.id)
          .then((res) => {
            this.$bus.$emit("showToastMessage", {
              message: "Document deleted successfully!",
              color: "success",
            });
            this.directDaDetails.da_documents.splice(index, 1);
            this.$bus.$emit("showLoader", false);
          })
          .catch((err) => {
            console.error(err);
            this.$bus.$emit("showToastMessage", {
              message: "Couldn't delete document!",
              color: "error",
            });
            this.$bus.$emit("showLoader", false);
          });
      }
    },

    async getLists(apis) {
      this.$bus.$emit("showLoader", true);

      return new Promise((resolve, reject) => {
        Promise.all(apis)
          .then(async (val) => {
            this.getListCalled = false;
            await this.checkLoadedData();
            this.$bus.$emit("showLoader", false);
            resolve();
          })
          .catch((err) => {
            this.getListCalled = false;
            this.$bus.$emit("showLoader", false);
            reject();
          });
      });
    },

    getdaHazardousCargoDetails() {
      if (this.hazardousId) {
        this.editMode = true;
        this.$bus.$emit("showLoader", true);
        this.$api.daHazardousCargo
          .getdaHazardousCargoDetails(this.hazardousId)
          .then((res) => {
            this.$bus.$emit("showLoader", false);
            this.daHazardousFormDetails = res.data;
          })
          .catch((err) => {
            console.error(err);
            this.$bus.$emit("showLoader", false);
          });
      }
    },
    async getDirectDeliveryAdviceObject(id) {
      await this.$api.deliveryAdvice
        .getDirectDeliveryAdvice(id)
        .then(async (res) => {
          this.directOrderId = id;
          this.directDaDetails = res.data;

          this.defaultData = JSON.parse(JSON.stringify(res.data));
          if (this.directDaDetails.da_hazardous_cargo_id) {
            this.hazardousId = this.directDaDetails.da_hazardous_cargo_id;
            this.getdaHazardousCargoDetails();
          }
          this.jobType = this.directDaDetails.job_type;
          this.formFieldHints = this.directDaDetails.order_containers.map(
            () => {
              return {};
            }
          );
          this.sourceId =
            this.directDaDetails &&
            this.directDaDetails.assigned_source_address;
          this.midId =
            this.directDaDetails && this.directDaDetails.assigned_mid_address;
          this.destinationId =
            this.directDaDetails &&
            this.directDaDetails.assigned_destination_address;
          await this.getContractList("yes");
          this.updateselectedContract();
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    getOrderObject(id) {
      this.$api.customerOrder
        .getCustomerOrderObject(id)
        .then((res) => {
          this.orderDetails = res.data;
          this.orderDetails.order_containers = res.data.order_containers.filter(
            (obj) => {
              if (!obj.assigned_to_da) {
                return obj;
              }
            }
          );
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    checkFormErrors() {
      if (
        this.formErrors &&
        this.formErrors.order_containers &&
        (this.formErrors.order_containers.length == 0 ||
          this.formErrors.order_containers.every(
            (obj) => !obj || Object.keys(obj).length == 0
          ))
      ) {
        delete this.formErrors.order_containers;
      }
    },
    addDocument() {
      this.daDocuments.push({
        document_type: this.document_type,
        document: this.document,
      });
      this.document = null;
      this.document_type = null;
    },
    removeFile(index) {
      this.daDocuments.splice(index, 1);
    },
    generateUrl(file) {
      return URL.createObjectURL(file);
    },
    getSize(bytes) {
      var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
      if (bytes == 0) return "0 Byte";
      var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
      return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
    },
    async uploadDADocuments(id) {
      let i = 0;
      while (i < this.daDocuments.length) {
        let payload = {
          id: id,
          data: null,
        };
        let newForm = new FormData();
        newForm.append("document_type", this.daDocuments[i].document_type);
        newForm.append("document", this.daDocuments[i].document);
        payload.data = newForm;
        await this.$api.deliveryAdvice.uploadDeliveryAdviceDocument(payload);
        i++;
      }
    },
    removeContainer(index) {
      this.directDaDetails.order_containers.splice(index, 1);
      this.formFieldHints.splice(index, 1);
    },
    checkDataForPrefillingFields() {
      let contracts = this.orderDetails.order_containers.map((obj) => {
        return obj.customer_contract;
      });
      for (let i = 0; i < contracts.length; i++) {
        let obj = this.allCustomerContractList.find(
          (obj) => obj.id == contracts[i]
        );
        this.displayDetails[i]["customer_contract"] = obj;
        if (obj.need_addresses_for.source) {
          this.displayDetails[i]["assigned_source_address"] =
            this.allCustomerAddressList.find(
              (obj) =>
                obj.id ==
                this.orderDetails.order_containers[i]["assigned_source_address"]
            );
        }
        if (obj.need_addresses_for.mid) {
          this.displayDetails[i]["assigned_mid_address"] =
            this.allCustomerAddressList.find(
              (obj) =>
                obj.id ==
                this.orderDetails.order_containers[i]["assigned_mid_address"]
            );
        }
        if (obj.need_addresses_for.destination) {
          this.displayDetails[i]["assigned_destination_address"] =
            this.allCustomerAddressList.find(
              (obj) =>
                obj.id ==
                this.orderDetails.order_containers[i][
                  "assigned_destination_address"
                ]
            );
        }
      }
    },
    addDAHazardousCargo(daID) {
      let obj = this.daHazardousFormDetails;
      function isEmptyObject(obj) {
        return JSON.stringify(obj) === "{}";
      }
      if (isEmptyObject(obj)) {
        return;
      }
      let payload = { ...this.daHazardousFormDetails, delivery_advice: daID };
      this.$api.daHazardousCargo
        .addDAHazardousCargo(payload)
        .then(() => {})
        .catch((err) => {
          console.log(err);
        });
    },
    submitForm() {
      const payload = { ...this.directDaDetails, payment_term: "Credit" };

      if (payload.assigned_destination_address) {
        payload.assigned_destination_address =
          payload.assigned_destination_address.id;
      }

      if (payload.assigned_source_address) {
        payload.assigned_source_address = payload.assigned_source_address.id;
      }

      if (payload.assigned_mid_address) {
        payload.assigned_mid_address = payload.assigned_mid_address.id;
      }

      if (payload.customer_contract) {
        payload.customer_contract = payload.customer_contract.id;
      }

      if (payload.customer && typeof payload.customer == typeof {}) {
        payload.customer = payload.customer.id;
      }

      let confirm = false;
      if (payload.is_sub_hire === "Yes" && payload.sub_hire_job_exist) {
        if (this.defaultData.contract != this.selectedContractId) {
          confirm = true;
        } else if (this.defaultData.contract == this.selectedContractId) {
          if (
            this.defaultData.order_containers.length !=
            this.directDaDetails.order_containers.length
          ) {
            confirm = true;
          } else if (
            this.defaultData.order_containers.length ==
            this.directDaDetails.order_containers.length
          ) {
            let oldContainer = this.defaultData.order_containers.map(
              (a) => a.container_type
            );
            let newContainer = this.directDaDetails.order_containers.map(
              (b) => b.container_type
            );
            if (
              JSON.stringify(oldContainer.sort()) !=
              JSON.stringify(newContainer.sort())
            ) {
              confirm = true;
            }
          }
        }
      }
      if (confirm) {
        const config = {
          status: "secondary",
          title: "SubHire Remove",
          text: "Updating the DA will lead to removal of current subhire, Please reassign the subhire after the submission",
        };
        this.$confirm(config).then(() => {
          this.submit(payload);
        });
      } else {
        this.submit(payload);
      }
    },
    submit(payload) {
      this.$bus.$emit("showLoader", true);

      this.$api.deliveryAdvice
        .updateDirectDeliveryAdvice(payload)
        .then((res) => {
          if (this.daDocuments.length > 0) {
            this.uploadDADocuments(res.data.da_id);
          }
          if (
            payload &&
            payload.is_hazmat == "Yes" &&
            this.daHazardousFormDetails
          ) {
            if (
              this.directDaDetails &&
              this.directDaDetails.da_hazardous_cargo_id
            ) {
              this.updateDAHazardousCargo();
            } else {
              this.addDAHazardousCargo(this.directDaDetails.id);
            }
          }
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message: `Delivery Advice ${res.data.da_no} updated successfully!`,
            color: "success",
          });

          this.$router.push(this.backRoute);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);

          this.formErrors = { ...this.formErrors, ...err.data };
          this.$bus.$emit("showToastMessage", {
            message: "Couldn't update Delivery Advice!",
            color: "error",
          });
        });
    },
    async setContainersType() {
      if (
        this.directDaDetails &&
        this.directDaDetails.customer_contract &&
        this.directDaDetails.customer_contract.container_types
      ) {
        this.allContainersType =
          this.directDaDetails.customer_contract.container_types;
      } else {
        this.allContainersType = [];
      }
    },
    async setCustomerAddresses() {
      this.directDaDetails.assigned_source_address =
        this.allCustomerAddressList.find(
          (obj) => obj.id == this.directDaDetails.assigned_source_address
        );
      this.directDaDetails.assigned_mid_address =
        this.allCustomerAddressList.find(
          (obj) => obj.id == this.directDaDetails.assigned_mid_address
        );
      this.directDaDetails.assigned_destination_address =
        this.allCustomerAddressList.find(
          (obj) => obj.id == this.directDaDetails.assigned_destination_address
        );
    },

    getTariffObject(id) {
      this.$api.customerContract
        .getCustomerContractObject(id)
        .then((res) => {
          this.$bus.$emit("showLoader", false);

          this.allCustomerContractList.push(res.data);
          this.directDaDetails.customer_contract = res.data;
          this.setContainersType();
        })
        .catch((err) => {
          this.$bus.$emit("showLoader", false);
        });
    },

    getCurrencyList() {
      return this.$api.currency
        .getCurrencySelectList({ limit: "all" })
        .then((res) => {
          this.currencyList = res.data;

          const currency = this.currencyList.find((c) => {
            return c.currency_code == this.$globals.currency;
          });

          if (currency) {
            this.directDaDetails.currency = currency.currency_code;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    async getCustomerAddressList(params = {}) {
      this.$bus.$emit("showLoader", true);

      params = {
        limit: "all",
        ...params,
      };
      if (typeof this.directDaDetails.customer == typeof {}) {
        params.company = this.directDaDetails.customer.id;
      } else {
        params.company = this.directDaDetails.customer;
      }
      await this.$api.customerAddress
        .getCustomerAddressSelectList(params)
        .then((res) => {
          this.allCustomerAddressList = res.data;
          this.setCustomerAddresses();
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    async getContractList(val) {
      if (this.directDaDetails && this.directDaDetails.job_type) {
        let params = {
          status: "Active",
          limit: "all",
          job_type: this.directDaDetails.job_type,
          ...params,
        };

        if (typeof this.directDaDetails.customer == typeof {}) {
          params.customer = this.directDaDetails.customer.id;
        } else {
          params.customer = this.directDaDetails.customer;
        }

        this.$api.customerContract
          .getCustomerContractSelectList(params)
          .then((res) => {
            this.allCustomerContractList = res.data;
            this.directDaDetails.customer_contract =
              this.allCustomerContractList.find(
                (obj) => obj.id == this.directDaDetails.customer_contract
              );
            if (
              this.directDaDetails.customer_contract == undefined &&
              val === "yes"
            ) {
              console.log("ff");
              const tariff = this.directDaDetails?.contract;
              if (tariff) {
                this.getTariffObject(tariff);
              } else {
                this.$bus.$emit("showLoader", false);
              }
            } else {
              // Handle the case where res.data is undefined
              this.setContainersType();
            }
          })
          .catch((err) => {
            console.error(err);
            this.$bus.$emit("showLoader", false);
          });
      }
    },
    getCustomerList(params = {}) {
      params = {
        company_type: "Customer",
        limit: "all",
        ...params,
        payment_term: "Credit",
      };
      return this.$api.company
        .getCompanySelectList(params)
        .then((res) => {
          this.allCustomerList = res.data;
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    getMilestoneList() {
      const params = {
        job_type: this.directDaDetails.customer_contract_job_type,
      };
      this.$api.milestone
        .getMilestoneList(params)
        .then((res) => {
          this.milestoneList = res.data;
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },

    getContainersType() {
      console.log("getContainerTypeSelectList called")
      return this.$api.containerType
        .getContainerTypeSelectList({ limit: "all" })
        .then((res) => {
          this.allContainersType = res.data;
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },

    async checkLoadedData() {
      this.selectedContainers = this.directDaDetails.delivery_advice_containers;
      if (!this.directDaDetails.milestones) {
        this.directDaDetails.milestones = [];
      }
      await this.getOrderObject(this.directDaDetails.order);
      await this.getMilestoneList();
      await this.getCustomerAddressList();
      await this.setBackRoute();
    },
    getBranchList(params = {}) {
      params = {
        limit: "all",
        ...params,
      };
      return this.$api.branch
        .getBranchSelectList(params)
        .then((res) => {
          this.allBranchList = res.data;
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    setBackRoute() {
      if ("backRoute" in this.$route.query) {
        this.backRoute.path = this.$route.query.backRoute;
      }
      if ("da_id" in this.$route.query) {
        if (!this.backRoute.query) {
          this.backRoute.query = {};
        }
        this.backRoute.query.da_id = this.$route.query.da_id;
      }
    },
    setJobType() {
      this.getContractList("no");
    },
    getJobTypeOptions() {
      return this.$api.bmr
        .getBMROptions()
        .then((res) => {
          this.allJobType = res.data.actions.POST.job_type.choices;
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
  },

  async beforeMount() {
    if (!isAuthenticated()) {
      this.$router.push("/");
    } else if (!hasRouteAccess(this.$route)) {
      this.$router.push(`/${localStorage.getItem("user_type").toLowerCase()}`);
    } else {
      const permissions = getPermissions("delivery_advice");
      const contractPermissions = getPermissions("contract");
      if (!permissions || !permissions.add) {
        this.$bus.$emit("showToastMessage", {
          message: "You don't have access to this page",
          color: "error",
        });
        this.$router.push(
          `/app/${
            localStorage.getItem("user_type")
              ? localStorage.getItem("user_type").toLowerCase()
              : "error"
          }`
        );
      } else {
        this.contrPermissions = { ...contractPermissions };
        if (!this.getListCalled) {
          this.getListCalled = true;
          this.getLists([
            this.getJobTypeOptions(),
            this.getContainersType(),
            this.getCustomerList(),
            await this.getDirectDeliveryAdviceObject(this.$route.params.id),
            this.getCurrencyList(),
            this.getDepotList(),
            this.getBranchList(),
            this.setBackRoute(),
          ]);
        }
      }
    }
  },
};
</script>

<style scoped>
.Container-Height-Class {
  height: calc(100vh - 297px);
  overflow-y: auto;
}
.DA-Form-Height-Class {
  height: calc(100vh - 297px);
  overflow-y: auto;
}
.DropZone {
  height: 250px;
}
.contract-field-width {
  width: calc(100% - 116px);
}
</style>
