<template>
  <ListComponent title="Vehicle Type" :totalCount="totalItems">
    <template #list>
      <v-row class="ma-0">
        <v-col cols="4" class="px-0 pt-0 pb-2 d-flex align-center">
          <v-text-field
            label="Search"
            class="search-field"
            dense
            outlined
            append-icon="mdi-magnify"
            clearable
            hide-details="auto"
            v-model="filters.search"
            @input="searchVehicleType"
          />

          <v-tooltip top>
            <template #activator="{ on, attrs }">
              <v-btn
                depressed
                icon
                v-on="on"
                v-bind="attrs"
                @click="showFilterDialog = true"
                class="mx-2"
              >
                <v-badge
                  dot
                  color="primary"
                  :value="Object.keys(filters).length"
                >
                  <v-icon>mdi-filter</v-icon>
                </v-badge>
              </v-btn>
            </template>
            <span v-if="Object.keys(filters).length == 0">Filters</span>

            <v-row v-else no-gutters>
              <v-col cols="12" class="font-weight-bold">Applied Filters</v-col>
              <v-col cols="12">
                <span
                  v-for="(value, key) in filters"
                  :key="key"
                  class="text-capitalize"
                  >{{ key.replace("_", " ") }}
                  <br />
                </span>
              </v-col>
            </v-row>
          </v-tooltip>
        </v-col>

        <v-col
          v-if="totalItems > 0"
          cols="8"
          class="px-0 pt-0 pb-2 d-flex justify-end"
        >
          <Pagination
            :pageNo="pageNo"
            :totalItems="totalItems"
            :pageSize="itemsPerPage"
            @itemsPerPageChange="itemsPerPageChanged"
            @prevPage="prevPage"
            @nextPage="nextPage"
          />
        </v-col>

        <v-col cols="12" class="pa-0">
          <AgGridVue
            style="width: 100%; height: calc(100vh - 172px)"
            class="ag-theme-alpine"
            id="myGrid"
            :columnDefs="headers"
            :context="context"
            @grid-ready="onGridReady"
            :defaultColDef="defaultColDef"
            :grid-options="gridOptions"
            :suppressDragLeaveHidesColumns="true"
            :rowData="allVehicleTypeList"
          />
        </v-col>
      </v-row>
    </template>

    <template #dialogs>
      <VehicleTypeForm
        v-model="showVehicleTypeForm"
        :editMode="isEditing"
        :editVehicleTypeID="editVehicleTypeID"
        @refreshList="itemsPerPageChanged(20)"
      />

      <AdminVehicleTypeFilters
        v-model="showFilterDialog"
        :bindingObject="filters"
        @resetFilters="resetFilters"
        @applyFilters="itemsPerPageChanged(20)"
      />
    </template>

    <template #actions>
      <v-btn
        @click="addVehicleType"
        color="primary"
        depressed
        v-if="permissions && permissions.add"
      >
        <v-icon>mdi-plus</v-icon>
        <span>Add Vehicle Type</span>
      </v-btn>
    </template>
  </ListComponent>
</template>

<script>
import ListComponent from "@/components/General/ListComponent.vue";
import { AgGridVue } from "ag-grid-vue";
import Pagination from "@/components/General/Pagination.vue";
import VehicleTypeButton from "@/components/AgGridComponents/VehicleTypeButton.vue";
import CustomHeader from "@/components/AgGridComponents/General/CustomHeader.vue";
import VehicleTypeForm from "@/components/ModuleBased/dialogForms/VehicleTypeForm.vue";
import AdminVehicleTypeFilters from "@/components/ModuleBased/dialogs/filters/AdminVehicleTypeFilters.vue";

import {
  isAuthenticated,
  hasRouteAccess,
  getPermissions,
} from "@/utils/functions";

export default {
  components: {
    ListComponent,
    AgGridVue,
    Pagination,
    VehicleTypeButton,
    agColumnHeader: CustomHeader,
    VehicleTypeForm,
    AdminVehicleTypeFilters,
  },
  data() {
    return {
      sorting: {},
      headers: [
        {
          headerName: "Type",
          field: "type_name",
          sortable: true,
          tooltipField: "type_name",
        },
        {
          headerName: "Payload Capacity (Ton)",
          field: "payload_capacity",
          sortable: true,
          tooltipField: "payload_capacity",
        },
        {
          headerName: "Trailer can be Attached",
          field: "attach_trailer",
          sortable: true,
          valueFormatter: function (params) {
            params.value ? "Yes" : "No";
          },
        },
        {
          headerName: "Temperature Controlled",
          field: "temperature_controlled",
          sortable: true,
          valueFormatter: function (params) {
            params.value ? "Yes" : "No";
          },
        },
        {
          headerName: "Actions",
          field: "actions",
          cellRendererFramework: "VehicleTypeButton",
          pinned: "right",
          width: 100,
        },
      ],
      allVehicleTypeList: [],
      permissions: null,

      // pagination vars
      pageNo: 1,
      itemsPerPage: 20,
      totalItems: 0,

      // filter vars
      filters: {},
      showFilterDialog: false,

      // bulk vars
      showBulkDialog: false,

      // Vehicle Type Form
      showVehicleTypeForm: false,
      isEditing: false,
      editVehicleTypeID: null,

      // ag-grid vars
      gridApi: null,
      gridColumnApi: null,
      defaultColDef: {
        resizable: true,
      },
      gridOptions: {
        onGridSizeChanged: () => {
          this.resizeGrid();
        },
        suppressRowClickSelection: true,
        suppressDragLeaveHidesColumns: true,
        enableCellTextSelection: true,
        tooltipShowDelay: 0,
        rowHeight: 35,
      },
    };
  },
  computed: {
    context() {
      return { parentComponent: this };
    },
    offset() {
      return this.itemsPerPage * (this.pageNo - 1);
    },
  },
  methods: {
    resizeGrid() {
      if (this.gridOptions && this.gridOptions.api) {
        setTimeout(() => {
          this.gridOptions.api.sizeColumnsToFit();
        }, 100);
      }
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.resizeGrid();
    },
    resetFilters() {
      this.filters = {};
      this.itemsPerPageChanged(20);
    },
    searchVehicleType() {
      this.pageNo = 1;
      this.getVehicleTypeList();
    },
    itemsPerPageChanged(e) {
      this.pageNo = 1;
      this.itemsPerPage = e;
      this.getVehicleTypeList();
    },
    prevPage() {
      this.pageNo--;
      this.getVehicleTypeList();
    },
    nextPage() {
      this.pageNo++;
      this.getVehicleTypeList();
    },
    applyGridSort(key, type) {
      if (this.filters && !this.filters.ordering) {
        this.filters.ordering = [];
      }

      if (type == null) {
        delete this.sorting[key];
        this.filters.ordering.splice(
          this.filters.ordering.indexOf(`-${key}`),
          1
        );
      } else if (type == "asc") {
        this.filters.ordering.push(key);
      } else if (type == "desc") {
        this.filters.ordering.splice(
          this.filters.ordering.indexOf(key),
          1,
          `-${key}`
        );
      }
      if (this.filters.ordering.length == 0) {
        delete this.filters.ordering;
      }
      this.itemsPerPageChanged(20);
    },
    getVehicleTypeList(params = {}) {
      this.$bus.$emit("showLoader", true);
      params = {
        offset: this.offset,
        limit: this.itemsPerPage,
        ...params,
        ...this.filters,
      };
      if ("ordering" in params && params.ordering.length > 0) {
        params = { ...params, ordering: params.ordering.join(",") };
      }
      this.$api.vehicleType
        .getVehicleTypeList(params)
        .then((res) => {
          this.$bus.$emit("showLoader", false);
          this.allVehicleTypeList = res.data;
          this.totalItems = res.count;
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    addVehicleType() {
      this.editVehicleTypeID = null;
      this.isEditing = false;
      this.showVehicleTypeForm = true;
    },
    editVehicleType(id) {
      this.editVehicleTypeID = id;
      this.isEditing = true;
      this.showVehicleTypeForm = true;
    },
  },
  beforeMount() {
    if (!isAuthenticated()) {
      this.$router.push("/");
    } else if (!hasRouteAccess(this.$route)) {
      this.$router.push(`/${localStorage.getItem("user_type").toLowerCase()}`);
    } else {
      const permissions = getPermissions("lane");
      if (!permissions || !permissions.view) {
        this.$bus.$emit("showToastMessage", {
          message: "You don't have access to this page",
          color: "error",
        });
        this.$router.push(
          `/app/${
            localStorage.getItem("user_type")
              ? localStorage.getItem("user_type").toLowerCase()
              : "error"
          }`
        );
      } else {
        this.permissions = { ...permissions };
        this.getVehicleTypeList();
      }
    }
  },
};
</script>
