import { render, staticRenderFns } from "./DirectDeliveryAdviceAdd.vue?vue&type=template&id=ae98dc08&scoped=true"
import script from "./DirectDeliveryAdviceAdd.vue?vue&type=script&lang=js"
export * from "./DirectDeliveryAdviceAdd.vue?vue&type=script&lang=js"
import style0 from "./DirectDeliveryAdviceAdd.vue?vue&type=style&index=0&id=ae98dc08&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ae98dc08",
  null
  
)

export default component.exports