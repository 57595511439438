<template>
  <ListComponent title="Orders">
    <template #list>
      <v-row class="ma-0">
        <v-col cols="4" class="pl-0 pr-2 pt-0 pb-2 d-flex align-center">
          <v-text-field
            label="Search"
            outlined
            dense
            append-icon="mdi-magnify"
            clearable
            hide-details="auto"
            v-model="filters.search"
            @input="searchOrder"
          ></v-text-field>

          <v-tooltip top>
            <template #activator="{ on, attrs }">
              <v-btn
                depressed
                icon
                v-on="on"
                v-bind="attrs"
                @click="showFiltersDialog = true"
                class="mx-2"
              >
                <v-badge
                  dot
                  color="primary"
                  :value="Object.keys(filters).length"
                >
                  <v-icon>mdi-filter</v-icon>
                </v-badge>
              </v-btn>
            </template>

            <span v-if="Object.keys(filters).length == 0">Filters</span>

            <v-row v-else no-gutters>
              <v-col cols="12" class="font-weight-bold">Applied Filters</v-col>
              <v-col cols="12">
                <span
                  v-for="(value, key) in filters"
                  :key="key"
                  class="text-capitalize"
                  >{{ key.replace("_", " ") }}
                  <br />
                </span>
              </v-col>
            </v-row>
          </v-tooltip>

          <Columns
            :headers="headers"
            :gridColInstance="gridColumnApi"
            :hideColumns="['order_completed_on']"
          />
        </v-col>

        <v-col cols="4" class="px-0 pt-0 pb-2">
          <v-tabs height="35" v-model="tab" centered>
            <v-tabs-slider></v-tabs-slider>
            <v-tab>
              Pending
              <v-badge
                v-if="totalOrderStatistics.pending_order"
                top
                :content="totalOrderStatistics.pending_order"
                overlap
                class="mb-4 ml-3"
              >
              </v-badge>
            </v-tab>
            <v-tab>
              Active
              <v-badge
                v-if="totalOrderStatistics.active_da"
                top
                :content="totalOrderStatistics.active_da"
                overlap
                class="mb-4 ml-3"
              >
              </v-badge>
            </v-tab>
            <v-tab>
              Completed
              <v-badge
                v-if="totalOrderStatistics.completed_da"
                top
                :content="totalOrderStatistics.completed_da"
                overlap
                class="mb-4 ml-3"
              >
              </v-badge>
            </v-tab>
            <v-tab>
              Cancelled
              <v-badge
                v-if="totalOrderStatistics.cancelled_da"
                top
                :content="totalOrderStatistics.cancelled_da"
                overlap
                class="mb-4 ml-3"
              >
              </v-badge>
            </v-tab>
          </v-tabs>
        </v-col>

        <v-col
          v-if="totalItems > 0"
          cols="4"
          class="px-0 pt-0 pb-2 d-flex justify-end"
        >
          <Pagination
            :pageNo="pageNo"
            :totalItems="totalItems"
            :pageSize="itemsPerPage"
            @itemsPerPageChange="itemsPerPageChanged"
            @prevPage="prevPage"
            @nextPage="nextPage"
          />
        </v-col>

        <v-col cols="12" class="pa-0">
          <AgGridVue
            style="width: 100%; height: calc(100vh - 172px)"
            class="ag-theme-alpine"
            id="myGrid"
            :columnDefs="headers"
            @columnMoved="resizeGrid"
            @columnVisible="resizeGrid"
            :context="context"
            @grid-ready="onGridReady"
            :defaultColDef="defaultColDef"
            :grid-options="gridOptions"
            :suppressDragLeaveHidesColumns="true"
            :rowData="allOrderList"
          ></AgGridVue>
        </v-col>
      </v-row>
    </template>

    <template #dialogs>
      <AdminOrderFilters
        :bindingObject="filters"
        v-model="showFiltersDialog"
        @resetFilters="
          resetFilters();
          getTotalOrderCount();
        "
        @applyFilters="
          itemsPerPageChanged(20);
          getTotalOrderCount();
        "
      />
    </template>
  </ListComponent>
</template>

<script>
import Columns from "@/components/General/Columns.vue";
import ListComponent from "@/components/General/ListComponent.vue";
import { AgGridVue } from "ag-grid-vue";
import AdminOrderButton from "@/components/AgGridComponents/AdminOrderButton.vue";
import Pagination from "@/components/General/Pagination.vue";
import CustomHeader from "@/components/AgGridComponents/General/CustomHeader.vue";
import {
  getPermissions,
  hasRouteAccess,
  isAuthenticated,
} from "@/utils/functions";
import AdminOrderFilters from "@/components/ModuleBased/dialogs/filters/AdminOrderFilters.vue";

export default {
  components: {
    ListComponent,
    Columns,
    AgGridVue,
    AdminOrderButton,
    agColumnHeader: CustomHeader,
    Pagination,
    AdminOrderFilters,
  },

  data() {
    return {
      sorting: {},
      headers: [
        {
          headerName: "Order No",
          field: "order_no",
          sortable: true,
        },

        { headerName: "BOE/Reference No", field: "boe", sortable: true },
        { headerName: "Payment Term", field: "payment_term", sortable: true },
        { headerName: "Customer Name", field: "customer_name", sortable: true },
        {
          headerName: "Order Date",
          field: "created",
          sortable: true,
          valueFormatter: (params) => {
            return this.$globals.formatDate(params.value, true);
          },
        },
        {
          headerName: "Containers/Utilized",
          field: "total_and_utilized_container",
        },
        { headerName: "Total DA(s)", field: "order_da_count" },
        { headerName: "Direct DA|Order", field: "is_direct_order_da" },

        {
          headerName: "Completed On",
          field: "order_completed_on",
        },
        {
          headerName: "Actions",
          field: "actions",
          cellRendererFramework: "AdminOrderButton",
          pinned: "right",
          width: 235,
        },
      ],

      permissions: null,
      da_permissions: null,
      searchDAStatisticTimeout: null,

      // details vars
      selectedOrderId: null,
      showDetails: false,

      // Pagination Vars
      showFiltersDialog: false,
      tab: null,
      itemsPerPage: 20,
      pageNo: 1,
      totalItems: 0,
      filters: {},
      totalOrderStatistics: [],

      // Ag-grid vars
      gridApi: null,
      gridColumnApi: null,
      defaultColDef: {
        resizable: true,
      },
      gridOptions: {
        onGridSizeChanged: () => {
          this.resizeGrid();
        },
        suppressRowClickSelection: true,
        suppressDragLeaveHidesColumns: true,
        enableCellTextSelection: true,
        tooltipShowDelay: 0,
        rowHeight: 35,
      },

      allOrderList: [],
    };
  },

  computed: {
    context() {
      return { parentComponent: this };
    },
    offset() {
      return this.itemsPerPage * (this.pageNo - 1);
    },
  },

  watch: {
    tab(val) {
      this.getCustomerOrderList();
      this.resizeGrid();
      const interval = setInterval(() => {
        if (this.gridColumnApi) {
          clearInterval(interval);
          this.gridColumnApi.setColumnVisible("order_completed_on", val == 2);
        }
      }, 300);
    },
  },

  methods: {
    resetFilters() {
      this.filters = {};
      this.itemsPerPageChanged(20);
    },

    resizeGrid() {
      if (this.gridApi) {
        setTimeout(() => {
          this.gridApi.sizeColumnsToFit();
        }, 100);
      }
    },

    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.resizeGrid();
    },

    searchOrder() {
      this.pageNo = 1;

      this.getCustomerOrderList();
      this.getTotalOrderCount();
      this.filters = {};
    },

    itemsPerPageChanged(e) {
      this.pageNo = 1;
      this.itemsPerPage = e;
      this.getCustomerOrderList();
    },

    prevPage() {
      this.pageNo--;
      this.getCustomerOrderList();
    },

    nextPage() {
      this.pageNo++;
      this.getCustomerOrderList();
    },
    applyGridSort(key, type) {
      if (this.filters && !this.filters.ordering) {
        this.filters.ordering = [];
      }

      if (type == null) {
        delete this.sorting[key];
        this.filters.ordering.splice(
          this.filters.ordering.indexOf(`-${key}`),
          1
        );
      } else if (type == "asc") {
        this.filters.ordering.push(key);
      } else if (type == "desc") {
        this.filters.ordering.splice(
          this.filters.ordering.indexOf(key),
          1,
          `-${key}`
        );
      }
      if (this.filters.ordering.length == 0) {
        delete this.filters.ordering;
      }
      this.itemsPerPageChanged(20);
    },
    getCustomerOrderList(params = {}) {
      this.$bus.$emit("showLoader", true);
      params = {
        offset: this.offset,
        limit: this.itemsPerPage,
        ...params,
        ...this.filters,
        status:
          this.tab == 0
            ? "Pending"
            : this.tab == 1
            ? "Active"
            : this.tab == 2
            ? "Completed"
            : "Cancelled",
      };

      if ("ordering" in params && params.ordering.length > 0) {
        params = { ...params, ordering: params.ordering.join(",") };
      }
      this.$api.customerOrder
        .getCustomerOrderList(params)
        .then((res) => {
          this.allOrderList = res.data;
          this.totalItems = res.count;
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    getTotalOrderCount(params) {
      if (this.searchDAStatisticTimeout) {
        clearTimeout(this.searchDAStatisticTimeout);
      }
      this.searchDAStatisticTimeout = setTimeout(() => {
        params = { ...this.filters };
        delete params.limit;
        delete params.offset;
        delete params.statuses;
        this.$api.customerOrder
          .getOrderListStatistics(params)
          .then((res) => {
            this.totalOrderStatistics = res.data;
          })
          .catch((err) => {
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message: "Couldn't  DA!",
              color: "error",
            });
          });
      }, 1000);
    },

    createDirectDeliveryAdvice() {
      const path = {
        path: "/app/admin/delivery-advice/direct-da-add",
        query: {
          active_tab: this.tab,
        },
      };
      this.$router.push(path);
    },
    createDeliveryAdvice(id) {
      const path = {
        path: `/app/admin/order/create-da/${id}`,
        query: {
          active_tab: this.tab,
        },
      };
      this.$router.push(path);
    },
    viewOrder(id) {
      const path = {
        path: `/app/admin/order/details/${id}`,
        query: {
          active_tab: this.tab,
        },
      };
      this.$router.push(path);
    },

    checkQuery() {
      if ("active_tab" in this.$route.query) {
        this.tab = parseInt(this.$route.query.active_tab);
      }
    },
  },

  beforeMount() {
    if (!isAuthenticated()) {
      this.$router.push("/");
    } else if (!hasRouteAccess(this.$route)) {
      this.$router.push(`/${localStorage.getItem("user_type").toLowerCase()}`);
    } else {
      const permissions = getPermissions("order");
      this.da_permissions = getPermissions("delivery_advice");
      if (!permissions || !permissions.view) {
        this.$bus.$emit("showToastMessage", {
          message: "You don't have access to this page",
          color: "error",
        });
        this.$router.push(
          `/app/${
            localStorage.getItem("user_type")
              ? localStorage.getItem("user_type").toLowerCase()
              : "error"
          }`
        );
      } else {
        this.permissions = { ...permissions };
      }
    }
  },

  mounted() {
    this.checkQuery();
    this.getTotalOrderCount();
  },
};
</script>
