<template>
  <FilterParent
    module="Trip Scheduling"
    v-model="showAdminTripSchedulingFilters"
  >
    <template #filterContent>
      <v-row no-gutters>
        <v-col cols="6" class="pa-2"> 
          <MultiSelect
            label="Select Transporter(S)"
            :items="allTransporter"
            itemText="company_name"
            itemValue="id"
            :showAll="false"
            :multiple="true"
            :chips="true"
            chipWidthStyle="max-width: 6.5vw;"
            hide-details="auto"
            v-model="filterObject.transporters"
          />
        </v-col>
        <v-col cols="6" class="pa-2"> 
          <MultiSelect
            label="Select Customer(S)"
            :items="allCustomer"
            itemText="company_name"
            itemValue="id"
            :showAll="false"
            :multiple="true"
            :chips="true"
            chipWidthStyle="max-width: 6.5vw;"
            hide-details="auto"
            v-model="filterObject.customers"
          />
        </v-col>

        <v-col cols="6" class="Form-Columns">
          <MultiSelect
            label="Branch"
            :items="allBranch"
            itemText="branch_code"
            itemValue="id"
            hide-details="auto"
            :clearable="true"
            v-model="filterObject.branch"
          />
        </v-col>
        <v-col cols="6" class="Form-Columns">
          <MultiSelect
            label="Depot"
            :items="allDepot"
            itemText="depot_name"
            itemValue="id"
            hide-details="auto"
            :clearable="true"
            v-model="filterObject.depot"
          />
        </v-col>
        <v-col cols="6" class="Form-Columns">
          <MultiSelect
            label="Sub Hire"
            :items="allSubHire"
            itemText="text"
            itemValue="value"
            hide-details="auto"
            :clearable="true"
            v-model="filterObject.is_sub_hire"
          />
        </v-col>
        <v-col cols="6" class="Form-Columns">
          <MultiSelect
            label="Container Wise"
            :items="allContainerCentric"
            itemText="text"
            itemValue="value"
            hide-details="auto"
            v-model="filterObject.containerCentric"
          />
        </v-col>
        <v-col cols="6" class="Form-Columns">
          <MultiSelect
            label="Is Hazmat"
            :items="allSubHire"
            itemText="text"
            itemValue="value"
            hide-details="auto"
            :clearable="true"
            v-model="filterObject.is_hazmat"
          />
        </v-col>
        <v-col cols="6" class="Form-Columns">
          <MultiSelect
            label="Select Job Type"
            :clearable="true"
            :items="allJobType"
            v-model="filterObject.job_type"
          />
        </v-col>
      </v-row>
    </template>
    <template #filterAction>
      <v-btn
        depressed
        @click="
          $emit('resetFilters');
          showAdminTripSchedulingFilters = false;
        "
      >
        Reset
      </v-btn>
      <v-btn
        color="primary"
        class="ml-4"
        depressed
        @click="
          $emit('applyFilters');
          showAdminTripSchedulingFilters = false;
        "
      >
        Apply
      </v-btn>
    </template>
  </FilterParent>
</template>

<script>
import InputField from "@/components/FormBaseComponents/InputField.vue";
import Autocomplete from "@/components/FormBaseComponents/Autocomplete.vue";
import MultiSelect from "@/components/FormBaseComponents/MultiSelect.vue";
import FilterParent from "@/components/General/FilterParent.vue";
import DateField from "@/components/FormBaseComponents/DateField.vue";

export default {
  components: {
    FilterParent,
    MultiSelect,
    InputField,
    Autocomplete,
    DateField,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    bindingObject: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      allTransporter: [],
      allBranch: [],
      allDepot: [],
      allCustomer: [],
      allJobType: [],
      allSubHire: [
        {
          text: "Yes",
          value: "true",
        },
        {
          text: "No",
          value: "false",
        },
      ],
      allContainerCentric: [
        {
          text: "Yes",
          value: "Yes",
        },
        {
          text: "No",
          value: "No",
        },
      ],
    };
  },
  watch: {
    value(val) {
      if (val) {
        this.getLists([
          this.getTransporterList(),
          this.getCustomerList(),
          this.getBranchList(),
          this.getDepotList(),
          this.getCustomerContractOptions(),
        ]);
      }
    },
  },
  computed: {
    showAdminTripSchedulingFilters: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    filterObject: {
      get() {
        return this.bindingObject;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    getLists(apis) {
      this.$bus.$emit("showLoader", true);

      return new Promise((resolve, reject) => {
        Promise.all(apis)
          .then((val) => {
            this.getListCalled = false;
            this.$bus.$emit("showLoader", false);
            resolve();
          })
          .catch((err) => {
            this.getListCalled = false;
            this.$bus.$emit("showLoader", false);
            reject();
          });
      });
    },
    getCustomerContractOptions() {
      this.$bus.$emit("showLoader", true);
      this.$api.customerContract
        .getCustomerContractOptions()
        .then((res) => {
          this.allJobType = res.data.actions.POST.job_type.choices.map(
            (obj) => obj.value
          );
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },

    getTransporterList(params = {}) {
      params = {
        offset: this.offset,
        limit: "all",
        company_types: "Transporter,Admin",
      };
      this.$api.company
        .getCompanyList(params)
        .then((res) => {
          this.allTransporter = res.data;
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    getCustomerList(params = {}) {
      this.$bus.$emit("showLoader", true);
      params = {
        company_type: "Customer",
        limit: "all",
      };
      this.$api.company
        .getCompanySelectList(params)
        .then((res) => {
          this.allCustomer = res.data;
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    getBranchList(params = {}) {
      params = {
        limit: "all",
        ...params,
      };
      this.$api.branch
        .getBranchList(params)
        .then((res) => {
          this.allBranch = res.data;
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    getDepotList(params = {}) {
      params = {
        limit: "all",
      };
      this.$api.depot
        .getDepotList(params)
        .then((res) => {
          this.allDepot = res.data;
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
  },
};
</script>

<style></style>
