<template>
  <v-row>
    <v-col
      cols="12"
      sm="6"
      md="4"
      lg="3"
      v-if="dashboardData && dashboardData.totals"
    >
      <v-card class="fill-height">
        <v-card-title class="grey darken-1 py-3">
          <span class="text-h6 white--text"> Assets </span>
        </v-card-title>

        <v-card-text class="mt-4">
          <table class="Primary-Table">
            <tbody>
              <tr v-for="(value, key) in dashboardData.totals" :key="key">
                <td>{{ key.replace("_", " ") }}</td>
                <th>{{ value }}</th>
              </tr>
            </tbody>
          </table>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col
      cols="12"
      sm="6"
      md="4"
      lg="3"
      v-if="dashboardData && dashboardData.totals"
    >
      <v-card class="fill-height">
        <v-card-title class="grey darken-1 py-3">
          <span class="text-h6 white--text"> Legs/Trips </span>
        </v-card-title>

        <v-card-text class="mt-4">
          <table class="Primary-Table">
            <tbody>
              <tr v-for="(value, key) in dashboardData.others" :key="key">
                <td>{{ key.replace("_", " ") }}</td>
                <th>{{ value }}</th>
              </tr>
            </tbody>
          </table>
        </v-card-text>
      </v-card>
    </v-col>

    <v-col
      cols="12"
      sm="6"
      md="4"
      lg="3"
      v-if="dashboardData && dashboardData.contracts"
    >
      <v-card class="fill-height">
        <v-card-title class="grey darken-1 py-3">
          <span class="text-h6 white--text"> Tariff </span>
        </v-card-title>

        <v-card-text class="mt-4">
          <table class="Primary-Table">
            <tbody>
              <tr v-for="(value, key) in dashboardData.contracts" :key="key">
                <td>{{ key.replace("_", " ") }}</td>
                <th>{{ value }}</th>
              </tr>
            </tbody>
          </table>
        </v-card-text>
      </v-card>
    </v-col>

    <v-col
      cols="12"
      v-if="
        'trip' in dashboardData && Object.keys(dashboardData.trip).length > 0
      "
    >
      <v-card>
        <v-card-title class="heading">
          <span>Trips Status Wise Bifurcation</span>
          <v-spacer></v-spacer>

          <v-chip class="mx-2">
            Total Trips : {{ dashboardData.trip.total }}
          </v-chip>

          <v-col cols="12" sm="6" md="4" lg="3">
            <DateField
              :inputProps="{
                dense: true,
                label: 'Start Date',
                clearable: true,
                'hide-details': 'auto',
              }"
              :max="today"
              v-model="startDate"
            />
          </v-col>

          <v-col cols="12" sm="6" md="4" lg="3">
            <DateField
              :inputProps="{
                dense: true,
                label: 'End Date',
                clearable: true,
                'hide-details': 'auto',
              }"
              :min="startDate"
              :max="today"
              :disabled="!startDate"
              v-model="endDate"
            />
          </v-col>

          <v-col cols>
            <v-btn depressed color="primary" @click="datesChanged()">
              Submit
            </v-btn>
          </v-col>
        </v-card-title>
        <v-divider></v-divider>

        <v-card-text
          v-if="
            dashboardData &&
            dashboardData.trip &&
            dashboardData.trip.trip_status_date_wise_data
          "
          class="Chart-Height"
        >
          <!-- <client-only>
            <Chart
              :options="chartOptions"
              :labels="dashboardData.trip.trip_status_date_wise_data.categories"
              :datasets="dashboardData.trip.trip_status_date_wise_data.values"
            />
          </client-only> -->
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import InputField from "@/components/FormBaseComponents/InputField.vue";
import DateField from "@/components/FormBaseComponents/DateField.vue";

import {
  generateChartOptions,
  generateChartSeries,
} from "@/utils/functions.js";

import Chart from "@/components/General/Chart.vue";

export default {
  name: "TransporterIndexPage",
  components: { InputField, Chart, DateField },
  data() {
    return {
      generateChartOptions,
      generateChartSeries,
      dashboardData: {},

      startDate: null,
      endDate: null,
      today: new Date().toISOString().slice(0, 10),

      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        height: "500",
        datasets: {
          bar: {
            categoryPercentage: 0.5,
            barPercentage: 1,
          },
        },
        scales: {
          xAxes: [
            {
              stacked: true,
            },
          ],
          yAxes: [
            {
              stacked: true,
            },
          ],
        },
      },
    };
  },
  computed: {
   
  },
  methods: {
    datesChanged() {
      this.getTransporterDashboard({
        start_date: this.startDate,
        end_date: this.endDate,
      });
    },
    getTransporterDashboard(params = {}) {
      this.$bus.$emit("showLoader", true);
      params = {
        ...params,
        // company: this.currentProfile.company,
      };
      this.$api.dashboard
        .getTransporterDashboardData(params)
        .then((res) => {
           this.$bus.$emit("showLoader", false);
          this.dashboardData = res.data;
        })
        .catch((err) => {
            this.$bus.$emit("showLoader", false);
          console.error(err);
        });
    },
  },
  mounted() {
    this.getTransporterDashboard();
  },
  
};
</script>