<template>
  <div>
    <v-row class="heading ma-0">
      <v-col cols="12" lg="4" class="d-flex">
        <v-tooltip top>
          <template #activator="{ on, attrs }">
            <v-btn
              icon
              @click="callBackRoute"
              v-bind="attrs"
              v-on="on"
              class="mr-1"
            >
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
          </template>
          <span>Back</span>
        </v-tooltip>
        <div>
          <div>
            <Tooltip
              tooltipTitle="LRFQ"
              v-if="lrfqDetails.lrfq_id"
              :tooltipText="lrfqDetails.lrfq_id"
              top
              tooltipClass="text-h5"
            />
            <span class="text-h5">&nbsp;|&nbsp;</span>
            <Tooltip
              tooltipTitle="Status"
              v-if="lrfqDetails.status"
              :tooltipText="lrfqDetails.status"
              top
              tooltipClass="text-h5"
            />
          </div>
          <div v-if="lrfqDetails.end_date">
            <span class="text-caption">End Date &amp; Time </span>
            <span class="font-weight-medium">
              {{ lrfqDetails.end_date | formatDate(true) }}
            </span>
          </div>
        </div>
      </v-col>
    </v-row>

    <v-divider></v-divider>

    <v-row class="ma-0">
      <v-col cols="12" style="height: calc(90vh)" class="overflow-y-auto">
        <div>
          <div v-for="(lrfq, i) in allLrfqList" :key="i" class="mb-4">
            <v-card flat>
              <v-expansion-panels
                flat
                class="background_color app-list-card-expansion-header"
              >
                <v-expansion-panel class="heading">
                  <v-expansion-panel-header
                    :class="'font-weight-bold blue lighten-3 pa-4'"
                  >
                    <template v-slot:actions>
                      <v-icon size="23"> $expand </v-icon>
                    </template>
                    <v-row class="ma-0">
                      <v-col cols="12" class="pa-0">
                        <v-chip small>{{ i + 1 }}</v-chip>
                        &nbsp;&nbsp;&nbsp;

                        <span
                          class="text-subtitle-1 font-weight-medium text--secondary"
                          >Route</span
                        >
                        <span
                          class="text-subtitle-1 font-weight-bold text--secondary"
                        >
                          |
                          {{ lrfq.lane }}</span
                        >

                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <span
                          class="text-subtitle-1 font-weight-medium text--secondary"
                          >Container Type</span
                        >
                        <span
                          class="text-subtitle-1 font-weight-bold text--secondary"
                        >
                          |
                          {{ lrfq.container_type }}</span
                        >
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                        <span
                          class="text-subtitle-1 font-weight-medium text--secondary"
                          >Container Count</span
                        >
                        <span
                          class="text-subtitle-1 font-weight-bold text--secondary"
                        >
                          |
                          {{ lrfq.container_count }}</span
                        >
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <span
                          class="text-subtitle-1 font-weight-medium text--secondary"
                          >Export</span
                        >
                        <span
                          class="text-subtitle-1 font-weight-bold text--secondary"
                        >
                          |
                          <v-tooltip bottom>
                            <template #activator="{ on, attrs }">
                              <v-icon
                                class="mr-4 ml-1"
                                color="background_color"
                                v-on="on"
                                v-bind="attrs"
                                @click="exportData(lrfq)"
                              >
                                mdi-file-download
                              </v-icon>
                            </template>
                            <span>Export Data</span>
                          </v-tooltip></span
                        >

                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </v-col>
                    </v-row>
                  </v-expansion-panel-header>
                  <v-divider></v-divider>
                  <v-expansion-panel-content class="white pa-2">
                    <v-row class="ma-0">
                      <v-col cols="12">
                        <AgGridVue
                          @grid-ready="gridReady"
                          :grid-options="gridOptions"
                          :column-defs="headers"
                          :default-col-def="defaultColDef"
                          :row-data="lrfq.transporters"
                          :context="context"
                          rowSelection="multiple"
                          style="width: 100%; height: calc(25vh)"
                          class="ag-theme-alpine"
                        ></AgGridVue>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-card>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";

import Tooltip from "@/components/General/Tooltip.vue";
import TransporterLRFQRequestAccept from "@/components/AgGridComponents/TransporterLRFQRequestAccept.vue";

import {
  isAuthenticated,
  hasRouteAccess,
  getPermissions,
} from "@/utils/functions";

export default {
  components: {
    AgGridVue,
    Tooltip,
    TransporterLRFQRequestAccept,
  },

  data() {
    return {
      backRoute: {},
      lrfqDetails: {},
      allLrfqList: [],

      permissions: null,

      // ag-grid vars
      gridApi: null,
      gridColumnApi: null,
      TripGridOptions: {
        onGridSizeChanged: (grid) => {
          if (grid && grid.api) {
            grid.api.sizeColumnsToFit();
          }
        },
        defaultColDef: {
          resizable: true,
        },
        rowHeight: 35,
        suppressRowClickSelection: true,
        suppressDragLeaveHidesColumns: true,
        enableCellTextSelection: true,
        tooltipShowDelay: 0,
      },
      headers: [
        {
          headerName: "Lrfq Request",
          field: "lrfq_request",
          sortable: true,
          minWidth: 120,
          pinned: "left",
        },
        {
          headerName: "Transporter",
          field: "transporter_name",
          sortable: true,
          minWidth: 170,
        },
        {
          headerName: `Rate (${this.$globals.currency})`,
          field: "rates",
          sortable: true,
          minWidth: 130,
        },

        {
          headerName: "Status",
          field: "status",
          sortable: true,
          minWidth: 170,
          cellRenderer: "BidStatusCellRenderer",
        },
        {
          headerName: "Job Type",
          field: "job_type",
          sortable: true,
          minWidth: 170,
          cellRenderer: "BidStatusCellRenderer",
        },
        {
          headerName: "Transporter Remark",
          field: "transporter_remark",
          minWidth: 200,
        },
        // {
        //   headerName: "Actions",
        //   field: "actions",
        //   cellRenderer: "TransporterLRFQRequestAccept",
        //   pinned: "right",
        //   minWidth: 180,
        // },
      ],

      tripGridApi: null,
      tripGridColumnApi: null,

      gridOptions: {
        onGridSizeChanged: () => {
          this.gridOptions.api.sizeColumnsToFit();
        },
        headerHeight: 40,
        rowHeight: 40,
        rowSelection: "multiple",
        onSelectionChanged: "onSelectionChanged",
        suppressRowClickSelection: true,
        suppressDragLeaveHidesColumns: true,
        enableCellTextSelection: true,
        rowSelection: null,
        rowData: null,
      },

      defaultColDef: {
        resizable: true,
        flex: 1,
        initialWidth: 200,
        wrapHeaderText: true,
        autoHeaderHeight: true,
      },
    };
  },

  computed: {
    context() {
      return { parentComponent: this };
    },
    currentProfile() {
      if (JSON.parse(localStorage.getItem("userProfile"))) {
        return JSON.parse(localStorage.getItem("userProfile"));
      } else {
        return null;
      }
    },
  },
  methods: {
    async exportData(lrfq) {
      this.$bus.$emit("showLoader", true);
      let params = {
        lrfq_id: this.lrfqDetails.lrfq_id,
        container_type: lrfq.container_type,
        lane_id: lrfq.lane_id,
      };
      await this.$api.lrfq
        .fetchAdminLrfqRequestGroupedBlop(params)
        .then(async (response) => {
          this.$bus.$emit("showLoader", false);
          var blob = response.data;

          var downloadLink = window.document.createElement("a");
          downloadLink.href = window.URL.createObjectURL(
            new Blob([blob], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            })
          );
          downloadLink.download = "LrfqDetails.xlsx";
          downloadLink.click();
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showToastMessage", {
            message: `${
              err.data && err.data.non_field_errors
                ? err.data.non_field_errors.join()
                : "Data not found"
            }`,
            color: "error",
          });

          this.$bus.$emit("showLoader", false);
        });
    },
    acceptRejectRate(id, status) {
      this.$confirm({
        status: "secondery",
        title: "Warning",
        color: "danger",
        text: "Are you sure you want to Accept/Reject the Rate?",
      })
        .then(() => {
          let params = {
            lrfq_request_id: id,
            status: status,
          };
          this.$bus.$emit("showLoader", true);
          this.$api.lrfq
            .acceptOrRejectRate(params)
            .then((res) => {
              this.$bus.$emit("showLoader", false);
              this.getLrfqDetails();
              this.$bus.$emit("showToastMessage", {
                message: "status updated successfully!",
                color: "success",
              });
            })
            .catch((err) => {
              this.$bus.$emit("showLoader", false);
              console.error(err);
            });
        })
        .catch(() => {
          console.log("Rejected");
        });
    },
    gridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
      this.resizeGrid();
    },
    resizeGrid() {
      if (this.gridApi) {
        setTimeout(() => {
          this.gridApi.sizeColumnsToFit();
        }, 100);
      }
    },

    // get Deatil Object  Api Call
    getLrfqDetails(params = {}) {
      this.$bus.$emit("showLoader", true);
      this.$api.lrfq
        .getLrfqDetails(this.$route.params.id)
        .then((res) => {
          this.lrfqDetails = res.data;
          this.allLrfqList = res.data.group_by_data;
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message: "Bid Detail not found!",
            color: "error",
          });
        });
    },

    callBackRoute() {
      if (Object.keys(this.backRoute).length === 0) {
        this.$router.push("/app/admin/lrfq/list");
      } else {
        this.$router.push(this.backRoute);
      }
    },
    setBackRoute() {
      if ("backRoute" in this.$route.query) {
        this.backRoute.path = this.$route.query.backRoute;
      }
      if ("active_tab" in this.$route.query) {
        if (!this.backRoute.query) {
          this.backRoute.query = {};
        }
        this.backRoute.query.active_tab = this.$route.query.active_tab;
      }
    },
  },
  beforeMount() {
    if (!isAuthenticated()) {
      this.$router.push("/");
    } else if (!hasRouteAccess(this.$route)) {
      this.$router.push(`/${localStorage.getItem("user_type").toLowerCase()}`);
    } else {
      this.permissions = getPermissions("delivery_advice");
      if (!this.permissions || !this.permissions.view) {
        this.$bus.$emit("showToastMessage", {
          message: "You don't have access to this page",
          color: "error",
        });
        this.$router.push(
          `/app/${
            localStorage.getItem("user_type")
              ? localStorage.getItem("user_type").toLowerCase()
              : "error"
          }`
        );
      } else {
        this.getLrfqDetails();
        this.setBackRoute();
      }
    }
  },
};
</script>

<style scoped>
.Cards-Height-Class {
  height: calc(100vh - 374px);
  overflow-y: auto;
}
</style>
