<template>
  <div>
    <div v-if="tripDetails && Object.keys(tripDetails).length == 0">
      <v-card style="background-color: #ffece0; height: 100%">
        <v-card-title>
          <v-row>
            <v-col cols="12" class="text-center py-0">
              <h5>Trip Not Found</h5>
            </v-col>
          </v-row>
        </v-card-title>
      </v-card>
    </div>
    <div v-else class="mb-12">
      <v-card style="background-color: #ffece0">
        <v-card-title>
          <v-row>
            <v-col cols="7" class="d-flex align-center py-0">
              <v-icon small class="pr-3" color="black">
                mdi-map-marker-path
              </v-icon>
              <h5>{{ tripDetails.trip_id }}</h5>
            </v-col>
            <v-col cols="5" class="d-flex align-center justify-end py-0">
              <h6 class="grey--text">{{ tripDetails.status }}</h6>
            </v-col>
            <v-col cols="7" class="d-flex align-center py-0">
              <v-icon small class="pr-3" color="black">
                mdi-calendar-range
              </v-icon>
              <h6 class="font-weight-light">
                {{ tripDetails.trip_date | formatDate(false) }}
              </h6>
            </v-col>
            <v-col cols="5" class="d-flex align-center justify-end py-0">
              <v-btn
                color="black"
                outlined
                @click="documentListDialog = true"
                x-small
                style="width: 100%; background-color: white"
              >
                View Documents
              </v-btn>
            </v-col>
            <v-col cols="12">
              <v-card elevation="0" class="white px-4 py-1 card-outlined">
                <span class="text-caption">BOE</span>
                <h5>
                  <span class="text-caption">{{ tripDetails.boe }}</span>
                </h5>
              </v-card>
            </v-col>
            <v-col cols="8" class="py-0"> </v-col>
            <v-col cols="4" class="d-flex align-center justify-end py-0">
              <a
                :href="googleMapsRedirectLink"
                target="_blank"
                v-if="googleMapsRedirectLink"
              >
                <v-btn
                  color="black"
                  outlined
                  x-small
                  class="d-flex justify-space-between px-4"
                  style="width: 100%; background-color: white"
                >
                  <span>View on Map</span>
                  <v-icon small color="black">mdi-map</v-icon>
                </v-btn>
              </a>
            </v-col>
            <v-col cols="12">
              <v-card elevation="0" class="white px-4 py-1 card-outlined">
                <span class="text-caption">
                  <v-icon small class="pr-3" color="black"
                    >mdi-map-marker</v-icon
                  >Source</span
                >
                <h5 style="line-height: 15px">
                  <span class="text-caption">
                    {{ tripDetails.source_address }}
                  </span>
                </h5>
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-card elevation="0" class="white px-4 py-1 card-outlined">
                <span class="text-caption">
                  <v-icon small class="pr-3" color="black">
                    mdi-map-marker-radius
                  </v-icon>
                  MidPoint</span
                >
                <h5 style="line-height: 15px">
                  <span class="text-caption">
                    {{ tripDetails.mid_address }}
                  </span>
                </h5>
              </v-card>
            </v-col>
            <v-col cols="12" v-if="tripDetails.destination_address">
              <v-card elevation="0" class="white px-4 py-1 card-outlined">
                <span class="text-caption">
                  <v-icon small class="pr-3" color="black">
                    mdi-map-marker-radius
                  </v-icon>
                  Destination</span
                >
                <h5 style="line-height: 15px">
                  <span class="text-caption">
                    {{ tripDetails.destination_address }}
                  </span>
                </h5>
              </v-card>
            </v-col>
          </v-row>
        </v-card-title>
      </v-card>
      <v-row no-gutters class="py-3 px-4">
        <v-col cols="5" class="d-flex align-center py-0">
          <v-btn
            color="black"
            outlined
            small
            @click="assetDetailDialog = true"
            style="width: 100%; background-color: white"
          >
            <v-icon small class="pr-1" color="black">
              mdi-truck-fast-outline
            </v-icon>
            Asset Details
          </v-btn>
        </v-col>
        <v-col cols="1" class="d-flex align-center py-0"> </v-col>
        <v-col cols="6" class="d-flex align-center justify-end py-0">
          <v-btn
            color="black"
            outlined
            small
            @click="containerDetailDialog = true"
            style="width: 100%; background-color: white"
          >
            <v-icon small class="pr-1" color="black"> mdi-cube-outline </v-icon>
            container Details
          </v-btn>
        </v-col>
        <v-col cols="12" class="py-4">
          <h3>Legs</h3>
        </v-col>
        <v-row>
          <v-col cols="12" v-for="(leg, i) in tripDetails.legs" :key="i">
            <v-btn
              color="black"
              outlined
              @click="openPodDialog(leg.id)"
              class="d-flex justify-space-between px-4"
              style="width: 100%; background-color: white"
            >
              <span> {{ leg.leg_containers }}</span>
              <v-btn color="primary" x-small>Upload POD </v-btn>
            </v-btn>
          </v-col>
        </v-row>
      </v-row>

      <div>
        <BaseDriverDialog
          fullscreen
          v-model="assetDetailDialog"
          title="Asset Details"
        >
          <template #information>
            <DriverAppAssetDetailVue
              :assetDetailDialog="assetDetailDialog"
              :tripDetails="tripDetails"
            />
          </template>
        </BaseDriverDialog>
        <BaseDriverDialog
          fullscreen
          v-model="containerDetailDialog"
          title="Container Details"
        >
          <template #information>
            <DriverAppContainerDetail
              :containerList="tripDetails.trip_containers"
            />
          </template>
        </BaseDriverDialog>
        <BaseDriverDialog fullscreen v-model="podListDialog" title="POD">
          <template #information>
            <DriverAppPod
              :selectedLegId="selectedLegId"
              :podListDialog="podListDialog"
            />
          </template>
        </BaseDriverDialog>
        <BaseDriverDialog
          fullscreen
          v-model="odometerReadingDialog"
          title="Odometer Reading"
        >
          <template #information>
            <DriverAppOdoMeterReadingDialog
              :odometerReadingDialog="odometerReadingDialog"
              :odometerReadingType="odometerReadingType"
              :tripDetails="tripDetails"
              @closeOdometerReadingDialog="closeOdometerReadingDialog()"
              @refreshTripDetails="getDriverAppTripDetails()"
            />
          </template>
        </BaseDriverDialog>
        <BaseDriverDialog
          fullscreen
          v-model="mileStoneDialog"
          title="Milestone Logs"
        >
          <template #information>
            <DriverAppMileStoneLogs
              :mileStoneDialog="mileStoneDialog"
              :tripDetails="tripDetails"
              @closeMileStoneDialog="closeMileStoneDialog()"
              @refreshTripDetails="getDriverAppTripDetails()"
            />
          </template>
        </BaseDriverDialog>
        <BaseDriverDialog
          fullscreen
          v-model="checkListDialog"
          title="Trip CheckList"
        >
          <template #information>
            <DriverAppCheckListDialog
              :checkListDialog="checkListDialog"
              :tripDetails="tripDetails"
              @closeDriverAppCheckListDialog="closeDriverAppCheckListDialog()"
              @refreshTripDetails="getDriverAppTripDetails()"
            />
          </template>
        </BaseDriverDialog>
        <BaseDriverDialog
          fullscreen
          v-model="documentListDialog"
          title="Documents"
        >
          <template #information>
            <DriverAppViewDocumentVue
              :documentListDialog="documentListDialog"
              :documentList="tripDetails.trip_da_attachments"
            />
          </template>
        </BaseDriverDialog>
      </div>
      <div
        class="btn-position-fixed-bottom"
        v-if="!tripDetails.is_checklist_passed"
      >
        <v-btn
          class="primary text-capitalize font-weight-bold"
          @click="checkListDialog = true"
          style="width: 100%"
        >
          Complete Checklist
        </v-btn>
      </div>
      <div
        class="btn-position-fixed-bottom"
        v-else-if="
          tripDetails.possible_status &&
          tripDetails.possible_status.length &&
          tripDetails.possible_status[0] == 'Enroute'
        "
      >
        <v-btn
          class="primary text-capitalize font-weight-bold"
          @click="updateTripStatus()"
          style="width: 100%"
        >
          Start Trip
        </v-btn>
      </div>
      <div class="btn-position-fixed-bottom" v-else>
        <v-btn
          class="primary text-capitalize font-weight-bold"
          @click="mileStoneDialog = true"
          style="width: 100%"
        >
          Update Milestone
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import BaseDriverDialog from "@/components/General/BaseDriverDialog.vue";
import DriverAppAssetDetailVue from "@/components/ModuleBased/details/driverApp/DriverAppAssetDetail.vue";
import DriverAppContainerDetail from "@/components/ModuleBased/details/driverApp/DriverAppContainerDetail.vue";
import DriverAppPod from "@/components/ModuleBased/details/driverApp/DriverAppPod.vue";
import DriverAppMileStoneLogs from "@/components/ModuleBased/details/driverApp/DriverAppMileStoneLogs.vue";
import DriverAppCheckListDialog from "@/components/ModuleBased/details/driverApp/DriverAppCheckListDialog.vue";
import DriverAppOdoMeterReadingDialog from "@/components/ModuleBased/details/driverApp/DriverAppOdoMeterReadingDialog.vue";
import DriverAppViewDocumentVue from "@/components/ModuleBased/details/driverApp/DriverAppViewDocument.vue";
import { createGoogleMapsDirectionLink } from "@/utils/functions.js";

export default {
  components: {
    BaseDriverDialog,
    DriverAppAssetDetailVue,
    DriverAppContainerDetail,
    DriverAppPod,
    DriverAppMileStoneLogs,
    DriverAppCheckListDialog,
    DriverAppViewDocumentVue,
    DriverAppOdoMeterReadingDialog,
  },
  data() {
    return {
      assetDetailDialog: false,
      containerDetailDialog: false,
      podListDialog: false,
      checkListDialog: false,
      odometerReadingDialog: false,
      odometerReadingType: "start",
      mileStoneDialog: false,
      documentListDialog: false,
      selectedLegId: null,
      tripDetails: {},
      googleMapsRedirectLink: null,
    };
  },
  computed: {},
  methods: {
    closeDriverAppCheckListDialog() {
      this.checkListDialog = false;
    },
    closeMileStoneDialog() {
      this.mileStoneDialog = false;
    },
    closeOdometerReadingDialog() {
      this.odometerReadingDialog = false;
    },
    updateTripStatus() {
      if (confirm("Are you sure want to start trip?")) {
        this.$bus.$emit("showLoader", true);
        let payload = {
          request_type: "update_status",
          status: this.tripDetails.possible_status[0],
        };
        this.$api.driverApp
          .updateDriverAppTrip(payload)
          .then((res) => {
            this.getDriverAppTripDetails();
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message: "Trip started successfully!",
              color: "success",
              hasActions: true,
            });
          })
          .catch((err) => {
            this.$bus.$emit("showLoader", false);
            if (
              err.data &&
              err.data.error &&
              err.data.error.odometer_reading &&
              err.data.error.odometer_reading[0] == false
            ) {
              this.odometerReadingDialog = true;
              this.$bus.$emit("showToastMessage", {
                message: "Please update odometer reading first!",
                color: "error",
                hasActions: true,
              });
            } else {
              this.$bus.$emit("showToastMessage", {
                message: "Couldn't start trip!",
                color: "error",
                hasActions: true,
              });
            }
          });
      }
    },
    openPodDialog(legId) {
      this.selectedLegId = legId;
      this.podListDialog = true;
    },
    getDriverAppTripDetails() {
      this.checkListDialog = false;
      this.$bus.$emit("showLoader", true);

      this.$api.driverApp
        .getDriverAppTripDetails()
        .then((res) => {
          this.$bus.$emit("showLoader", false);
          this.tripDetails = res.data;
          this.$bus.$emit("setTripDetails", res.data);
          let destinationCoords = null;
          let midCoords = null;
          let sourceCoords = `${this.tripDetails.source_point.latitude},${this.tripDetails.source_point.longitude}`;
          if (this.tripDetails.destination_address) {
            destinationCoords = `${this.tripDetails.destination_point.latitude},${this.tripDetails.destination_point.longitude}`;
            midCoords = `${this.tripDetails.mid_point.latitude},${this.tripDetails.mid_point.longitude}`;
          } else {
            destinationCoords = `${this.tripDetails.mid_point.latitude},${this.tripDetails.mid_point.longitude}`;
          }
          this.googleMapsRedirectLink = createGoogleMapsDirectionLink(
            sourceCoords,
            destinationCoords,
            midCoords
          );
        })
        .catch((err) => {
          console.error(err);
          if (err.data && err.data.status == 404) {
            this.tripDetails = {};
            this.$bus.$emit("setTripDetails", this.tripDetails);
          }
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message: "Trip not found!",
            color: "error",
            hasActions: true,
          });
        });
    },
  },
  beforeMount() {
    this.getDriverAppTripDetails();
  },
};
</script>

<style scoped>
.v-application {
  display: initial !important;
}
.card-outlined {
  border: 1px solid #0000004f !important;
  border-radius: 6px;
}
.btn-position-fixed-bottom {
  position: fixed;
  bottom: 0;
  width: 100%;
}
</style>