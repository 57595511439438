<template>
  <FilterParent module="Quotation" v-model="showQuotationFilters">
    <template #filterContent>
      <v-row class="ma-0">
        <v-col cols="12" class="pa-2 mb-0">
          <MultiSelect
            :items="allCustomerList"
            itemText="company_name"
            itemValue="id"
            label="Select Customer Company"
            v-model="filterObject.customer"
          />
        </v-col>
        <v-col cols="12" class="pa-2 mb-0">
          <MultiSelect
            :items="sourceList"
            itemText="location_code"
            itemValue="id"
            label="Source"
            v-model="filterObject.source_location"
          />
        </v-col>

        <v-col cols="12" class="pa-2 mb-0">
          <MultiSelect
            :items="destinationList"
            itemText="location_code"
            itemValue="id"
            label="Destination"
            v-model="filterObject.destination_location"
          />
        </v-col>
        <v-col cols="12" class="pa-2 mb-0">
          <MultiSelect
            :items="JobTypeValues"
            itemText="text"
            itemValue="value"
            label="Job Type"
            v-model="filterObject.job_type"
          />
        </v-col>

        <v-col cols="6" class="pa-2">
          <DateField
            :inputProps="{
              dense: true,
              label: 'Start Date ',
              'hide-details': 'auto',
            }"
            v-model="filterObject.start_date"
          />
        </v-col>

        <v-col cols="6" class="pa-2">
          <DateField
            :inputProps="{
              dense: true,
              label: 'End Date ',
              'hide-details': 'auto',
            }"
            v-model="filterObject.end_date"
          />
        </v-col>
      </v-row>
    </template>
    <template #filterAction>
      <v-btn
        depressed
        @click="
          $emit('resetFilters');
          showQuotationFilters = false;
        "
      >
        Reset
      </v-btn>
      <v-btn
        color="primary"
        class="ml-4"
        depressed
        @click="
          $emit('applyFilters');
          showQuotationFilters = false;
        "
      >
        Apply
      </v-btn>
    </template>
  </FilterParent>
</template>

<script>
import InputField from "@/components/FormBaseComponents/InputField.vue";
import Autocomplete from "@/components/FormBaseComponents/Autocomplete.vue";
import MultiSelect from "@/components/FormBaseComponents/MultiSelect.vue";
import FilterParent from "@/components/General/FilterParent.vue";
import DateField from "@/components/FormBaseComponents/DateField.vue";

export default {
  components: {
    FilterParent,
    MultiSelect,
    InputField,
    Autocomplete,
    DateField,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    bindingObject: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      allCustomerList: [],
      allTransporterList: [],
      sourceList: [],
      midList: [],
      destinationList: [],
      allLaneList: [],
      paymentTerms: [],
      allBranch: [],
      allDepot: [],
      listLoading: false,
      laneCount: 0,
      JobTypeValues: [
        {
          text: "Import",
          value: "Import",
        },
        {
          text: "Export",
          value: "Export",
        },
        {
          text: "FCL",
          value: "FCL",
        },
        {
          text: "LCL",
          value: "LCL",
        },
        {
          text: "Empty",
          value: "Empty",
        },
      ],
    };
  },
  watch: {
    value(val) {
      if (val) {
        this.getCustomerList();
        this.getLocationList();

      }
    },
  },
  computed: {
    showQuotationFilters: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    filterObject: {
      get() {
        return this.bindingObject;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    getCustomerList() {
      this.$api.company
        .getCompanyList({
          limit: "all",
          company_type: "Customer",
        })
        .then((res) => {
          this.allCustomerList = res.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getTransporterList() {
      this.$api.company
        .getCompanyList({
          limit: "all",
          company_type: "Transporter",
        })
        .then((res) => {
          this.allTransporterList = res.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },

    getLocationList() {
      this.$bus.$emit("showLoader", true);

      this.$api.location
        .getLocationSelectList({ limit: "all" })
        .then((res) => {
          this.$bus.$emit("showLoader", false);
          this.sourceList = res.data;
          this.midList = res.data;
          this.destinationList = res.data;
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
  },
};
</script>

<style></style>
