<template>
  <FilterParent
    module="Vehicle"
    maxWidth="500"
    v-model="showTransporterVehicleFilter"
  >
    <template #filterContent>
      <v-row class="ma-0">
        <v-col cols="12" class="Form-Columns">
          <MultiSelect
            label="Vehicle Type"
            name="vehicle-filter-vehicle-type"
            :items="allVehicleTypeList"
            itemText="title"
            itemValue="id"
            hide-details="auto"
            v-model="filterObject.vehicle_type"
          />
        </v-col>
        <v-col cols="12" class="Form-Columns">
          <MultiSelect
            label="Depot"
            name="depot-filter-vehicle-type"
            :items="allDepot"
            itemText="depot_name"
            itemValue="depot_name"
            hide-details="auto"
            v-model="filterObject.depot"
          />
        </v-col>
      </v-row>
    </template>

    <template #filterAction>
      <v-btn
        color="secondary"
        depressed
        name="vehicle-filter-reset"
        @click="$emit('resetFilters'), (showTransporterVehicleFilter = false)"
      >
        Reset
      </v-btn>
      <v-btn
        color="primary"
        class="ml-4"
        depressed
        name="vehicle-filter-apply"
        @click="$emit('applyFilters'), (showTransporterVehicleFilter = false)"
      >
        Apply
      </v-btn>
    </template>
  </FilterParent>
</template>

<script>
import MultiSelect from "@/components/FormBaseComponents/MultiSelect.vue";
import FilterParent from "@/components/General/FilterParent.vue";

export default {
  components: {
    FilterParent,
    MultiSelect,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    bindingObject: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      allVehicleTypeList: [],
      allDepot: [],
    };
  },
  watch: {
    value(val) {
      if (val) {
        this.getVehicleList();
        this.getDepotList();
      }
    },
  },
  computed: {
    showTransporterVehicleFilter: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    filterObject: {
      get() {
        return this.bindingObject;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    getDepotList(params = {}) {
      params = {
        limit: "all",
      };
      this.$api.depot
        .getDepotList(params)
        .then((res) => {
          this.allDepot = res.data;
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    getVehicleList() {
      this.$bus.$emit("showLoader", true);
      this.$api.vehicleType
        .getVehicleTypeList({ limit: "all" })
        .then((res) => {
          this.allVehicleTypeList = res.data;
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
  },
};
</script>
