<template>
  <v-row class="ma-0">
    <v-col
      cols="3"
      class="pa-0"
      v-if="
        (this.params.data && this.params.data.status == 'Pending') ||
        this.params.data.status == 'Approved' ||
        this.params.data.status == 'Invoice Updated' ||
        this.params.data.status == 'Completed'
      "
    >
      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-icon
            class="mr-4 ml-1"
            color="primary"
            v-on="on"
            v-bind="attrs"
            @click="viewItem"
          >
            mdi-eye
          </v-icon>
        </template>
        <span>View</span>
      </v-tooltip>
    </v-col>

    <v-col
      cols="3"
      class="pa-0"
      v-if="
        this.params.data.status == 'Created' ||
        this.params.data.status == 'Pending' ||
        this.params.data.status == 'Approved'
      "
    >
      <v-tooltip top>
        <template #activator="{ on, attrs }">
          <v-icon
            v-if="
              params &&
              params.context &&
              params.context.parentComponent &&
              params.context.parentComponent.permissions &&
              params.context.parentComponent.permissions.change
            "
            class="mr-4 ml-1"
            color="primary"
            :disabled="params.data.is_used"
            v-on="on"
            v-bind="attrs"
            @click="editItem"
          >
            mdi-pencil
          </v-icon>
        </template>
        <span>Edit</span>
      </v-tooltip>
    </v-col>

    <v-col
      cols="3"
      class="pa-0"
      v-if="this.params.data.status == 'Invoice Updated'"
    >
      <v-tooltip top>
        <template #activator="{ on, attrs }">
          <v-icon
            v-if="
              params &&
              params.context &&
              params.context.parentComponent &&
              params.context.parentComponent.permissions &&
              params.context.parentComponent.permissions.change
            "
            class="mr-4 ml-1"
            color="primary"
            :disabled="params.data.is_used"
            v-on="on"
            v-bind="attrs"
            @click="editInvoiceDetails"
          >
            mdi-pencil
          </v-icon>
        </template>
        <span>Edit</span>
      </v-tooltip>
    </v-col>

    <v-col cols="3" class="pa-0" v-if="this.params.data.status == 'Pending'">
      <v-tooltip top>
        <template #activator="{ on, attrs }">
          <v-icon
            color="success"
            v-on="on"
            v-bind="attrs"
            @click="updateStatus(params.data.id, 'Approved')"
            class="ml-2 mr-2"
            v-if="
              params &&
              params.context &&
              params.context.parentComponent &&
              params.context.parentComponent.permissions &&
              params.context.parentComponent.permissions.add &&
              params.data.is_all_legs_completed
            "
          >
            mdi-check
          </v-icon>
        </template>
        <span>Approved</span>
      </v-tooltip>
    </v-col>

    <v-col cols="3" class="pa-0" v-if="this.params.data.status == 'Approved'">
      <v-tooltip top>
        <template #activator="{ on, attrs }">
          <v-icon
            color="success"
            v-on="on"
            v-bind="attrs"
            @click="updateStatus(params.data.id, 'Invoice Updated')"
            class="ml-2 mr-2"
            v-if="
              params &&
              params.context &&
              params.context.parentComponent &&
              params.context.parentComponent.permissions &&
              params.context.parentComponent.permissions.add
            "
          >
            mdi-check
          </v-icon>
        </template>
        <span>Update the invoice details</span>
      </v-tooltip>
    </v-col>

    <v-col
      cols="3"
      class="pa-0"
      v-if="this.params.data.status == 'Invoice Updated'"
    >
      <v-tooltip top>
        <template #activator="{ on, attrs }">
          <v-icon
            color="success"
            v-on="on"
            v-bind="attrs"
            @click="updateStatus(params.data.id, 'Completed')"
            class="ml-2 mr-2"
            v-if="
              params &&
              params.context &&
              params.context.parentComponent &&
              params.context.parentComponent.permissions &&
              params.context.parentComponent.permissions.add
            "
          >
            mdi-check
          </v-icon>
        </template>
        <span>Complete the sub hire job</span>
      </v-tooltip>
    </v-col>

    <v-col cols="3" class="pa-0" v-if="this.params.data.status == 'Created'">
      <v-tooltip top>
        <template #activator="{ on, attrs }">
          <v-icon
            class="ml-2 mr-1"
            color="primary"
            v-on="on"
            v-bind="attrs"
            @click="createBid"
          >
            mdi-gavel
          </v-icon>
        </template>
        <span>Create Bid</span>
      </v-tooltip>
    </v-col>

    <v-col cols="3" class="pa-0">
      <v-tooltip top>
        <template #activator="{ on, attrs }">
          <v-icon
            class="ml-2 mr-1"
            color="primary"
            v-on="on"
            v-bind="attrs"
            @click="viewLogs"
          >
            mdi-information
          </v-icon>
        </template>
        <span>Sub Hire Job Logs</span>
      </v-tooltip>
    </v-col>
  </v-row>
</template>

<script>
export default {
  methods: {
    createBid() {
      this.params.context.parentComponent.createBid(this.params.data);
    },
    viewItem() {
      this.params.context.parentComponent.viewSubHire(this.params.data.id);
    },
    editItem() {
      this.params.context.parentComponent.editSubHire(
        this.params.data.id,
        this.params.data.first_leg_id,
        this.params.data.status,
        this.params.data.customer
      );
    },
    editInvoiceDetails() {
      this.params.context.parentComponent.editSubHireInvoice(
        this.params.data.id,
        this.params.data.status,
        this.params.data.invoice_no,
        this.params.data.invoice_date
      );
    },
    updateStatus(id, status) {
      if (status == "Approved") {
        this.params.context.parentComponent.apporvedSubHire(id, status);
      } else if (status == "Invoice Updated") {
        this.params.context.parentComponent.invoiceUpdatedSubHire(id, status);
      } else if (status == "Completed") {
        this.params.context.parentComponent.subHireCompleted(id, status);
      }
    },
    viewLogs() {
      this.params.context.parentComponent.viewSubHireLogs(this.params.data.id);
    },
  },
};
</script>

<style>
</style>