import TermsCondition from "@/pages/customer/rate/terms_condition/TermsConditionList.vue";

const TermsConditionRoutes = [
  {
    path: "terms_condition",
    name: "app-customer-rate-terms_condition",
    component: TermsCondition,
  },
];

export default TermsConditionRoutes;
