<template>
  <v-dialog fullscreen v-model="showMarkDAReadyForInvoiceForm">
    <v-card>
      <v-card-title class="secondary white--text mb-4 rounded-0">
        <span> Order Documents </span>

        <v-spacer></v-spacer>

        <v-btn icon @click="showMarkDAReadyForInvoiceForm = false">
          <v-icon class="white--text">mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <AgGridVue
          style="width: 100%; height: calc(100vh - 320px)"
          class="ag-theme-alpine"
          id="myGrid"
          :columnDefs="headers"
          :context="context"
          @grid-ready="onGridReady"
          rowSelection="multiple"
          @selection-changed="setSelectedRows"
          :defaultColDef="defaultColDef"
          :grid-options="gridOptions"
          :suppressDragLeaveHidesColumns="true"
          :rowData="allRowList"
        ></AgGridVue>

        <v-col v-if="totalItems > 0" cols="12" class="d-flex justify-end">
          <Pagination
            :pageNo="pageNo"
            :totalItems="totalItems"
            :pageSize="itemsPerPage"
            @itemsPerPageChange="itemsPerPageChanged"
            @prevPage="prevPage"
            @nextPage="nextPage"
          />
        </v-col>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { getFileNameFromUrl, downloadBlobData } from "@/utils/functions.js";

import { AgGridVue } from "ag-grid-vue";
import CustomerPODButton from "@/components/AgGridComponents/CustomerPODButton.vue";

export default {
  components: {
    AgGridVue,
    CustomerPODButton,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    daID: {
      type: [Number, Object],
      default: null,
    },
  },
  computed: {
    showMarkDAReadyForInvoiceForm: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    context() {
      return { parentComponent: this };
    },
    offset() {
      return this.itemsPerPage * (this.pageNo - 1);
    },
  },
  watch: {
    value(val) {
      if (val) {
        let daIDDoc = this.daID;
        this.getAttachments(daIDDoc);
      }
    },
  },
  data() {
    return {
      totalItems: 0,
      attachmentsList: [],

      allRowList: [],

      // ag-grid vars
      defaultColDef: {
        resizable: true,
      },
      gridOptions: {
        onGridSizeChanged: () => {
          this.resizeGrid();
        },
        isRowSelectable: (rowNode) => {
          if (
            rowNode.data &&
            rowNode.data.payment_term == "Cash" &&
            !rowNode.data.payment_receipt
          ) {
            return false;
          } else {
            return true;
          }
        },
        suppressRowClickSelection: true,
        suppressDragLeaveHidesColumns: true,
        enableCellTextSelection: true,
        tooltipShowDelay: 0,
        rowHeight: 35,
      },
      headers: [
        {
          headerName: "Document Type",

          valueGetter: () => "POD", // Static value for each row
          minWidth: 60,
        },
        {
          headerName: "Document",
          field: "pod_title",
          minWidth: 60,
        },
        {
          headerName: "Created By",
          field: "created_by",
          minWidth: 60,
        },
        {
          headerName: "Creates Date",
          field: "created",
          minWidth: 60,
          valueFormatter: (params) => {
            return this.$globals.formatDate(params.value, true);
          },
        },
        {
          headerName: "Actions",
          field: "actions",
          cellRendererFramework: "CustomerPODButton",
          pinned: "right",
          width: 120,
        },
      ],
    };
  },
  methods: {
    viewDeliveryAdviceDocument(data) {
      window.open(data.pod, "_blank");
    },
    async downloadAttachment(data) {
      this.$bus.$emit("showLoader", true);

      try {
        const response =
          await this.$api.customerOrder.getDownloadDocumentsBlopObject(data.id);

        this.$bus.$emit("showLoader", false);

        const contentType = response.data.type;
        const fileExtension = response.data.type.split("/")[1];
        const fileName = this.getFileNameFromUrl(data.pod);
        downloadBlobData(
          response.data,
          `${this.getFileNameFromUrl(data.pod).split(".")[0]}`,
          contentType
        );
      } catch (err) {
        console.error(err);
        this.$bus.$emit("showLoader", false);
      }
    },
    setSelectedRows() {
      this.selectedRows = this.gridApi.getSelectedRows();
      this.daCount = this.selectedRows.length;
    },
    resizeGrid() {
      if (this.gridOptions && "api" in this.gridOptions) {
        setTimeout(() => {
          this.gridOptions.api.sizeColumnsToFit();
        }, 100);
      }
    },

    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.resizeGrid();
    },
    headersChanged(value) {
      this.headerSelected = value;
      this.resizeGrid();
    },
    getFileNameFromUrl,

    getAttachments(id) {
      this.attachmentsList = [];

      this.$bus.$emit("showLoader", true);
      let params = {
        da_id: id,
      };

      this.$api.customerOrder
        .getCustomerOrderPODAttachments(params)
        .then((res) => {
          this.allRowList = res.data;
          this.totalItems = res.count;

          this.attachmentsList = res.data.filter(
            (attachment) => attachment.urls && attachment.urls.length
          );
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
  },
};
</script>

