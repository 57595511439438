<template>
  <v-dialog max-width="65%" v-model="showUserForm">
    <v-card>
      <v-card-title class="secondary white--text">
        <span class="text-capitalize">Create New User</span>
        <v-spacer></v-spacer>
        <v-btn icon @click="resetForm(), $emit('closeUserForm')" color="white">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-form v-if="showUserForm" v-model="isValid" class="py-6">
          <!-- Input fields and form controls -->
          <v-row>
            <v-col cols="12" lg="6">
              <InputField
                type="text"
                v-model="userDetails.username"
                :rules="[(val) => !!val || 'Username is required!']"
                label="Username*"
                required
                :error-messages="formErrors.username"
                @input="delete formErrors.username"
              />
            </v-col>

            <v-col cols="12" lg="6">
              <InputField
                :type="isTextInput ? 'text' : 'password'"
                :rules="[(val) => !!val || 'Password is required!']"
                :label="'Password*'"
                :append-icon="!isTextInput ? 'mdi-eye-off' : 'mdi-eye'"
                required
                v-model="userDetails.password"
                @click:append="isTextInput = !isTextInput"
                :error-messages="formErrors.password"
                @input="delete formErrors.password"
              />
            </v-col>

            <v-col cols="12" lg="6" class="Form-Columns-Dense">
              <Autocomplete
                :loading="listLoading"
                :totalItems="customerCount"
                :items="allCustomerList"
                @updateList="getCustomerList"
                label="Select Customer *"
                itemText="company_name"
                itemValue="id"
                :rules="[(v) => !!v || 'Customer is Required']"
                hide-details="auto"
                v-model="userDetails.company"
                :error-messages="formErrors.company"
                @input="delete formErrors.company"
              />
            </v-col>

            <v-col cols="12" lg="6">
              <InputField
                readonly
                class="grey lighten-3"
                type="text"
                label="Company Name*"
                required
                v-model="userDetails.company_name"
                :error-messages="formErrors.company_name"
                @input="delete formErrors.company_name"
              />
            </v-col>

            <v-col cols="12" lg="6">
              <InputField
                readonly
                class="grey lighten-3"
                type="text"
                label="First Name*"
                required
                v-model="userDetails.first_name"
                :error-messages="formErrors.first_name"
                @input="delete formErrors.first_name"
              />
            </v-col>

            <v-col cols="12" lg="6">
              <InputField
                readonly
                class="grey lighten-3"
                type="text"
                label="Last Name*"
                required
                v-model="userDetails.last_name"
                :error-messages="formErrors.last_name"
                @input="delete formErrors.last_name"
              />
            </v-col>

            <v-col cols="12" lg="6">
              <InputField
                readonly
                class="grey lighten-3"
                type="text"
                :rules="[(val) => !!val || 'Designation is required!']"
                label="Designation*"
                required
                v-model="userDetails.designation"
                :error-messages="formErrors.designation"
                @input="delete formErrors.designation"
              />
            </v-col>

            <v-col cols="12" lg="2">
              <MultiSelect
                readonly
                class="grey lighten-3"
                :items="allPhoneCodeList"
                :rules="[(val) => !!val || 'Phone code is required!']"
                label="Phone code*"
                required
                v-model="userDetails.phone_code"
                :error-messages="formErrors.phone_code"
                @change="delete formErrors.phone_code"
              />
            </v-col>
            <v-col cols="12" lg="4">
              <InputField
                readonly
                type="number"
                class="grey lighten-3"
                :rules="[(val) => !!val || 'Contact Number is required!']"
                label="Contact Number*"
                required
                v-model="userDetails.contact_number"
                :error-messages="formErrors.contact_number"
                @input="delete formErrors.contact_number"
              />
            </v-col>

            <v-col cols="12" lg="6">
              <InputField
                readonly
                type="text"
                class="grey lighten-3"
                :rules="[
                  (val) => !!val || 'E-mail is required!',
                  (val) => /.+@.+\..+/.test(val) || 'E-mail must be valid',
                ]"
                label="Email*"
                required
                v-model="userDetails.email"
                :error-messages="formErrors.email"
                @input="delete formErrors.email"
              />
            </v-col>
          </v-row>
        </v-form>
        <v-divider></v-divider>
      </v-card-text>

      <v-card-actions class="d-flex justify-end">
        <v-btn
          color="primary"
          depressed
          :disabled="!isValid"
          @click="submitForm"
        >
          Submit
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import InputField from "@/components/FormBaseComponents/InputField.vue";
import MultiSelect from "@/components/FormBaseComponents/MultiSelect.vue";
import { encryptText } from "@/utils/functions";
import Autocomplete from "@/components/FormBaseComponents/Autocomplete.vue";

export default {
  components: {
    InputField,
    MultiSelect,
    Autocomplete,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    module: {
      type: String,
      required: true,
    },
    newUserId: {
      type: Number,
    },
  },
  computed: {
    showUserForm: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  data() {
    return {
      userDetails: {},
      formErrors: {},
      allPhoneCodeList: [],
      allCustomerList: [],
      isValid: true,
      isTextInput: false,
      listLoading: false,

      customerCount: 0,
    };
  },
  watch: {
    value(val) {
      if (val && this.newUserId != null) {
        this.getNewUserDetails(this.newUserId);
      }
      this.getPhoneCodeList();
      this.getCustomerList();
    },
  },
  methods: {
    getCustomerList(params = {}) {
      this.listLoading = true;
      params = {
        company_type: "Customer",
        offset: 0,
        limit: 10,
        ...params,
      };
      this.$api.company
        .getCompanySelectList(params)
        .then((res) => {
          this.listLoading = false;
          if ("count" in res) {
            this.customerCount = res.count;
          }

          if (params.offset == 0) {
            this.allCustomerList = res.data;
          } else {
            this.allCustomerList.push(...res.data);
          }
        })
        .catch(() => {
          this.listLoading = false;
        });
    },
    getNewUserDetails(id) {
      this.$bus.$emit("showLoader", true);
      this.$api.unauthorisedApi
        .getNewUserObject(id)
        .then((res) => {
          this.$bus.$emit("showLoader", false);
          this.userDetails = res.data;
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    getPhoneCodeList(params = {}) {
      params = {
        ...params,
        limit: "all",
      };
      this.$api.country
        .getCountryList(params)
        .then((res) => {
          this.allPhoneCodeList = res.data.map((obj) => obj.phone_code);
        })
        .catch((err) => {
          console.error(err);
        });
    },
    updateNewUser() {
      console.log(this.userDetails, "here");
      this.$bus.$emit("showLoader", true);
      let payload = {
        id: this.userDetails.id,
        data: { is_active: true },
      };

      this.$api.unauthorisedApi
        .updateUser(payload)
        .then((res) => {
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message: "User Updated Successfully!",
            color: "success",
          });
        })
        .catch((err) => {
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message: "Couldn't Update User!",
            color: "error",
          });
          this.formErrors = err.data;
        });
    },
    submitForm() {
      this.$bus.$emit("showLoader", true);
      let payload;
      console.log(this.userDetails);
      payload = {
        ...this.userDetails,
        is_active: "Yes",
      };
      if (payload.company && typeof payload.company == "object") {
        payload.company = payload.company.id;
      }
      if (this.userDetails && this.userDetails.password) {
        payload.password = encryptText(this.userDetails.password);
      }
      this.$api.user
        .addUser(payload)
        .then((res) => {
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message: "User created successfully!",
            color: "success",
          });
          this.updateNewUser();
          this.$emit("refreshList");
          this.resetForm();
          this.showUserForm = false;
        })
        .catch((err) => {
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message: "Couldn't create user!",
            color: "error",
          });
          this.formErrors = err.data;
        });
    },
    resetForm() {
      this.userDetails = {};
    },
  },
};
</script>
