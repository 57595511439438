<template>
  <v-autocomplete
    :items="allHeaders"
    v-model="selectedValues"
    label="Selected Header Show"
    :item-text="itemText"
    :item-value="itemValue"
    multiple
    dense
    outlined
    hide-details="auto"
  >
    <template v-slot:selection="{ item, index }">
      <v-chip v-if="index === 0" small>
        <span>{{ `${itemText}` in item ? item[itemText] : item }}</span>
      </v-chip>
      <span v-if="index === 1" class="grey--text text-caption">
        +{{ selectedValues.length - 1 }} others
      </span>
    </template>
  </v-autocomplete>
</template>

<script>
import { getAllUserPreferances, getUserPreferances } from "@/utils/functions";

export default {
  props: {
    allHeaders: {
      type: Array,
      default: () => [],
    },
    itemText: {
      type: String,
    },
    itemValue: {
      type: String,
    },
    selectedHeaders: {
      type: Array,
      default: () => [],
    },
    localStorageKey: {
      type: String,
    },
  },
  computed: {
    selectedHeadersObject() {
      return this.allHeaders.filter((header) => {
        return this.selectedValues.indexOf(header.field) > -1;
      });
    },
  },
  data() {
    return {
      selectedValues: [],
    };
  },
  watch: {
    selectedValues(val) {
      this.$emit("headersChanged", this.selectedHeadersObject);
      this.syncPreferences(val);
    },
  },
  methods: {
    syncPreferences(value) {
      if (this.localStorageKey) {
        let allPrefrence = getAllUserPreferances();

        allPrefrence[this.localStorageKey] = value;

        localStorage.setItem("userPreferences", JSON.stringify(allPrefrence));
        this.$api.user
          .setUserPreferences(allPrefrence)
          .then((res) => {})
          .catch((err) => {});
      }
    },
    populateHeaders() {
      let headers;
      if (this.localStorageKey && this.localStorageKey.length) {
        headers = getUserPreferances(this.localStorageKey);
      }

      if (!headers || !headers.length) {
        this.selectedValues = this.allHeaders.map((header) => header.field);
      } else {
        this.selectedValues = headers;
      }
    },
  },
  created() {
   if (typeof window !== 'undefined') {
    this.populateHeaders();
  }
  },
};
</script>