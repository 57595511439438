<template>
  <FilterParent module="Bid" maxWidth="500" v-model="showAdminUserFilters">
    <template #filterContent>
      <v-row class="ma-0">
        <v-col cols="7" lg="7" class="Form-Columns mt-0">
          <Autocomplete
            :loading="listLoading"
            :totalItems="laneCount"
            :items="allLaneList"
            @updateList="getLaneList"
            label="Route *"
            itemText="title"
            itemValue="id"
            v-model="filterObject.lane"
          />
        </v-col>
        <v-col cols="5" lg="5" class="pa-2">
          <DateField
            :inputProps="{
              dense: true,
              label: 'End Date ',
              'hide-details': 'auto',
            }"
            v-model="filterObject.end_date"
          />
        </v-col>
        <v-col cols="6" lg="6" class="pa-2">
          <MultiSelect
            hide-details="auto"
            :items="allContainersType"
            label="Select Container Type"
            itemText="type_name"
            itemValue="id"
            v-model="filterObject.container_category"
          />
        </v-col>
        <v-col cols="6" lg="6" class="Form-Columns">
          <MultiSelect
            label="Select Job Type"
            :items="allJobType"
            v-model="filterObject.job_type"
          />
        </v-col>
        
      </v-row>
    </template>

    <template #filterAction>
      <v-btn
        depressed
        color="secondary"
        @click="$emit('resetFilters'), (showAdminUserFilters = false)"
      >
        Reset
      </v-btn>

      <v-btn
        color="primary"
        class="ml-4"
        depressed
        @click="$emit('applyFilters'), (showAdminUserFilters = false)"
      >
        Apply
      </v-btn>
    </template>
  </FilterParent>
</template>

<script>
import DateField from "@/components/FormBaseComponents/DateField.vue";

import FilterParent from "@/components/General/FilterParent.vue";
import Autocomplete from "@/components/FormBaseComponents/Autocomplete.vue";
import MultiSelect from "@/components/FormBaseComponents/MultiSelect.vue";

export default {
  components: {
    FilterParent,
    MultiSelect,
    DateField,
    Autocomplete,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    bindingObject: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      allLaneList: [],
      allJobType: [],
      allContainersType: [],
      listLoading: false,
      laneCount: 0,
    };
  },
  watch: {
    value(val) {
      if (val) {
        this.getLaneList();
        this.getContainersType();
        this.getCustomerContractOptions();
      }
    },
  },
  computed: {
    showAdminUserFilters: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    filterObject: {
      get() {
        return this.bindingObject;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    getCustomerContractOptions() {
      this.$bus.$emit("showLoader", true);
      this.$api.customerContract
        .getCustomerContractOptions()
        .then((res) => {
          this.allJobType = res.data.actions.POST.job_type.choices.map(
            (obj) => obj.value
          );
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    getContainersType() {
      return this.$api.containerType
        .getContainerTypeSelectList({ limit: "all" })
        .then((res) => {
          this.allContainersType = res.data;
        })
        .catch(() => {
          this.$bus.$emit("showLoader", false);
        });
    },
    getLaneList(params = {}) {
      this.listLoading = true;
      params = {
        is_active: true,
        limited: true,
        offset: 0,
        limit: 10,
        ...params,
      };

      this.$api.lane
        .getLaneList(params)
        .then((res) => {
          this.listLoading = false;
          if ("count" in res) {
            this.laneCount = res.count;
          }

          if (params.offset == 0) {
            this.allLaneList = res.data;
          } else {
            this.allLaneList.push(...res.data);
          }
        })
        .catch((err) => {
          this.listLoading = false;
        });
    },
  },
};
</script>

<style></style>
