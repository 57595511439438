<template>
  <v-row class="ma-0">
    <v-col cols="3" class="pa-0">
      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-icon
            class="mr-4 ml-1"
            color="primary"
            v-on="on"
            v-bind="attrs"
            @click="viewItem"
          >
            mdi-eye
          </v-icon>
        </template>
        <span>View </span>
      </v-tooltip>
    </v-col>

    <v-col
      cols="3"
      class="pa-0"
      color="primary"
      v-if="
        this.params.data.status == 'Pending' ||
        this.params.data.status == 'Participated'
      "
    >
      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-icon color="primary" class="mr-2 ml-1" v-on="on" v-bind="attrs" @click="editItem">
           mdi-gavel
          </v-icon>
        </template>
        <span>Submit Rate</span>
      </v-tooltip>
    </v-col>
  </v-row>
</template>

<script>
export default {
  methods: {
    viewItem() {
      this.params.context.parentComponent.viewBidDetails(this.params.data);
    },

    editItem() {
      this.params.context.parentComponent.editBidDetails(this.params.data);
    },
  },
};
</script>

<style>
</style>