<template>
  <v-app-bar
    v-bind="$attrs"
    :clipped-left="true"
    :clipped-right="true"
    fixed
    app
    elevation="2"
  >
    <v-app-bar-nav-icon @click.stop="$emit('toggleDrawer')" />

    <!-- Brand Logo -->
    <img
      class="Brand-Logo"
      alt="Brand Logo"
      src="@/assets/images/brand-logo.png"
    />
    <v-spacer></v-spacer>
    <!-- Notification -->
    <v-menu
      close-on-content-click
      max-width="400px"
      nudge-left="345"
      offset-y
      content-class="Messages-Menu"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-badge class="mx-2" dot top overlap :value="unreadMessages.length">
          <v-icon class="" v-on="on" v-bind="attrs">
            mdi-message-text-outline
          </v-icon>
        </v-badge>
      </template>

      <v-card width="400px" elevation="0">
        <v-card-title class="primary px-4 py-2">
          <span class="text-body-1 white--text">New Messages</span>
        </v-card-title>
        <v-divider />
        <v-card-text
          style="max-height: 350px"
          class="pa-0 overflow-y-auto"
          @scroll="handleMessageHistoryScroll"
          id="messageNotificationCard"
        >
          <v-list class="pa-0" v-if="!unreadMessages.length">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-subtitle> No new messages </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-list v-else class="pa-0" two-line>
            <v-list-item
              :class="{ 'border-top-light': index != 0 }"
              v-for="(message, index) in unreadMessages"
              :key="index"
              @click="$emit('openChat', message.trip_pk)"
            >
              <v-list-item-content>
                <v-list-item-title class="py-1 font-weight-bold">
                  {{ message.trip_id }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ message.company_name }}
                </v-list-item-subtitle>

                <v-list-item-subtitle>
                  <span class="font-weight-bold">
                    {{ message.sender_type }} | {{ message.sender_name }}:
                  </span>
                  <span>
                    {{
                      `${
                        message.message_format == "Text"
                          ? message.message
                          : "Received Attachment"
                      }`
                    }}
                  </span>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-menu>
    <v-list class="py-1 grey lighten-4">
      <v-list-item class="px-2" dense>
        <v-menu
          v-model="openNotifications"
          :close-on-content-click="false"
          max-width="400px"
          nudge-left="345"
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-badge
              :content="unreadCount"
              :color="unreadCount == 0 ? '' : 'green'"
              class="mt-1"
              top
              overlap
            >
              <span>
                <v-btn
                  icon
                  v-on="on"
                  v-bind="attrs"
                  :class="openNotifications ? 'text-primary' : ''"
                >
                  <v-icon>mdi-bell</v-icon>
                </v-btn>
              </span>
            </v-badge>
          </template>
          <v-card width="400px" elevation="0">
            <v-card-title
              class="pt-4 pb-2 text-primary body-1 d-flex justify-space-between"
            >
              <v-col cols="6">
                <span>Notifications</span>
              </v-col>
              <v-col cols="6" class="text-right pa-0">
                <v-btn
                  icon
                  @click="clearAllCompanyNotifications()"
                  v-if="companyNotifications && companyNotifications.length > 0"
                >
                  <v-icon>mdi-close-circle-outline</v-icon>
                </v-btn>
              </v-col>
            </v-card-title>
            <v-card-text
              elevation-0
              style="max-height: 350px"
              class="pa-0 overflow-y-auto overflow-x-hidden"
              @scroll="handleScroll"
              id="notificationCard"
              ref="notificationParent"
            >
              <div v-if="noNewNotifications" class="d-flex justify-center py-2">
                <span>No new Notifications!</span>
              </div>
              <div v-for="(noti, i) in companyNotifications" :key="i" v-else>
                <v-row
                  :class="[
                    'pl-4',
                    'pa-0',
                    'my-1',
                    noti.is_read ? 'grey--text' : '',
                  ]"
                  :style="
                    !noti.is_read
                      ? { backgroundColor: '#feeee4', cursor: 'pointer' }
                      : { backgroundColor: 'white', cursor: 'pointer' }
                  "
                >
                  <v-col cols="12" class="pa-0 ma-0">
                    <hr style="opacity: 0.4" />
                  </v-col>
                  <v-col cols="2" class="d-flex align-center justify-center">
                    <div
                      style="
                        display: flex;
                        justiy-cotent: center;
                        align-items: center;
                        padding: 15px;
                      "
                    >
                      <v-icon color="primary" v-if="noti.module_name == 'Order'"
                        >mdi-inbox-multiple</v-icon
                      >
                      <v-icon
                        color="primary"
                        v-else-if="noti.module_name == 'Trip'"
                        >mdi-bus-clock</v-icon
                      >
                      <v-icon
                        color="primary"
                        v-else-if="noti.module_name == 'Legpod'"
                        >mdi-truck-fast</v-icon
                      >
                      <v-icon
                        color="primary"
                        v-else-if="noti.module_name == 'Customer Contract'"
                        >mdi-briefcase</v-icon
                      >
                      <v-icon
                        color="primary"
                        v-else-if="noti.module_name == 'TripMilestone'"
                        >mdi-bus-clock</v-icon
                      >
                      <v-icon
                        color="primary"
                        v-else-if="noti.module_name == 'Quotation'"
                        >mdi-text-box-check-outline</v-icon
                      >
                      <v-icon
                        color="primary"
                        v-else-if="noti.module_name == 'Report'"
                        >mdi-finance</v-icon
                      >
                       <v-icon
                        color="primary"
                        v-else-if="noti.module_name == 'Bid'"
                        >mdi-gavel</v-icon
                      >
                        <v-icon
                        color="primary"
                        v-else-if="noti.module_name == 'LRFQ'"
                        >mdi-file-delimited</v-icon
                      >
                    </div>
                  </v-col>
                  <v-col
                    cols="8"
                    class="d-flex align-center"
                    @click="companyNotificationMarkAsRead(noti)"
                  >
                    <v-row no-gutters>
                      <v-col cols="12" class="text-body-2">
                        <span>
                          {{ noti.title }}
                        </span>
                      </v-col>
                      <v-col cols="12" class="text-caption">
                        {{ noti.message }}
                      </v-col>
                      <v-col
                        cols="12"
                        class="text-caption font-weight-bold mt-1"
                      >
                        {{ noti.created }}
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="2" class="d-flex align-center">
                    <v-btn
                      small
                      icon
                      class="primary--text"
                      @click="companyNotificationMarkAsCleared(noti)"
                    >
                      <v-icon small>mdi-close</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
            </v-card-text>
          </v-card>
        </v-menu>
      </v-list-item>
    </v-list>

    <!-- <v-btn icon @click="$emit('showRightDrawer')" class="d-none">
      <v-icon>mdi-tune</v-icon>
    </v-btn> -->

    <v-menu offset-y v-if="currentProfile" content-class="Profile-Menu">
      <template v-slot:activator="{ on, attrs }">
        <table v-bind="attrs" v-on="on">
          <tbody>
            <tr>
              <td rowspan="2" class="pr-2">
                <v-avatar color="primary" size="36">
                  <v-icon dark> mdi-account-circle </v-icon>
                </v-avatar>
              </td>
              <th class="text-left">{{ currentProfile.username }}</th>
            </tr>
            <tr>
              <td class="text-left">{{ currentProfile.company_name }}</td>
            </tr>
          </tbody>
        </table>
      </template>
      <v-card>
        <v-card-title class="py-2">
          <span>Profile</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <table class="Normal-Table">
            <tbody>
              <tr>
                <td>Name</td>
                <th class="px-2">:</th>
                <th>
                  {{ currentProfile.first_name }}
                  {{ currentProfile.last_name }}
                </th>
              </tr>
              <tr>
                <td>Company Name</td>
                <th class="px-2">:</th>
                <th>{{ currentProfile.company_name }}</th>
              </tr>
              <tr>
                <td>Role</td>
                <th class="px-2">:</th>
                <th>{{ currentProfile.role_name }}</th>
              </tr>
              <tr>
                <td>Username</td>
                <th class="px-2">:</th>
                <th>{{ currentProfile.username }}</th>
              </tr>
              <tr>
                <td>Email</td>
                <th class="px-2">:</th>
                <th>{{ currentProfile.email }}</th>
              </tr>
              <tr>
                <td>Contact No</td>
                <th class="px-2">:</th>
                <th>{{ currentProfile.full_contact_number }}</th>
              </tr>
            </tbody>
          </table>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="py-1">
          <v-list dense class="Full-Width">
            <v-list-item @click="showChangePasswordDialog = true">
              <v-list-item-icon>
                <v-icon>mdi-key-variant</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="text-button">
                  Change Password
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="logout">
              <v-list-item-icon>
                <v-icon>mdi-power</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="text-button">
                  Logout
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-actions>
      </v-card>
    </v-menu>

    <ChangePasswordDialog
      v-model="showChangePasswordDialog"
    ></ChangePasswordDialog>
  </v-app-bar>
</template>

<script>
import { isImpersonating } from "@/utils/functions";
import ChangePasswordDialog from "@/components/ModuleBased/dialogForms/ChangePasswordDialog.vue";

export default {
  components: {
    ChangePasswordDialog,
  },
  props: {
    companyNotifications: {
      type: Array,
      default: () => [],
    },
    unreadMessages: {
      type: Array,
      default: () => [],
    },
    unreadCount: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      title: "Gulftainer",
      currentProfile: null,
      ltPermission: null,
      noNewNotifications: false,
      showChangePasswordDialog: false,
    };
  },
  computed: {
    openNotifications: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    handleMessageHistoryScroll() {},

    clearAllCompanyNotifications(payload = {}) {
      if (confirm("Are you sure you want to clear all notifications?")) {
        this.$bus.$emit("showLoader", true);

        this.$api.company
          .clearAllCompanyNotification(payload)
          .then((res) => {
            this.$bus.$emit("showLoader", false);
            this.$emit("refreshNotificationHistory", { clearAll: true });
          })
          .catch(() => {
            this.$bus.$emit("showLoader", false);
          });
      }
    },
    handleScroll(ev) {
      let notificationCard = document.getElementById("notificationCard");
      const clientHeight = notificationCard.clientHeight;
      const scrollHeight = notificationCard.scrollHeight;
      const scrollTop = notificationCard.scrollTop;
      let refreshHistory = Math.ceil(clientHeight + scrollTop) == scrollHeight;
      if (refreshHistory) {
        this.$emit("getOldNotifications", refreshHistory);
      }
    },
    companyNotificationMarkAsRead(notification) {
      this.$bus.$emit("showLoader", true);

      this.$api.company
        .companyNotificationMarkAsRead({
          id: notification.id,
          data: {},
        })
        .then((res) => {
          this.$bus.$emit("showLoader", false);
          this.$router.push({
            path: notification.redirect_link,
          });
          this.$emit("refreshNotificationHistory", { clearAll: true });
        })
        .catch(() => {
          this.$bus.$emit("showLoader", false);
        });
    },
    companyNotificationMarkAsCleared(notification) {
      this.$bus.$emit("showLoader", true);

      this.$api.company
        .companyNotificationMarkAsCleared({
          id: notification.id,
          data: {},
        })
        .then((res) => {
          this.$bus.$emit("showLoader", false);
          this.$emit("refreshNotificationHistory", { clearAll: true });
        })
        .catch(() => {
          this.$bus.$emit("showLoader", false);
        });
    },
    logout() {
      const config = {
        status: "error",
        title: "Logout",
        text: "Are you sure you want to Logout?",
      };
      this.$confirm(config)
        .then(() => {
          this.$bus.$emit("showLoader", true);
          this.$api.authentication
            .logout()
            .then(() => {
              this.$bus.$emit("showLoader", false);
              if (isImpersonating()) {
                localStorage.removeItem("i_user");
                this.loadData();
              } else {
                localStorage.clear();
                this.$router.push("/app/login");
              }
              this.$emit("closeWebSocket");
            })
            .catch((err) => {
              console.error(err);
              this.$bus.$emit("showLoader", false);
            });
        })
    },
    loadData() {
      this.$bus.$emit("showLoader", true);
      this.$api.authentication
        .getProfileInfo()
        .then((res) => {
          this.$bus.$emit("showLoader", false);
          localStorage.setItem("userProfile", JSON.stringify(res.data));
          localStorage.setItem("user_type", res.data.user_type);
          localStorage.setItem(
            "permissions",
            JSON.stringify(res.data.permissions)
          );
          const preference = res?.data?.user_preferences || {};
          localStorage.setItem("userPreferences", JSON.stringify(preference));
          // this.$router.push(`/app/${res.data.user_type.toLowerCase()}`);
        })
        .catch((err) => {
          this.$bus.$emit("showLoader", false);
        });
    },
  },
  mounted() {
    let interval = setInterval(() => {
      let obj = JSON.parse(localStorage.getItem("userProfile"));
      if (obj) {
        clearInterval(interval);
        this.currentProfile = obj;
      }
    }, 500);
  },
};
</script>

<style scoped>
.Profile-Menu {
  margin-top: 10px;
}
</style>
