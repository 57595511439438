<template>
  <FilterParent
    module="Invoice"
    maxWidth="500"
    v-model="showAdminCustomerInvoiceFilters"
  >
    <template #filterContent>
      <v-row class="ma-0">
        <v-col cols="12">
          <MultiSelect
            hide-details="auto"
            label="Select Customer Company"
            :items="allCustomerList"
            itemText="company_name"
            itemValue="id"
            v-model="filterObject.customer"
          />
        </v-col>
        <v-col cols="12">
          <MultiSelect
            hide-details="auto"
            label="Select Invoice Type"
            :items="allInvoiceTypeList"
            itemText="text"
            itemValue="value"
            v-model="filterObject.invoice_type"
          />
        </v-col>
        <v-col cols="12">
          <MultiSelect
            hide-details="auto"
            label="Select Job Type"
            :items="allJobType"
            v-model="filterObject.job_type"
          />
        </v-col>

        <v-col cols="12" class="pb-0">
          <span class="text-h6 font-weight-medium">Invoice Date Filter</span>
        </v-col>

        <v-col cols="12">
          <InputField
            hide-details="auto"
            type="date"
            label="Select Start Date"
            v-model="filterObject.start_date"
          />
        </v-col>

        <v-col cols="12">
          <InputField
            hide-details="auto"
            type="date"
            label="Select End Date"
            v-model="filterObject.end_date"
          />
        </v-col>
      </v-row>
    </template>

    <template #filterAction>
      <v-btn
        color="secondary"
        depressed
        @click="
          $emit('resetFilters'), (showAdminCustomerInvoiceFilters = false)
        "
      >
        Reset
      </v-btn>
      <v-btn
        color="primary"
        class="ml-4"
        depressed
        @click="
          $emit('applyFilters'), (showAdminCustomerInvoiceFilters = false)
        "
      >
        Apply
      </v-btn>
    </template>
  </FilterParent>
</template>

<script>
import InputField from "@/components/FormBaseComponents/InputField.vue";
import MultiSelect from "@/components/FormBaseComponents/MultiSelect.vue";
import FilterParent from "@/components/General/FilterParent.vue";

export default {
  components: {
    FilterParent,
    MultiSelect,
    InputField,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    bindingObject: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      allCustomerList: [],
      allJobType: [],
      allInvoiceTypeList: [
        {
          text: "General",
          value: "General",
        },
        {
          text: "Bill Of Entry",
          value: "Bill Of Entry",
        },
        {
          text: "Miscellaneous",
          value: "Miscellaneous",
        },
      ],
    };
  },
  watch: {
    value(val) {
      if (val) {
        this.getCustomerList();
        this.getCustomerContractOptions();
      }
    },
  },
  computed: {
    showAdminCustomerInvoiceFilters: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    filterObject: {
      get() {
        return this.bindingObject;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    currentProfile() {
      let obj = JSON.parse(localStorage.getItem("userProfile"));
      if (!obj) {
        return null;
      } else {
        return obj;
      }
    },
  },
  methods: {
    getCustomerContractOptions() {
      this.$bus.$emit("showLoader", true);
      this.$api.customerContract
        .getCustomerContractOptions()
        .then((res) => {
          this.allJobType = res.data.actions.POST.job_type.choices.map(
            (obj) => obj.value
          );
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    getCustomerList() {
      this.$bus.$emit("showLoader", true);
      this.$api.company
        .getCompanyList({ limit: "all", company_type: "Customer" })
        .then((res) => {
          this.allCustomerList = res.data;
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
  },
};
</script>

<style></style>
